import lscache from "lscache";
import React, { useEffect, useState } from "react";
import { Paturi } from "../../../components";
import { useInterval } from "../../../hooks";
import { REST } from "../../../Utils/index";
import Tables from "./Components/Tables";
import Wrapper from "./Components/Wrapper";

const Listare_SefSectie = (props) => {
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [data, setData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [infoToAssign, setInfoToAssign] = useState(null);
  const [ticketFormState, setTicketFormState] = useState({
    values: data,
  });

  const [isAssignVisible, setIsAssignVisible] = useState(false);
  const [ticketIdForAssignToMedic, setTicketIdForAssignToMedic] = useState(null);

  const handleInfoToAssign = (e) => {
    setInfoToAssign(e);
    setIsAssignVisible(true);
  };

  const scrollToView = () => {
    if (isAssignVisible) {
      document.getElementById("assignToMed").scrollIntoView();
    }
  }

  useEffect(() => {
    getFormState();
    // eslint-disable-next-line
  }, [infoToAssign]);

  const getData = async (pageToLoad) => {
    await REST.get(`/spital/getAllTicketsForListing?page=${pageToLoad}&size=10`)
      .then((answer) => {
        setData(answer.data.content);

        setMaxPage(answer.data.totalPages);
        lscache.set("numberOfTickets", answer.data.numberOfElements);
        setIsPageLoading(false);
        // apel backend pentru returnare lista apeluri
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          setMaxPage(1);
        }
      });
  };

  const handleEditEntry = (id) => {
    window.location = `/chestionar-ss/${id}`;
  };

  const handleCheckEntry = async (id) => {
    if (lscache.get("operatorType") === "SefSectie") {
      await REST.put(
        `/spital/updateTicketStatus?&status=Registratura&ticketId=${id}`
      )
        .then((answer) => {
          getData(page);
        })
        .catch((err) => { });
    }
  };

  // eslint-disable-next-line
  const handleRejectEntry = async (id) => {
    if (lscache.get("operatorType") === "SefSectie") {
      await REST.put(
        `/spital/updateTicketStatus?&status=Operator C&ticketId=${id}`
      )
        .then((answer) => {
          getData(page);
        })
        .catch((err) => { });
    }
  };

  const getFormState = async () => {
    if (infoToAssign) {
      await REST.get(`/spital/getTicketById/?id=${infoToAssign}`)
        .then((answer) => {
          const allData = answer.data;
          setTicketFormState((formState) => ({
            ...formState,
            values: allData.form,
            tickedId: infoToAssign,
            // eslint-disable-next-line
            finalConfirmHour: allData.form["finalConfirmHour"],
          }));
        })
        .catch((err) => { });
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value - 1);
    getData(value - 1);
  };

  useEffect(() => {
    getData(0);
    // eslint-disable-next-line
  }, []);

  useInterval(() => {
    getData(page);
  }, 3000);

  if (isPageLoading) {
    return (
      <div className={"listareContent"}>
        <h1>Listare chestionare</h1>
        <div>
          <Wrapper>
            <Tables
              data={[]}
              page={page}
              handleChangePage={handleChangePage}
              maxPage={maxPage}
              handleInfoToAssign={handleInfoToAssign}
            />
          </Wrapper>
        </div>
      </div>
    );
  } else {
    return (
      <div className={"listareContent"}>
        <h1>Listare tichete</h1>
        <div>
          <Wrapper>
            <Tables
              data={data}
              page={page}
              handleEdit={handleEditEntry}
              handleCheck={handleCheckEntry}
              handleRejectEntry={handleRejectEntry}
              handleChangePage={handleChangePage}
              handleInfoToAssign={handleInfoToAssign}
              maxPage={maxPage}
              setTicketIdForAssignToMedic={setTicketIdForAssignToMedic}
            />
          </Wrapper>
        </div>
        <div id="assignToMed">
          {isAssignVisible && (
            <Paturi
              formState={ticketFormState}
              assignToMedic={true}
              ticketId={ticketIdForAssignToMedic}
              scrollToView={scrollToView}
            />
          )}
        </div>
      </div>
    );
  }
};

export default Listare_SefSectie;
