import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { REST } from "../../Utils/index";

const DRGTextInput = (props) => {
  const {
    item,
    formState,
    handleChange,
    hasError,
    handleBlur,
    hideLabel,
    index,
    myDrg,
  } = props;
  // eslint-disable-next-line
  const [error, setError] = useState({ message: null });
  // eslint-disable-next-line
  const [drg, setDrg] = useState(myDrg);
  const [inputs, setInputs] = useState({
    text:
      formState.values[item.key] &&
      (formState.values[item.key][index]
        ? formState.values[item.key][index]["text"]
        : formState.values[item.key]["text"]),
    drg:
      formState.values[item.key] &&
      (formState.values[item.key][index]
        ? formState.values[item.key][index]["drg"]
        : formState.values[item.key]["drg"]),
  });

  useEffect(() => {
    if (myDrg) {
      getDRGData(myDrg.drg).then((answer) => {
        if (answer !== null) {
          setInputs({
            drg: myDrg.drg,
            text: answer,
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [myDrg]);

  console.log(`myDrg`, myDrg)

  const getDRGData = async (drgCode) => {
    let drgText = null;
    await REST.get(`/spital/getDrgByDrgCode?drgCode=${drgCode}`)
      .then((answer) => {
        drgText = answer.data.drgDescription;
        return new Promise(answer);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          setError({
            message,
          });
        }
      });
    return drgText;
  };
  const getDRGDesc = async (drgCode) => {
    let drgDesc = null;

    let body = {
      key: drgCode,
    };
    await REST.post(`/spital/getDrgByDrgDescription`, body)
      .then((answer) => {
        //setError({ message: null });
        drgDesc = answer.data[0].drgCode;
        return new Promise(answer);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          setError({
            message,
          });
        }
      });
    return drgDesc;
  };

  var itemName = item.key;

  const handleMyChange = async (event, key, name, index) => {
    event.persist();
    let vals = inputs;
    vals[key] = event.target.value;
    if (
      key === "drg" &&
      event.target.value.search(/[0-9]{1}-[0-9]{2,3}/g) > -1 &&
      event.target.value.split("-").length < 4
    ) {
      await getDRGData(event.target.value).then((response) => {
        vals = {
          ...inputs,
          text: event.target.value ? response : "",
        };
      });
    } else if (key === "text") {
      await getDRGDesc(event.target.value).then((response) => {
        if (response !== "None") {
          vals = {
            ...inputs,
            drg: response,
          };
        }
      });
    }

    setInputs(vals);

    handleChange({
      target: {
        name: name,
        type: "multiple",
        index: index,
        value: vals,
      },
      persist: () => true,
    });
  };

  return (
    <div className="answerBox">
      {!hideLabel ? (
        <div className="DRGlabelContainer">
          <p className="labelText">{item.label}</p>
        </div>
      ) : (
        <div></div>
      )}

      <div className="DRGTextFieldContainer">
        <TextField
          id={`${itemName}${index ? index : ""}Text`}
          name={`${itemName}${index ? index : ""}Text`}
          label={item.displayLabel}
          className="DRGTextField"
          onChange={(event) => {
            handleMyChange(event, "text", itemName, index);
          }}
          onBlur={(event) => {
            handleBlur(event);
          }}
          disabled={item.answer ? item.answer.disabled : false}
          error={hasError(itemName)}
          helperText={hasError(itemName) ? formState.errors[itemName][0] : null}
          value={inputs ? inputs["text"] : ""}
        />

        <TextField
          id={`${itemName}${index ? index : ""}DRG`}
          name={`${itemName}${index ? index : ""}DRG`}
          label={item.displayLabel}
          className="DRGNrField"
          onChange={(event) => {
            handleMyChange(event, "drg", itemName, index);
          }}
          onBlur={(event) => {
            handleBlur(event);
          }}
          disabled={item.answer ? item.answer.disabled : false}
          error={hasError(itemName)}
          helperText={hasError(itemName) ? formState.errors[itemName][0] : null}
          value={inputs ? inputs["drg"] : ""}
        />
      </div>
    </div>
  );
};

export default DRGTextInput;
