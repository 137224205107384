import { Button, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";
import QrReader from "react-qr-reader";
import { Link, Redirect } from "react-router-dom";
import bigQr from "../../../assets/images/qr/big-qr.svg";
import { REST } from "../../../Utils/index";

const SimpleQr = () => {
  const [scanResult, setScanResult] = useState("");
  const [status, setStatus] = useState("");
  const [code, setCode] = useState("");
  const [openCamera, setOpenCamera] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState("");
  const [isAtiTicket, setIsAtiTicket] = useState("");

  const updateTicket = async (data) => {
    await REST.put(`/spital/updateTicketStatus?status=Medic&ticketId=${data}`)
      .then((answer) => {
        setIsAtiTicket(answer.data.ticketType);

        setError({ message: null });
        setStatus("registratura");
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          setError({
            message,
          });
        }
      });
  };

  const handleScan = async (data) => {
    if (data) {
      setScanResult(data);
      if (data != null) {
        updateTicket(data);
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const Scan = () => {
    if (status === "registratura") {
      return (
        <Redirect
          activeClassName="active"
          className="nav-link-simple"
          to={
            isAtiTicket === "Ati_Ticket"
              ? `/ati-id/${scanResult}`
              : `/chestionar/${scanResult}`
          }
        />
      );
    } else {
      return null;
    }
  };

  const handleInputCode = (event) => {
    setCode(event.target.value);
  };

  const opneCamera = () => {
    setOpenCamera(true);
  };

  return (
    <Grid container className={"qr_root"}>
      {openCamera ? (
        <Grid item xs={12} md={12} lg={12} className={"camera_container"}>
          <div className={"qr_container"}>
            <QrReader
              delay={10}
              onError={handleError}
              onScan={handleScan}
              resolution={2000}
              style={{ width: "100%" }}
              constraints={{
                focusMode: "continuous",
                sharpness: 999,
                torch: true,
                exposureMode: "continuous",
                whiteBalanceMode: "continuous",
                focusDistance: 100,
                facingMode: "environment",
              }}
            />
            <br />
            <Scan />
          </div>
        </Grid>
      ) : (
        <Grid item container className={"body_wrapper"}>
          <div className={"body_scan_container"}>
            <div className={"code_input_container"}>
              <img src={bigQr} alt="" style={{ width: 200, height: 200 }} />
            </div>

            <div className={"btn_container"}>
              <Button className={"submitBtn"} onClick={opneCamera}>
                Scaneaza
              </Button>
            </div>
          </div>

          <div className={"body_input_container"}>
            <div className={"code_title_container"}>
              <h4>Introduceti codul alfa numeric</h4>
            </div>

            <div className={"code_input_container"}>
              <TextField
                id="outlined-password-input"
                placeholder="..."
                type="text"
                variant="outlined"
                className={"code_input"}
                onChange={handleInputCode}
              />
            </div>

            <div className={"btn_container"}>
              <Link
                activeclassname="active"
                className="nav-link-simple submitBtn"
                to={`/chestionar/${code}`}
              >
                Aplica
              </Link>
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default SimpleQr;
