import React from "react";
import { AtiProvider } from "../../../context/Ati.context";

import Accordion01 from "./components/_Accordions/01_Form/01_Accordion";
import SaveBtns from "./components/SaveBtns";
const AtiCreate = props => {
  return (
    <AtiProvider>
      <div className={"chestionarContent"}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h1>ADAUGA PACIENT ATI</h1>
        </div>

        <div className="allFormsContainer">
          <div className="accordion mb-spacing-0">
            <Accordion01 />
          </div>
        </div>
        <SaveBtns />
      </div>
    </AtiProvider>
  );
};

export default AtiCreate;
