import React, { useState, useEffect, useRef } from "react";
import {
  ClickAwayListener,
  Grow,
  Popper,
  MenuItem,
  Paper,
} from "@material-ui/core";
import patientIcon from "../../../../assets/images/avatars/patientIcon.svg";
import { REST } from "../../../../Utils/index";

const DropdownMenu = (props) => {
  const { item } = props;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [vitals, setVitals] = useState(null);

  const getVitals = async () => {
    await REST.get(`spital/getLastVitals?ticketId=${item.ticketId}`).then(
      (answer) => {
        setVitals(answer.data);
      }
    );
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    getVitals();
  };

  const handleClose = (event, redirectTo) => {
    if (redirectTo) handleRedirect(redirectTo);
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleRedirect = (redirectTo) => {
    const origin = window.location.origin;
    window.location.href = `${origin}/chestionar/${redirectTo}`;
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={"patient_list"}>
      <div className="patient_card" onClick={handleToggle} ref={anchorRef}>
        <div className="patient_icon">
          <img src={patientIcon} alt={""} />
        </div>
      </div>
      <div className="patient_name">
        <p className="name_label">
          {item.firstName} {item.lastName}
        </p>
      </div>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuItem
                  style={{ display: "block" }}
                  id={`menu-list-grow-${item.id}`}
                  onKeyDown={handleListKeyDown}
                >
                  <ul>
                    <MenuItem
                      onClick={(e) =>
                        handleClose(
                          e,
                          item.ticketId
                        )(
                          (window.location = window.location = `/chestionar/${item.ticketId}`)
                        )
                      }
                    >
                      {/* {item.id} */}
                      <h6>
                        <b>Vizualizati fisa pacientului</b>
                      </h6>
                    </MenuItem>
                    <MenuItem>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h6>Semne vitale:</h6>
                        <p>
                          <b style={{ display: "flex", flexDirection: "row" }}>
                            BPM:{" "}
                            {vitals && vitals && vitals["VITAL HR"] ? (
                              vitals && vitals && vitals["VITAL HR"]
                            ) : (
                              <span> - </span>
                            )}
                          </b>
                        </p>

                        <p>
                          <b style={{ display: "flex", flexDirection: "row" }}>
                            SpO2-FP:{" "}
                            {vitals && vitals && vitals["VITAL PR(spo2)"] ? (
                              vitals && vitals && vitals["VITAL PR(spo2)"]
                            ) : (
                              <span> - </span>
                            )}
                          </b>
                        </p>

                        <p>
                          <b style={{ display: "flex", flexDirection: "row" }}>
                            ST-II:{" "}
                            {vitals && vitals["VITAL ST2"] ? (
                              vitals && vitals["VITAL ST2"]
                            ) : (
                              <span> - </span>
                            )}
                          </b>
                        </p>

                        <p>
                          <b style={{ display: "flex", flexDirection: "row" }}>
                            ART:{" "}
                            {vitals &&
                            vitals.data &&
                            vitals.data["VITAL ART(M)"] ? (
                              <span>
                                <span>
                                  {vitals &&
                                    vitals.data &&
                                    vitals.data["VITAL ART(S)"]}
                                </span>
                                <span>
                                  {vitals &&
                                    vitals.data &&
                                    vitals.data["VITAL ART(D)"]}
                                </span>
                                <span>
                                  (
                                  {vitals &&
                                    vitals.data &&
                                    vitals.data["VITAL ART(M)"]}
                                  )
                                </span>
                              </span>
                            ) : (
                              <span className="missingValueArt"> - </span>
                            )}
                          </b>
                        </p>

                        <p>
                          <b style={{ display: "flex", flexDirection: "row" }}>
                            PVC:{" "}
                            {vitals && vitals && vitals["VITAL CVP(M)"] ? (
                              vitals && vitals && vitals["VITAL CVP(M)"]
                            ) : (
                              <span> - </span>
                            )}
                          </b>
                        </p>

                        <p>
                          <b style={{ display: "flex", flexDirection: "row" }}>
                            SpO2:{" "}
                            {vitals && vitals && vitals["VITAL SpO2"] ? (
                              vitals && vitals && vitals["VITAL SpO2"]
                            ) : (
                              <span> - </span>
                            )}
                          </b>
                        </p>

                        <p>
                          <b style={{ display: "flex", flexDirection: "row" }}>
                            IMP-FR:{" "}
                            {vitals && vitals && vitals["VITAL rRESP(imp)"] ? (
                              vitals && vitals && vitals["VITAL rRESP(imp)"]
                            ) : (
                              <span> - </span>
                            )}
                          </b>
                        </p>

                        <p>
                          <b style={{ display: "flex", flexDirection: "row" }}>
                            Tpiele:{" "}
                            {vitals && vitals && vitals["VITAL TSKIN"] ? (
                              vitals && vitals && vitals["VITAL TSKIN"]
                            ) : (
                              <span> - </span>
                            )}
                          </b>
                        </p>

                        <p>
                          <b style={{ display: "flex", flexDirection: "row" }}>
                            Trect:{" "}
                            {vitals && vitals && vitals["VITAL TRECT"] ? (
                              vitals && vitals && vitals["VITAL TRECT"]
                            ) : (
                              <span> - </span>
                            )}
                          </b>
                        </p>
                      </div>
                    </MenuItem>
                  </ul>
                </MenuItem>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default DropdownMenu;
