import React, { useState, useEffect } from "react";
import { AtiProvider } from "../../../context/Ati.context";
import { getHistoryPathArray } from "../../../helpers";
import { REST } from "../../../Utils";

import { PopulateCtx } from "./components/PopulateCtx";

const AtiEdit = (props) => {
  const { history, location } = props;
  const sheetId = getHistoryPathArray(history)[2];

  const [ctxData, setCtxData] = useState(null);
  const [allData, setAllData] = useState(null);
  const [monitorData, setMonitorData] = useState("");

  const accordionState = () => {
    let accordions = {};
    for (let i = 1; i < 30; i++) {
      accordions[`accordion${i}`] = false;
    }
    return accordions;
  };

  const [accordionStates, setAccordionStates] = useState(accordionState());

  const toggleAccordion = (nth) => {
    setAccordionStates({
      ...accordionStates,
      [`accordion${nth}`]: !accordionStates[`accordion${nth}`],
    });
  };
  // eslint-disable-next-line
  const getDataForVitals = async (entryId) => {
    await REST.get(`/spital/getTicketById/?id=${entryId}`)
      .then((answer) => {
        if (!answer.data.form) {
          window.location = "/listare";
        }

        setMonitorData(answer.data.monitorData);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          window.location = "/listare";
        }
      });
  };

  const getData = async () => {
    await REST.get(`/ati/getAtisheetsById?sheetId=${sheetId}`)
      .then((answer) => {
        const prepareData = {
          sheetId: answer.data.id,
          ...answer.data,
          ...answer.data.patient,
        };

        delete prepareData.patient;

        setCtxData(prepareData);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <AtiProvider>
      <div className={"chestionarContent"}>
        <PopulateCtx
          ctxData={ctxData}
          toggleAccordion={toggleAccordion}
          accordionStates={accordionStates}
          sheetId={sheetId}
          toggle={toggle}
          setAllData={setAllData}
          activeTab={activeTab}
          history={history}
          location={location}
          allData={allData}
          monitorData={monitorData}
        />
      </div>
    </AtiProvider>
  );
};

export default AtiEdit;
