const acc1Hydrate = (ctx, ctxData) => {
  const {
    firstName,
    lastName,
    cnp,
    serial,
    birthSerialId,
    birthdate,
    cas,
    casNumber,
    sex,
    bloodType,
    rh,
    legalCounty,
    legalCity,
    legalStreet,
    legalStreetNr,
    county,
    locality,
    street,
    number,
    admissionDate,
    dischargeDate,
    weightNewBorn,
    profession,
    workPlace,
    hospitalizationDays,
    hospitalizationSickLeave,
    identityNumber,
    birthSerialNumber,
    ciCounty,
    registrationNr,
    hospitalCity,
    hospitalName,
    hospitalDepartment,
    doctorSeal,
    eligibilityCriteria
  } = ctxData;

  ctx.dispatch({
    type: ctx.ACTIONS.ACC1,
    payload: {
      firstName,
      lastName,
      cnp,
      serial,
      birthSerialId,
      birthdate,
      cas,
      casNumber,
      sex,
      bloodType,
      rh,
      legalCounty,
      legalCity,
      legalStreet,
      legalStreetNr,
      county,
      locality,
      street,
      number,
      admissionDate,
      dischargeDate,
      weightNewBorn,
      profession,
      workPlace,
      hospitalizationDays,
      hospitalizationSickLeave,
      identityNumber,
      birthSerialNumber,
      ciCounty,
      registrationNr,
      hospitalCity,
      hospitalName,
      hospitalDepartment,
      doctorSeal,
      eligibilityCriteria
    }
  });
};

export default acc1Hydrate;
