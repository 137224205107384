import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  container: {
    maxHeight: 440,
  },
  head: {
    backgroundColor: "#fff",
    minWidth: "80px",
  },
  tableContainer: {
    maxHeight: "100%",
    display: "flex",
    flexdirection: "row",
    borderRadius: 10,
  },
  cell: {
    minWidth: "80px",
  },
  roomNameCell: {
    minWidth: "50px",
    maxWidth: "70px",
  },
  cellTds: {
    minWidth: "100px",
  },

  headerContainer: {
    display: "flex",
    flexdirection: "row",
    justifyContent: "space-between",
  },
}));

const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#d1d7e5",
    color: theme.palette.common.black,
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    backgroundColor: "#d1d7e5",
    minWidth: "70px",
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#d1d7e5",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function CustomizedTables(props) {
  const classes = useStyles();

  const { data } = props;

  const myHours =
    data &&
    data[0] &&
    data[0].appointments.map((item, index) => {
      return (
        <StyledTableCell
          key={`style_table_cell_${index}`}
          className={`${classes.head} thuri`}
          id={`th-paturi-${index + 1}`}
        >
          {moment(item.start).format("HH:mm")} -
          {moment(item.end).format("HH:mm")}
        </StyledTableCell>
      );
    });

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StickyTableCell className={classes.head}>
                <div className={classes.cell}>Sali</div>
              </StickyTableCell>
              {myHours}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((n, index) => {
                const color =
                  n.roomId === 1
                    ? "#FBA922"
                    : n.roomId === 2
                    ? "#F0584A"
                    : n.roomId === 3
                    ? "#2B5877"
                    : null;

                return (
                  <StyledTableRow key={`table---${index}`}>
                    <StickyTableCell
                      className={classes.roomNameCell}
                      style={{ width: "100px", backgroundColor: color }}
                    >
                      <div>{n.roomName}</div>
                    </StickyTableCell>
                    {n.appointments.map((item, index) => {
                      return (
                        <StyledTableCell
                          key={`status_item--${index}`}
                          className={`${
                            item.status === "Liber"
                              ? `status_available`
                              : `status_booked`
                          }`}
                          onClick={() =>
                            console.log(
                              "SELECTED ROOM:",
                              n.roomId,
                              " START: ",
                              item.start,
                              " END: ",
                              item.end
                            )
                          }
                        >
                          <div>{item.status}</div>
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
