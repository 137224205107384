import React, { useState, useEffect } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import lscache from "lscache";

const TimePickerInput = (props) => {
  const {
    item,
    formState,
    handleChange,
    customStyle,
    customName,
    value,
  } = props;
  var itemName = item && item.key;

  var [myDate, setMyDate] = useState(
    (formState && formState.values && formState.values[itemName]) || null
  );
  var itemNameLabel = customName !== undefined ? customName : "";

  // eslint-disable-next-line
  const handleMyChange = (event, itemName) => {
    event.persist();

    if (!("" + event.target.value).toLowerCase().includes("invalid")) {
      setMyDate(event.target.value);
    }
  };

  useEffect(() => {
    handleChange({
      target: {
        type: "Text",
        name: `${itemName}${itemNameLabel}`,
        value: myDate,
      },
      persist: () => true,
    });
    // eslint-disable-next-line
  }, [myDate]);

  return (
    <div className={customStyle ? "" : "questionGrid"}>
      <div className={customStyle ? "" : "answerBox"}>
        {lscache.get("operatorType") === "SefSectie" ? null : (
          <div className="labelContainer">
            <p className="labelText">{item && item.label}</p>
          </div>
        )}
        <div className={customStyle ? "" : "timePickerContainer"}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              margin="normal"
              ampm={false}
              clearable
              id={`${itemName}${itemNameLabel}`}
              name={`${itemName}${itemNameLabel}`}
              // label={`Ora`}
              placeholder={"OO:MM"}
              error={false}
              helperText={null}
              onChange={(date) => {
                handleMyChange(
                  { target: { value: date }, persist: () => true },
                  `${itemName}${itemNameLabel}`
                );
              }}
              value={myDate || value || ""}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      {lscache.get("operatorType") === "Operator A" ? (
        customStyle ? (
          <div></div>
        ) : (
          <div className="answerTextContainer">
            <div className="answerDotContainer">
              <p className="answerDot">A</p>
            </div>
          </div>
        )
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default TimePickerInput;
