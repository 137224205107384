import axios from "axios";
import AuthHeader from "../Services/AuthHeader";

let url;

if (
  process &&
  process.env &&
  process.env.NODE_ENV &&
  process.env.NODE_ENV === "development"
) {
  url = "http://localhost:3000/v1";
} else {
  url = "http://maversanalytics.com";
}

export const rURL = url;
// export const beURL = "https://telemed.wtzconsult.com/telemed";
// export const beURL = "http://robert.wtzconsult.com:8080/telemed";
export const beURL = "https://backendtelemed.realgrowsoft.com/telemed";
// export const beURL = "http://192.168.0.219:8081/telemed";

export const MEDIA_PATH = "http://localhost:3000/static/media";
export const IMG_PATH = "http://localhost:3000/static/images";

export const REST = axios.create({
  baseURL: beURL,
  timeout: 10000,
  headers: { "X-engage-initiator": "frontend", ...AuthHeader() },
});

export const REST2 = axios.create({
  baseURL: url,
  timeout: 20000,
  headers: { "X-engage-initiator": "frontend" },
});

export const xhr = (data) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(data.method, data.url);
    xhr.onload = () => resolve(xhr);
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();
  });
};

export const simulateClick = (elem) => {
  // Create our event (with options)

  // eslint-disable-next-line
  const evt = new MouseEvent("click", {
    bubbles: true,
    cancelable: true,
    view: window,
  });
  // If cancelled, don't dispatch our event
};

export const changeSelectedDateFormat = (date) => {
  var dd = String(date.getDate()).padStart(2, "0");
  var mm = String(date.getMonth() + 1).padStart(2, "0");
  var yyyy = date.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
};

export const getCurrentDay = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = mm + "/" + dd + "/" + yyyy;
  return today;
};

export const getFromDate = (needDay = false, needHour = false, date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    minute = "" + d.getMinutes(),
    second = d.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (minute.length < 2) minute = "0" + minute;
  if (second.length < 2) second = "0" + second;

  return `${needDay ? `${day}-${month}-${year}` : ""} ${
    needHour ? `${hour}:${minute}:${second}` : ""
  }`;
};

export const getStringFromBetween = {
  results: [],
  string: "",
  getFromBetween: function(sub1, sub2) {
    if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0)
      return false;
    var SP = this.string.indexOf(sub1) + sub1.length;
    var string1 = this.string.substr(0, SP);
    var string2 = this.string.substr(SP);
    var TP = string1.length + string2.indexOf(sub2);
    return this.string.substring(SP, TP);
  },
  removeFromBetween: function(sub1, sub2) {
    if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0)
      return false;
    var removal = sub1 + this.getFromBetween(sub1, sub2) + sub2;
    this.string = this.string.replace(removal, "");
  },
  getAllResults: function(sub1, sub2) {
    // first check to see if we do have both substrings
    if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return;

    // find one result
    var result = this.getFromBetween(sub1, sub2);
    // push it to the results array
    this.results.push(result);
    // remove the most recently found one from the string
    this.removeFromBetween(sub1, sub2);

    // if there's more substrings
    if (this.string.indexOf(sub1) > -1 && this.string.indexOf(sub2) > -1) {
      this.getAllResults(sub1, sub2);
    } else return;
  },
  get: function(string, sub1, sub2) {
    this.results = [];
    this.string = string;
    this.getAllResults(sub1, sub2);
    return this.results;
  },
};
