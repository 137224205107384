import { Grid } from "@material-ui/core";
import React from "react";

const ARTButton = (props) => {
  const { vitals } = props;
  return (
    <Grid container className="widgetContainer">
      <Grid item xs={4} md={4}>
        <div className="artTextContainer">
          <p className="artText">ART</p>
        </div>
      </Grid>
      <Grid item xs={8} md={8}>
        <div className="artValueContainer">
          {vitals && vitals.data && vitals.data["VITAL ART(M)"] ? (
            <div>
              <div>{vitals && vitals.data && vitals.data["VITAL ART(S)"]}</div>
              <div>{vitals && vitals.data && vitals.data["VITAL ART(D)"]}</div>
              <div>
                ({vitals && vitals.data && vitals.data["VITAL ART(M)"]})
              </div>
            </div>
          ) : (
            <div className="missingValueArt"> - </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ARTButton;
