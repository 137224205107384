import { Grid, Modal } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import validate from "validate.js";
import {
  AutocompleteDRGInput,
  CheckBoxInput,
  DatePickerInput,
  DRGTextInput,
  DropDownInput,
  FormData,
  IntraHospitalTransfer,
  LongTextInput,
  MultipleDRGTextInput,
  MultipleFormData,
  MultipleLongTextInput,
  MultipleRadioInput,
  MultipleSignTextInput,
  MultipleSurgicalTextInputs,
  NewLineInput,
  RadioInput,
  RadiologicalInvestigations,
  SignTextInput,
  SurgicalTextInputs,
  TextInput,
  TimePickerInput,
  TitleForInput,
  UploadedFiles,
  Uploader
} from "../../../../../components";
import { examUploadLabels } from "../../../../../Utils/examUploadLabels";
import { beURL, REST } from "../../../../../Utils/index";
import { obsEmptyState } from "./components/Mocks/obsEmptyState";
import Mocks from "./components/Mocks/secondFormMock";
import SecondFormSchema from "./SecondFormSchema";
import buletinAnalize from "../../../../..//assets/images/buletinAnalize.png"

const SecondForm = (props) => {

  const { enqueueSnackbar } = useSnackbar();
  const {
    data,
    ticketId,
    handleSecondPage,
    getAllDRG,
    obsData,
    accordion1,
    accordion2,
    accordion3,
    accordion4,
    accordion5,
    toggleAccordion1,
    toggleAccordion2,
    toggleAccordion3,
    toggleAccordion4,
    toggleAccordion5,
    save,
    setModalSave
  } = props;
  // eslint-disable-next-line
  const [formState, setFormState] = useState({
    isValid: false,
    values: obsData || obsEmptyState,
    touched: {},
    errors: {},
    counter: 0,
    speechResponse: "",
  });
  const [uploadModal, setUplModal] = useState(false);
  const [uploadedFilesModa, setUploadedFilesModal] = useState(false);
  // eslint-disable-next-line
  const [fileName, setFileName] = useState(null);
  const [showOkButton, setShowOkButton] = React.useState(true);
  const [uploadDocumentsLabel, setUploadDocumentsLabel] = useState(null);
  const [uploaderExamsSectionType, setUploaderExamsSectionType] = useState(
    null
  );
  const [sectionFiles, setSectionFiles] = useState({
    alteExameneSpec: null,
    exameneLaborator: null,
    exameneRadiologice: null,
    exameneEcografice: null,
    exameneAnatomoPatologice: null,
    pacientDocuments: null,
  });

  // eslint-disable-next-line
  const [sections, setSections] = useState([
    "alteExameneSpec",
    "exameneLaborator",
    "exameneRadiologice",
    "exameneEcografice",
    "exameneAnatomoPatologice",
    "patientUploads",
  ]);

  // eslint-disable-next-line
  const examFilesRequest = async (section) => {
    await REST.get(
      `spital/files/getSecondOpinionFiles?section=${section}&ticketId=${ticketId}`
    )
      .then((answer) => {
        // console.log(answer.data.files, ">>>>>")
        if (answer.data.files) {
          // sectionFiles[section] = answer.data.files;
          setSectionFiles((sectionFiles) => {
            return {
              ...sectionFiles,
              [section]: answer.data.files,
            };
          });
        }
      })
      .catch((err) => { });
  };

  useEffect(() => {
    // eslint-disable-next-line
    // sections.map((section) => {
    // examFilesRequest(section);
    // });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, SecondFormSchema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    handleSecondPage(formState.values);
    // eslint-disable-next-line
  }, [formState.values]);



  const examSwitchFunc = (key) => {
    let type;
    if (key) {
      switch (key) {
        case "otherSpecialtyExams":
          type = "alteExameneSpec";
          break;
        case "laboratoryExams":
          type = "exameneLaborator";
          break;
        case "radiologyExams":
          type = "exameneRadiologice";
          break;
        case "ecographExams":
          type = "exameneEcografice";
          break;
        case "anatomoPatoExams":
          type = "exameneAnatomoPatologice";
          break;
        case "pacientDocuments":
          type = "patientUploads";
          break;
        default:
          type = null;
          break;
      }
    }

    return (
      type !== undefined &&
      type !== null &&
      sectionFiles[type] !== undefined &&
      sectionFiles[type] !== null &&
      sectionFiles[type].map((picture) => {
        const filePath = `${beURL}/spital/files/getFileByFilenamePatientUpload?fileName=${picture}&section=${type}&ticketId=${ticketId}`;

        return (
          <div className={`uploadedFileItem`} style={{ maxWidth: "150px" }}>
            <a href={filePath} target={`_blank`}>
              <img src={filePath} alt="uploaded file" />
            </a>
          </div>
        );
      })
    );
  };

  const showSnack = (variant) => {
    enqueueSnackbar("Un nou eveniment a fost adaugat!", { variant });
  };

  const handleBlur = (event) => {
    document
      .querySelectorAll("[data-replace='#" + event.target.name + "#']")
      .forEach((el) => {
        el.innerText = el.dataset.q.replace(
          el.dataset.replace,
          event.target.value
        );
      });
  };

  const handleChange = (event, callLoad = null, forList = [], ref = null) => {
    event.persist();

    let myVal = null;
    let tmpVals = formState.values;

    if (ref !== null && ref.affects !== undefined) {
      ref.affects.forEach((el) => {
        delete tmpVals[el];
      });
    }
    if (ref !== null) {
      if (ref.setConfirmed !== undefined) {
        delete tmpVals["confirmed"];
        if (event.target.value !== "false" && event.target.value.length) {
          tmpVals.confirmed = true;
        }
      }
    }
    if (event.target.type === "multiple") {
      if (event.target.index === undefined) {
        myVal = event.target.value;
      } else {
        myVal =
          formState.values[event.target.name] !== undefined
            ? formState.values[event.target.name]
            : [];
        if (myVal === null) {
          myVal = [event.target.value];
        } else {
          myVal[event.target.index] = event.target.value;
        }
      }
    } else if (forList !== null && forList.key !== undefined) {
      if (callLoad && callLoad === null) {
      } else {
        if (typeof callLoad === "function") {
          let cat = forList["cat"];
          myVal =
            formState.values[event.target.name] === null
              ? {}
              : formState.values[event.target.name];
          myVal = {
            ...myVal,
            [cat]: event.target.value,
          };

          callLoad(forList.sub);
        }
      }
    } else if (event.target.type === "radio") {
      if (event.replace) {
        event.target.name = event.replace;
      }
      myVal = event.target.value;
    } else if (event.target.type === "Date") {
      myVal = new Date(event.target.value);
    } else if (event.target.type === "checkbox") {
      let getKey =
        event.replace === undefined ? event.target.name : event.replace;
      let listVals =
        formState.values[getKey] === undefined ? [] : formState.values[getKey];
      if (event.target.checked) {
        if (Array.isArray(listVals)) {
          listVals.push(event.target.value);
        } else {
          listVals = event.target.value;
        }
      } else {
        if (Array.isArray(listVals)) {
          listVals = listVals.filter((el) => {
            return el !== event.target.value;
          });
        } else {
          listVals = false;
        }
      }
      myVal = listVals;
    } else {
      if (event.replace) {
        event.target.name = event.replace;
      }
      myVal = event.target.value;
    }
    let useName = event.target.name;
    const getRadioValueBasedOnTargetName = () => {
      if (
        event.target.name === "intraDischargeState" ||
        event.target.name === "intraDischargeType" ||
        event.target.name === "admissionType" ||
        event.target.name === "intraDeath"
      ) {
        return event.target.value;
      }

      return event.target.type === "radio" ? myVal === "true" : myVal;
    };

    setFormState((formState) => ({
      ...formState,
      counter: Object.keys(formState.values).length + 1,
      values: {
        ...tmpVals,
        [useName]: getRadioValueBasedOnTargetName(),
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleOkButtonFromRadio = () => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        confirmed: true,
      },
    });
    setShowOkButton(false);
  };

  const closeModal = () => {
    setUplModal(false);
  };

  const closeUploadedFilesModal = () => {
    setUploadedFilesModal(false);
  };

  const handleModal = (label) => {
    setUploadDocumentsLabel(label);
    setUploaderExamsSectionType(examUploadLabels[label]);
    setUplModal(!uploadModal);
  };

  const handleUploadedFilesModal = (label) => {
    setUploaderExamsSectionType(examUploadLabels[label]);
    setUploadedFilesModal(!uploadedFilesModa);
  };

  const setUplaodedFileName = (fileName) => {
    setFileName(fileName);
  };

  return (
    <Grid container className="SecondForm">
      {Mocks.map((item, index) => {
        if (item.answer.type === "wait") {
          return null;
        }

        let showIt = true;
        if (item.depends !== undefined) {
          if (Array.isArray(item.depends.vals)) {
            showIt = item.depends.vals.includes(
              formState.values[item.depends.key]
            );
          } else {
            showIt = item.depends.vals === formState.values[item.depends.key];
          }
        }
        return (
          showIt &&
          (() => {
            if (item.answer.type === "TextInput") {
              return (
                <Grid item xs={4} key={`${item.answer.type}_${index}`}>
                  <TextInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    label={item.label}
                  />
                </Grid>
              );
            } else if (item.answer.type === "NewLineInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <NewLineInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    label={item.label}
                  />
                </Grid>
              );
            } else if (item.answer.type === "TitleForInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <TitleForInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    label={item.label}
                  />
                </Grid>
              );
            } else if (item.answer.type === "FormData") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <FormData
                    item={item}
                    formState={data}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    label={item.label}
                  />
                </Grid>
              );
            } else if (item.answer.type === "MultipleFormData") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <MultipleFormData
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    label={item.label}
                  />
                </Grid>
              );
            } else if (item.answer.type === "RadioInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <RadioInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showOkButton={showOkButton}
                    handleOkButtonFromRadio={handleOkButtonFromRadio}
                  />
                </Grid>
              );
            } else if (item.answer.type === "AutocompleteDRGInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <AutocompleteDRGInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showColumn={true}
                    getAllDRG={getAllDRG}
                  />
                </Grid>
              );
            } else if (item.answer.type === "DRGTextInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <DRGTextInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showColumn={true}
                  />
                </Grid>
              );
            } else if (item.answer.type === "SignTextInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <SignTextInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showColumn={true}
                  />
                </Grid>
              );
            } else if (item.answer.type === "MultipleSignTextInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <MultipleSignTextInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showColumn={true}
                  />
                </Grid>
              );
            } else if (item.answer.type === "MultipleDRGTextInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <MultipleDRGTextInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                  />
                </Grid>
              );
            } else if (item.answer.type === "RadiologicalInvestigations") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <RadiologicalInvestigations
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    ticketId={ticketId}
                    showRecord={item.answer.showRecord}
                  />
                </Grid>
              );
            } else if (item.answer.type === "SurgicalTextInputs") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <SurgicalTextInputs
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showSnack={showSnack}
                  />
                </Grid>
              );
            } else if (item.answer.type === "LongTextInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <LongTextInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showRecord={item.answer.showRecord}
                    label={item.label}
                    handleModal={handleModal}
                    handleUploadedFilesModal={handleUploadedFilesModal}
                    ticketId={ticketId}
                  // formValue={item.answer.speechResponse}
                  />
                </Grid>
              );
            } else if (item.answer.type === "MultipleLongTextInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <MultipleLongTextInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showRecord={item.answer.showRecord}
                    isCaseDeceased={item.answer.isCaseDeceased}
                    hideAddButton={item.answer.hideAddButton}
                  // formValue={item.answer.speechResponse}
                  />
                </Grid>
              );
            } else if (item.answer.type === "MultipleRadioInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <MultipleRadioInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showOkButton={showOkButton}
                    handleOkButtonFromRadio={handleOkButtonFromRadio}
                    accordion1={accordion1}
                    accordion2={accordion2}
                    accordion3={accordion3}
                    accordion4={accordion4}
                    accordion5={accordion5}
                    toggleAccordion1={toggleAccordion1}
                    toggleAccordion2={toggleAccordion2}
                    toggleAccordion3={toggleAccordion3}
                    toggleAccordion4={toggleAccordion4}
                    toggleAccordion5={toggleAccordion5}
                    className={item.answer.className}
                  />
                </Grid>
              );
            } else if (item.answer.type === "TimePickerInput") {
              return (
                <Grid item xs={6} key={`${item.answer.type}_${index}`}>
                  <TimePickerInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showOkButton={showOkButton}
                    handleOkButtonFromRadio={handleOkButtonFromRadio}
                    label={item.answer.timeLabel}
                  />
                </Grid>
              );
            } else if (item.answer.type === "DatePickerInput") {
              return (
                <Grid item xs={6} key={`${item.answer.type}_${index}`}>
                  <DatePickerInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    label={item.answer.dateLabel}
                  />
                </Grid>
              );
            } else if (item.answer.type === "wait") {
              return <div></div>;
            } else if (item.answer.type === "DropDownInput") {
              return (
                <Grid item xs={4} key={`${item.answer.type}_${index}`}>
                  <DropDownInput
                    item={item}
                    formState={data}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showOkButton={showOkButton}
                    handleOkButtonFromRadio={handleOkButtonFromRadio}
                    isDisabled={item.answer.isDisabled}
                  />
                </Grid>
              );
            } else if (item.answer.type === "MultipleSurgicalTextInputs") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <MultipleSurgicalTextInputs
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showOkButton={showOkButton}
                    handleOkButtonFromRadio={handleOkButtonFromRadio}
                    save={save}
                    setModalSave={setModalSave}
                  />
                </Grid>
              );
            } else if (item.answer.type === "IntraHospitalTransfer") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <IntraHospitalTransfer
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showOkButton={showOkButton}
                    handleOkButtonFromRadio={handleOkButtonFromRadio}
                  />
                </Grid>
              );
            } else if (item.answer.type === "CheckBoxInput") {
              return (
                <Grid item xs={4} key={`${item.answer.type}_${index}`}>
                  <CheckBoxInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showOkButton={showOkButton}
                    handleOkButtonFromRadio={handleOkButtonFromRadio}
                  />
                </Grid>
              );
            }

            else {

              return <div></div>;
            }
          })()
        );

      })}
      <div style={{marginTop:300}}> </div>
      <div className={"documenteTrimiseDePacient"}>
        <h5>Documente trimise de Pacient</h5>


        <div style={{ padding: 50 }}>
          <img src={buletinAnalize} alt="buletinAnalize" />
        </div>

        <h5>Documente trimise de Pacient in format OCR</h5>
        <div style={{ padding: 50 }}>Laborator de Analize Medicale si Anatomie Patologica - Splaiul Independentei, 319C
          Bucureşti Sectorul 6 Tel: 021 9268
          Buletin de analize medicale


          Nume: Cătălin Alexandru
          Denumire	Rezultate	UM	Interval biologic de referinţa
          HEMATOLOGIE  =	5.75	mil./pL	[4.44-5.61]
          HEMOLEUCOGRAMA CU FORMULA LEUCOCITARA.Hb.Ht. INDICI ERITROCITARI  =	17.4	g/dL	[13.5-16.9]
          Număr de eritrocite (RBC) =	50.9	%	[40 - 49.4]
          Hemoglobina (HGB) =	50.9	%	[40 - 49.4]
          Hematocrit (HCT) =	50.9	%	[40 - 49.4]
          Volumul mediu eritrocitar (MCV) =	88.5	fL	[81.8-95.5]
          Hemoglobina eritrocitara medie (MCH)  =	30.3	fL	[27-32.3]
          Concentraţia medie a hemoglobinei eritrocitare (MCHC) =	34.2	pg	[32.4 - 35]
          Lărgimea distribuţiei eritrocitare coeficient variaţie (RDW) =	13.1	g/dL	[11.6 - 14.8]
          Număr de leucocite (WBC) =	7.54	%	[3.91-10.9]
          Procentul de neutrofile (NEUT%) =	50.4	mii/pL	[41-70.7]
          Procentul de eozinofile (EOS%) =	1.2	%	[0.6 - 7.6]
          Procentul de bazofile (BAS%) =	0.8	%	[0.1 - 1.2]
          Procentul de limfocite (LYM%) =	35.5	%	[19.1 -47.9]
          Procentul de monocite (MON%) =	12.1	%	[5.2-15.2]
          Număr de neutrofile (NEUT) =	3.8	mii/pL	[1.8-6.98]
          Număr de eozinofile (EOS) =	0.09	mii/pL	[0.03 - 0.59]
          Număr de bazofile (BAS) =	0.06	mii/pL	[0.01 - 0.07]
          Număr de limfocite (LYM) =	2.68	mii/pL	[1.26-3.35]
          Număr de monocite (MON) =	0.91	mii/pL	[0.29 - 0.95]
          Număr de trombocite (PLT) =	325	mii/pL	[150-450]
        </div>
        <div className={"pacientFilesUploaded"}>
          {examSwitchFunc("pacientDocuments")}
        </div>
      </div>

      <Modal
        open={uploadModal}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(3px)",
          width: "100%",
          height: "100%",
        }}
      >
        <div>
          <Uploader
            uploadDocumentsLabel={uploadDocumentsLabel}
            uploaderExamsSectionType={uploaderExamsSectionType}
            ticketId={ticketId}
            setUplaodedFileName={setUplaodedFileName}
          />
        </div>
      </Modal>

      <Modal
        open={uploadedFilesModa}
        onClose={closeUploadedFilesModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(3px)",
          width: "100%",
          height: "100%",
        }}
      >
        <div>
          <UploadedFiles
            ticketId={ticketId}
            uploaderExamsSectionType={uploaderExamsSectionType}
            uploadDocumentsLabel={uploadDocumentsLabel}
          />
        </div>
      </Modal>
    </Grid>
  );
};

export default SecondForm;
