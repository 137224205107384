import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React from "react";
import { useAti } from "../../../../../../hooks";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ManagementPlanTable = () => {
  const ctx = useAti();
  const classes = useStyles();

  return (
    <>
      {ctx.rState.acc20.values.patientMonitoring && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell align="right">Program alimentare Ora</TableCell>
                <TableCell align="right">Hidratare ora</TableCell>
                <TableCell align="right">Tapotaj</TableCell>
                <TableCell align="right">Pansamente</TableCell>
                <TableCell align="right">
                  Modificare pozitie in pat (ora)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ctx.rState.acc20.values.patientMonitoring.map((row, index) => {
                return (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {moment(row.date).format("DD-MM-YYYY")}
                    </TableCell>

                    <TableCell align="right">
                      {row.alimentationProgram.map((item) => {
                        return moment(item).format("HH:mm") || "--";
                      })}
                    </TableCell>
                    <TableCell align="right">
                      {row.hydratation.map((item) => {
                        return moment(item).format("HH:mm") || "--";
                      })}
                    </TableCell>
                    <TableCell align="right">{row.stipple || "--"}</TableCell>
                    <TableCell align="right">{row.patchs || "--"}</TableCell>
                    <TableCell align="right">
                      {row.position.map((item) => {
                        return moment(item).format("HH:mm") || "--";
                      })}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ManagementPlanTable;
