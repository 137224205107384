import { InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const DropDownInput = (props) => {
  const { item, formState, handleChange, isDisabled } = props;

  var itemName = item.key;

  const [evLocal, setEvLocal] = useState(null);
  const [ev, setEv] = useState({
    target: {
      name: null,
      type: null,
      value:
        (formState && formState.values && formState.values[itemName]) || null,
    },
    persist: () => true,
  });

  const changeHandler = (event) => {
    event.persist();

    setEvLocal(event);
    setEv((prevState) => {
      return {
        ...prevState,
        target: {
          value: event.target.value,
          type: event.target.type,
          name: event.target.name,
        },
      };
    });
  };

  useEffect(() => {
    handleChange(ev);
    // eslint-disable-next-line
  }, [ev]);

  useEffect(() => {
    setEv((prevState) => {
      return {
        ...prevState,
        target: {
          value:
            (formState && formState.values && formState.values[itemName]) ||
            null,
        },
      };
    });
    // eslint-disable-next-line
  }, [formState]);

  const getValue = () => {
    if (evLocal && evLocal.target && evLocal.target.value) {
      if (evLocal.target.value === "Test") {
        return "";
      } else {
        return evLocal.target.value;
      }
    }

    if (ev.target.value === "Test") {
      return "";
    } else {
      return ev.target.value;
    }
  };

  return (
    <div className="answerBox">
      <div className="labelContainer">
        <p className="labelText">{item.label}</p>
      </div>
      <div className="singleDropDownContainer">
        <InputLabel id="singleDropDown"></InputLabel>
        <Select
          id={`${itemName}`}
          name={`${itemName}`}
          label={item.displayLabel}
          className="singleDropDown"
          onChange={changeHandler}
          disabled={isDisabled}
          value={getValue() || ""}
        >
          <MenuItem value="NONE">
            <em>None</em>
          </MenuItem>
          {item.answer.selectList.map((el) => {
            return (
              <MenuItem value={el} key={`dropDownInput_${el}`}>
                {el}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default DropDownInput;
