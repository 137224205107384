import universalAction, { acc17, acc19, acc21 } from "./Ati.actions.context";

const reducer = (state, action) => {
  const {
    type,
    payload,
    payloadKey,
    eraseFromNestedCollection,
    addToNestedCollection,
    isPayloadForTable,
  } = action;
  const stateKey = type.toLowerCase();
  const isActionGenerated = action.generated;

  if (!!type) {
    if (!isPayloadForTable && type !== "sheetId") {
      // action for accordions WITHOUT data collections
      // ( see _Ati.documentation.context.js for explanations )
      return universalAction(
        stateKey,
        state,
        type,
        payload,
        payloadKey,
        eraseFromNestedCollection,
        addToNestedCollection,
        isActionGenerated
      );
    }

    switch (type) {
      case "sheetId":
        return { ...state, sheetId: payload };

      case "ACC17":
        return acc17(state, stateKey, payloadKey, payload, isActionGenerated);

      case "ACC7":
      case "ACC8":
      case "ACC9":
      case "ACC12":
      case "ACC13":
      case "ACC16":
      case "ACC19":
      case "ACC20":
        return acc19(state, stateKey, payloadKey, payload, isActionGenerated);

      case "ACC21":
        return acc21(state, stateKey, payloadKey, payload, isActionGenerated);

      default:
        return { ...state };
    }
  }

  return {
    ...state,
  };
};

export default reducer;

export const createReducer = (state, action) => {
  const { type, payload } = action;

  if (!!type) {
    switch (type) {
      case "firstAccordion":
        return {
          ...state,
          ...payload,
        };
      default:
        return { ...state };
    }
  }

  return {
    ...state,
  };
};
