// eslint-disable-next-line
import { Button, Grid, MenuItem, Modal, Select } from "@material-ui/core";
// eslint-disable-next-line
import FormControl from "@material-ui/core/FormControl";
// eslint-disable-next-line
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import { OperatingRoomsContext } from "context/operatingRooms.context";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import { REST } from "../../Utils";
// eslint-disable-next-line
import TimePickerInput from "../InputsAti/TimePickerInput";
import OperatingRoomDisponibilityModal from "../OperatingRoomDisponibilityModal";
import AutocompleteDRGInput from "./AutocompleteDRGInput";
// eslint-disable-next-line
import DatePickerInput from "./DatePickerInput";
import TextInput from "./TextInput";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    width: 200,
  },
  inputRoot: {
    color: "inherit",
    // backgroundColor: "Red",
    width: "100%",
  },
  inputInput: {
    margin: theme.spacing(1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const SurgicalTextInputs = (props) => {
  // eslint-disable-next-line
  const { state: operatingRooms } = useContext(OperatingRoomsContext);

  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line
  const classes = useStyles();
  const {
    item,
    formState,
    handleChange,
    hasError,
    handleBlur,
    index,
    save,
    setModalSave
  } = props;
  const [inputs, setInputs] = useState({
    ...(formState.values[item.key] !== undefined
      ? formState.values[item.key][index] !== undefined
        ? formState.values[item.key][index]
        : {
          drg: null,
          consent: null,
          mainMedic: null,
          secondMedic: null,
          thirdMedic: null,
          assistant: null,
          atiMedic: null,
          surgeryDate: null,
          surgeryHour: null,
          surgeryDurationHours: null,
          surgeryDurationMinutes: null,
        }
      : {
        drg: null,
        consent: null,
        mainMedic: null,
        secondMedic: null,
        thirdMedic: null,
        assistant: null,
        atiMedic: null,
        surgeryDate: null,
        surgeryHour: null,
        surgeryDurationHours: null,
        surgeryDurationMinutes: null,
      }),
  });
  // eslint-disable-next-line
  const [surgeryHours, setSurgeryHours] = useState({
    hours: 0,
    minutes: 0,
  });
  // eslint-disable-next-line
  const [room, setRoom] = useState({
    roomName: "",
  });
  // eslint-disable-next-line
  const [procedure, setProcedure] = useState("");
  // eslint-disable-next-line
  const [doctors, setDoctors] = useState({
    mainMedic: null,
    anesthesiologist: null,
    nurse: null,
  });
  // eslint-disable-next-line
  const [date, setDate] = useState(null);
  // eslint-disable-next-line
  const [hour, setHour] = useState(new Date());

  const [
    isDisponibilityModalVisible,
    setIsDisponibilityModalVisible,
  ] = useState(false);

  const showSnack = (variant) => {
    enqueueSnackbar("Programarea a fost efectuata cu succes!", { variant });
  };

  var itemName = item.key;

  const handleMyChange = (event, key, name, index) => {
    let vals = {
      ...inputs,
      [key]: event.target.value,
    };

    setInputs(vals);

    handleChange({
      target: {
        name: name,
        type: "multiple",
        index: index,
        value: vals,
      },
      persist: () => true,
    });
  };

  // eslint-disable-next-line
  const handleSurgeryMinutes = (event) => {
    setSurgeryHours((state) => {
      return {
        ...state,
        minutes: event.target.value,
      };
    });
  };

  // eslint-disable-next-line
  const handleSurgeryHours = (event) => {
    event &&
      event.target &&
      event.target.value &&
      setSurgeryHours((state) => {
        return {
          ...state,
          hours: event.target.value,
        };
      });
  };

  // eslint-disable-next-line
  const handleSurgeryRoom = (event) => {
    setRoom((state) => {
      return {
        ...state,
        roomName: event.target.value,
      };
    });
  };

  // eslint-disable-next-line
  const addOperatingRoomAppointment = async (
    start,
    noOfHours,
    noOfMinutes,
    roomId
  ) => {
    const dataForAppointment = {
      roomId: roomId,
      numberOfHours: noOfHours,
      numberOfMinutes: noOfMinutes,
      procedureName: formState.values[itemName][0].drg.description,
      title: formState.values[itemName][0].drg.description,
      doctorName: `Doctor: ${formState.values[itemName][0].mainMedic ||
        ""}, Anestezist: ${formState.values[itemName][0].atiMedic ||
        ""}, Asistenta: ${formState.values[itemName].assistant || ""}`,
      start,
      patientSheetId: window.location.pathname.split("/")[2],
    };

    await REST.post(`/spital/addOperatingRoomAppointment`, dataForAppointment)
      .then((answer) => {
        console.log("ANSWER: ", answer);
        showSnack("success");
        save();
        setIsDisponibilityModalVisible(false)
      })
      .catch((error) => { });
  };

  return (
    <div>
      <div className="surgicalDRGTextFieldContainer">
        <AutocompleteDRGInput
          item={{ key: "drg", answer: item.answer }}
          formState={{
            values: {
              drg:
                formState.values[itemName] &&
                formState.values[itemName][index] &&
                formState.values[itemName][index]["drg"],
            },
          }}
          handleChange={(event, a, b, c) => {
            handleMyChange(
              { target: { value: event.target.value } },
              "drg",
              itemName,
              index
            );
          }}
          handleBlur={handleBlur}
          hasError={hasError}
          hideLabel={true}
          showNumberInput={item.answer.showNumberInput}
          setProcedure={setProcedure}
        />
      </div>
      {item.answer.showAdditionalInput ? (
        <div className="surgicalDRGTextFieldContainer">
          <TextInput
            item={{ key: "consent" }}
            formState={{
              values: {
                consent: inputs["consent"],
              },
            }}
            handleChange={(event) => {
              handleMyChange(event, "consent", itemName, index);
            }}
            handleBlur={handleBlur}
            hasError={hasError}
            label={item.answer.showAdditionalInputLabel}
          />
        </div>
      ) : (
        <div></div>
      )}
      <div className="answerBox">
        <Grid container direction="row" className="surgicalTeamContainer">
          <Grid item xs={3} className="surgicalTeamTextContainer">
            <p className="textContainer">Echipa operatorie:</p>
          </Grid>

          <Grid item xs={9} className="surgicalTeamInputsContainer">
            <Grid item xs={12} className="mainOperator">
              <TextInput
                item={{ key: "mainMedic" }}
                formState={{
                  values: {
                    mainMedic: inputs["mainMedic"],
                  },
                }}
                handleChange={(event) => {
                  handleMyChange(event, "mainMedic", itemName, index);
                }}
                handleDoctor={(e) => {
                  setDoctors((state) => {
                    return {
                      ...state,
                      mainMedic: e.target.value,
                    };
                  });
                }}
                handleBlur={handleBlur}
                hasError={hasError}
                label={"Medic operator principal:"}
              />
            </Grid>
            <Grid container direction="row" className="operatorsContainer">
              <Grid item xs={6} className="secondOperator">
                <TextInput
                  item={{ key: "secondMedic" }}
                  formState={{
                    values: {
                      secondMedic: inputs["secondMedic"],
                    },
                  }}
                  handleChange={(event) => {
                    handleMyChange(event, "secondMedic", itemName, index);
                  }}
                  handleBlur={handleBlur}
                  hasError={hasError}
                  label={"Medic operator II:"}
                />
              </Grid>
              <Grid item xs={6} className="atiOperator">
                <TextInput
                  item={{ key: "atiMedic" }}
                  formState={{
                    values: {
                      atiMedic: inputs["atiMedic"],
                    },
                  }}
                  handleChange={(event) => {
                    handleMyChange(event, "atiMedic", itemName, index);
                  }}
                  handleDoctor={(e) => {
                    setDoctors((state) => {
                      return {
                        ...state,
                        anesthesiologist: e.target.value,
                      };
                    });
                  }}
                  handleBlur={handleBlur}
                  hasError={hasError}
                  label={"Medic ATI"}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" className="operatorsContainer">
              <Grid item xs={6} className="thirdOperator">
                <TextInput
                  item={{ key: "thirdMedic" }}
                  formState={{
                    values: {
                      thirdMedic: inputs["thirdMedic"],
                    },
                  }}
                  handleChange={(event) => {
                    handleMyChange(event, "thirdMedic", itemName, index);
                  }}
                  handleBlur={handleBlur}
                  hasError={hasError}
                  label={"Medic operator III:"}
                />
              </Grid>
              <Grid item xs={6} className="operatorAssistant">
                <TextInput
                  item={{ key: "assistant" }}
                  formState={{
                    values: {
                      assistant: inputs["assistant"],
                    },
                  }}
                  handleChange={(event) => {
                    handleMyChange(event, "assistant", itemName, index);
                  }}
                  handleDoctor={(e) => {
                    setDoctors((state) => {
                      return {
                        ...state,
                        nurse: e.target.value,
                      };
                    });
                  }}
                  handleBlur={handleBlur}
                  hasError={hasError}
                  label={"Asistent/a"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* <Grid
        container
        direction="row"
        className={`surgery_appointment_container`}
      >
        <Grid item xs={3} className="surgery_appointment_title_container">
          <p className="textContainer">Data si ora:</p>
        </Grid>

        <Grid item xs={9} className="surgery_appointment_form">
          <DatePickerInput
            item={{ key: "surgeryDate" }}
            formState={{
              values: {
                surgeryDate: inputs["surgeryDate"],
              },
            }}
            handleChange={(event) => {
              handleMyChange(event, "surgeryDate", itemName, index);
            }}
            handleBlur={handleBlur}
            hasError={hasError}
            label={item.answer.dateLabel}
            setDate={setDate}
          />

          <TimePickerInput
            item={{ key: "surgeryHour" }}
            formState={{
              values: {
                surgeryHour: inputs["surgeryHour"],
              },
            }}
            handleChange={(event) => {
              handleMyChange(event, "surgeryHour", itemName, index);
            }}
            setHour={setHour}
            handleBlur={handleBlur}
            // hasError={hasError}
            label={item.answer.dateLabel}
          />
        </Grid>
      </Grid> */}

      {/* <Grid container className={`surgery_appointment_form_container`}>
        <Grid item xs={3} className="form_item">
          <FormControl className={`${classes.formControl}`}>
            <Select
              item={{ key: "surgeryDurationHours" }}
              formstate={{
                values: {
                  surgeryHour: inputs["surgeryDurationHours"],
                },
              }}
              onChange={(event) => {
                handleMyChange(event, "surgeryDurationHours", itemName, index);
                handleSurgeryHours(event);
              }}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={surgeryHours.hours}
              // onChange={handleSurgeryHours(event)     }
              className={classes.selectEmpty}
            >
              {unavailabilityHours.map((item, index) => {
                return (
                  <MenuItem
                    value={item.value}
                    key={`SurgicalTextInputs_unavailabilityHours_MenuItem_${index}`}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>Numarul de ore</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={3} className="form_item">
          <FormControl className={classes.formControl}>
            <Select
              item={{ key: "surgeryDurationMinutes" }}
              formstate={{
                values: {
                  surgeryHour: inputs["surgeryDurationMinutes"],
                },
              }}
              onChange={(event) => {
                handleMyChange(
                  event,
                  "surgeryDurationMinutes",
                  itemName,
                  index
                );
                handleSurgeryMinutes(event);
              }}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={surgeryHours.minutes}
              className={classes.selectEmpty}
            >
              {unavailabilityMinutes.map((item, index) => {
                return (
                  <MenuItem
                    value={item.value}
                    key={`SurgicalTextInputs_unavailabilityMinutes_MenuItem_${index}`}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>Numarul de minute</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={3} className="form_item">
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={room.roomName}
              onChange={handleSurgeryRoom}
              className={classes.selectEmpty}
            >
              {operatingRooms.map((item) => {
                return (
                  <MenuItem
                    value={item.id}
                    key={`SurgicalTextInputs_roomsList_MenuItem_${index}`}
                  >
                    {item.roomName}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>Selectati sala de operatii</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={3} className="form_btn">
          <Button
            variant="contained"
            color="primary"
            className={`surgery_btn`}
            // onClick={addOperatingRoomAppointment}
            onClick={() => setIsDisponibilityModalVisible(true)}
          >
            <p className={`btn-text`}>VERIFICA DISPONIBILITATE</p>
          </Button>
        </Grid>
      </Grid> */}
      <Grid container className={`surgery_appointment_form_container`}>
        <Button
          variant="contained"
          color="primary"
          className={`surgery_btn`}
          // onClick={addOperatingRoomAppointment}
          onClick={() => {
            setIsDisponibilityModalVisible(true)
            setModalSave(true)
          }}
        >
          <p className={`btn-text`}>VERIFICA DISPONIBILITATE</p>
        </Button>
      </Grid>
      <Modal
        open={isDisponibilityModalVisible}
        onBackdropClick={() => setIsDisponibilityModalVisible(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // backdropFilter: "blur(3px)",
          width: "100%",
          height: "100%",
        }}
      >
        <div className="operating_room_disponibility_modal">
          <OperatingRoomDisponibilityModal
            addAppointment={addOperatingRoomAppointment}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SurgicalTextInputs;
