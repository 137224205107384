import { Grid, InputAdornment } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { REST } from "../../../../Utils/index";
import DropdownMenu from "./DropdownMenu";
import { useParams } from "react-router-dom";

const MyPatients = (props) => {
  const getCurrentYear = new Date().getFullYear();
  const getCurrentMonth = new Date().getMonth() + 1;

  const [inputBg, setInputBg] = useState(false);
  const toggleInputBg = () => setInputBg(!inputBg);

  const [patientList, setPatientList] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  // eslint-disable-next-line
  const [year, setYear] = useState(getCurrentYear);
  // eslint-disable-next-line
  const [month, setMonth] = useState(
    getCurrentMonth < 10 ? `0${getCurrentMonth}` : getCurrentMonth
  );

  const { id } = useParams();

  // eslint-disable-next-line
  const defaultProps = {
    options: patientList,
    getOptionLabel: (option) => option.firstName + " " + option.lastName,
  };

  const getPatientList = async () => {
    const startDateString = `${year}/${month}/01`;
    const endDateString = `${year}/${month}/31`;
    // eslint-disable-next-line
    const startDate = new Date(`${month} 01, ${year} 00:00:00`);
    await REST.get(
      `spital/getHospitalizedPatientsByDoctor?doctorId=${id}&startDate=${startDateString}&endDate=${endDateString}`
    ).then((answer) => {
      setPatientList(answer.data);
    });
  };

  useEffect(() => {
    getPatientList();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (event) => {
    let newList = patientList.filter(
      (patientList) =>
        patientList.firstName.includes(event.target.value) ||
        patientList.lastName.includes(event.target.value)
    );
    setSearchValue(newList);
  };

  return (
    <div className="myPatients_root">
      <div
        className={clsx("d-flex align-items-center transition-base px-4 py-1", {
          "py-3 bg-secondary": inputBg,
        })}
      >
        <div className="search-wrapper w-100">
          <TextField
            variant="outlined"
            size="medium"
            className="bg-white search-width80"
            classes={{ root: "input-border-0" }}
            id="input-with-icon-textfield225-1"
            placeholder="Cautati pacient..."
            autoCapitalize="words"
            onFocus={toggleInputBg}
            onBlur={toggleInputBg}
            onChange={(event) => {
              handleSearch(event);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      <div className="list_container">
        {searchValue !== null ? (
          <Grid container className="patient_container">
            {searchValue.map((item, index) => {
              const ticket = item.ticketId;
              return (
                <Grid
                  item
                  xs={2}
                  className="patient_list"
                  key={`patient_${index}_${ticket}`}
                >
                  <DropdownMenu item={item} />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Grid className="patient_container">
            {patientList.map((item, index) => {
              const ticket = item.ticketId;
              return (
                <Grid
                  item
                  xs={2}
                  className="patient_list"
                  key={`patient_${index}_${ticket}`}
                >
                  <DropdownMenu item={item} />
                </Grid>
              );
            })}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default MyPatients;
