import React, { useState, useEffect, useContext } from "react";
import { Grid, Modal } from "@material-ui/core";
import { SecondOpinionContext } from "context/secondOpinionContext";
import { beURL, REST } from "../../../../Utils";
import { typeTitles } from "./types";

import { secondOpinionMock } from "../../../../context/secondOpinionMock";
import moment from "moment";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory();

const SecondOpinionBody = (props) => {
  const context = useContext(SecondOpinionContext);

  const [openImgModal, setOpenImgModal] = useState(false);
  const [expandedImage, setExpandedImage] = useState(null);

  const [sectionFiles, setSectionFiles] = useState({
    alteExameneSpec: null,
    exameneLaborator: null,
    exameneRadiologice: null,
    exameneEcografice: null,
    exameneAnatomoPatologice: null,
    pacientDocuments: null,
  });
  // eslint-disable-next-line
  const [sections, setSections] = useState([
    "alteExameneSpec",
    "exameneLaborator",
    "exameneRadiologice",
    "exameneEcografice",
    "exameneAnatomoPatologice",
    "patientUploads",
  ]);

  const secondOpinionFormState = context && context.secondOpinionForm;
  const ticketId = browserHistory.location.search.replace("?", "");

  const isArray = (value) => Array.isArray(value);
  const isObject = (value) => typeof value === "object" && value !== null;

  const examFilesRequest = async (section) => {
    await REST.get(
      `spital/files/getSecondOpinionFiles?section=${section}&ticketId=${ticketId}`
    )
      .then((answer) => {
        if (answer.data.files) {
          // sectionFiles[section] = answer.data.files;
          setSectionFiles((sectionFiles) => {
            return {
              ...sectionFiles,
              [section]: answer.data.files,
            };
          });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    // eslint-disable-next-line
    sections.map((section) => {
      examFilesRequest(section);
    });
    // eslint-disable-next-line
  }, []);

  const examSwitchFunc = (key) => {
    let type;
    if (key) {
      switch (key) {
        case "otherSpecialtyExams":
          type = "alteExameneSpec";
          break;
        case "laboratoryExams":
          type = "exameneLaborator";
          break;
        case "radiologyExams":
          type = "exameneRadiologice";
          break;
        case "ecographExams":
          type = "exameneEcografice";
          break;
        case "anatomoPatoExams":
          type = "exameneAnatomoPatologice";
          break;
        case "pacientDocuments":
          type = "patientUploads";
          break;
        default:
          type = null;
          break;
      }
    }

    return (
      type !== undefined &&
      type !== null &&
      sectionFiles[type] !== undefined &&
      sectionFiles[type] !== null &&
      sectionFiles[type].map((picture) => {
        const endpoint =
          type === "patientUploads"
            ? "getFileByFilenamePatientUpload"
            : "getFileByFilename";
        const filePath = `${beURL}/spital/files/${endpoint}?fileName=${picture}&section=${type}&ticketId=${ticketId}`;

        return (
          <div className={`uploadedFileItem`} style={{ maxWidth: "150px" }}>
            <a href={filePath} target={`_blank`}>
              <img src={filePath} alt="uploaded file" />
            </a>
          </div>
        );
      })
    );
  };

  const handleExpandedClick = (localValue, index) => {
    setExpandedImage(localValue);
  };

  const thumbs = (value, index) => {
    const localValue = value;
    return (
      <div
        style={{
          display: "inline-flex",
          borderRadius: 2,
          border: "1px solid #eaeaea",
          marginBottom: 8,
          marginRight: 8,
          width: 100,
          height: 100,
          padding: 4,
          boxSizing: "border-box",
        }}
        key={`${index}--files-photo`}
        onClick={() => handleExpandedClick(localValue, index)}
      >
        <div style={{ display: "flex", minWidth: 0, overflow: "hidden" }}>
          <img
            src={`${beURL}/spital/getImage?path=${value}&ticketId=${ticketId}`}
            alt={""}
          />
        </div>
      </div>
    );
  };

  const ExpandedThumbs = () => {
    return (
      <div
        style={{
          display: "inline-flex",
          borderRadius: 2,
          border: "1px solid #eaeaea",
          marginBottom: 8,
          marginRight: 8,
          width: "100%",
          height: "100%",
          padding: 4,
          boxSizing: "border-box",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => setOpenImgModal(false)}
      >
        <div
          style={{ display: "flex", minWidth: 0, overflow: "hidden" }}
          onClick={handleExpandPhoto}
        >
          <img
            src={`${beURL}/spital/getImage?path=${expandedImage}&ticketId=${ticketId}`}
            alt={""}
          />
        </div>
      </div>
    );
  };

  const handleExpandPhoto = () => {
    setOpenImgModal(true);
  };
  const handleCloseSecondModal = () => {
    setOpenImgModal(false);
  };

  // map through array
  const mapThroughValue = (key, arr, arrIndex) => {
    if (arr) {
      return (
        <Grid item xs={12} className="objectContainerArray">
          <p className="objectContainerArrayTitle">
            {typeTitles[key] || typeTitles[key] === undefined
              ? typeTitles[key]
              : null}
          </p>
          {arr.map((value, index) => {
            return mapThroughArrayKeys(value, key, arrIndex + index);
          })}
        </Grid>
      );
    } else {
      return;
    }
  };
  
  const mapThroughArrayKeys = (obj, ...args) => {
   
    if (
      args[0] === "mainSurgery" ||
      args[0] === "concomitantWithMainSurgery" ||
      args[0] === "otherSurgicalInterventions"
    ) {
      return renderRowSurgery(obj);
    // } else if (args[0] === "intraHospitalTransfer") {
    //   return renderRowIntraHospital(obj);
    } else if (
      args[0] === "radiologicInvestigations" ||
      args[0] === "funcExplorations"
    ) {
      return renderRowRadiologicalAndFunc(obj);
    }
    return (
      <Grid item xs={12} className="objectContainerArray">
        {Object.entries(obj)?.map(([key, value], index) => {
          if (isObject(value)) {
            if (isArray(value)) {
              if (key === "files") {
                return value.map((img, index) => {
                  return renderRowArray(key, value, args[1] + index);
                });
              }
            } else {
              // eslint-disable-next-line
              return Object.entries(value).map(([key, value], index) => {
                if (key === "id") {
                  return null;
                } else {
                  return renderRowArray(key, value, args[1] + index);
                }
              });
            }
          }
          if (key === "id") {
            return null;
          } else {
            return renderRowArray(key, value, args[1] + index);
          }
        })}
      </Grid>
    );
  };

  // const renderRowIntraHospital = (obj) => {
  //   return (
  //     <div>
  //       <Grid container direction="row" className="intraHospitalContainer">
  //         <Grid item xs={3} className="departmentContainer">
  //           <Grid item xs={4} className="titleTextField">
  //             Sectia:
  //           </Grid>
  //           <Grid item xs={8} className="textField">
  //             {(obj && obj["Department"]) || (
  //               <div className="objectValueEmpty" />
  //             )}
  //           </Grid>
  //         </Grid>

  //         <Grid item xs={3} className="admissionContainer">
  //           <Grid item xs={12} className="admissionDateContainer">
  //             <Grid item xs={5} className="titleTextField">
  //               Data Intrarii:
  //             </Grid>

  //             <Grid item xs={7} className="textField">
  //               {obj["AdmissionDate"] !== null ? (
  //                 moment(obj["AdmissionDate"]).format("DD/MM/YYYY")
  //               ) : (
  //                 <div className="objectValueEmpty" />
  //               )}
  //             </Grid>
  //           </Grid>
  //           <Grid item xs={12} className="admissionHourContainer">
  //             <Grid item xs={5} className="titleTextField">
  //               Ora Intrarii:
  //             </Grid>
  //             <Grid item xs={7} className="textField">
  //               {obj["AdmissionHour"] !== null ? (
  //                 moment(obj["AdmissionHour"]).format("HH:MM")
  //               ) : (
  //                 <div className="objectValueEmpty" />
  //               )}
  //             </Grid>
  //           </Grid>
  //         </Grid>

  //         <Grid item xs={3} className="dischargeContainer">
  //           <Grid item xs={12} className="dischargeDateContainer">
  //             <Grid item xs={5} className="titleTextField">
  //               Data Iesirii:
  //             </Grid>
  //             <Grid item xs={7} className="textField">
  //               {obj["DischargeDate"] !== null ? (
  //                 moment(obj["DischargeDate"]).format("DD/MM/YYYY")
  //               ) : (
  //                 <div className="objectValueEmpty" />
  //               )}
  //             </Grid>
  //           </Grid>
  //           <Grid item xs={12} className="dischargeHourContainer">
  //             <Grid item xs={5} className="titleTextField">
  //               Ora Iesirii:
  //             </Grid>
  //             <Grid item xs={7} className="textField">
  //               {obj["DischargeHour"] !== null ? (
  //                 moment(obj["DischargeHour"]).format("HH:MM")
  //               ) : (
  //                 <div className="objectValueEmpty" />
  //               )}
  //             </Grid>
  //           </Grid>
  //         </Grid>

  //         <Grid item xs={3} className="nrOfDaysContainer">
  //           <Grid item xs={8} className="titleTextField">
  //             Nr. zile spit.
  //           </Grid>
  //           <Grid item xs={4} className="textField">
  //             {/* {(obj && obj["NrOfDays"] + "") || (
  //               <div className="objectValueEmpty" />
  //             )} */}
  //             {obj &&
  //             obj["NrOfDays"] !== null &&
  //             obj &&
  //             obj["NrOfDays"] !== undefined ? (
  //               obj && obj["NrOfDays"] + ""
  //             ) : (
  //               <div className="objectValueEmpty" />
  //             )}
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </div>
  //   );
  // };

  const renderRowSurgery = (obj) => {
    return (
      <div>
        <Grid item xs={12} className="surgicalDRGTextFieldContainer">
          <Grid item xs={3} className="titleTextField">
            Codul Drg:
          </Grid>
          <Grid item xs={9} className="textField">
            {(obj && obj["drg"] && obj["drg"]["procedureCode"] !== null) ||
            (obj && obj["drg"] && obj["drg"]["description"] !== null) ? (
              obj["drg"]["procedureCode"] + " - " + obj["drg"]["description"]
            ) : (
              <div className="objectValueEmpty" />
            )}
          </Grid>
        </Grid>
        {obj["consent"] !== null ? (
          <Grid item xs={12} className="surgicalDRGTextFieldContainer">
            <Grid item xs={3} className="titleTextField">
              Consimtamantul pentru interventie:
            </Grid>
            <Grid item xs={9} className="textField">
              {(obj && obj["consent"]) || <div className="objectValueEmpty" />}
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )}
        <div className="answerBox">
          <Grid container direction="row" className="surgicalTeamContainer">
            <Grid item xs={3} className="surgicalTeamTextContainer">
              <p className="textContainer">Echipa operatorie:</p>
            </Grid>

            <Grid item xs={9} className="surgicalTeamInputsContainer">
              <Grid item xs={12} className="mainOperator">
                <Grid item xs={4} className="titleTextField">
                  Medic operator principal:
                </Grid>
                <Grid item xs={8} className="textField">
                  {(obj && obj["mainMedic"]) || (
                    <div className="objectValueEmpty" />
                  )}
                </Grid>
              </Grid>
              <Grid container direction="row" className="operatorsContainer">
                <Grid item xs={6} className="otherOperator">
                  <Grid item xs={4} className="titleTextField">
                    Medic operator II:
                  </Grid>
                  <Grid item xs={8} className="textField">
                    {(obj && obj["secondMedic"]) || (
                      <div className="objectValueEmpty" />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={6} className="otherOperator">
                  <Grid item xs={4} className="titleTextField">
                    Medic ATI:
                  </Grid>
                  <Grid item xs={8} className="textField">
                    {(obj && obj["atiMedic"]) || (
                      <div className="objectValueEmpty" />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="row" className="operatorsContainer">
                <Grid item xs={6} className="otherOperator">
                  <Grid item xs={4} className="titleTextField">
                    Medic operator III:
                  </Grid>
                  <Grid item xs={8} className="textField">
                    {(obj && obj["thirdMedic"]) || (
                      <div className="objectValueEmpty" />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={6} className="otherOperator">
                  <Grid item xs={4} className="titleTextField">
                    Asistent/a:
                  </Grid>
                  <Grid item xs={8} className="textField">
                    {(obj && obj["assistant"]) || (
                      <div className="objectValueEmpty" />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid item xs={12} className="surgicalDRGTextFieldContainer">
          <Grid item xs={3} className="titleTextField">
            Data operatiei:
          </Grid>
          <Grid item xs={9} className="textField">
            {obj["surgeryDate"] !== null ? (
              moment(obj["surgeryDate"]).format("DD/MM/YYYY")
            ) : (
              <div className="objectValueEmpty" />
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderRowRadiologicalAndFunc = (obj) => {
    return (
      <Grid container className="objectContainer">
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <Grid item xs={3} className="objectKey">
            Codul Drg:
          </Grid>
          <Grid item xs={9} className="objectValue">
            {(obj && obj["drg"] && obj["drg"]["procedureCode"] !== null) ||
            (obj && obj["drg"] && obj["drg"]["description"] !== null) ? (
              obj["drg"]["procedureCode"] + " - " + obj["drg"]["description"]
            ) : (
              <div className="objectValueEmpty" />
            )}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <Grid item xs={3} className="objectKey">
            Interpretare:
          </Grid>
          <Grid item xs={9} className="objectValue">
            {(obj && obj["interpretare"]) || (
              <div className="objectValueEmpty" />
            )}
          </Grid>
        </Grid>
        {obj["files"].map((item, index) => {
          return (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "40px",
              }}
            >
              <Grid item xs={3} className="objectKey">
                Documente:
              </Grid>
              <Grid item xs={9} className="objectValue">
                <aside
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginTop: 16,
                  }}
                  onClick={handleExpandPhoto}
                >
                  {thumbs(item, index)}
                </aside>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderRowArray = (key, value, index) => {
    return (
      <Grid
        item
        xs={12}
        key={`secondOpinion-${index}`}
        className="objectContainer"
      >
        <Grid item xs={2} className="objectKey">
          <p>{typeTitles[key]}: </p>
        </Grid>
        <Grid item xs={10} className="objectValue">
          {value || <div className="objectValueEmpty" />}
        </Grid>
      </Grid>
    );
  };

  // map through objects
  // eslint-disable-next-line
  const mapThroughObjectKeys = (obj, ...args) => {
    if (obj) {
      return (
        <Grid item xs={12} className="objectContainerObjArray">
          <p className="objectContainerObjArrayTitle">
            {typeTitles[args[0]] || ""}
          </p>
          {Object.entries(obj).map(([key, value], index) => {
            if (key === "id") {
              return null;
            } else {
              return renderRowObj(key, value, args[1] + index);
            }
          })}
        </Grid>
      );
    } else {
      return;
    }
  };
  const renderRowObj = (key, value, index) => {
    return (
      <Grid
        item
        xs={12}
        key={`secondOpinion-${index}`}
        className="objectContainer"
      >
        <Grid item xs={3} className="objectKey">
          <p>{typeTitles[key]}: </p>
        </Grid>
        <Grid item xs={9} className="objectValue">
          {value || <div className="objectValueEmpty" />}
        </Grid>
      </Grid>
    );
  };

  // objects
  const renderRow = (key, value, index) => {
    return (
      <Grid
        item
        xs={12}
        key={`secondOpinion-${index}`}
        className="objectContainer"
      >
        <Grid item xs={3} className="objectKey">
          <p>{typeTitles[key]}: </p>
        </Grid>
        <Grid item xs={9} className="objectValue">
          {value === true
            ? (value = "Da")
            : value === false
            ? (value = "Nu")
            : value === null
            ? (value = <div className="objectValueEmpty" />)
            : key === "intraDeathDate"
            ? (value = moment(value).format("DD/MM/YYYY"))
            : key === "pacientDocuments" || key === "operatorADocuments"
            ? value === ""
            : value || <div className="objectValueEmpty" />}
          {examSwitchFunc(key)}
        </Grid>
      </Grid>
    );
  };

  const parseObj = () => {
    if (secondOpinionFormState !== null) {
      return Object.entries(secondOpinionMock).map(([key, value], index) => {
        let html;

        if (isArray(value)) {
          if (secondOpinionFormState && secondOpinionFormState[key] !== null) {
            html = mapThroughValue(key, secondOpinionFormState[key], index);
          }
        } else if (isObject(value)) {
          if (secondOpinionFormState && secondOpinionFormState[key] !== null) {
            html = mapThroughObjectKeys(
              secondOpinionFormState[key],
              key,
              index
            );
          }
        } else {
          if (secondOpinionFormState && secondOpinionFormState[key] !== null) {
            html = renderRow(key, secondOpinionFormState[key], index);
          }
        }

        return html;
      });
    } else {
      return (
        <div>
          <h3>Nu avem date despre pacient</h3>
        </div>
      );
    }
  };

  return (
    <Grid container className="secondOpinionBody">
      {parseObj()}

      <Modal
        open={openImgModal}
        onClose={handleCloseSecondModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(3px)",
          width: "100%",
          height: "100%",
        }}
      >
        <div>
          <ExpandedThumbs />
        </div>
      </Modal>
    </Grid>
  );
};
export default SecondOpinionBody;
