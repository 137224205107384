import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const MonthPicker = (props) => {
  const { year, monthToSet, monthSplit } = props;

  const [febDays, setFebDays] = useState();

  const allMonths = [
    { month: "Ianurie", days: 31, nrMonth: "01" },
    { month: "Februarie", days: febDays ? 29 : 28, nrMonth: "02" },
    { month: "Martie", days: 31, nrMonth: "03" },
    { month: "Aprilie", days: 30, nrMonth: "04" },
    { month: "Mai", days: 31, nrMonth: "05" },
    { month: "Iunie", days: 30, nrMonth: "06" },
    { month: "Iulie", days: 31, nrMonth: "07" },
    { month: "August", days: 31, nrMonth: "08" },
    { month: "Septembrie", days: 30, nrMonth: "09" },
    { month: "Octombrie", days: 31, nrMonth: "10" },
    { month: "Noiembrie", days: 30, nrMonth: "11" },
    { month: "Decembrie", days: 31, nrMonth: "12" },
  ];

  let newDate = new Date();
  let currentMonth = allMonths[newDate.getMonth()].month;

  const [anchorEl, setAnchorEl] = useState(null);
  const [monthName, setMonthName] = useState(monthSplit);
  const [monthNr, setMonthNr] = useState("");

  const leapYear = () => {
    if (year !== null || year !== undefined || year !== null) {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    setFebDays(leapYear());
    setAnchorEl(null);
    setMonthName(item ? item.month : currentMonth);
    // month(item.nrMonth)
    setMonthNr(item ? item.nrMonth : currentMonth);
  };

  // eslint-disable-next-line
  useEffect(() => { }, [febDays]);

  useEffect(() => {
    monthToSet(monthNr);
    // eslint-disable-next-line
  }, [monthName]);

  return (
    <div className="monthPicker">
      <div className="monthPickerLabel">
        <p>Luna:</p>
      </div>
      <div className="monthPickerInputContainer">
        <Button
          onClick={handleClick}
          className="monthPickerInput"
          endIcon={<ArrowDropDownIcon />}
        >
          {monthName !== undefined ? monthName : currentMonth}
        </Button>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {allMonths.map((item, index) => {
            return (
              <MenuItem
                key={`month_picker_${index}`}
                onClick={() => handleClose(item)}
              >
                {item.month}
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    </div>
  );
};

export default MonthPicker;
