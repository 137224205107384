const dateGenerale = [
  {
    title: "",
    key: "lastName",
    label: "Nume",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "firstName",
    label: "Prenume",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },

  {
    key: "cnp",
    label: "CNP",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      isDisabled: false,
    },
  },

  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "serial",
    label: "C.I / B.I seria:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },

  {
    key: "birthSerialId",
    label: "Certificat  nastere (copil) seria:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },

  {
    title: "",
    key: "birthdate",
    label: "Data nașterii",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
      isDisabled: false,
    },
  },
  {
    key: "cas",
    label: "CAS",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: [
        "CASAOPSNAJ",
        "TRANSPORTURI",
        "CASMB",
        "JUDETEANA",
        "TALON DE PENSIE",
      ],
      isDisabled: false,
    },
  },
  {
    key: "casNumber",
    label: "Numar CAS",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "sex",
    label: "Sexul",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: ["Male", "Female"],
    },
  },
  {
    title: "",
    key: "bloodType",
    label: "Grup sangvin",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: ["0", "A", "B", "AB", "unknown"],
      selectListName: ["0 I", "A II", "B III", "AB IV", "Nu cunosc"],
    },
  },
  {
    title: "",
    key: "rh",
    label: "RH",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: ["negativ", "pozitiv", "unknown"],
      selectListName: ["Negativ", "Pozitiv", "Nu Cunosc"],
    },
  },
];

export default dateGenerale;
