const tipBFormSchema = {
  lastName: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
    length: {
      maximum: 32,
    },
  },
  firstName: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
    length: {
      maximum: 32,
    },
  },
  birthDate: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
    // format: {
    //   pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
    //   message: "formatul cerut este ZZ/LL/AAAA",
    // },
  },

  CNP: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
    length: {
      maximum: 13,
      minimum: 13,
      message: "este format din 13 cifre",
    },
    format: {
      pattern: /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/,
      message: "nu este valid",
    },
  },
  serialNumber: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
    length: {
      maximum: 6,
      minimum: 6,
      message: "este format din 6 cifre",
    },
    format: {
      pattern: /^[0-9]*$/,
      message: "nu este valid",
    },
  },
  serialId: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
    length: {
      maximum: 2,
      minimum: 2,
      message: "este format din 2 litere",
    },
    format: {
      pattern: /^[A-z]+$/,
      message: "nu este valid",
    },
  },
  email: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
    length: {
      maximum: 64,
      message: "accepta maximum 64 caractere",
    },
    email: {
      message: "nu este valid",
    },
  },

  profession: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
    length: {
      maximum: 32,
    },
  },
  county: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
    length: {
      maximum: 124,
    },
  },
  city: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
    length: {
      maximum: 124,
    },
  },
  street: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
    length: {
      maximum: 124,
    },
  },
  streetNr: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
    length: {
      maximum: 124,
    },
  },
  cas: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },
  hasTicket: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },
  whereIsThePain: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
    length: {
      maximum: 128,
    },
  },
  alergies: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
    length: {
      maximum: 32,
    },
  },

  inGenTreatment: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },
  inAntiCoagulantTreatment: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },
  inOsteoTreatment: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },
  hadSurgery: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },

  prevTreatmentIssues: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },
  congenital: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },
  peachemaker: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },
  bloodType: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },
  rh: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },

  alcoholTobacco: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },
  selectData: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },

  finalConfirmDate: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },
  finalConfirmHour: {
    presence: { allowEmpty: false, message: "necesita completarea campului" },
  },
};

export default tipBFormSchema;
