import React from 'react'
import CommentIcon from '@material-ui/icons/Comment';

const FloatingChat = (props) => {
    const { handleMessages } = props;
  
    const handleVisibility = () => {
        handleMessages();
    };
  
    return (
      <div>
        <CommentIcon
          className="floatingSave"
          onClick={handleVisibility}
          style={{ height: 50, width: 50 }}
        />
      </div>
    );
  };
  
  export default FloatingChat;