import { Card, Modal } from "@material-ui/core";
import { createBrowserHistory } from "history";
import MicRecorder from "mic-recorder-to-mp3";
import React, { useEffect, useState } from "react";
import stopRecord from "../../../../../../assets/images/apps/record/pause.svg";
import startRecord from "../../../../../../assets/images/apps/record/rec.svg";
import { REST } from "../../../../../../Utils";
import Button from "../Buttons1/index";
import DatePicker from "../FormsDatepicker1/index";

const browserHistory = createBrowserHistory();

export default function LivePreviewExample(props) {
  const { ticketId, evolutionData } = props;
  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const [listArray, setListArray] = useState([{}]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState({ message: null });
  const [speechE, setSpeechE] = useState("");
  const [speechT, setSpeechT] = useState("");
  // eslint-disable-next-line
  const [patientName, setPatientName] = useState("");

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [date, setDate] = useState("");
  const [evolution, setEvolution] = useState("");
  const [treatment, setTreatment] = useState("");
  const [evolutionToShow, setEvolutionToShow] = useState("");
  const [treatmentToShow, setTreatmentToShow] = useState("");
  const [showMore, setShowMore] = useState([]);

  const [interpretareE, setInterpretareE] = useState(null);
  const [interpretareT, setInterpretareT] = useState(null);

  const currentPage = browserHistory.location.pathname;

  useEffect(() => {
    if (evolution.length > 200) {
      setEvolutionToShow(evolution.substring(0, 200) + "...");
    } else {
      setEvolutionToShow(evolution);
    }
    // eslint-disable-next-line
  }, [evolution]);

  useEffect(() => {
    if (treatment.length > 200) {
      setTreatmentToShow(treatment.substring(0, 200) + "...");
    } else {
      setTreatmentToShow(treatment);
    }
    // eslint-disable-next-line
  }, [treatment]);

  const handleSpeechE = (answer) => {
    setSpeechE(answer);
  };

  const handleSpeechT = (answer) => {
    setSpeechT(answer);
  };

  const getData = async (entryId) => {
    await REST.get(`/spital/getTicketById/?id=${entryId}`)
      .then((answer) => {
        setError({ message: null });
        setListArray(answer.data.evolutionSheet.evolutionList);
        setPatientName(answer.data.patientName);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          setError({
            message,
          });
        }
        setIsPageLoading(false);
      });
  };

  useEffect(() => {
    if (currentPage !== "/second-opinion") {
      //  setListArray(data);
    } else {
      setListArray(evolutionData);
    }

    getData(ticketId);
    // eslint-disable-next-line
  }, []);

  const addItem = async () => {
    if (date === "" && evolution === "" && treatment === "") {
      handleCloseModal();
    } else if (date !== "" && evolution === "" && treatment === "") {
      handleCloseModal();
    } else {
      var body = {
        date: date,
        evolution: evolution,
        evolutionToShow: evolutionToShow,
        treatment: treatment,
        treatmentToShow: treatmentToShow,
      };

      listArray.push(body);

      var bodyToSend = {
        evolutionList: listArray,
      };

      await REST.post(
        `/spital/addEvolutionSheet?ticketId=${ticketId}`,
        bodyToSend
      )
        .then((answer) => {
          setError({ message: null });
          getData(ticketId);
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            setError({
              message,
            });
          }
        });

      handleCloseModal();
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDate("");
    setEvolution("");
    setTreatment("");
    setResponse("");
    setSpeechE("");
    setSpeechT("");
    setInterpretareE("");
    setInterpretareT("");
  };

  const handleEvolutionChange = (event) => {
    setEvolution(event.target.value);
    setInterpretareE(event.target.value);
  };
  const handleTreatmentChange = (event) => {
    setTreatment(event.target.value);
    setInterpretareT(event.target.value);
  };

  const handleShowMore = (item) => {
    if (showMore.includes(item)) {
      setShowMore(showMore.filter((el) => el !== item));
    } else {
      setShowMore([...showMore, item]);
    }
  };

  // --------------- add recorder -----------

  const [isRecordinE, setIsRecordingE] = useState(false);
  const [isRecordinT, setIsRecordingT] = useState(false);
  const [isBlocked, setIsBlocked] = useState(true);
  // eslint-disable-next-line
  const [blobURL, setBlobURL] = useState("");
  // eslint-disable-next-line
  const [recorder, setRecorder] = useState(new MicRecorder({ bitRate: 128 }));
  // eslint-disable-next-line
  const [respose, setResponse] = useState("");

  useEffect(() => {
    navigator.getUserMedia(
      // navigator.mediaDevices.getUserMedia(
      { audio: true },
      () => {
        setIsBlocked(false);
      },
      () => {
        setIsBlocked(true);
      }
    );
    // eslint-disable-next-line
  }, []);

  const startRecordingE = () => {
    if (isBlocked) {
    } else {
      setResponse("");
      handleSpeechE("");
      recorder
        .start()
        .then(() => {
          setIsRecordingE(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const startRecordingT = () => {
    if (isBlocked) {
    } else {
      setResponse("");
      handleSpeechT("");
      recorder
        .start()
        .then(() => {
          setIsRecordingT(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecordingE = async () => {
    await recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setBlobURL(blobURL);

        var f = new FileReader();
        f.onload = async function(e) {
          const body = "body=" + e.target.result.toString("base64").slice(37);
          await REST.post(`/spital/speechToText?ticketId=${ticketId}`, body)
            .then((answer) => {
              setError({ message: null });
              setResponse(answer.data);
              setEvolution(answer.data);
              handleSpeechE(answer.data);
            })
            .catch((err) => {
              if (err && err.response && err.response.data) {
                const { message } = err.response.data;
                setError({
                  message,
                });
              }
            });
        };
        f.readAsDataURL(new Blob(buffer));
        setIsRecordingE(false);
      });
  };

  const stopRecordingT = async () => {
    await recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setBlobURL(blobURL);

        var f = new FileReader();
        f.onload = async function(e) {
          const body = "body=" + e.target.result.toString("base64").slice(37);
          await REST.post(`/spital/speechToText?ticketId=${ticketId}`, body)
            .then((answer) => {
              setError({ message: null });
              setResponse(answer.data);
              setTreatment(answer.data);
              handleSpeechT(answer.data);
            })
            .catch((err) => {
              if (err && err.response && err.response.data) {
                const { message } = err.response.data;
                setError({
                  message,
                });
              }
            });
        };
        f.readAsDataURL(new Blob(buffer));
        setIsRecordingT(false);
      });
  };
  // --------------- end of recoreder ---------

  if (isPageLoading) {
    return <div></div>;
  } else {
    return (
      <div>
        <div className={"EvolutionList"}>
          <div className={"modal__container"}>
            <Modal
              open={isModalOpen}
              onClose={handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backdropFilter: "blur(3px)",
                width: "100%",
                height: "100%",
              }}
            >
              <div>
                <Card
                  style={{
                    backgroundColor: "lightgray",
                    height: "620px",
                    width: "495px",
                    padding: "20px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p style={{ fontWeight: "900", marginTop: "25px" }}>
                      1. Selecteaza Data:
                    </p>
                    <div style={{}}>
                      <DatePicker
                        setDate={setDate}
                        date={date}
                        isModalOpen={isModalOpen}
                      ></DatePicker>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "1em",
                    }}
                  >
                    <p style={{ fontWeight: "900", marginTop: "20px" }}>
                      2. Noteaza Evolutie:
                    </p>
                    <div>
                      {isRecordinE ? (
                        <div onClick={() => stopRecordingE()}>
                          <img src={stopRecord} alt={""} />
                        </div>
                      ) : (
                        <div onClick={() => startRecordingE()}>
                          <img src={startRecord} alt={""} />
                        </div>
                      )}
                    </div>
                  </div>

                  <textarea
                    type="text"
                    onChange={handleEvolutionChange}
                    value={interpretareE || speechE || ""}
                    style={{
                      padding: "12px 20px",
                      height: "150px",
                      width: "450px",
                      border: "1px solid black",
                      borderRadius: "15px",
                      resize: "none",
                    }}
                  ></textarea>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "1em",
                    }}
                  >
                    <p style={{ fontWeight: "900", marginTop: "20px" }}>
                      3. Noteaza Tratament:
                    </p>
                    <div>
                      {isRecordinT ? (
                        <div onClick={() => stopRecordingT()}>
                          <img src={stopRecord} alt={""} />
                        </div>
                      ) : (
                        <div onClick={() => startRecordingT()}>
                          <img src={startRecord} alt={""} />
                        </div>
                      )}
                    </div>
                  </div>
                  <textarea
                    type="text"
                    onChange={handleTreatmentChange}
                    value={interpretareT || speechT || ""}
                    style={{
                      padding: "12px 20px",
                      height: "150px",
                      width: "450px",
                      border: "1px solid black",
                      borderRadius: "15px",
                      resize: "none",
                    }}
                  ></textarea>
                  <Button onClick={addItem} text="ADAUGA"></Button>
                </Card>
              </div>
            </Modal>
          </div>

          <div className={"header"}>
            <h1 className={"header__text"}> Foaie Evolutie si Tratament</h1>

            <div className={"header__button"}>
              {currentPage !== "/second-opinion" ? (
                <Button onClick={handleOpenModal} text="ADAUGA"></Button>
              ) : null}
            </div>
          </div>
          <div className={"list__container"}>
            <ul className={"list"}>
              <li className={"list__header__date"}>
                <p className={"list__header__date__text"}>Data</p>
              </li>
              <li className={"list__header__evolution"}>
                <p className={"list__header__evolution__text"}>Evolutie</p>
              </li>
              <li className={"list__header__treatment"}>
                <p className={"list__header__treatment__text"}>Tratament</p>
              </li>
            </ul>

            {listArray
              .map((item, index) => (
                <div key={index}>
                  <ul className={"list__content"}>
                    <li className={"list__item__date"}>
                      <p className={"list__item__date__text"}>{item.date}</p>
                      <div className={"list__item__date__button"}>
                        <Button
                          onClick={() => handleShowMore(item)}
                          text={
                            !showMore.includes(item)
                              ? "Vezi mai mult"
                              : "Vezi mai putin"
                          }
                        ></Button>
                      </div>
                    </li>
                    {!showMore.includes(item) ? (
                      <li className={"list__item__evolution"}>
                        <p className={"list__item__evolution__text"}>
                          {item.evolutionToShow}
                        </p>
                      </li>
                    ) : (
                      <li className={"list__item__evolution"}>
                        <p className={"list__item__evolution__text"}>
                          {item.evolution}
                        </p>
                      </li>
                    )}
                    {!showMore.includes(item) ? (
                      <li className={"list__item__treatment"}>
                        <p className={"list__item__treatment__text"}>
                          {item.treatmentToShow}
                        </p>
                      </li>
                    ) : (
                      <li className={"list__item__treatment"}>
                        <p className={"list__item__treatment__text"}>
                          {item.treatment}
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
              ))
              .reverse()}
          </div>
        </div>
      </div>
    );
  }
}
