const addToArrayHelper = (arrAccumulator, valueToAdd) => {
  if (Array.isArray(arrAccumulator) && arrAccumulator.length) {
    // if (Array.isArray(valueToAdd) && valueToAdd.length) {
    //   return [...arrAccumulator, ...valueToAdd];
    // }
    return [...arrAccumulator, valueToAdd];
  } else {
    return [valueToAdd];
  }
};

export default addToArrayHelper;
