import DateFnsUtils from "@date-io/date-fns";
import { Button, Card, Grid, Modal } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MicRecorder from "mic-recorder-to-mp3";
import React, { useEffect, useState } from "react";
import stopRecord from "../../assets/images/apps/record/pause.svg";
import startRecord from "../../assets/images/apps/record/rec.svg";
import { beURL, REST } from "../../Utils";
import AutocompleteDRGInput from "./AutocompleteDRGInput";
import DropzonePictures from "./DropzonePictures";
import {
  expandedThumb,
  thumb,
  thumbInner,
  thumbsContainer,
} from "./styles/radiologie";

const RadiologicalInvestigations = (props) => {
  const {
    item,
    formState,
    handleChange,
    hasError,
    handleBlur,
    hideAddButton,
    ticketId,
    index,
  } = props;

  // eslint-disable-next-line
  const [error, setError] = useState({ message: null });
  const [inputs, setInputs] = useState(
    (formState &&
      formState.values &&
      formState.values[item.key] &&
      Array.isArray(formState.values[item.key]) &&
      formState.values[item.key].map(() => "")) ||
      []
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  // eslint-disable-next-line
  const [images, setImages] = useState([]);
  const [isSecondModalOpen, setSecondIsModalOpen] = useState(false);
  // eslint-disable-next-line
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [edit, setEdit] = useState({
    currentIndex: index ? index : -1,
    list: formState.values[item.key] ? formState.values[item.key] : [],
  });

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [date, setDate] = useState(new Date());

  // eslint-disable-next-line
  const [drg, setDrg] = useState("");
  // eslint-disable-next-line
  const [interpretare, setInterpretare] = useState(null);

  // eslint-disable-next-line
  const [files, setFiles] = useState([]);
  const [speech, setSpeech] = useState("");

  // eslint-disable-next-line
  const [photosPath, setPhotosPath] = useState([]);

  const [modalInputs, setModalInputs] = useState({
    drg: {},
    date: new Date(),
    interpretare: "",
    files: [],
  });

  const handleShowFiles = (val) => {
    var newArr = [];
    val.map((item) => newArr.push(item));
    setPhotosPath(newArr);

    let list = edit.list ? edit.list : [];

    list[edit.currentIndex] = list[edit.currentIndex]
      ? list[edit.currentIndex]
      : { files: [] };
    list[edit.currentIndex].files = val;

    setEdit({
      currentIndex: edit.currentIndex,
      list: list,
    });
  };

  const handleCloseSecondModal = () => {
    setSecondIsModalOpen(false);
  };

  React.useEffect(() => {
    var currentDate = getCurrentDay();
    setSelectedDate(currentDate);
    setDate(currentDate);
    // eslint-disable-next-line
  }, [isModalOpen === true]);

  React.useEffect(() => {
    setDate(selectedDate);
    // eslint-disable-next-line
  }, [selectedDate]);

  React.useEffect(() => {
    if (edit.list && edit.list.length === 0) {
      getData(ticketId);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setImages([]);
    setHasSaved(false);
    // eslint-disable-next-line
  }, [hasSaved]);

  const addInput = () => {
    setInputs([...inputs, ""]);
  };

  // eslint-disable-next-line
  const removeInput = () => {
    setInputs(inputs.splice(1));
  };

  var itemName = item.key;

  const getData = async (entryId) => {
    await REST.get(`/spital/getTicketById/?id=${entryId}`)
      .then((answer) => {
        if (item.key === "radiologicInvestigations") {
          setEdit({
            currentIndex: index,
            list:
              answer.data.radiologicalInvestigations.RadiologicalInvestigations,
          });
          setInputs(
            answer.data.radiologicalInvestigations.RadiologicalInvestigations.map(
              (el) => ""
            )
          );
        } else if (item.key === "funcExplorations") {
          setEdit({
            currentIndex: index,
            list: answer.data.functionalExplorations.RadiologicalInvestigations,
          });
          setInputs(
            answer.data.radiologicalInvestigations.RadiologicalInvestigations.map(
              (el) => ""
            )
          );
        }
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          setError({ message });
        }
      });
  };
  const handleEdit = (item, index) => {
    setIsModalOpen(true);

    let list = edit.list ? edit.list : [];
    setEdit({
      list: list,
      currentIndex: index,
    });
    setModalInputs(
      edit.list && edit.list[index]
        ? edit.list[index]
        : { drg: null, date: new Date(), interpretare: "", files: [] }
    );

    setSpeech(null);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    let list = edit.list;
    if (edit.currentIndex >= 0) {
      list[edit.currentIndex] = {
        date,
        drg,
        interpretare,
        files,
      };
    }
    // eslint-disable-next-line
  }, [date, drg, interpretare, files]);

  const handleSave = async () => {
    var body = {
      radiologicInvestigations: edit.list,
    };

    if (item.key === "radiologicInvestigations") {
      await REST.post(
        `/spital/addRadiologicalInvestigations?ticketId=${ticketId}`,
        body
      )
        .then((answer) => {
          handleChange({
            target: {
              name: "radiologicInvestigations",
              value: edit.list,
            },
            persist: () => true,
          });
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            setError({
              message,
            });
          }
        });
    } else if (item.key === "funcExplorations") {
      // eslint-disable-next-line
      var body = {
        funcExplorations: edit.list,
      };
      await REST.post(
        `/spital/addFunctionalExplorations?ticketId=${ticketId}`,
        body
      )
        .then((answer) => {
          handleChange({
            target: {
              name: "funcExplorations",
              value: edit.list,
            },
            persist: () => true,
          });
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            setError({
              message,
            });
          }
        });
    }

    setHasSaved(true);
    setIsModalOpen(false);
  };

  const handleDateChange = (date) => {
    changeSelectedDateFormat(date);
  };

  const changeSelectedDateFormat = (date) => {
    date = date === undefined ? new Date() : date;
    handleMyChange({
      target: {
        value: date,
        name: "date",
      },
    });
  };

  useEffect(() => {
    if (edit.list) {
      let editList = JSON.parse(JSON.stringify(edit.list));
      editList[edit.currentIndex] = modalInputs;
      setEdit({
        currentIndex: edit.currentIndex,
        list: editList,
      });
    }
    // eslint-disable-next-line
  }, [modalInputs]);

  const getCurrentDay = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  };

  const handleSpeech = (answer) => {
    setSpeech(answer);
    formState.values[itemName] = answer;
  };
  useEffect(() => {
    if (speech && speech.trim() !== "") {
      let list = edit.list;
      if (list[edit.currentIndex] === undefined) {
        list[edit.currentIndex] = {};
      }
      list[edit.currentIndex]["interpretare"] = speech;
      setEdit({
        currentIndex: edit.currentIndex,
        list: list,
      });
    }
    // eslint-disable-next-line
  }, [speech]);

  const thumbs = () => {
    if (edit.list && edit.list[edit.currentIndex])
      return edit.list[edit.currentIndex]
        ? edit.list[edit.currentIndex].files
          ? edit.list[edit.currentIndex].files.map((item, index) => {
              return (
                <div style={thumb} key={`${index}--files-photo-plm`}>
                  <div style={thumbInner} onClick={expandedThumbs}>
                    <img
                      src={`${beURL}/spital/getImage?path=${item}&ticketId=${ticketId}`}
                      alt={""}
                    />
                  </div>
                </div>
              );
            })
          : []
        : [];
  };

  const expandedThumbs = () => {
    if (edit.list && edit.list[edit.currentIndex])
      return edit.list[edit.currentIndex]
        ? edit.list[edit.currentIndex].files
          ? edit.list[edit.currentIndex].files.map((item, index) => {
              return (
                <div
                  style={expandedThumb}
                  key={`${index}--files-photo-plm`}
                  onClick={() => setSecondIsModalOpen(false)}
                >
                  <div style={thumbInner} onClick={handleExpandPhoto}>
                    <img
                      src={`${beURL}/spital/getImage?path=${item}&ticketId=${ticketId}`}
                      alt={""}
                    />
                  </div>
                </div>
              );
            })
          : []
        : [];
  };
  const handleExpandPhoto = () => {
    setSecondIsModalOpen(true);
  };
  // --------------- add recorder -----------

  const [isRecordin, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(true);
  // eslint-disable-next-line
  const [blobURL, setBlobURL] = useState("");
  // eslint-disable-next-line
  const [recorder, setRecorder] = useState(new MicRecorder({ bitRate: 128 }));
  // eslint-disable-next-line
  const [respose, setResponse] = useState("");

  useEffect(() => {
    navigator.getUserMedia(
      // navigator.mediaDevices.getUserMedia(
      { audio: true },
      () => {
        setIsBlocked(false);
      },
      () => {
        setIsBlocked(true);
      }
    );
  }, []);

  const startRecording = () => {
    if (isBlocked) {
    } else {
      setResponse("");
      handleSpeech("");
      recorder
        .start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = async () => {
    await recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setBlobURL(blobURL);

        var f = new FileReader();
        f.onload = async function(e) {
          const body = "body=" + e.target.result.toString("base64").slice(37);
          await REST.post(`/spital/speechToText?ticketId=${ticketId}`, body)
            .then((answer) => {
              setError({ message: null });
              setResponse(answer.data);
              handleSpeech(answer.data);
            })
            .catch((err) => {
              if (err && err.response && err.response.data) {
                const { message } = err.response.data;
                setError({
                  message,
                });
              }
            });
        };
        f.readAsDataURL(new Blob(buffer));
        setIsRecording(false);
      });
  };

  // --------------- end of recoreder ---------

  const handleMyChange = (event, key, name, index) => {
    if (key === undefined) {
      key = event.target.name;
    }
    let vals = {
      ...modalInputs,
      [key]: event.target.value,
    };

    setModalInputs(vals);
  };

  return (
    <div
      className="answerBox"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(3px)",
          width: "100%",
          height: "100%",
        }}
      >
        <div>
          <Card
            style={{
              backgroundColor: "lightgray",
              height: "800px",
              width: "495px",
              padding: "20px",
              overflowY: "scroll",
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <p style={{ fontWeight: "900", marginTop: "25px" }}>
                  1. Selecteaza Data:
                </p>
                <Card
                  style={{
                    height: "35px",
                    width: "200px",
                    marginTop: "20px",
                    marginRight: " 90px",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="m-4">
                      <KeyboardDatePicker
                        item={{ key: "radiological" }}
                        style={{ marginTop: "-20px" }}
                        disableToolbar
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        name={"date"}
                        value={modalInputs.date ? modalInputs.date : new Date()}
                        onChange={(event) => handleDateChange(event)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <p style={{ fontWeight: "900", marginTop: "20px" }}>
                  4. Numarul:
                </p>
                <AutocompleteDRGInput
                  item={{ key: "drg", answer: item.answer }}
                  formState={{
                    values: {
                      drg: modalInputs.drg ? modalInputs.drg : null,
                    },
                  }}
                  handleChange={(event, a, b, c) => {
                    handleMyChange(
                      { target: { value: event.target.value } },
                      "drg",
                      itemName,
                      index
                    );
                  }}
                  handleBlur={handleBlur}
                  hasError={hasError}
                  hideLabel={true}
                  showNumberInput={item.answer.showNumberInput}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1em",
                  }}
                >
                  <p style={{ fontWeight: "900", marginTop: "20px" }}>
                    5. Interpretare:
                  </p>
                  <div>
                    {isRecordin ? (
                      <div onClick={() => stopRecording()}>
                        <img src={stopRecord} alt={""} />
                      </div>
                    ) : (
                      <div onClick={() => startRecording()}>
                        <img src={startRecord} alt={""} />
                      </div>
                    )}
                  </div>
                </div>
                <textarea
                  type="text"
                  name={"interpretare"}
                  onChange={handleMyChange}
                  value={
                    modalInputs.interpretare ? modalInputs.interpretare : null
                  }
                  style={{
                    padding: "12px 20px",
                    height: "250px",
                    width: "450px",
                    border: "1px solid black",
                    borderRadius: "15px",
                    resize: "none",
                  }}
                ></textarea>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div>
                  <p style={{ fontWeight: "700" }}>Fisiere existente</p>

                  <aside style={thumbsContainer} onClick={handleExpandPhoto}>
                    {thumbs()}
                  </aside>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <DropzonePictures
                  ticketId={ticketId}
                  handleShowFiles={handleShowFiles}
                />
              </Grid>
            </Grid>

            <div style={{ paddingLeft: "350px" }}>
              <Button
                onClick={handleSave}
                style={{
                  backgroundColor: "#3c44b1",
                  color: "white",
                  fontSize: "13px",
                  fontWeight: "900",
                  height: "35px",
                  width: "100px",
                }}
              >
                SALVEAZA
              </Button>
            </div>
          </Card>
        </div>
      </Modal>
      <Modal
        open={isSecondModalOpen}
        onClose={handleCloseSecondModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(3px)",
          width: "100%",
          height: "100%",
        }}
      >
        <div>{expandedThumbs()}</div>
      </Modal>
      {/* </div> */}
      <div className="multipleDRGlabelContainer">
        <p className="labelText">{item.label}</p>
        {hideAddButton ? null : (
          <div className={"buttonsContainer"}>
            <Button className={"addButton"} onClick={(e) => addInput(e)}>
              <p className={"buttonText"}>+</p>
            </Button>
          </div>
        )}
      </div>
      <div className="radiologicalInvestigationsTextFieldContainer">
        <ol>
          {inputs.map((input, index) => (
            <li key={`input_li_${input}`}>
              <div className={"row"}>
                <div className={"textFieldContainer"}>
                  <TextField
                    id={`${itemName}${index}`}
                    name={`${itemName}${index}`}
                    label={""}
                    value={
                      edit.list
                        ? edit.list[index]
                          ? edit.list[index].drg
                            ? edit.list[index].drg.procedureCode +
                              " " +
                              edit.list[index].drg.description
                            : ""
                          : ""
                        : ""
                    }
                    className="textField"
                    disabled={true}
                  />
                </div>
                <Button
                  className={"edit__button"}
                  onClick={(event) => {
                    handleEdit(item, index);
                  }}
                >
                  EDIT
                  {/* <p className={"edit__button_text"}>EDIT</p> */}
                </Button>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default RadiologicalInvestigations;
