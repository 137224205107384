import clsx from "clsx";
import {
  Button as Btn,
  Card,
  Collapse,
  Grid,
  List,
  ListItem,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Temperatura from "./Temperatura";
import RapoarteTemperatura from "./RapoarteTemperatura";
import React from "react";
import { useAti } from "../../../../../../hooks";

const Accordion17 = (props) => {
  const ctx = useAti();
  const {
    accordionStates,
    toggleAccordion,
    toggle,
    setAllData,
    sheetId,
    activeTab,
  } = props;
  return (
    <Card
      className={clsx("card-box", {
        "panel-open": accordionStates[`accordion21`],
      })}
    >
      <Card>
        <div className="card-header">
          <div className="panel-title">
            <div className="accordion-toggle">
              <Btn
                variant="text"
                size="large"
                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                onClick={() => toggleAccordion(21)}
                aria-expanded={accordionStates[`accordion21`]}
              >
                <span>Foaie de masuratori vitale</span>
                <FontAwesomeIcon
                  icon={["fas", "angle-up"]}
                  className="font-size-xl accordion-icon"
                />
              </Btn>
            </div>
          </div>
        </div>
        {accordionStates[`accordion21`] && (
          <Collapse in={accordionStates[`accordion21`]}>
            <div className={"region temperatura m-all-3"}>
              <h1
                style={{
                  fontSize: "32px",
                  fontWeight: "900",
                  justifyContent: "flex-start",
                  paddingBottom: "20px",
                  // marginLeft: "-20px"
                }}
              >
                Evidenta masuratori vitale
              </h1>

              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card className="p-3">
                    <List className="nav-tabs nav-tabs-primary d-flex">
                      <ListItem
                        button
                        disableRipple
                        selected={activeTab === "0"}
                        onClick={() => {
                          toggle("0");
                        }}
                      >
                        Adauga Valori
                      </ListItem>
                      <ListItem
                        button
                        disableRipple
                        selected={activeTab === "1"}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Rapoarte
                      </ListItem>
                    </List>

                    <div
                      className={clsx("tab-item-wrapper", {
                        active: activeTab === "0",
                      })}
                      index={0}
                    >
                      <div className="my-5">
                        {/*{allData && allData.tempMatrix && (*/}
                        <Temperatura
                          allData={ctx.rState.acc21.values.temperatureSheet}
                          setAllData={setAllData}
                          sheetId={sheetId}
                        />
                        {/*)}*/}
                      </div>
                    </div>
                    <div
                      className={clsx("tab-item-wrapper", {
                        active: activeTab === "1",
                      })}
                      index={1}
                    >
                      <div className="text-center my-5">
                        {ctx.rState.acc21.values.temperatureSheet && (
                          <RapoarteTemperatura
                            allData={ctx.rState.acc21.values.temperatureSheet}
                          />
                        )}
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Collapse>
        )}
      </Card>
    </Card>
  );
};

export default Accordion17;
