import React, { useState } from "react";
import QrReader from "react-qr-reader";
import { Redirect } from "react-router-dom";
import { REST } from "../../../Utils/index";
import { Grid } from "@material-ui/core";

const QrScanner = () => {
  // const { data } = props

  const [scanResult, setScanResult] = useState("");
  const [status, setStatus] = useState("");
  // eslint-disable-next-line
  const [error, setError] = useState("");
  const [isAtiTicket, setIsAtiTicket] = useState("");

  const updateTicket = async (data) => {
    await REST.put(`/spital/updateTicketStatus?status=Medic&ticketId=${data}`)
      .then((answer) => {
        setError({ message: null });
        setIsAtiTicket(answer.data.ticketType);
        setStatus("registratura");
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          setError({
            message,
          });
        }
      });
  };

  const handleScan = async (data) => {
    if (data) {
      setScanResult(data);
      if (data != null) {
        updateTicket(data);
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const Scan = () => {
    if (status === "registratura") {
      return (
        <Redirect
          activeClassName="active"
          className="nav-link-simple"
          to={
            isAtiTicket === "Ati_Ticket"
              ? `/ati-id/${scanResult}`
              : `/chestionar/${scanResult}`
          }
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Grid container className={"qr_root"}>
      <Grid item xs={12} md={12} lg={12} className={"camera_container"}>
        <div className={"qr_container"}>
          <QrReader
            delay={10}
            onError={handleError}
            onScan={handleScan}
            resolution={2000}
            style={{ width: "100%" }}
            constraints={{
              focusMode: "continuous",
              sharpness: 999,
              torch: true,
              exposureMode: "continuous",
              whiteBalanceMode: "continuous",
              focusDistance: 100,
              facingMode: "environment",
            }}
          />
          <br />
          <Scan />
        </div>
      </Grid>
    </Grid>
  );
};

export default QrScanner;
