import { all } from "./spitalList";

export default [
  {
    key: "lastName",
    label: "Nume",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "firstName",
    label: "Prenume",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "birthDate",
    label: "Data nașterii",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "CNP",
    label: "CNP",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "serialId",
    label: "Serie Buletin",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "serialNumber",
    label: "Numar Buletin",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },

  {
    key: "phone",
    label: "Telefon",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  // {
  //   key: "confirmPhone",
  //   label: "Confirma telefon",
  //   answer: {
  //     type: "RadioInput",
  //     required: true,
  //     selectList: [],
  //   },
  // },
  {
    key: "email",
    label: "Email",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },

  {
    key: "county",
    label: "Judet de resedinta",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "city",
    label: "Localitate de resedinta",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "street",
    label: "Strada de resedinta",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "streetNr",
    label: "Numar Strada de resedinta",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "legalCounty",
    label: "Judet de domiciliu",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "legalCity",
    label: "Localitate de domiciliu",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "legalStreet",
    label: "Strada de domiciliu",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "legalStreetNr",
    label: "Numar Strada de domiciliu",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "profession",
    label: "Profesia",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "cas",
    label: "CAS",
    affects: ["hasTicket", "overCost", "confirmed"],
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: [
        "CASAOPSNAJ",
        "TRANSPORTURI",
        "CASMB",
        "JUDETEANA",
        "TALON DE PENSIE",
      ],
      // isDisabled: true,
    },
  },
  {
    key: "hasTicket",
    label: "Bilet trimitere",
    affects: ["confirmed"],
    setConfirmed: true,
    depends: {
      key: "cas",
      vals: [
        "CASAOPSNAJ",
        "TRANSPORTURI",
        "CASMB",
        "JUDETEANA",
        "TALON DE PENSIE",
      ],
    },
    answer: {
      type: "RadioInput",
      required: true,
      selectList: [],
      showInput: true,
      // isDisabled: true,
    },
  },

  {
    key: "hasTicket",
    label: "Contracost",
    displayLabel: "Trimitere",
    affects: ["confirmed"],

    depends: {
      key: "cas",
      vals: [undefined, "NONE"],
    },

    question: "Doriti un consult de specialitate contracost?",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: [],
      showInput: false,
      showCheckBox: false,
      showText: false,
    },
  },
  {
    key: "whereIsThePain",
    label: "Ce va supara? Ce va deranjeaza?",
    question: " Ce va supara? Ce va deranjeaza?",
    answer: {
      key: "reportedProblem",
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "alergies",
    label: "Alergii",
    question:
      " In regula, va rog sa imi transmiteti daca suferiti de alergii sau intolerante medicamentoase sau nemedicamentoase.",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "inGenTreatment",
    label:
      "Urmati vreun tratament specific? Homeopatic, medicamentos, fitoterapeutic?",
    question:
      " In prezent urmati vreun tratament specific? Homeopatic, medicamentos, fitoterapeutic?",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: [],
      showInput: true,
      showText: false,
      showCheckBox: false,
      // isDisabled: true,
    },
  },
  {
    key: "inAntiCoagulantTreatment",
    label: "Urmati vreun tratament cu anticoagulante?",
    question: " Urmati vreun tratament cu anticoagulante?",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "inOsteoTreatment",
    label:
      "Urmati vreun tatament impotriva osteoporozei cu unul dintre urmatoarele medicamente : Fosamax, Fasavance, Actonelm Bonviva, Zometa, Aclasta?",
    question:
      "Urmati vreun tatament impotriva osteoporozei cu unul dintre urmatoarele medicamente : Fosamax, Fasavance, Actonelm Bonviva, Zometa, Aclasta?",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "hadSurgery",
    affects: ["anesthezis", "anesthezisIssues"],

    label:
      "Alte incidente sau afectiuni ati mai avut? Ati mai fost supus unor interventii chirurgicale?",
    question:
      " Alte incidente sau afectiuni ati mai avut? Ati mai fost supus unor interventii chirurgicale?",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "anesthezis",
    label: "Anestezie",
    depends: {
      key: "hadSurgery",
      vals: [true],
    },
    question: "Anestezia a fost loco-regionala, prin sedare, generala?",
    answer: {
      type: "CheckBoxInput",
      required: true,
      selectList: ["loco-regionala", "prin sedare", "generala"],
      // isDisabled: true,
    },
  },
  {
    key: "anesthezisIssues",
    depends: {
      key: "hadSurgery",
      vals: [true],
    },
    label: "Au fost probleme legate de anestezie?",
    question: " Au fost probleme legate de anestezie?",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "prevTreatmentIssues",
    label:
      "La tratamentele sau anesteziile anterioare au aparut complicatii? Greata, lesin, anumite alergii?",
    question:
      " La tratamentele sau anesteziile anterioare au aparut complicatii? Greata, lesin, anumite alergii?",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: [],
      showText: false,
      showCheckBox: false,
      showInput: false,
      // isDisabled: true,
    },
  },

  {
    key: "bloodType",
    label: "Grupa sanguina (dacă o cunoașteți)",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: ["0", "A", "B", "AB", "unknown"],
      selectListName: ["0 I", "A II", "B III", "AB IV", "Nu cunosc"],
      // isDisabled: true,
    },
  },
  {
    key: "rh",
    label: "RH",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: ["negativ", "pozitiv", "unknown"],
      selectListName: ["Negativ", "Pozitiv", "Nu Cunosc"],
      // isDisabled: true,
    },
  },
  {
    key: "alcoholTobacco",
    label: "Consum alcool/tutun",
    question: " Sunteti sau ati fost consumator de tutun sau alcool?",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },
  {
    key: "peachemaker",
    label: "Pacemaker",
    question: " Pacemaker?",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: [],
      // isDisabled: true,
    },
  },

  {
    key: "confirmData",
    label: "Confirmare programare",
    excludeFromForm: true,
    question:
      " In regula, va multumesc frumos pentru toate detaliile. Va rog sa imi confirmati ca doriti un consult de specialitate, contracost, la doctorul .., la .. . Informatiile sunt corecte?",
    answer: {
      type: "wait",
      required: true,
      selectList: [],
    },
  },
  {
    question:
      " Numai putin sa verific cand este domnul doctor liber. Va rog sa asteptati cateva minute la telefon.",
    answer: {
      key: null,
      type: "wait",
    },
  },
  {
    key: "congenital",
    label:
      "Suferiti de boli congenitale, de inima, vasculare, hipertensiune arteriala, atac vascular cerebral, gastrita, ulcer, probleme respiratorii (astm), boli ale ficatului, ale rinichiului, biabet, boli endocrine, boli psihice, neurologice, atacuri de panica, boli infectioase, neoplasme?",
    question:
      " Suferiti de boli congenitale, de inima, vasculare, hipertensiune arteriala, atac vascular cerebral, gastrita, ulcer, probleme respiratorii (astm), boli ale ficatului, ale rinichiului, biabet, boli endocrine, boli psihice, neurologice, atacuri de panica, boli infectioase, neoplasme?",
    answer: {
      type: "CheckBoxInput",
      required: true,
      selectList: [
        "congenitale",
        "de inima",
        "vasculare",
        "hipertensiune arteriala",
        "atac vascular cerebral",
        "gastrita",
        " ulcer",
        " probleme respiratorii (astm)",
        " boli ale ficatului",
        " ale rinichiului",
        " diabet",
        "boli endocrine",
        "boli psihice",
        "neurologice",
        "atacuri de panica",
        "boli infectioase",
        "neoplasme",
        "niciuna",
      ],
    },
  },
  {
    key: "selectData",
    label: "Unde doriti sa mergeti pentru consultatie?",
    question: " Unde doriti sa mergeti pentru consultatie?",
    answer: {
      type: "MultipleDropDownInput",
      required: true,
      selectList: all,
    },
  },
  {
    key: "selectData2",
    label: "Unde doriti sa mergeti pentru consultatie, alternativa 2?",
    question: " Unde doriti sa mergeti pentru consultatie?(2)",
    answer: {
      type: "MultipleDropDownInput",
      required: true,
      selectList: all,
    },
  },
  {
    key: "selectData3",
    label: "Unde doriti sa mergeti pentru consultatie, alternativa 3?",
    question: " Unde doriti sa mergeti pentru consultatie?(3)",
    answer: {
      type: "MultipleDropDownInput",
      required: true,
      selectList: all,
    },
  },
  {
    key: "finalConfirmDate",
    label: "Data programarii",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
      customStyle: true,
    },
  },

  {
    key: "finalConfirmHour",
    label: "Ora programarii",
    answer: {
      type: "TimePickerInput",
      required: true,
      selectList: [],
      customStyle: true,
    },
  },
  {
    question: " O zi buna va doresc!",
    answer: {
      key: null,
      type: "wait",
    },
  },
];
