import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button as Btn, Card, Collapse } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useAti } from "../../../../../../hooks";
import DowntonBody from "./DowntonBody";

const Accordion11 = (props) => {
  const {
    toggleAccordion,
    accordionStates,
    formData,
    ticketId,
    userData,
    handleFourthAccordion,
  } = props;

  const ctx = useAti();

  // eslint-disable-next-line
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (ctx.rState.acc15.values && ctx.rState.acc15.values.downtown) {
      // setTotal(0);

      const coll = Object.values(ctx.rState.acc15.values.downtown).map(
        (item) => {
          return [
            "NU",
            "None",
            "Nici unul",
            "Alte medicamente",
            "Orientat",
            "Sigura cu ajutor",
            "Imposibila",
          ].includes(item)
            ? 0
            : 1;
        }
      );

      const set = coll.reduce((acc, item) => {
        return acc + item;
      });

      setTotal(set);
    }
  }, [ctx.rState.acc15]);

  return (
    <Card
      className={clsx("card-box", {
        "panel-open": accordionStates[`accordion15`],
      })}
    >
      <Card>
        <div className="card-header">
          <div className="panel-title">
            <div className="accordion-toggle">
              <Btn
                variant="text"
                size="large"
                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                onClick={() => toggleAccordion(15)}
                aria-expanded={accordionStates[`accordion15`]}
              >
                <span>Evaluarea riscului de cadere (SCALA DOWNTON)</span>
                <FontAwesomeIcon
                  icon={["fas", "angle-up"]}
                  className="font-size-xl accordion-icon"
                />
              </Btn>
            </div>
          </div>
        </div>
        {accordionStates[`accordion15`] && (
          <Collapse in={accordionStates[`accordion15`]}>
            <div className={"accordion_body"}>
              <h3 className="formSubtitle">
                Evaluarea riscului de cadere (SCALA DOWNTON){" "}
              </h3>
              <DowntonBody
                data={formData}
                ticketId={ticketId}
                userData={userData}
                handleFirstPage={handleFourthAccordion}
              />
              <div style={{ marginTop: "2rem", marginLeft: "3rem" }}>
                Legenda: Se considera risc mare, cand suma punctelor obtinute in
                urma evaluarii risculrilor este mai mare de 2.
              </div>
            </div>
          </Collapse>
        )}
      </Card>
    </Card>
  );
};

export default Accordion11;
