import React from "react";
import TextField from "@material-ui/core/TextField";

const SignTextInput = (props) => {
  const {
    item,
    formState,
    handleChange,
    label,
    additionalName,
    className,
  } = props;

  var itemName = item.key;
  var additionalNameVal = additionalName !== undefined ? additionalName : "";
  var labelVal = label !== undefined ? label : item.label;

  return (
    <div
      className={item && className ? className : "answerBox"}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="signlabelContainer">
        <p className="labelText">{labelVal}</p>
      </div>
      <div className="signTextFieldContainer">
        <div>
          <TextField
            id={`${itemName}${additionalNameVal}`}
            name={`${itemName}${additionalNameVal}`}
            label={""}
            className="textField"
            onChange={(event) => {
              handleChange(event);
            }}
            value={
              (formState && formState.values && formState.values[itemName]) ||
              (formState &&
                formState.values &&
                formState.values[`${itemName}${additionalNameVal}`]) ||
              ""
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SignTextInput;
