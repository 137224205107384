export default [
  {
    title: "",
    key: "name",
    label: "    Stimate(a) coleg(a), va informam ca",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      className: "subsemnat",
    },
  },
  {
    title: "",
    key: "birthDate",
    label: "nascut la data de",
    answer: {
      type: "DatePickerInput",
      required: true,
      className: "dischargeDate",
    },
  },
  {
    title: "",
    key: "CNP",
    label: ", CNP/cod unic de asigurare",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      className: "subsemnat",
    },
  },
  {
    title: "",
    key: "finalConfirmDate",
    label: ", a fost consultat in serviciul nostru la data de",
    answer: {
      type: "DatePickerInput",
      required: true,
      className: "dischargeDate",
    },
  },
  {
    title: "",
    key: "register",
    label: "nr. F.O./nr. din Registrul de consultatii",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      className: "subsemnat",
    },
  },
  {
    title: "",
    key: "title",
    label: "",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
      className: "footer",
    },
  },
  {
    title: "",
    key: "reasonForHospitalization",
    label: "Motivele prezentarii:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      className: "longTextInput",
    },
  },
  {
    title: "",
    key: "admissionDiagnostic",
    label: "Diagnosticul:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      className: "longTextInput",
    },
  },
  // {
  //   title: "",
  //   key: "anamneza",
  //   label: "Anamneza:",
  //   answer: {
  //     type: "MultipleLongTextInput",
  //     required: true,
  //     selectList: [],
  //     className: "longTextInput",
  //   },
  // },
  {
    key: "anamneza",
    label: "Anamneza:",
    answer: {
      type: "MultipleLongTextInput",
      required: true,
      selectList: [],
      labelList: [
        { heredoColateral: "Antecedente heredo-colaterale" },
        { personal: "Antecedente personale, fiziologice si patologice" },
        { conditions: "Conditii de viata si munca" },
        { behavior: "Comportamente (fumat, alcool etc.)" },
        {
          medication:
            "Medicatie de fond administrata inaintea internarii (inclusiv preparate hormonale si imunosupresoare)",
        },
      ],
      showRecord: false,
      hideAddButton: true,
    },
  },
  {
    title: "",
    key: "riskFactors",
    label: "-factori de risc:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      className: "longTextInput",
    },
  },
  {
    title: "",
    key: "title",
    label: "Examen clinic",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
      className: "titleText",
    },
  },
  {
    title: "",
    key: "general",
    label: "-general:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      className: "longTextInput",
    },
  },
  {
    title: "",
    key: "local",
    label: "-local:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      className: "longTextInput",
    },
  },
  {
    title: "",
    key: "title",
    label: "Examen de laborator",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
      className: "titleText",
    },
  },
  {
    title: "",
    key: "normalValues",
    label: "-cu valori normale:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      className: "longTextInputRow",
    },
  },
  {
    title: "",
    key: "pathologicalValues",
    label: "-cu valori patologice:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      className: "longTextInputRow",
    },
  },
  {
    title: "",
    key: "title",
    label: "Examene paraclinice",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
      className: "titleText",
    },
  },
  {
    title: "",
    key: "ekg",
    label: "-EKG:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      className: "longTextInputRow",
    },
  },
  {
    title: "",
    key: "eco",
    label: "-ECO:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      className: "longTextInputRow",
    },
  },
  {
    title: "",
    key: "rx",
    label: "-Rx:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      className: "longTextInputRow",
    },
  },
  {
    title: "",
    key: "other",
    label: "Altele:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      className: "longTextInput",
    },
  },
  {
    title: "",
    key: "performedTreatment",
    label: "Tratament efectuat:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      className: "longTextInput",
    },
  },
  {
    title: "",
    key: "otherInfo",
    label: "Alte informatii referitoare la starea de sanitate a asiguratului:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      className: "longTextInput",
    },
  },
  {
    title: "",
    key: "recomendedTreatment",
    label: "Tratament recomandat:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      className: "longTextInput",
    },
  },
  {
    key: "indication",
    label: "Indicatie de revenire pentru internare:",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: [
        "Da, revin pentru internare in termen de{{termenDeRevenire}}",
        "Nu, nu este necesar revenirea pentru internare",
      ],
      className: "column",
    },
  },
  {
    key: "prescription",
    label: "Se completeaza obligatoriu una din cele trei informatii:",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: [
        "S-a eliberat prescriptie medicala, seria {{prescriptieSerie}} numarul {{prescriptieNumar}}",
        "Nu s-a eliberat prescriptie medicala deoarece nu a fost necesar.",
        "NU s-a eliberat prescriptie medicala.",
      ],
      className: "column",
    },
  },
  {
    key: "vacation",
    label: "Se completeaza obligatoriu una din cele trei informatii:",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: [
        "S-a eliberat concediu medical la externare, seria {{concediuSerie}} numarul {{concediuNumar}} ",
        "Nu s-a eliberat concediu medical la externare deoarece nu a fost necesar.",
        "NU s-a eliberat concediu medical la externare.",
      ],
      className: "column",
    },
  },
  {
    key: "medicalCare",
    label: "Se completeaza obligatoriu una din cele doua informatii:",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: [
        "S-a eliberat recomandare pentru ingrijiri medicale la domiciliu/paliative la domiciliu.",
        "Nu s-a eliberat recomandare pentru ingrijiri medicale la domiciliu/paliative la domiciliu, deoarece nu a fost necesar.",
      ],
      className: "column",
    },
  },
  {
    key: "medicalDevices",
    label: "Se completeaza obligatoriu una din cele doua informatii:",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: [
        "S-a eliberat prescriptie medicala pentru dispozitive medicale in ambulatoriu.",
        "Nu s-a eliberat prescriptie medicala pentru dispozitive medicale in ambulatoriu deoarece nu a fost necesar (cu viza Unitatii judetene de implementare a programului, pentru diabet)",
      ],
      className: "column",
    },
  },
  {
    title: "",
    key: "countyUnit",
    label: "Unitatea judeteana de diabet zaharat",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      className: "subsemnat",
    },
  },
  {
    title: "",
    key: "socialSecurityNr",
    label: "Nr. inregistrare a asiguratului",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      className: "subsemnat",
    },
  },
  {
    title: "",
    key: "dischargeDate",
    label: "Data",
    answer: {
      type: "DatePickerInput",
      required: true,
      className: "dischargeDateColumn",
    },
  },
  {
    key: "signature",
    label: "Semnatura si parafa medicului",
    answer: {
      type: "SignTextInput",
      required: true,
      selectList: [],
      className: "dischargeSign",
    },
  },
  {
    title: "",
    key: "transmissionPath",
    label: "Calea de transmitere: prin asigurat",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      className: "subsemnatColumn",
    },
  },
  {
    title: "",
    key: "title",
    label:
      " *) Scrisoarea medicală se întocmeşte în două exemplare, din care un exemplar rămâne la medicul care a efectuat consultaţia/serviciul în ambulatoriul de specialitate, iar un exemplar este transmis medicului de familie/medicului de specialitate din ambulatoriul de specialitate.",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
      className: "footer",
    },
  },
  {
    title: "",
    key: "title",
    label:
      "Scrisoarea medicală sau biletul de ieşire din spital sunt documente tipizate care se întocmesc la data externării, într-un singur exemplar care este transmis medicului de familie/medicului de specialitate din ambulatoriul de specialitate, direct ori prin intermediul asiguratului.",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
      className: "footer",
    },
  },
];
