import { Grid } from "@material-ui/core";
import debounce from "lodash.debounce";
import React, { useCallback } from "react";
import {
  AutocompleteDRGInput,
  CheckBoxInput,
  DatePickerInput,
  DisabledTextInput,
  DRGTextInput,
  DropDownInput,
  LongTextInput,
  MultipleDRGTextInput,
  MultipleDropDownInput,
  MultipleRadioInput,
  NewLineInput,
  RadioInput,
  TextInput,
  TimePickerInput,
  TitleForInput,
} from "./index";

const RenderAtiAccordionNonTableComponent = (props) => {
  const {
    Mocks,
    ctxState,
    ctxAccKey,
    ctxAccCollectionKey,
    accKey,
    changeHandler,
    setValueSecondDD,
    setValueThirdDD,
    valueSecondDD,
    valueThirdDD,
    children,
    sheetId,
  } = props;

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  const formState = !!ctxState[ctxAccKey]?.values[accKey]
    ? { values: { ...ctxState[ctxAccKey]?.values[accKey] } }
    : ctxState[ctxAccKey];

  return (
    <Grid container direction="row" className="SecondForm">
      {/* eslint-disable-next-line */}
      {Mocks.map((item, index) => {
        if (item.answer.type === "wait") {
          return null;
        }

        let showIt = true;
        if (item.depends !== undefined) {
          if (Array.isArray(item.depends.vals)) {
            showIt = item.depends.vals.includes(
              ctxState[ctxAccKey][item.depends.key]
            );
          } else {
            showIt =
              item.depends.vals === ctxState[ctxAccKey][item.depends.key];
          }
        }
        return (
          showIt &&
          (() => {
            if (item.answer.type === "TextInput") {
              return (
                <Grid item xs={8} key={`${item.answer.type}_${index}`}>
                  <TextInput
                    key={item.key}
                    item={item}
                    ctxAccKey={ctxAccKey}
                    formState={formState}
                    handleChange={debouncedChangeHandler}
                    label={item.label}
                    isDisabled={item.answer.isDisabled}
                  />
                </Grid>
              );
            } else if (item.answer.type === "DisabledTextInput") {
              return (
                <Grid item xs={4} key={`${item.answer.type}_${index}`}>
                  <DisabledTextInput
                    key={item.key}
                    item={item}
                    formState={formState}
                    ctxAccKey={ctxAccKey}
                  />
                </Grid>
              );
            } else if (item.answer.type === "NewLineInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <NewLineInput />
                </Grid>
              );
            } else if (item.answer.type === "TitleForInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <TitleForInput item={item} label={item.label} />
                </Grid>
              );
            } else if (item.answer.type === "RadioInput") {
              return (
                <Grid item xs={4} key={`${item.answer.type}_${index}`}>
                  <RadioInput
                    key={item.key}
                    item={item}
                    formState={formState}
                    handleChange={debouncedChangeHandler}
                    ctxAccCollectionKey={ctxAccCollectionKey}
                    ctxAccKey={ctxAccKey}
                  />
                </Grid>
              );
            } else if (item.answer.type === "MultipleRadioInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <MultipleRadioInput
                    key={item.key}
                    item={item}
                    formState={formState}
                    // handleChange={handleChange}
                    handleChange={debouncedChangeHandler}
                    ctxAccKey={ctxAccKey}
                  />
                </Grid>
              );
            } else if (item.answer.type === "TimePickerInput") {
              return (
                <Grid item xs={8} key={`${item.answer.type}_${index}`}>
                  <TimePickerInput
                    key={item.key}
                    item={item}
                    formState={formState}
                    handleChange={debouncedChangeHandler}
                    ctxAccKey={ctxAccKey}
                  />
                </Grid>
              );
            } else if (item.answer.type === "DatePickerInput") {
              return (
                <Grid item xs={6} key={`${item.answer.type}_${index}`}>
                  <DatePickerInput
                    key={item.key}
                    item={item}
                    isDisabled={item.answer.isDisabled}
                    formState={formState}
                    handleChange={debouncedChangeHandler}
                    ctxAccKey={ctxAccKey}
                  />
                </Grid>
              );
            } else if (item.answer.type === "wait") {
              return <div></div>;
            } else if (item.answer.type === "DropDownInput") {
              return (
                <Grid item xs={8} key={`${item.answer.type}_${index}`}>
                  <DropDownInput
                    key={item.key}
                    item={item}
                    formState={formState}
                    handleChange={debouncedChangeHandler}
                    ctxAccKey={ctxAccKey}
                  />
                </Grid>
              );
            } else if (item.answer.type === "MultipleDropDownInput") {
              return (
                <MultipleDropDownInput
                  setValueSecondDD={setValueSecondDD}
                  setValueThirdDD={setValueThirdDD}
                  valueSecondDD={valueSecondDD}
                  valueThirdDD={valueThirdDD}
                  item={item}
                  formState={formState}
                  handleChange={debouncedChangeHandler}
                  ctxAccKey={ctxAccKey}
                />
              );
            } else if (item.answer.type === "LongTextInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <LongTextInput
                    item={item}
                    formState={formState}
                    handleChange={debouncedChangeHandler}
                    showRecord={item.answer.showRecord}
                    label={item.label}
                    className={item.answer.className}
                    ticketId={sheetId}
                    ctxAccKey={ctxAccKey}
                  />
                </Grid>
              );
            } else if (item.answer.type === "AutocompleteDRGInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <AutocompleteDRGInput
                    item={item}
                    formState={formState}
                    handleChange={debouncedChangeHandler}
                    showColumn={true}
                    ctxAccKey={ctxAccKey}
                  />
                </Grid>
              );
            } else if (item.answer.type === "DRGTextInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <DRGTextInput
                    item={item}
                    formState={formState}
                    handleChange={debouncedChangeHandler}
                    showColumn={true}
                    ctxAccKey={ctxAccKey}
                  />
                </Grid>
              );
            } else if (item.answer.type === "MultipleDRGTextInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <MultipleDRGTextInput
                    item={item}
                    formState={formState}
                    handleChange={debouncedChangeHandler}
                    ctxAccKey={ctxAccKey}
                  />
                </Grid>
              );
            } else if (item.answer.type === "CheckBoxInput") {
              return (
                <Grid item xs={7} key={`${item.answer.type}_${index}`}>
                  <CheckBoxInput
                    key={item.key}
                    item={item}
                    formState={formState}
                    handleChange={debouncedChangeHandler}
                    ctxAccCollectionKey={ctxAccCollectionKey}
                    ctxAccKey={ctxAccKey}
                  />
                </Grid>
              );
            } else {
              return <div key={`${Math.random()}--key`}></div>;
            }
          })()
        );
      })}
      {children}
    </Grid>
  );
};

export default RenderAtiAccordionNonTableComponent;
