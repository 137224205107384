import React from "react";
import { aparatTypes } from "../AparatTypes/aparatTypes";
import { orderByDateAndHour } from "./raportareHelpers";
import AllMatrix from "./AllMatrix";

const RapoarteTemperatura = props => {
  const { allData } = props;

  const filterBasedOnType = type => {
    const filteredFromUndefined = allData.filter(
      item =>
        item[type] && !!item[type].length && !isNaN(parseFloat(item[type]))
    );

    const final = filteredFromUndefined.map((item, index) => {
      return {
        orig: item[type],
        value: parseFloat(item[type]) || 0,
        date: item["vital_date"]
      };
    });

    return orderByDateAndHour(final);
  };

  let accumulators = {};

  aparatTypes.map(type => (accumulators[type] = filterBasedOnType(type)));

  return (
    <>
      <AllMatrix
        accumulators={accumulators}
        // filteredAcc={accumulatorsWithDate}
        // allUniqueDates={allUniqueDates}
      />
    </>
  );
};

export default RapoarteTemperatura;
