import { Grid } from "@material-ui/core";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import React from "react";
// eslint-disable-next-line
const BpmButton = (props) => {
  const { vitals } = props;
  return (
    <Grid container className="widgetContainer">
      <Grid item xs={4} md={4}>
        <div className="widgetTextBPM">BPM</div>
      </Grid>
      <Grid item xs={8} md={8}>
        <div className="widgetValueBPM">
          {vitals && vitals.data && vitals.data["VITAL HR"] ? (
            vitals && vitals.data && vitals.data["VITAL HR"]
          ) : (
            <div className="missingValue"> - </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
// eslint-disable-next-line
const SpO2FPButton = (props) => {
  const { vitals } = props;
  return (
    <Grid container className="widgetContainer">
      <Grid item xs={4} md={4}>
        <div className="widgetTextSp02-fp">SpO2-FP</div>
      </Grid>
      <Grid item xs={8} md={8}>
        <div className="widgetValueSp02-fp">
          {vitals && vitals.data && vitals.data["VITAL PR(spo2)"] ? (
            vitals && vitals.data && vitals.data["VITAL PR(spo2)"]
          ) : (
            <div className="missingValue"> - </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
// eslint-disable-next-line
const STButton = (props) => {
  const { vitals } = props;
  return (
    <Grid container className="widgetContainer">
      <Grid item xs={4} md={4}>
        <div className="widgetTextST-II">ST-II</div>
      </Grid>
      <Grid item xs={8} md={8}>
        <div className="widgetValueST-II">
          {vitals && vitals.data && vitals.data["VITAL ST2"] ? (
            vitals && vitals.data && vitals.data["VITAL ST2"]
          ) : (
            <div className="missingValue"> - </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
// eslint-disable-next-line
const ARTButton = (props) => {
  const { vitals } = props;
  return (
    <Grid container className="widgetContainer">
      <Grid item xs={4} md={4}>
        <div className="artTextContainer">
          <p className="artText">ART</p>
        </div>
      </Grid>
      <Grid item xs={8} md={8}>
        <div className="artValueContainer">
          {vitals && vitals.data && vitals.data["VITAL ART(M)"] ? (
            <div>
              <div>{vitals && vitals.data && vitals.data["VITAL ART(S)"]}</div>
              <div>{vitals && vitals.data && vitals.data["VITAL ART(D)"]}</div>
              <div>
                ({vitals && vitals.data && vitals.data["VITAL ART(M)"]})
              </div>
            </div>
          ) : (
            <div className="missingValueArt"> - </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
// eslint-disable-next-line
const PVCButton = (props) => {
  const { vitals } = props;
  return (
    <Grid container className="widgetContainer">
      <Grid item xs={4} md={4}>
        <div className="widgetText">PVC</div>
      </Grid>
      <Grid item xs={8} md={8}>
        <div className="widgetValue">
          {vitals && vitals.data && vitals.data["VITAL CVP(M)"] ? (
            vitals && vitals.data && vitals.data["VITAL CVP(M)"]
          ) : (
            <div className="missingValue"> - </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

// eslint-disable-next-line
const SpO2Button = (props) => {
  const { vitals } = props;
  return (
    <Grid container className="widgetContainer">
      <Grid item xs={4} md={4}>
        <div className="widgetTextSp02-fp">SpO2</div>
      </Grid>
      <Grid item xs={8} md={8}>
        <div className="widgetValueSp02-fp">
          {vitals && vitals.data && vitals.data["VITAL SpO2"] ? (
            vitals && vitals.data && vitals.data["VITAL SpO2"]
          ) : (
            <div className="missingValue"> - </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

// eslint-disable-next-line
const IMPButton = (props) => {
  const { vitals } = props;
  return (
    <Grid container className="widgetContainer">
      <Grid item xs={4} md={4}>
        <div className="widgetText">IMP-FR</div>
      </Grid>
      <Grid item xs={8} md={8}>
        <div className="widgetValue">
          {vitals && vitals.data && vitals.data["VITAL rRESP(imp)"] ? (
            vitals && vitals.data && vitals.data["VITAL rRESP(imp)"]
          ) : (
            <div className="missingValue"> - </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

// eslint-disable-next-line
const TpieleButton = (props) => {
  const { vitals } = props;
  return (
    <Grid container className="widgetContainer">
      <Grid item xs={4} md={4}>
        <div className="widgetTextTskin">Tpiele</div>
      </Grid>
      <Grid item xs={8} md={8}>
        <div className="widgetValueTskin">
          {vitals && vitals.data && vitals.data["VITAL TSKIN"] ? (
            vitals && vitals.data && vitals.data["VITAL TSKIN"]
          ) : (
            <div className="missingValue"> - </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

// eslint-disable-next-line
const TrectButton = (props) => {
  const { vitals } = props;
  return (
    <Grid container className="widgetContainer">
      <Grid item xs={4} md={4}>
        <div className="widgetText">Trect</div>
      </Grid>
      <Grid item xs={8} md={8}>
        <div className="widgetValueTskin">
          {vitals && vitals.data && vitals.data["VITAL TRECT"] ? (
            vitals && vitals.data && vitals.data["VITAL TRECT"]
          ) : (
            <div className="missingValue"> - </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const FloatingMonitorBtn = (props) => {
  const { handleVitals } = props;

  const handleVisibility = () => {
    handleVitals();
  };

  return (
    <div>
      <AddToQueueIcon
        className="floatingMonitorBtn"
        onClick={handleVisibility}
        style={{ height: 50, width: 50 }}
      />
    </div>
  );
};

export default FloatingMonitorBtn;
