import React from "react";
import Table from "./Tables15/index";

const EvolutionList = (props) => {
  const { history, location, ticketId, evolutionData } = props;

  return (
    <div className={"EvolutionList"}>
      <Table
        location={location}
        history={history}
        ticketId={ticketId}
        evolutionData={evolutionData}
      ></Table>
    </div>
  );
};

export default EvolutionList;
