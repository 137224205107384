import { REST } from "../../../../../../../Utils/index";

const hospitals = async (specialtyName) => {
  return await REST.get(
    `/callcenter/getHospitalBySpecialityName?specialityName=${specialtyName[0]}`
  ).then((answer) => {
    return answer.data.map((el) => {
      return {
        id: el.id,
        name: el.hospitalName,
        cat: "spital",
        sub: { toCall: sections, param: [el.id, specialtyName[1]] },
      };
    });
  });
};

const sections = async (param) => {
  let hospitalId = param[0];
  let specialityId = param[1];
  return await REST.get(
    `/callcenter/getDepartmentsAndMedicsByHospitalId?hospitalId=${hospitalId}&specialityId=${specialityId}`
  ).then((answer) => {
    return answer.data.map((el) => {
      return {
        id: el.medicId,
        name: el.departmentName + " " + el.medicName,
        cat: "medic",
        sub: {},
      };
    });
  });
};

const all = async () => {
  return await REST.get(`/callcenter/getSpecialty`).then((answer) => {
    return answer.data.map((el) => {
      return {
        id: el.id,
        name: el.name,
        cat: "spec",
        sub: { toCall: hospitals, param: [el.name, el.id] },
      };
    });
  });
};

export { all, hospitals, sections };
