import React, { createContext, useReducer } from "react";
import { formStateReducer } from '../reducers/fromStateReducer';
import { userProfileReducer } from '../reducers/userProfileReducer';


export const FormStateContext = createContext();

// const browserHistory = createBrowserHistory();

const FormStateContextProvider = (props) => {

  const [userDataContext, dispatch] = useReducer(userProfileReducer, {});

  const [formStateContext, dispatchFormState] = useReducer(formStateReducer, {});

  return (
    <FormStateContext.Provider
      value={{
        formStateContext,
        dispatchFormState,
        userDataContext,
        dispatch
      }}
    >
      {props.children}
    </FormStateContext.Provider>
  );
};

export default FormStateContextProvider;
