import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faDiscord,
  faDribbble,
  faFacebook,
  faGithub,
  faGoogle,
  faHtml5,
  faInstagram,
  faPinterest,
  faReact,
  faSlack,
  faTwitter,
  faVuejs,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAddressCard,
  faBell,
  faBuilding,
  faCalendarAlt,
  faChartBar,
  faCheckCircle,
  faClock,
  faCommentDots,
  faComments,
  faDotCircle,
  faEnvelope,
  faEye,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faFileWord,
  faFolder,
  faFolderOpen,
  faGem,
  faImages,
  faKeyboard,
  faLifeRing,
  faLightbulb,
  faMap,
  faObjectGroup,
  faQuestionCircle,
  far,
  faSquare,
  faThumbsUp,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faUserCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAlignCenter,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAltH,
  faArrowUp,
  faAward,
  faBars,
  faBatteryEmpty,
  faBatteryFull,
  faBirthdayCake,
  faBomb,
  faBusAlt,
  faCameraRetro,
  faCarBattery,
  faCaretRight,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCog,
  faCubes,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExclamation,
  faExternalLinkAlt,
  faEyeDropper,
  faFilm,
  faHeart,
  faInfoCircle,
  faLemon,
  faLink,
  faMapMarkedAlt,
  faNetworkWired,
  faPager,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPrint,
  faQuoteRight,
  faReply,
  fas,
  faSave,
  faSearch,
  faShapes,
  faShareSquare,
  faSignOutAlt,
  faSitemap,
  faSlidersH,
  faSmile,
  faStar,
  faStarHalfAlt,
  faSync,
  faTable,
  faTachometerAlt,
  faTimes,
  faUnlockAlt,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { ThemeProvider } from "@material-ui/styles";
import { createBrowserHistory } from "history";
import lscache from "lscache";
import { SnackbarProvider } from "notistack";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Router } from "react-router-dom";
import validate from "validate.js";
import "./assets/scss/index.scss";
import validators from "./common/validators";
import configureStore from "./config/configureStore";
import FormStateContexProvider from "./context/formStateContext";
import OperatingRoomsProvider from "./context/operatingRooms.context";
import SecondOpinionContextProvider from "./context/secondOpinionContext";
import Routes from "./Routes";
import theme from "./theme";
import ScrollToTop from "./Utils/ScrollToTop";

import moment from "moment";

import "moment/locale/ro";

moment.locale("ro");

library.add(
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
);
library.add(
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
);
library.add(
  fas,
  faExclamation,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faCheck,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
);

const browserHistory = createBrowserHistory();

validate.validators = {
  ...validate.validators,
  ...validators,
};

const Apages = ["formular"];
const Bpages = ["chestionar"];
// eslint-disable-next-line
let redirect = false;

const store = configureStore();
const App = () => {
  const role = lscache.get("operatorType");
  const currentPage = browserHistory.location.pathname.split("/")[1];
  if (currentPage === "second-opinion") {
    redirect = false;
  } else {
    if (role === "Operator A" && Bpages.includes(currentPage)) {
      redirect = true;
      window.location = "/listare";
      return;
    }

    if (role !== "Operator A" && Apages.includes(currentPage)) {
      redirect = true;
      window.location = "/listare";
      return;
    }
  }

  return (
    <FormStateContexProvider>
      <SecondOpinionContextProvider>
        <Provider store={store}>
          <BrowserRouter>
            <ScrollToTop>
              <ThemeProvider theme={theme}>
                <Router history={browserHistory}>
                  <OperatingRoomsProvider>
                    <SnackbarProvider maxSnack={3}>
                      <Routes />
                    </SnackbarProvider>
                  </OperatingRoomsProvider>
                </Router>
              </ThemeProvider>
            </ScrollToTop>
          </BrowserRouter>
        </Provider>
      </SecondOpinionContextProvider>
    </FormStateContexProvider>
  );
};

export default App;
