import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { TableDatePicker } from "../../../../components/Inputs";
import CustomizedTables from "./Table/Table";
import moment from "moment";
import { REST } from "../../../../Utils";

const today = moment(new Date()).format("YYYY-MM-DD");
const AvailabilityTable = (props) => {
  const { surgeryRooms } = props;

  const [selectedDate, setSelectedDate] = useState(today);
  const [data, setData] = useState(null);
  // eslint-disable-next-line
  const [operatingHours, setOperatingHours] = useState({
    start: null,
    end: null,
  });

  const getOverview = async () => {
    await REST.get(
      `/spital/getOperatingRoomsOverview?date=${moment(selectedDate).format(
        "YYYY-MM-DD"
      )}`
    ).then((answer) => {
      setData(answer.data);
    });
  };

  const getOperatingHours = async () => {
    //TOOD: request for hours
    let start = new Date();
    start.setHours(7);
    start.setMinutes(0);

    let end = new Date();
    end.setHours(22);
    end.setMinutes(0);
    setOperatingHours({ start: start, end: end });
  };

  useEffect(() => {
    getOverview();
    getOperatingHours();
    // eslint-disable-next-line
  }, [selectedDate]);

  return (
    <Grid container className={`availability__table__container`}>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <h3>Tabel de disponibilitate al blocului operator</h3>
      </Grid>
      <Grid item xs={12} md={12} className={`datePicker__container`}>
        <TableDatePicker onChange={setSelectedDate} />
      </Grid>

      <Grid item xs={12} md={12} className={`table__container`}>
        <CustomizedTables
          surgeryRooms={surgeryRooms}
          data={data}
          operatingHours={operatingHours}
        />
      </Grid>
    </Grid>
  );
};

export default AvailabilityTable;
