import React, { createContext, useReducer } from "react";
import { operatingRoomsReducer } from '../reducers/operatingRoomsReducer';

export const OperatingRoomsContext = createContext();

const OperatingRoomsProvider = (props) => {
    const [state, dispatch] = useReducer(operatingRoomsReducer, []);

    return (
        <OperatingRoomsContext.Provider value={{ state, dispatch }}>
            {props.children}
        </OperatingRoomsContext.Provider>
    );
}

export default OperatingRoomsProvider;