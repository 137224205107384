const ConsultAlteDisciplineMock = [
  {
    title: "",
    key: "date",
    label: "Selecteaza Data",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },

  {
    title: "",
    key: "purpose",
    label: "Problema/Scopul",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },

  {
    title: "",
    key: "consultationResult",
    label: "Rezultatul consultului",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  }
];

export default ConsultAlteDisciplineMock;
