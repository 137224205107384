const PlanIngrijire = [
  {
    key: "needOfBreathAndGoodRespiration",
    label: "Nevoia de a respira si de a avea o buna circulatie",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Dependent", "Independent"],
      isDisabled: false
    }
  },

  {
    key: "needOfBreathAndGoodRespirationDescription",
    label: "Observatii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "needToEatAndDrink",
    label: "Nevoia de a manca si bea",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Dependent", "Independent"],
      isDisabled: false
    }
  },

  {
    key: "needToEatAndDrinkDescription",
    label: "Observatii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "needToEliminate",
    label: "Nevoia de a elimina",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Dependent", "Independent"],
      isDisabled: false
    }
  },

  {
    key: "needToEliminateDescription",
    label: "Observatii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "needToMoveAndHaveAGoodPosture",
    label: "Nevoia de a se misca si de a avea o buna postura",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Dependent", "Independent"],
      isDisabled: false
    }
  },

  {
    key: "needToMoveAndHaveAGoodPostureDescription",
    label: "Observatii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "needToFallAsleepAndRest",
    label: "Nevoia de a dormi si de a se odihni",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Dependent", "Independent"],
      isDisabled: false
    }
  },

  {
    key: "needToFallAsleepAndRestDescription",
    label: "Observatii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "needToDressAndUndress",
    label: "Nevoia de a se imbraca / dezbraca",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Dependent", "Independent"],
      isDisabled: false
    }
  },

  {
    key: "needToDressAndUndressDescription",
    label: "Observatii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "needToKeepBodyTemperatureInIormalLimits",
    label: "Nevoia de a tine temperatura corpului in limite normale",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Dependent", "Independent"],
      isDisabled: false
    }
  },

  {
    key: "needToKeepBodyTemperatureInIormalLimitsDescription",
    label: "Observatii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "needToBeCleanAndTidy",
    label:
      "Nevoia de a fi curat, ingrijit, de a proteja tegumentele si mucoasele",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Dependent", "Independent"],
      isDisabled: false
    }
  },

  {
    key: "needToBeCleanAndTidyDescription",
    label: "Observatii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "needToAvoidDangers",
    label: "Nevoia de a evita pericolele",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Dependent", "Independent"],
      isDisabled: false
    }
  },

  {
    key: "needToAvoidDangersDescription",
    label: "Observatii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "needToCommunicate",
    label: "Nevoia de a comunica",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Dependent", "Independent"],
      isDisabled: false
    }
  },

  {
    key: "needToCommunicateDescription",
    label: "Observatii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "needToActAccordingToOwnBeliefs",
    label:
      "Nevoia de a actiona conform propriilor convingeri si valori, de a-si practica religia",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Dependent", "Independent"],
      isDisabled: false
    }
  },

  {
    key: "needToActAccordingToOwnBeliefsDescription",
    label: "Observatii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "needToBePreoccupiedWithWork",
    label: "Nevoia de a fi preocupat in vederea realizarii",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Dependent", "Independent"],
      isDisabled: false
    }
  },

  {
    key: "needToBePreoccupiedWithWorkDescription",
    label: "Observatii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "needToRecreate",
    label: "Nevoia de a se recreea",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Dependent", "Independent"],
      isDisabled: false
    }
  },

  {
    key: "needToRecreateDescription",
    label: "Observatii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "needToLearn",
    label: "Nevoia de a invata cum sa-ti pastrezi sanatatea",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Dependent", "Independent"],
      isDisabled: false
    }
  },

  {
    key: "needToLearnDescription",
    label: "Observatii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  }
];

export default PlanIngrijire;
