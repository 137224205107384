import { Button, Card, Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import validate from "validate.js";
import hero6 from "../../../assets/images/hero-bg/hero-1.jpg";
import logoImage from "../../../assets/images/logo.png";
import loginFormSchema from "../../../Schemas/loginFormSchema";

const Login = (props) => {
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, loginFormSchema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="hero-wrapper w-100 bg-composed-wrapper bg-midnight-bloom min-vh-100">
          <div
            className="flex-grow-1 w-100 d-flex align-items-center"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="bg-composed-wrapper--image opacity-6"
              style={{ backgroundImage: "url(" + hero6 + ")" }}
            />
            <div className="bg-composed-wrapper--bg bg-second opacity-7" />
            <div className="bg-composed-wrapper--content p-3 p-md-5">
              <Container className={"container"} style={{ width: "600px" }}>
                <Card className="rounded-sm modal-content p-3 bg-white-10">
                  <Card className="rounded-sm overflow-hidden shadow-xxl font-size-sm p-3 p-sm-0">
                    <Grid container spacing={0} style={{ padding: "40px" }}>
                      <Grid
                        item
                        lg={12}
                        className="d-flex align-items-center justify-content-center flex-column"
                      >
                        <img src={logoImage} className={"logoImage"} alt={""} />
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={"singInButton"}
                          onClick={() => (window.location = "/login")}
                          style={{ background: "#0070a0", padding: "1rem" }}
                        >
                          Autentifica-te
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Card>
              </Container>
            </div>
          </div>
          <div className="hero-footer w-100 pb-4" />
        </div>
      </div>
    </>
  );
};

export default Login;
