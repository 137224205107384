import React, { useState, useEffect } from "react";
import { Switch, Redirect } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { ClimbingBoxLoader } from "react-spinners";

import { RouteWithLayout } from "./components";
import {
  Website as WebsiteLayout,
  IsLogged as IsLoggedLayout,
  SecondOpinionLayout,
} from "./layouts";

import * as V from "./views";
import SurgeryRoom from "../src/views/Website/SalaOperatii/Room";

const Routes = () => {
  // eslint-disable-next-line
  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
            >
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={"#3c44b1"} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the live preview examples
                  <span className="font-size-lg d-block text-dark">
                    This live preview instance can be slower than a real
                    production build!
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  return (
    <Switch>
      <RouteWithLayout
        component={V.Homepage}
        exact
        layout={WebsiteLayout}
        path="/"
      />

      <RouteWithLayout
        component={V.Login}
        exact
        layout={WebsiteLayout}
        path="/login"
      />

      <RouteWithLayout
        component={V.SecondOpinion}
        exact
        layout={SecondOpinionLayout}
        path="/second-opinion"
      />

      <RouteWithLayout
        component={V.Listare}
        exact
        layout={IsLoggedLayout}
        path="/listare"
      />

      <RouteWithLayout
        component={V.Listare_SefSectie}
        exact
        layout={IsLoggedLayout}
        path="/listare-ss"
      />

      <RouteWithLayout
        component={V.Dashboard}
        exact
        layout={IsLoggedLayout}
        path="/dashboard-ss"
      />

      <RouteWithLayout
        component={V.MedDashboard}
        exact
        layout={IsLoggedLayout}
        path="/dashboard-md/:id/:name"
      />

      <RouteWithLayout
        component={V.QrScan}
        exact
        layout={IsLoggedLayout}
        path="/qrScan"
      />

      <RouteWithLayout
        component={V.SimpleQr}
        exact
        layout={IsLoggedLayout}
        path="/scanQr"
      />
      <RouteWithLayout
        component={V.QrScanner}
        exact
        layout={IsLoggedLayout}
        path="/qrScanner"
      />

      <RouteWithLayout
        component={V.Calendar}
        exact
        layout={IsLoggedLayout}
        path="/calendar"
      />

      <RouteWithLayout
        component={V.SalaOperatii}
        exact
        layout={IsLoggedLayout}
        path="/sala-operatii"
      />

      <RouteWithLayout
        component={SurgeryRoom}
        exact
        layout={IsLoggedLayout}
        path="/sala-operatii/:id/:name"
      />

      <RouteWithLayout
        component={V.EquipmentManagement}
        exact
        layout={IsLoggedLayout}
        path="/management-echipamente"
      />

      <RouteWithLayout
        component={V.Chestionar}
        exact
        layout={IsLoggedLayout}
        path="/chestionar/:id"
      />

      <RouteWithLayout
        component={V.Chestionar_SefSectie}
        exact
        layout={IsLoggedLayout}
        path="/chestionar-ss/:id"
      />

      <RouteWithLayout
        component={V.Raportare}
        exact
        layout={IsLoggedLayout}
        path="/raportare"
      />

      <RouteWithLayout
        component={V.AtiEditPatientPage}
        exact
        layout={IsLoggedLayout}
        path="/ati-id/:id"
      />

      <RouteWithLayout
        component={V.AtiAddPatientPage}
        exact
        layout={IsLoggedLayout}
        path="/ati-add"
      />

      <RouteWithLayout
        component={V.ChangePassword}
        exact
        layout={IsLoggedLayout}
        path="/change-password"
      />

      <RouteWithLayout
        component={V.NotFound}
        exact
        layout={WebsiteLayout}
        path="/404"
      />
      <Redirect to="/404" />
    </Switch>
  );
};

export default Routes;
