import React from "react";

const FormData = (props) => {
  const { question, answer } = props;

  return (
    <div className="answerBox">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingLeft: "10px",
          width: "100%",
        }}
      >
        <p style={{ width: "50%" }}>{question}</p>
        <p style={{ width: "50%" }}>{answer}</p>
      </div>
    </div>
  );
};

export default FormData;
