import React from "react";
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import lscache from "lscache";
import { getStringFromBetween } from "../../Utils";
import Button from "../../views/Website/Chestionar/components/EvolutionList/Buttons1/index";

const MultipleRadioInput = (props) => {
  const {
    item,
    formState,
    handleChange,
    className,
    accordion1,
    accordion2,
    accordion3,
    accordion4,
    accordion5,
    toggleAccordion1,
    toggleAccordion2,
    toggleAccordion3,
    toggleAccordion4,
    toggleAccordion5,
  } = props;

  var itemName = item.key;

  const goToDischargeSheet = (props) => {
    if (accordion1) {
      toggleAccordion1();
    }
    if (accordion2) {
      toggleAccordion2();
    }
    if (accordion3) {
      toggleAccordion3();
    }
    if (accordion4) {
      toggleAccordion4();
    }

    if (!accordion5) {
      toggleAccordion5();
    }


    window.scrollTo({ top: 600, behavior: "smooth" });
  };

  return (
    <div className={item && className ? className : "answerBox"}>
      {lscache.get("operatorType") === "SefSectie" ? null : (
        <div className="labelContainer">
          {itemName === "intraDischargeState" ? (
            <div>
              <div className={"buttonContainer"}>
                <Button
                  onClick={() => goToDischargeSheet()}
                  text={"VEZI FISA EXTERNARE"}
                />
              </div>
              <p className="labelText">{item.label}</p>
            </div>
          ) : (
            <p className="labelText">{item.label}</p>
          )}
        </div>
      )}

      <div className="mutliRadioContainer">
        <RadioGroup
          id={`${itemName}`}
          name={`${itemName}`}
          value={
            (formState && formState.values && formState.values[itemName]) ||
            null
          }
          onChange={(event) => handleChange(event, null, [], item)}
          className="radioList"
        >
          {item.answer.selectList.map((el, index) => {
            let extra;

            const hasInterpolation = getStringFromBetween.get(el, "{{", "}}");

            if (!!hasInterpolation.length) {
              extra = (
                <div>
                  {hasInterpolation.map((interpo, index) => {
                    return (
                      <TextField
                        id={interpo}
                        name={interpo}
                        placeholder={interpo
                          .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
                          .toUpperCase()}
                        onChange={(event) => handleChange(event)}
                        className={"m-r-1-rem"}
                        value={formState.values[interpo]}
                        key={`interpo--${index}`}
                      />
                    );
                  })}
                </div>
              );
            }

            return (
              <FormControlLabel
                key={`multiple_radio_input_${el}`}
                value={el}
                control={<Radio />}
                label={
                  item.answer.selectListName !== undefined
                    ? item.answer.selectListName[index]
                    : extra || el
                }
              />
            );
          })}
        </RadioGroup>
      </div>
    </div>
  );
};

export default MultipleRadioInput;
