import React, { useState } from "react";
import { evaluareDependeta as Mocks } from "./Mocks";

import { RenderAtiAccordionNonTableComponent } from "../../../../../../components/componentsAti";
import { useAti } from "../../../../../../hooks";
import { writeToCtxHandleChange } from "../../../../../../helpers";
import { accordionConfigs } from "../../../../../../context/hydrateAccordionsFactory.helper";

const EvaluationForm = props => {
  const ctx = useAti();

  const { sheetId } = props;

  const [valueSecondDD, setValueSecondDD] = useState([]);
  const [valueThirdDD, setValueThirdDD] = useState([]);

  const ctxAccKey = "acc14";

  const handleChange = event => {
    event.persist();

    writeToCtxHandleChange(
      event,
      ctx,
      ctx.ACTIONS[accordionConfigs[ctxAccKey].accNo],
      accordionConfigs[ctxAccKey].accKey
    );
  };

  const changeHandler = event => {
    handleChange(event);
  };

  return (
    <RenderAtiAccordionNonTableComponent
      Mocks={Mocks}
      ctxState={ctx.rState}
      ctxAccKey={ctxAccKey}
      changeHandler={changeHandler}
      setValueSecondDD={setValueSecondDD}
      setValueThirdDD={setValueThirdDD}
      valueSecondDD={valueSecondDD}
      valueThirdDD={valueThirdDD}
      sheetId={sheetId}
    />
  );
};

export default EvaluationForm;
