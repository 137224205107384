import { Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CalendarComponent from "../../../components/Calendar";
import { REST } from "../../../Utils";

const Calendar = () => {
  const [appointments, setAppointments] = useState([]);
  const [data, setData] = useState([]);
  const [appointmentInfo, setAppointmentInfo] = useState({
    title: "",
  });
  const [agendaModal, setAgendaModal] = useState(false);

  const getAppointments = async () => {
    await REST.get(`/spital/getAppointments`).then((answer) => {
      setData(answer.data);
    });
  };

  useEffect(() => {
    getAppointments();
    // eslint-disable-next-line
  }, []);

  const CustomEvent = ({ title }) => {
    return (
      <div
        className={`calendar-cell-general calendar-cell-sala-1`}
        onClick={() => {
          setAppointmentInfo({
            title: title,
          });
          handleAgenda();
        }}
      >
        <h5>{title}</h5>
      </div>
    );
  };

  const javaData = () => {
    var newArr = [];
    data &&
      // eslint-disable-next-line
      data.map((item) => {
        let newValue = {
          id: item.id,
          title: CustomEvent({
            title: item.title,
          }),
          // title: item.title,
          start: new Date(item.start),
          end: new Date(item.end),
        };
        newArr.push(newValue);
      });
    setAppointments(newArr);
  };

  useEffect(() => {
    javaData();
    // eslint-disable-next-line
  }, [data]);

  const handleAgenda = () => {
    setAgendaModal(!agendaModal);
  };

  const defaultView = "day";
  const defaultDate = new Date();

  return (
    <div>
      <CalendarComponent
        events={appointments}
        defaultView={defaultView}
        defaultDate={defaultDate}
      />

      <Modal
        open={agendaModal}
        onBackdropClick={handleAgenda}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(3px)",
          width: "100%",
          height: "100%",
        }}
      >
        <div>
          {/*<MyCustomEvent appointmentInfo={appointmentInfo} />*/}
          <div
            className={`modal-cell calendar-cell-${appointmentInfo.department}`}
          >
            <div className={`modal-patient`}>
              Pacient: {appointmentInfo.title}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Calendar;
