export default [
  {
    key: "ora",
    label: "Ora",
    answer: {
      type: "TimePickerInput",
      required: true,
      selectList: [],
      customStyle: true,
    },
  },
];
