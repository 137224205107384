import clsx from "clsx";
import { Button as Btn, Card, Collapse } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import EvaluationForm from "./EvaluationForm";
import { useAti } from "../../../../../../hooks";

const Accordion10 = (props) => {
  const {
    toggleAccordion,
    accordionStates,
    formData,
    ticketId,
    userData,
    handleFourthAccordion,
  } = props;

  const ctx = useAti();

  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (
      ctx.rState.acc14.values &&
      ctx.rState.acc14.values.levelOfDependencies
    ) {
      // setTotal(0);

      const coll = Object.values(
        ctx.rState.acc14.values.levelOfDependencies
      ).map((item) => {
        return item === "NONE" ? 0 : parseInt(item, 10);
      });

      const set = coll.reduce((acc, item) => {
        return acc + item;
      });

      setTotal(set);
    }
  }, [ctx.rState.acc14]);

  return (
    <Card
      className={clsx("card-box", {
        "panel-open": accordionStates[`accordion14`],
      })}
    >
      <Card>
        <div className="card-header">
          <div className="panel-title">
            <div className="accordion-toggle">
              <Btn
                variant="text"
                size="large"
                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                onClick={() => toggleAccordion(14)}
                aria-expanded={accordionStates[`accordion14`]}
              >
                <span>Fisa evaluare nivel de dependenta</span>
                <FontAwesomeIcon
                  icon={["fas", "angle-up"]}
                  className="font-size-xl accordion-icon"
                />
              </Btn>
            </div>
          </div>
        </div>
        {accordionStates[`accordion14`] && (
          <Collapse in={accordionStates[`accordion14`]}>
            <div className={"accordion_body"}>
              <h3 className="formSubtitle">
                Fisa evaluare nivel de dependenta{" "}
              </h3>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h4 style={{ marginLeft: "2rem" }}>EVALUARE INITIALA</h4>
                <EvaluationForm
                  data={formData}
                  ticketId={ticketId}
                  userData={userData}
                  handleFirstPage={handleFourthAccordion}
                />
              </div>

              <div
                style={{
                  margin: "2rem",
                  // display: "flex",
                  // flexDirection: "row"
                }}
              >
                <h5>TOTAL: </h5>
                <h5>{total}</h5>
              </div>

              <div style={{ marginLeft: "2rem" }}>
                <h5>Legenda:</h5>
                <br />
                <p>
                  Prin acordarea unui punct de la 1 - 4 ficarei nevoie (in
                  fucntie de gravitate) si totalizand toate aceste puncte se{" "}
                  <br /> obtine un total care poate varia intre 14-56 puncte.
                  Totalul punctelor determina stabilire nevoilor de <br />{" "}
                  dependenta astfle:
                </p>
                <ul style={{ marginLeft: "4rem" }}>
                  <li>nivel 1 = 14 p - pacient independent su autonom</li>
                  <li>nivel 2 = 15 - 28 p - pacient cu dependenta moderata </li>
                  <li>nivel 3 = 29 - 42 p - pacient cu dependenta majora </li>
                  <li>nivel 4 = 43 - 56 p - pacient cu dependenta totala </li>
                </ul>
              </div>
            </div>
          </Collapse>
        )}
      </Card>
    </Card>
  );
};

export default Accordion10;
