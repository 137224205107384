import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import lscache from "lscache";
import React, { useEffect, useState } from "react";

const RadioInput = (props) => {
  const { item, formState, handleChange } = props;

  var itemName = item.key;

  const [evLocal, setEvLocal] = useState({
    target: {
      name: null,
      type: null,
      value: formState && formState.values && formState.values[itemName],
    },
    persist: () => true,
  });
  const [ev, setEv] = useState({
    target: {
      name: null,
      type: null,
      value:
        (formState && formState.values && formState.values[itemName]) || null,
    },
    persist: () => true,
  });

  const changeHandler = (event) => {
    event.persist();

    setEvLocal({
      target: {
        name: event.currentTarget.name,
        type: event.currentTarget.type,
        value: event.currentTarget.value,
      },
      persist: () => true,
    });
    setEv((prevState) => {
      return {
        ...prevState,
        target: {
          value: event.target.value,
          type: event.target.type,
          name: event.target.name,
        },
      };
    });
  };

  useEffect(() => {
    handleChange(ev);
    setEvLocal(ev);
    // eslint-disable-next-line
  }, [ev]);

  useEffect(() => {
    setEv((prevState) => {
      return {
        ...prevState,
        target: {
          value:
            (formState && formState.values && formState.values[itemName]) ||
            null,
        },
      };
    });
    // eslint-disable-next-line
  }, [formState]);

  // eslint-disable-next-line
  const getValue = () => {
    if (evLocal && evLocal.target && evLocal.target.value) {
      return evLocal.target.value;
    }

    return ev.target.value;
  };

  return (
    <div className="answerBox">
      {lscache.get("operatorType") === "SefSectie" ? null : (
        <div className="labelContainer">
          <p className="labelText">{item.label}</p>
        </div>
      )}

      <div className="radioContainer">
        <RadioGroup
          id={`${itemName}`}
          name={`${itemName}`}
          value={
            (evLocal && evLocal.target && evLocal.target.value === "true") ||
            (evLocal && evLocal.target && evLocal.target.value === true)
              ? true
              : false
          }
          onChange={changeHandler}
          className="radioList"
        >
          <FormControlLabel value={true} control={<Radio />} label="Da" />
          <FormControlLabel value={false} control={<Radio />} label="Nu" />
        </RadioGroup>
      </div>
    </div>
  );
};

export default RadioInput;
