const evaluareDependeta = [
  {
    key: "needOfBreathAndGoodRespiration",
    label: "1. Nevoia de a respira si a avea o buna circulatie",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4"],
      isDisabled: false,
    },
  },

  {
    key: "needToKeepBodyTemperatureInIormalLimits",
    label: "2. Nevoia de a-si metine temperatura corpului in limite normale",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4"],
      isDisabled: false,
    },
  },

  {
    key: "needToEatAndDrink",
    label: "3. Nevoia de a manca si bea",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4"],
      isDisabled: false,
    },
  },

  {
    key: "needToEliminate",
    label: "4. Nevoia de a elimina",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4"],
      isDisabled: false,
    },
  },

  {
    key: "needToMoveAndHaveAGoodPosture",
    label: "5. Nevoia de a se misca si avea o buna postura",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4"],
      isDisabled: false,
    },
  },

  {
    key: "needToFallAsleepAndRest",
    label: "6. Nevoia de a dormi si a se odihni",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4"],
      isDisabled: false,
    },
  },

  {
    key: "needToDressAndUndress",
    label: "7. Nevoia de a se imbraca si dezbraca",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4"],
      isDisabled: false,
    },
  },

  {
    key: "needToBeCleanAndTidy",
    label: "8. Nevoia de a fi curat si ingirjit, de a proteja tegmentele",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4"],
      isDisabled: false,
    },
  },

  {
    key: "needToAvoidDangers",
    label: "9. Nevoia de evita pericolele",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4"],
      isDisabled: false,
    },
  },

  {
    key: "needToCommunicate",
    label: "10. Nevoia de a comunica",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4"],
      isDisabled: false,
    },
  },

  {
    key: "needToActAccordingToOwnBeliefs",
    label:
      "11. Nevoia de a actiona conform propriilor convingeri si valor de a practica religia",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4"],
      isDisabled: false,
    },
  },

  {
    key: "needToBePreoccupiedWithWork",
    label: "12. Nevoia de a fi preocupat de munca, de a se realiza",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4"],
      isDisabled: false,
    },
  },

  {
    key: "needToRecreate",
    label: "13. Nevoia de a se recrea",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4"],
      isDisabled: false,
    },
  },

  {
    key: "needToLearn",
    label: "14. Nevoia de a invata cum sa-si pastreze sanatatea",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4"],
      isDisabled: false,
    },
  },

  // {
  //     // label: "EVALUARE RISCULUI DE DOLIU PENTRU APARTINATORI:",
  //     description: "Legenda:",
  //     answer: {
  //         type: "TitleForInput",
  //         required: true,
  //         selectList: [],
  //     },
  // },

  // {
  //     label: "Legenda:",
  //     description: "Prin acordarea unui punct de la 1 - 4 ficarei nevoie (in fucntie de gravitate) si totalizand toate aceste puncte se obtine un total care poate varia intre 14-56 puncte. Totalul punctelor determina stabilire nevoilor de dependenta astfle:",
  //     description: "Prin acordarea unui punct de la 1 - 4 ficarei nevoie (in fucntie de gravitate) si totalizand toate aceste puncte se obtine un total care poate varia intre 14-56 puncte. Totalul punctelor determina stabilire nevoilor de dependenta astfle:",
  //     answer: {
  //         type: "TitleForInput",
  //         required: true,
  //         selectList: [],
  //     },
  // },
];

export default evaluareDependeta;
