import { all } from "./spitalList";

export default [
  {
    title: "",
    key: "lastName",
    label: "Nume",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "firstName",
    label: "Prenume",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },

  {
    title: "",
    key: "birthDate",
    label: "Data nașterii",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
      isDisabled: true,
    },
  },
  {
    title: "",
    key: "sex",
    label: "Sexul",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: ["Male", "Female"],
    },
  },
  {
    title: "",
    key: "bloodType",
    label: "Grup sangvin",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: ["0", "A", "B", "AB", "unknown"],
      selectListName: ["0 I", "A II", "B III", "AB IV", "Nu cunosc"],
    },
  },
  {
    title: "",
    key: "rh",
    label: "RH",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: ["negativ", "pozitiv", "unknown"],
      selectListName: ["Negativ", "Pozitiv", "Nu Cunosc"],
    },
  },

  {
    label: "Domiciliul legal:",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
    },
  },

  {
    label: "Domiciliul legal:",
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "legalCounty",
    label: "Judet",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "legalCity",
    label: "Localitate",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "legalStreet",
    label: "Strada",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "legalStreetNr",
    label: "Numarul Strazii",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },

  {
    label: "Resedinta:",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "county",
    label: "Judet",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "city",
    label: "Localitate",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "street",
    label: "Strada",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "streetNr",
    label: "Numarul Strazii",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "admissionDate",
    label: "Data internarii:",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "dischargeDate",
    label: "Data externarii:",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
    },
  },
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "weightNewBorn",
    label: "Greutatea la nastere (nou nascuti):",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "profession",
    label: "Ocupatia:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "workPlace",
    label: "Locul de munca",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "hospitalizationDays",
    label: "Nr. zile spitalizare",
    question: "Un nr. de tel si o adresa de mail?",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "hospitalizationSickLeave",
    label: "Nr. zile c. m. spitalizare",
    question: "Un nr. de tel si o adresa de mail?",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "serialId",
    label: "C.I / B.I seria:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "serialNumber",
    label: "Numar CI",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "birthSerialId",
    label: "Certificat  nastere (copil) seria:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "birthSerialNumber",
    label: "Numar",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },

  {
    key: "ciCounty",
    label: "Judetul:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "registrationNr",
    label: "Nr. inregistrare:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },

  {
    key: "CNP",
    label: "CNP",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      isDisabled: true,
    },
  },
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
    },
  },

  {
    key: "hospitalCity",
    label: "Localitate",
    value: "Bucuresti",
    answer: {
      type: "DisabledTextInput",
      required: true,
      selectList: [],
      disabled: true,
    },
  },
  {
    key: "hospitalName",
    label: "Spitalul",
    value: "",
    answer: {
      type: "DisabledTextInput",
      required: true,
      selectList: [],
      disabled: true,
    },
  },
  {
    key: "hospitalDepartment",
    label: "Sectia",
    value: "",
    answer: {
      type: "DisabledTextInput",
      required: true,
      selectList: [],
      disabled: true,
    },
  },
  // {
  //   key: "selectData",
  //   label: "",
  //   answer: {
  //     type: "MultipleDropDownInput",
  //     required: true,
  //     selectList: all,
  //   },
  // },

  {
    key: "doctorSeal",
    label: "Intocmit de:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: all,
    },
  },
];
