import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React from "react";
import { useAti } from "../../../../../../hooks";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ManagementPlanTable = () => {
  const ctx = useAti();
  const classes = useStyles();

  return (
    <>
      {ctx.rState.acc12.values.managementPlans && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell align="right">Probleme / Nevoi</TableCell>
                <TableCell align="right">Obiectiv / Interventii</TableCell>
                <TableCell align="right">Revizuire</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ctx.rState.acc12.values.managementPlans.map((row, index) => {
                return (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {moment(row.date).format("YYYY-MM-DD")}
                    </TableCell>

                    <TableCell align="right">{row.problems || "--"}</TableCell>
                    <TableCell align="right">
                      {row.interventions || "--"}
                    </TableCell>
                    <TableCell align="right">{row.review || "--"}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ManagementPlanTable;
