import { all } from "../../../../../../../../Utils/spitalList";

const examenClinicGeneral = [
  {
    label: "EXAMENUL CLINIC GENERAL",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "teguments",
    label: "Tegumente si mucoase:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "adiposeConnectiveTissue",
    label: "Tesut conjunctiv-adipos",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "nodalSystem",
    label: "Sistem ganglionar",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "muscularSystem",
    label: "Sistem muscular",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "osteoSystem",
    label: "Sistem osteo-articular",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "respiratorySystem",
    label: "Sistem respirator",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "cardioVascularSystem",
    label: "Aparat Cardiovascular",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "digestiveSystem",
    label: "Aparat Digestiv",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "nutritionState",
    label: "Starea de nutritie",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "uroGenitalSystem",
    label: "APARAT URO-GENITAL",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "nervousAndTactilSystem",
    label: "SISTEM NERVOS, ENDOCRIN, ORGANE DE SIMT",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "stareConstienta",
    label: "Starea de constienta",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "selectPacientConfuz",
    label: "Pacientul a mai fost confuz in ultimul timp?",
    displayLabel: "Pacientul a mai fost confuz in ultimul timp?",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["Da", "Nu", "Nu se cunoaste", "Coma"]
    }
  },

  {
    key: "scorMmse",
    label: "Scor MMSE",
    answer: {
      type: "TextInput",
      required: true,
      selectList: all
    }
  }
];

export default examenClinicGeneral;
