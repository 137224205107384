import React from "react";
import { aparatTypes } from "../AparatTypes/aparatTypes";
import AllMatrix from "./AllMatrix";
import { orderByDateAndHour } from "./raportareHelpers";

const RapoarteTemperatura = (props) => {
  const { allData } = props;

  const filterBasedOnType = (type) => {
    const filteredFromUndefined = allData.filter(
      (item) =>
        item[type] && !!item[type].length && !isNaN(parseFloat(item[type]))
    );

    const final = filteredFromUndefined.map((item, index) => {
      return {
        orig: item[type],
        value: parseFloat(item[type]) || 0,
        date: item["VITAL DATE"],
      };
    });

    return orderByDateAndHour(final);
  };

  let accumulators = {};

  aparatTypes.map((type) => (accumulators[type] = filterBasedOnType(type)));

  return (
    <>
      <AllMatrix accumulators={accumulators} />
    </>
  );
};

export default RapoarteTemperatura;
