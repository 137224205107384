import React from "react";
import { IoIosSave } from "react-icons/io";

const FloatingSave = (props) => {
  const { handleSubmit } = props;

  return (
    <IoIosSave
      className="floatingSave"
      onClick={handleSubmit}
      style={{ height: 50 }}
    />
  );
};

export default FloatingSave;
