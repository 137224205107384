import React, { useEffect, useState } from "react";
import SignTextInput from "./SignTextInput";

const MultipleSignTextInput = (props) => {
  const { item, formState, handleChange, hasError, handleBlur } = props;

  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    setInputs([...inputs, ""]);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {item.answer.selectLabelList.map((items, index) => (
        <SignTextInput
          key={`SignTextInput_${index}`}
          item={item}
          formState={formState}
          handleChange={handleChange}
          handleBlur={handleBlur}
          index={index}
          hasError={hasError}
          label={items}
          additionalName={item.answer.additionalName[index]}
        />
      ))}
    </div>
  );
};

export default MultipleSignTextInput;
