import { orderBy } from "lodash";
import moment from "moment";

export const getRapoarteAccumulators = allValues => {};

export const orderByDateAndHour = acc => {
  return orderBy(
    acc,
    function(o) {
      return new moment(`${o.date}`).format("YYYYMMDD");
    },
    ["asc"]
  );
};
