import { Grid } from "@material-ui/core";
import lscache from "lscache";
import React, { useEffect, useState } from "react";
import validate from "validate.js";
import {
  CheckBoxInput,
  DatePickerInput,
  DisabledTextInput,
  DropDownInput,
  MultipleRadioInput,
  NewLineInput,
  RadioInput,
  TextInput,
  TimePickerInput,
  TitleForInput,
} from "../../../../../components";
import { REST } from "../../../../../Utils/index";
import Mocks from "./components/Mocks";
import FormSchema from "./FormSchema";

const Form = (props) => {
  const { data, ticketId, handleFirstPage } = props;
  // eslint-disable-next-line
  const [error, setError] = useState({ message: null });
  const [formState, setFormState] = useState({
    isValid: false,
    values: data,
    touched: {},
    errors: {},
    counter: 0,
  });

  const [showOkButton, setShowOkButton] = React.useState(true);
  const [dataDRG, setDRGData] = useState(null);

  useEffect(() => {
    const errors = validate(formState.values, FormSchema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));

    handleFirstPage(formState.values);
    // eslint-disable-next-line
  }, [formState.values]);

  useEffect(() => {
    let values = formState.values;
    setFormState((formState) => ({
      ...formState,
      values: {
        ...values,
        sex: parseInt(values.CNP[0]) % 2 ? "Male" : "Female",
      },
    }));
    // eslint-disable-next-line
  }, [formState.values["CNP"]]);

  const handleBlur = (event) => {
    document
      .querySelectorAll("[data-replace='#" + event.target.name + "#']")
      .forEach((el) => {
        el.innerText = el.dataset.q.replace(
          el.dataset.replace,
          event.target.value
        );
      });
  };

  const handleChange = (event, callLoad = null, forList = [], ref = null) => {
    event.persist();

    let myVal = null;
    let tmpVals = formState.values;

    if (ref !== null && ref.affects !== undefined) {
      ref.affects.forEach((el) => {
        delete tmpVals[el];
      });
    }
    if (ref !== null) {
      if (ref.setConfirmed !== undefined) {
        delete tmpVals["confirmed"];
        if (event.target.value !== "false" && event.target.value.length) {
          tmpVals.confirmed = true;
        }
      }
    }

    if (forList !== null && forList.key !== undefined) {
      if (callLoad && callLoad === null) {
      } else {
        if (typeof callLoad === "function") {
          let cat = forList["cat"];
          myVal =
            formState.values[event.target.name] === null
              ? {}
              : formState.values[event.target.name];
          myVal = {
            ...myVal,
            [cat]: event.target.value,
          };

          callLoad(forList.sub);
        }
      }
    } else if (event.target.type === "radio") {
      if (event.replace) {
        event.target.name = event.replace;
      }
      myVal = event.target.value;
    } else if (event.target.type === "Date") {
      myVal = new Date(event.target.value);
    } else if (event.target.type === "checkbox") {
      let getKey =
        event.replace === undefined ? event.target.name : event.replace;
      let listVals =
        formState.values[getKey] === undefined ? [] : formState.values[getKey];
      if (event.target.checked) {
        if (Array.isArray(listVals)) {
          listVals.push(event.target.value);
        } else {
          listVals = event.target.value;
        }
      } else {
        if (Array.isArray(listVals)) {
          listVals = listVals.filter((el) => {
            return el !== event.target.value;
          });
        } else {
          listVals = false;
        }
      }
      myVal = listVals;
    } else {
      if (event.replace) {
        event.target.name = event.replace;
      }
      myVal = event.target.value;
    }

    const getRadioValueBasedOnTargetName = () => {
      if (
        event.target.name === "bloodType" ||
        event.target.name === "sex" ||
        event.target.name === "rh"
      ) {
        return event.target.value;
      }

      return event.target.type === "radio" ? myVal === "true" : myVal;
    };

    setFormState((formState) => ({
      ...formState,
      counter: Object.keys(formState.values).length + 1,
      values: {
        ...tmpVals,
        [event.target.name]: getRadioValueBasedOnTargetName(),
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  // eslint-disable-next-line
  const handleSubmit = async () => {
    if (formState.isValid) {
      document.querySelectorAll(".submitButton").forEach((el) => {
        el.disabled = true;
      });
      const body = {
        form: formState.values,
        patientCnp: formState.values.CNP,
        patientName: formState.values.name,
      };

      await REST.post(`/spital/updateTicketCC?ticketId=${ticketId}`, body)
        .then((answer) => {
          setError({ message: null });

          window.location = "/listare";
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            setError({
              message,
            });
          }
        });
    } else {
      setError({ message: null });
    }
  };

  // eslint-disable-next-line
  const handleApprove = async () => {
    if (formState.isValid) {
      document.querySelectorAll(".submitButton").forEach((el) => {
        el.disabled = true;
      });
      const body = {
        form: formState.values,
        patientCnp: formState.values.CNP,
        patientName: formState.values.name,
      };

      await REST.post(`/spital/updateTicketCC?ticketId=${ticketId}`, body)
        .then((answer) => {
          setError({ message: null });

          if (lscache.get("operatorType") === "Operator B") {
            REST.put(
              `/spital/updateTicketStatus?&status=Operator C&ticketId=${ticketId}`
            )
              .then((answer) => {
                window.location = "/listare";
              })
              .catch((err) => {});
          } else if (lscache.get("operatorType") === "Operator C") {
            REST.put(
              `/spital/updateTicketStatus?&status=approved&ticketId=${ticketId}`
            )
              .then((answer) => {
                window.location = "/listare";
              })
              .catch((err) => {});
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            setError({
              message,
            });
          }
        });
    } else {
      setError({ message: null });
    }
  };

  // eslint-disable-next-line
  const handleReject = async () => {
    if (formState.isValid) {
      document.querySelectorAll(".submitButton").forEach((el) => {
        el.disabled = true;
      });
      const body = {
        form: formState.values,
        patientCnp: formState.values.CNP,
        patientName: formState.values.name,
      };

      await REST.post(`/spital/updateTicketCC?ticketId=${ticketId}`, body).then(
        (answer) => {
          setError({ message: null });

          if (lscache.get("operatorType") === "Operator B") {
            REST.put(
              `/spital/updateTicketStatus?&status=rejected&ticketId=${ticketId}`
            )
              .then((answer) => {
                window.location = "/listare";
              })
              .catch((err) => {});
          } else if (lscache.get("operatorType") === "Operator B") {
            REST.put(
              `/spital/updateTicketStatus?&status=rejected&ticketId=${ticketId}`
            )
              .then((answer) => {
                window.location = "/listare";
              })
              .catch((err) => {});
          } else if (lscache.get("operatorType") === "Operator C") {
            REST.put(
              `/spital/updateTicketStatus?&status=Operator B&ticketId=${ticketId}`
            )
              .then((answer) => {
                window.location = "/listare";
              })
              .catch((err) => {});
          }
        }
      );
    } else {
      setError({ message: null });
    }
  };

  // eslint-disable-next-line
  const getDRGData = async (drgCode) => {
    await REST.get(`/spital/getDrgByDrgCode?drgCode=${drgCode}`)
      .then((answer) => {
        setError({ message: null });

        setDRGData(answer.data.drgDescription);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          setError({
            message,
          });
        }
      });
  };

  const handleOkButtonFromRadio = () => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        confirmed: true,
      },
    });
    setShowOkButton(false);
  };

  return (
    <Grid container direction="row" className="SecondForm">
      {Mocks.map((item, index) => {
        if (item.answer.type === "wait") {
          return null;
        }
        let showIt = true;
        if (item.depends !== undefined) {
          if (Array.isArray(item.depends.vals)) {
            showIt = item.depends.vals.includes(
              formState.values[item.depends.key]
            );
          } else {
            showIt = item.depends.vals === formState.values[item.depends.key];
          }
        }
        return (
          showIt &&
          (() => {
            if (item.answer.type === "TextInput") {
              return (
                <Grid item xs={4} key={`${item.answer.type}_${index}`}>
                  <TextInput
                    key={item.key}
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    label={item.label}
                    isDisabled={item.answer.isDisabled}
                  />
                </Grid>
              );
            } else if (item.answer.type === "DisabledTextInput") {
              return (
                <Grid item xs={4} key={`${item.answer.type}_${index}`}>
                  <DisabledTextInput
                    key={item.key}
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                  />
                </Grid>
              );
            } else if (item.answer.type === "NewLineInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <NewLineInput
                    key={item.key}
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    label={item.label}
                  />
                </Grid>
              );
            } else if (item.answer.type === "TitleForInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <TitleForInput
                    key={item.key}
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    label={item.label}
                  />
                </Grid>
              );
            } else if (item.answer.type === "RadioInput") {
              return (
                <Grid item xs={4} key={`${item.answer.type}_${index}`}>
                  <RadioInput
                    key={item.key}
                    item={item}
                    dataDRG={dataDRG}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showOkButton={showOkButton}
                    handleOkButtonFromRadio={handleOkButtonFromRadio}
                  />
                </Grid>
              );
            } else if (item.answer.type === "MultipleRadioInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <MultipleRadioInput
                    key={item.key}
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showOkButton={showOkButton}
                    handleOkButtonFromRadio={handleOkButtonFromRadio}
                  />
                </Grid>
              );
            } else if (item.answer.type === "TimePickerInput") {
              return (
                <Grid item xs={4} key={`${item.answer.type}_${index}`}>
                  <TimePickerInput
                    key={item.key}
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showOkButton={showOkButton}
                    handleOkButtonFromRadio={handleOkButtonFromRadio}
                  />
                </Grid>
              );
            } else if (item.answer.type === "DatePickerInput") {
              return (
                <Grid item xs={4} key={`${item.answer.type}_${index}`}>
                  <DatePickerInput
                    key={item.key}
                    item={item}
                    isDisabled={item.answer.isDisabled}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                  />
                </Grid>
              );
            } else if (item.answer.type === "wait") {
              return <div></div>;
            } else if (item.answer.type === "DropDownInput") {
              return (
                <Grid item xs={4} key={`${item.answer.type}_${index}`}>
                  <DropDownInput
                    key={item.key}
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showOkButton={showOkButton}
                    handleOkButtonFromRadio={handleOkButtonFromRadio}
                  />
                </Grid>
              );
            } else if (item.answer.type === "CheckBoxInput") {
              return (
                <Grid item xs={4} key={`${item.answer.type}_${index}`}>
                  <CheckBoxInput
                    key={item.key}
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showOkButton={showOkButton}
                    handleOkButtonFromRadio={handleOkButtonFromRadio}
                  />
                </Grid>
              );
            } else {
              return <div></div>;
            }
          })()
        );
      })}
    </Grid>
  );
};

export default Form;
