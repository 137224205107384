import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React from "react";
import { useAti } from "../../../../../../hooks";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const FoaiePaliativeTable = () => {
  const ctx = useAti();
  const classes = useStyles();

  return (
    <>
      {ctx.rState.acc16.values.generalObservationSheet && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Medicatie Prescriptie Regulata</TableCell>
                <TableCell align="right">Doza unica</TableCell>
                <TableCell align="right">Cale administrare</TableCell>
                <TableCell align="right">Data incepere</TableCell>
                <TableCell align="right">Nr Zile</TableCell>
                <TableCell align="right">Frecventa</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ctx.rState.acc16.values.generalObservationSheet &&
                ctx.rState.acc16.values.generalObservationSheet.map(
                  (row, index) => {
                    return (
                      <TableRow
                        key={row.name}
                        style={
                          index % 2
                            ? { background: "#fdffe0" }
                            : { background: "white" }
                        }
                      >
                        <TableCell>
                          {(row.medication && row.medication.toString()) ||
                            "--"}
                        </TableCell>
                        <TableCell align="right">
                          {(row.singleDose && row.singleDose.toString()) ||
                            "--"}
                        </TableCell>
                        <TableCell align="right">
                          {(row.routeOfAdministration &&
                            row.routeOfAdministration.toString()) ||
                            "--"}
                        </TableCell>
                        <TableCell align="right">
                          {(row.startDate &&
                            moment(row.startDate).format("DD-MM-YYYY")) ||
                            "--"}
                        </TableCell>
                        <TableCell align="right">
                          {(row.numberOfDays && row.numberOfDays) || "--"}
                        </TableCell>
                        <TableCell align="right">
                          {(row.frequency && row.frequency.toString()) || "--"}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default FoaiePaliativeTable;
