import Accordion01 from "../_Accordions/01_Form/01_Accordion";
import Accordion02 from "../_Accordions/02_SecondForm/02_Accordion";
import Accordion03 from "../_Accordions/03_EvaluareHolistica/03_Accordion";
import Accordion04 from "../_Accordions/04_EvaluarePsihoEmotionala/04_Accordion";
import Accordion05 from "../_Accordions/05_EvaluareReligioasa";
import Accordion06 from "../_Accordions/06_EvaluareStareSociala/06_Accordion";
import Accordion07 from "../_Accordions/07_CoordonareaIngrijirii/07_Accordion";
import Accordion08 from "../_Accordions/08_ConsultAlteDiscipline/08_Accordion";
// import Accordion09 from "../_Accordions/09_Pansamente/09_Accordion";
// import Accordion10 from "../_Accordions/10_Waterlow/10_Accordion";
import Accordion11 from "../_Accordions/11_InvestigatiiParaclinice/11_Accordion";
import Accordion12 from "../_Accordions/12_PlanManagement/12_Accordion";
// import Accordion13 from "../_Accordions/13_EvolutionList/13_Accordion";
import Accordion14 from "../_Accordions/14_FisaEvaluareDependenta/14_Accordion";
import Accordion15 from "../_Accordions/15_EvaluareRiscCadere/15_Accordion";
import Accordion16 from "../_Accordions/16_FoaiePaliative/16_Accordion";
import Accordion17 from "../_Accordions/17_Medicatie/17_Accordion";
import Accordion18 from "../_Accordions/18_PlanIngrijire/18_Accordion";
import Accordion19 from "../_Accordions/19_EvolutieSimptome/19_Accordion";
import Accordion20 from "../_Accordions/20_MonitorizarePacient/20_Accordion";
import Accordion21 from "../_Accordions/21_FoaieTemperatura/21_Accordion";
import Button from "../../../Chestionar/components/EvolutionList/Buttons1";
import {
  BackToTop,
  FloatingSave,
} from "../../../../../components/componentsAti";
// import FloatingButton from "../../../../../../src/components/FloatingButton/FloatingButton";
// import DraggableScanButton from "../../../../../../src/components/DraggableScanButton/DraggableScanButton";

import React from "react";

const AllAccordionsComponent = (props) => {
  const {
    toggleAccordion,
    accordionStates,
    sheetId,
    toggle,
    setAllData,
    activeTab,
    history,
    location,
    allData,
    handleSubmit,
  } = props;

  return (
    <>
      <div className="allFormsContainer">
        <>
          <div className="accordion mb-spacing-0">
            <Accordion01
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              ticketId={sheetId}
            />

            <Accordion02
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              ticketId={sheetId}
            />

            <Accordion03
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              ticketId={sheetId}
            />

            <Accordion04
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              ticketId={sheetId}
            />

            <Accordion05
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              ticketId={sheetId}
            />

            <Accordion06
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              history={history}
              location={location}
              ticketId={sheetId}
            />

            <Accordion07
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              history={history}
              location={location}
              ticketId={sheetId}
            />

            <Accordion08
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              history={history}
              location={location}
              ticketId={sheetId}
            />

            {/* <Accordion09
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              history={history}
              location={location}
              ticketId={sheetId}
            /> */}

            {/*<Accordion10*/}
            {/*  toggleAccordion={toggleAccordion}*/}
            {/*  accordionStates={accordionStates}*/}
            {/*/>*/}

            <Accordion11
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              ticketId={sheetId}
            />
            <Accordion12
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              ticketId={sheetId}
            />

            {/*<Accordion13*/}
            {/*  history={history}*/}
            {/*  location={location}*/}
            {/*  ticketId={sheetId}*/}
            {/*  toggleAccordion={toggleAccordion}*/}
            {/*  accordionStates={accordionStates}*/}
            {/*/>*/}

            <Accordion14
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              ticketId={sheetId}
            />
            <Accordion15
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              ticketId={sheetId}
            />

            <Accordion16
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              history={history}
              location={location}
              sheetId={sheetId}
            />

            <Accordion17
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              ticketId={sheetId}
            />

            <Accordion18
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              ticketId={sheetId}
              // handleFourthAccordion={handleFourthAccordion}
            />

            <Accordion19
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              sheetId={sheetId}
              // handleFourthAccordion={handleFourthAccordion}
            />

            <Accordion20
              toggleAccordion={toggleAccordion}
              accordionStates={accordionStates}
              ticketId={sheetId}
              // handleFourthAccordion={handleFourthAccordion}
            />

            <Accordion21
              accordionStates={accordionStates}
              toggleAccordion={toggleAccordion}
              toggle={toggle}
              setAllData={setAllData}
              sheetId={sheetId}
              activeTab={activeTab}
              allData={allData}
            />
          </div>
        </>
      </div>
      <div
        className={"sendForm_btn"}
        // style={{ marginTop: "1.5rem", justifyContent:"center" }}
      >
        <Button onClick={handleSubmit} text="SALVEAZA"></Button>
      </div>
      <BackToTop />
      <FloatingSave handleSubmit={handleSubmit} />
      {/*<FloatingButton thicketId={sheetId} monitorData={monitorData} />*/}
      {/*<DraggableScanButton />*/}
    </>
  );
};

export default AllAccordionsComponent;
