import React, { useState, useEffect } from "react";
import Mocks from "../../../../_ATI_Mocks";

import { RenderAtiAccordionNonTableComponent } from "../../../../../../components/componentsAti";
import { useAti } from "../../../../../../hooks";

const Form = (props) => {
  const [valueSecondDD, setValueSecondDD] = React.useState([]);
  const [valueThirdDD, setValueThirdDD] = React.useState([]);
  const ctx = useAti();

  const [formState, setFormState] = useState({
    firstAccordion: { values: {} },
  });

  const handleChange = (event) => {
    if (event.target.value) {
      setFormState((prevState) => {
        return {
          ...prevState,
          firstAccordion: {
            values: {
              ...prevState.firstAccordion.values,
              [event.target.name]:
                event.target.type === "checkbox"
                  ? event.target.checked
                  : event.target.value,
              sex:
                prevState.firstAccordion.values &&
                prevState.firstAccordion.values.CNP &&
                prevState.firstAccordion.values.CNP[0] &&
                parseInt(prevState.firstAccordion.values.CNP[0]) % 2
                  ? "Male"
                  : "Female",
            },
          },
        };
      });
    }
  };

  useEffect(() => {
    ctx.dispatchCreate({
      type: "firstAccordion",
      payload: formState,
    });
    // eslint-disable-next-line
  }, [formState]);

  const changeHandler = (event) => {
    handleChange(event);
  };

  return (
    <RenderAtiAccordionNonTableComponent
      Mocks={Mocks}
      ctxState={ctx.state}
      ctxAccKey={"firstAccordion"}
      changeHandler={changeHandler}
      setValueSecondDD={setValueSecondDD}
      setValueThirdDD={setValueThirdDD}
      valueSecondDD={valueSecondDD}
      valueThirdDD={valueThirdDD}
    />
  );
};

export default Form;
