const schema = {
  date: {
    presence: true
  },
  ora: {
    presence: true
  },
  "VITAL SpO2": {
    presence: false,
    numericality: {
      onlyInteger: false,
      lessThanOrEqualTo: 100,
      message: "trebuie sa fie intre 0-100"
    }
  },
  "VITAL HR": {
    presence: false,
    numericality: {
      onlyInteger: false,
      lessThanOrEqualTo: 300,
      message: "trebuie sa fie intre 0-300"
    }
  },
  "VITAL RESP": {
    presence: false,
    numericality: {
      onlyInteger: false,
      lessThanOrEqualTo: 100,
      message: "trebuie sa fie intre 0-100"
    }
  },
  "VITAL TRECT": {
    presence: false,
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
      lessThanOrEqualTo: 50,
      message: "trebuie sa fie intre 0-50"
    }
  },
  "VITAL TSKIN": {
    presence: false,
    numericality: {
      onlyInteger: false,
      lessThanOrEqualTo: 50,
      message: "trebuie sa fie intre 0-50"
    }
  },
  "VITAL ART(S)": {
    presence: false,
    numericality: {
      onlyInteger: false,
      lessThanOrEqualTo: 400,
      message: "trebuie sa fie intre 0-400"
    }
  },
  "VITAL ART(D)": {
    presence: false,
    numericality: {
      onlyInteger: false,
      lessThanOrEqualTo: 400,
      message: "trebuie sa fie intre 0-400"
    }
  }
};

export default schema;
