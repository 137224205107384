const monitorizarePacient = [
  {
    title: "",
    key: "date",
    label: "Data",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },

  {
    // label: "Domiciliul legal:",
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: []
    }
  }, 

  {
    title: "",
    key: "alimentationProgram",
    label: "Program alimentare / ore",
    answer: {
      type: "TimePickerInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },
  {
    title: "",
    key: "hydratation",
    label: "Hidratare",
    answer: {
      type: "TimePickerInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },
  {
    title: "",
    key: "stipple",
    label: "Tapotaj",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["dimineata", "seara"],
      isDisabled: false
    }
  },
  {
    title: "",
    key: "patchs",
    label: "Pansament",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },
  {
    title: "",
    key: "position",
    label: "Modificare pozitie in pat (ore)",
    answer: {
      type: "TimePickerInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },
  {
    // label: "Domiciliul legal:",
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: []
    }
  }, 
];

export default monitorizarePacient;
