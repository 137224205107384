import React, { useState } from "react";
import { Button, Modal, Card } from "@material-ui/core";
import { REST } from "../../../../../Utils";
import printImg from "../../../../../assets/images/qr/printImg.png";

const PrintButton = (props) => {
  const { ticketId } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textToShow, setTextToShow] = useState("");

  const handlePrint = async () => {
    //do request for printing
    await REST.get(`/spital/printQr?ticketId=${ticketId}`)
      .then((answer) => {
        setTextToShow("Printare in curs de derulare...");
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          setTextToShow("Printarea a esuat.");
        }
      });

    handleOpenModal();
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={"PrintButton"}>
      <div className={"modal__container"}>
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(3px)",
            width: "100%",
            height: "100%",
          }}
        >
          <div>
            <Card
              style={{
                backgroundColor: "lightgray",
                height: "220px",
                width: "500px",
                padding: "20px",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "30px",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                {textToShow}
              </p>
              <Button
                onClick={handleCloseModal}
                style={{
                  height: "50px",
                  width: "70px",
                  backgroundColor: "#3c44b1",
                  marginLeft: "200px",
                  marginTop: "20px",
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontWeight: "900",
                    fontSize: "14px",
                    padding: "10px",
                    marginTop: "15px",
                    textAlign: "center",
                  }}
                >
                  OK
                </p>
              </Button>
            </Card>
          </div>
        </Modal>
      </div>
      <Button
        className={"button"}
        onClick={handlePrint}
        style={{
          height: "55px",
          width: "155px",
          backgroundColor: "#3c44b1",
          marginTop: "50px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p
            className={"button__text"}
            style={{
              color: "white",
              fontWeight: "900",
              fontSize: "14px",
              padding: "10px",
              marginTop: "15px",
            }}
          >
            PRINT QR
          </p>
          <img
            src={printImg}
            style={{ height: "40px", width: "40px", marginTop: "14px" }}
            alt={""}
          />
        </div>
      </Button>
    </div>
  );
};

export default PrintButton;
