const FormSchema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32
    }
  },
  birthDate: {
    presence: { allowEmpty: false, message: "is required" },
    // format: {
    //   pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
    //   message: "format is DD/MM/YYYY"
    // }
  },

  CNP: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 13
    },
    format: {
      pattern: /^[0-9]+$/,
      message: "only numbers"
    }
  },
  serialNumber: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 8
    }
  },
  phone: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 14
    },
    format: {
      pattern: /^[0-9]+$/,
      message: "only numbers"
    }
  },
  confirmPhone: {
    presence: { allowEmpty: false, message: "is required" }
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64
    },
    email: {
      message: "doesn't look like a valid email"
    }
  },

  profession: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32
    }
  },
  address: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 124
    }
  },
  cas: {
    presence: { allowEmpty: false, message: "is required" }
  },
  hasTicket: {
    presence: { allowEmpty: false, message: "is required" }
  },
  whereIsThePain: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128
    }
  },
  alergies: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32
    }
  },

  inGenTreatment: {
    presence: { allowEmpty: false, message: "is required" }
  },
  inAntiCoagulantTreatment: {
    presence: { allowEmpty: false, message: "is required" }
  },
  inOsteoTreatment: {
    presence: { allowEmpty: false, message: "is required" }
  },
  hadSurgery: {
    presence: { allowEmpty: false, message: "is required" }
  },
  anesthezis: {
    presence: { allowEmpty: false, message: "is required" }
  },
  anesthezisIssues: {
    presence: { allowEmpty: false, message: "is required" }
  },
  prevTreatmentIssues: {
    presence: { allowEmpty: false, message: "is required" }
  },
  congenital: {
    presence: { allowEmpty: false, message: "is required" }
  },
  peachemaker: {
    presence: { allowEmpty: false, message: "is required" }
  },
  bloodType: {
    presence: { allowEmpty: false, message: "is required" }
  },

  alcoholTobacco: {
    presence: { allowEmpty: false, message: "is required" }
  },
  selectData: {
    presence: { allowEmpty: false, message: "is required" }
  },
  confirmData: {
    presence: { allowEmpty: false, message: "is required" }
  },
  finalConfirm_data: {
    presence: { allowEmpty: false, message: "is required" },
    format: {
      pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
      message: "format is DD/MM/YYYY"
    }
  },
  finalConfirm_ora: {
    presence: { allowEmpty: false, message: "is required" },
    format: {
      pattern: /^(?:[01]\d|2[0-3]):[0-5]\d$/,
      message: "format is DD/MM/YYYY"
    }
  }
};

export default FormSchema;
