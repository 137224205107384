import { Button, Card, Table } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Pagination from "@material-ui/lab/Pagination";
import lscache from "lscache";
import * as moment from "moment";
import React from "react";

const Tables = (props) => {
  const {
    data,
    maxPage,
    handleChangePage,
    handleEdit,
    handleInfoToAssign,
    setTicketIdForAssignToMedic,
  } = props;

  // eslint-disable-next-line

  const handleTicketInfo = (e) => {
    handleInfoToAssign(e);
  };

  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header py-3">
          <div className="card-header--title font-size-lg">Lista tichete</div>
          <div className="card-header--actions"></div>
        </div>

        <div className="table-responsive-md">
          <Table className="table table-hover text-nowrap mb-0">
            <thead>
              <tr>
                <th className="bg-white text-left">ID</th>
                <th className="bg-white text-center">PACIENT</th>
                <th className="bg-white text-center">CNP</th>
                <th className="bg-white text-center">OPERATOR</th>
                <th className="bg-white text-center">STATUS</th>
                <th className="bg-white text-center">DATA CREARII</th>
                {lscache.get("operatorType") === "SefSectie" && (
                  <th className="bg-white text-center">OPERATIUNI</th>
                )}
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={`sef_sectie_table_${item.id}`}>
                  <td className="font-weight-bold">
                    <div>#{item.ticketId}</div>
                  </td>
                  <td>
                    {`${item.patient.firstName || ""} ${item.patient.lastName ||
                      ""}`}
                  </td>
                  <td className="text-center">
                    {item.patient && item.patient.cnp}
                  </td>
                  <td className="text-center">
                    <div className="avatar-icon-wrapper avatar-icon-sm">
                      {item.operatorName}
                    </div>
                  </td>
                  <td>
                    <div className="badge-neutral-danger text-danger text-center">
                      {item.status}
                    </div>
                  </td>
                  <td className="text-center text-black-50">
                    {" "}
                    {moment(item.creationDate).format("DD-MM-YYYY, HH:mm")}
                  </td>
                  <td>
                    <div
                      className={"operationsContainer"}
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        cursor: "pointer",
                      }}
                    >
                      <EditIcon onClick={() => handleEdit(item.ticketId)} />
                      <Button
                        size="large"
                        variant="contained"
                        style={{ backgroundColor: "grey" }}
                        className="btn-primary"
                        onClick={() => {
                          setTicketIdForAssignToMedic(item.ticketId);
                          handleTicketInfo(item.ticketId);
                        }}
                      >
                        Atribuie unui medic
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="card-footer py-3 d-flex justify-content-between">
          <Pagination
            className="pagination-second"
            variant="outlined"
            count={maxPage}
            onChange={handleChangePage}
          />
        </div>
      </Card>
    </>
  );
};

export default Tables;
