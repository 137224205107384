import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button as Btn, Card, Collapse } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { RenderAccForm } from "../../../../../../components/InputsAti";
import Mocks from "./Mocks";

const Accordion20 = (props) => {
  const {
    toggleAccordion,
    accordionStates,
    formData,
    ticketId,
    userData,
  } = props;
  return (
    <Card
      className={clsx("card-box", {
        "panel-open": accordionStates[`accordion11`],
      })}
    >
      <Card>
        <div className="card-header">
          <div className="panel-title">
            <div className="accordion-toggle">
              <Btn
                variant="text"
                size="large"
                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                onClick={() => toggleAccordion(11)}
                aria-expanded={accordionStates[`accordion11`]}
              >
                <span>Investigatii Paraclinice</span>
                <FontAwesomeIcon
                  icon={["fas", "angle-up"]}
                  className="font-size-xl accordion-icon"
                />
              </Btn>
            </div>
          </div>
        </div>
        {accordionStates[`accordion11`] && (
          <Collapse in={accordionStates[`accordion11`]}>
            <div className={"accordion_body"}>
              <h3 className="formSubtitle">Investigatii Paraclinice</h3>
              {/*<InvestigatiiParaclinice*/}
              {/*  data={formData}*/}
              {/*  ticketId={ticketId}*/}
              {/*  userData={userData}*/}
              {/*/>*/}

              <RenderAccForm
                data={formData}
                ticketId={ticketId}
                userData={userData}
                ctxAccKey={"acc11"}
                Mocks={Mocks}
              />
            </div>
          </Collapse>
        )}
      </Card>
    </Card>
  );
};

export default Accordion20;
