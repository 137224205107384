import React, { useEffect, useState, useRef, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { REST, beURL } from "../../Utils/index";
import Dropzone from "react-dropzone";
import UploadedFiles from "./UploadedFiles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    backgroundColor: "#fff",
    border: `3px solid #000`,
  },

  dropzone: {
    border: "2px dashed #333",
    textAlign: "center",
    fontFamily: "Arial",
    backgroundColor: "#e3e3e3",
    borderRadius: "3px",
    padding: "1rem",
    margin: "1rem 0",
  },

  dropParagraph: {
    padding: "40px",
  },

  fontFamily: {
    fontFamily: "Arial",
  },
}));

const Uploader = (props) => {
  const classes = useStyles();
  const { ticketId, uploaderExamsSectionType, uploadDocumentsLabel } = props;

  const [auth, setAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [display, setDisplay] = useState(false);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    setAuth(true);
    // eslint-disable-next-line
  }, []);

  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
    } else didMountRef.current = true;
    // eslint-disable-next-line
  });

  const onUpload = async (files) => {
    setIsLoading(true);
    setDisplay(false);
    const formData = new FormData();

    // eslint-disable-next-line
    files.map((item) => {
      formData.append("files", item);
    });

    formData.append("section", uploaderExamsSectionType);
    formData.append("ticketId", ticketId);

    // eslint-disable-next-line
    const result = await REST.post(
      `${beURL}/spital/files/uploadFiles`,
      formData
    ).then((answer) => {
      setIsLoading(false);
      setDisplay(true);
      setTrigger(!trigger);
    });
  };

  return (
    <div className={classes.root}>
      {isLoading && <div>Loading ...</div>}
      {auth && (
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <h3>
              Salvati documente pentru <br /> {uploadDocumentsLabel}
            </h3>
            <Fragment>
              {uploaderExamsSectionType && (
                <Dropzone
                  onDrop={onUpload}
                  // accept={'.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf'}
                  accept={".png,.jpeg,.jpg,.JPEG,.JPG"}
                  disabled={isLoading}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className={classes.dropzone}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className={classes.dropParagraph}>
                          Click sau Drag 'n' drop
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}
              {display && <p>S-au salvat documentele dumneavoastra</p>}

              <UploadedFiles
                ticketId={ticketId}
                uploaderExamsSectionType={uploaderExamsSectionType}
                uploadDocumentsLabel={uploadDocumentsLabel}
                trigger={trigger}
              />
            </Fragment>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Uploader;
