const hydrateAccordionsFactory = (dispatch, ctxData, actionType, accKey) => {
  let payload = { [accKey]: null };

  if (ctxData[accKey]) {
    if (Array.isArray(ctxData[accKey])) {
      payload[accKey] = [...ctxData[accKey]];
    } else {
      payload[accKey] = {
        ...ctxData[accKey]
      };
    }
  }

  dispatch({
    type: actionType,
    payload
  });
};

// 01 => updateAtiSheet
// 02 => updateAtiSheet
// 03 => addHolisticEvaluation
// 04 => addPsychoEmotionalEvaluation
// 05 => addSpritualEvaluation
// 06 => addSocialEvaluation
// 07 => addCareCoordonation
// 08 => addConsultation
// 09 => addPatch
// 10 => --
// 11 => addParaclinicalInvestigation
// 12 => addManagementPlan
// 13 => addEvolutionGraph => GRESIT
// 14 => addLevelOfDependency
// 15 => addDowntown => GRESIT
// 16 => addGeneralObservations
// 17 => addMedicines
// 18 => addCarePlan
// 19 => addEvolutionGraph
// 20 => addMonitoring
// 21 => updateTemperatureSheet

export default hydrateAccordionsFactory;

export const accordionConfigs = {
  acc1: {
    accKey: null,
    accNo: "ACC1",
    saveEndpoint: "updateAtiSheet"
  },
  acc2: {
    accKey: null,
    accNo: "ACC2",
    saveEndpoint: "updateAtiSheet"
  },
  acc3: {
    accKey: "holisticEvaluation",
    accNo: "ACC3",
    saveEndpoint: "addHolisticEvaluation"
  },

  acc4: {
    accKey: "psychoEmotinnalEvaluation",
    accNo: "ACC4",
    saveEndpoint: "addPsychoEmotionalEvaluation"
  },

  acc5: {
    accKey: "spiritualReligiousEvaluation",
    accNo: "ACC5",
    saveEndpoint: "addSpritualEvaluation"
  },
  acc6: {
    accKey: "socialEvaluation",
    accNo: "ACC6",
    saveEndpoint: "addSocialEvaluation"
  },
  acc7: {
    accKey: "careCoordanation",
    accNo: "ACC7",
    saveEndpoint: "addCareCoordonation"
  },
  acc8: {
    accKey: "consultations",
    accNo: "ACC8",
    saveEndpoint: "addConsultation"
  },
  acc9: {
    accKey: "dressings",
    accNo: "ACC9",
    saveEndpoint: "addPatch"
  },
  acc11: {
    accKey: "paraclinicalInvestigations",
    accNo: "ACC11",
    saveEndpoint: "addParaclinicalInvestigation"
  },
  acc12: {
    accKey: "managementPlans",
    accNo: "ACC12",
    saveEndpoint: "addManagementPlan"
  },
  acc13: {
    accKey: "evolutionAndTreatmentSheets",
    accNo: "ACC13",
    saveEndpoint: "addEvolutionGraph" // 'addEvolutionGraph' => GRESIT
  },
  acc14: {
    accKey: "levelOfDependencies",
    accNo: "ACC14",
    saveEndpoint: "addLevelOfDependency"
  },
  acc15: {
    accKey: "downtown",
    accNo: "ACC15",
    saveEndpoint: "addDowntown" // "addDowntown" => GRESIT
  },
  acc16: {
    accKey: "generalObservationSheet",
    accNo: "ACC16",
    saveEndpoint: "addGeneralObservations"
  },
  acc17: {
    accKey: "medicines",
    accNo: "ACC17",
    saveEndpoint: "addMedicines"
  },
  acc18: {
    accKey: "carePlan",
    accNo: "ACC18",
    saveEndpoint: "addCarePlan"
  },
  acc19: {
    accKey: "evolutionGraph",
    accNo: "ACC19",
    saveEndpoint: "addEvolutionGraph"
  },
  acc20: {
    accKey: "patientMonitoring",
    accNo: "ACC20",
    saveEndpoint: "addMonitoring"
  },
  acc21: {
    accKey: "temperatureSheet",
    accNo: "ACC21",
    saveEndpoint: "updateTemperatureSheet"
  }
};
