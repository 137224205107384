import clsx from "clsx";
import { Button as Btn, Card, Collapse } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { RenderAccForm } from "../../../../../../components/InputsAti";
import Mocks from "./Mocks/formMock";

const Accordion14 = (props) => {
  const {
    toggleAccordion,
    accordionStates,
    formData,
    ticketId,
    userData,
  } = props;
  return (
    <Card
      className={clsx("card-box", {
        "panel-open": accordionStates[`accordion18`],
      })}
    >
      <Card>
        <div className="card-header">
          <div className="panel-title">
            <div className="accordion-toggle">
              <Btn
                variant="text"
                size="large"
                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                onClick={() => toggleAccordion(18)}
                aria-expanded={accordionStates[`accordion18`]}
              >
                <span>Planul de ingrijire</span>
                <FontAwesomeIcon
                  icon={["fas", "angle-up"]}
                  className="font-size-xl accordion-icon"
                />
              </Btn>
            </div>
          </div>
        </div>
        {accordionStates[`accordion18`] && (
          <Collapse in={accordionStates[`accordion18`]}>
            <div className={"accordion_body"}>
              <h3 className="formSubtitle">Planul de ingrijire</h3>
              <RenderAccForm
                data={formData}
                ticketId={ticketId}
                userData={userData}
                ctxAccKey={"acc18"}
                Mocks={Mocks}
              />
            </div>
          </Collapse>
        )}
      </Card>
    </Card>
  );
};

export default Accordion14;
