const writeToCtxHandleChange = (event, ctx, actionType, payloadKey = null) => {
  const extractFromCollection =
    event.target.type === "checkbox" && !event.target.checked;
  const addToCollection =
    event.target.type === "checkbox" && event.target.checked;
  const canBuildPayload = !!event.target.name && !!event.target.value;

  const getRadioValueBasedOnTargetName = () => {
    const value = event.target.value === "0" ? false : event.target.value;
    return event.target.type === "radio" ? value : [event.target.value];
  };

  const getValueForPayload = () => {
    if (["checkbox", "radio"].includes(event.target.type))
      return getRadioValueBasedOnTargetName();

    if (event.target.value.hasOwnProperty("procedureCode")) {
      return `${event.target.value.procedureCode} - ${event.target.value.description}`;
    }

    return event.target.value;
  };

  if (canBuildPayload) {
    let payload,
      eraseFromNestedCollection = null,
      addToNestedCollection = null;

    let initialPayload = {
      [event.target.name]: getValueForPayload()
    };

    if (payloadKey) {
      payload = {
        payloadKey,
        ...initialPayload
      };

      if (extractFromCollection) {
        eraseFromNestedCollection = {
          name: event.target.name,
          valueToBeErased: event.target.value
        };
      }

      if (addToCollection) {
        addToNestedCollection = {
          name: event.target.name,
          valueToBeAdded: event.target.value
        };
      }
    } else {
      payload = initialPayload;
    }

    ctx.dispatch({
      type: actionType,
      payload,
      payloadKey,
      eraseFromNestedCollection,
      addToNestedCollection,
      generated: false
    });
  }
};

export default writeToCtxHandleChange;
