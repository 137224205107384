const holisticMock = [
  {
    title: "",
    key: "date",
    label: "Data (ZZ/LL/AAAA)",
    answer: {
      type: "DatePickerInput",
      required: true
    }
  },
  {
    label: "PROBLEME, SIMPTOME ŞI SEMNE",
    description:
      "Se va nota intensitatea semnelor şi simptomelor pe o scală de la 1 la 10 sau scală verbală (uşoară, moderată, severă, neprecizată), după caz, minim la semnele şi simptomele marcate cu*",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "ecogStatus",
    label: "Status de performanţă pe Scala ECOG",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: [0, 1, 2, 3, 4],
      isDisabled: false
    }
  },
  {
    label: "Domiciliul legal:",
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "ppsStatus",
    label: "Scala PPS",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0],
      isDisabled: false
    }
  },
  {
    title: "",
    key: "mobility",
    label: "Mobilizare",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: ["activ", "support", "passive"],
      selectListName: ["activa", "cu sprijin", "pasiva"]
    }
  },
  {
    // key: "conditions",
    key: "fatigue",
    label: "Fatigabilitate*",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "integument",
    label: "Tegumente/Mucoase/Tesut celular subcutanat",
    answer: {
      type: "MultipleLongTextInput",
      required: true,
      hideAddButton: true,
      selectList: [],
      labelList: [
        { paloare: "paloare" },
        { cianoza: "cianoză" },
        { icter: "icter" },
        { tumori: "tumori ulcerate" },
        { hipersudoratie: "hipersudoraţie" },
        { eruptii: "erupţii" },
        { prurit: "prurit*" },
        { stome: "stome" },
        { escare: "escare (grad)" },
        { limfedem: "limfedem/edem" },
        { fistula: "fistulă" }
      ],
      showRecord: false,
      isCaseDeceased: false
      // dateLabel: "Data si ora decesului:",
    }
  },

  {
    key: "osteoarticularSystem",
    label: "Aparat Osteoarticular",
    answer: {
      type: "CheckBoxInput",
      required: true,
      selectList: ["fracturi", "amputatii", "altele"]
      // isDisabled: true,
    }
  },

  {
    key: "respiratory",
    label: "Respiratorii",
    answer: {
      type: "MultipleLongTextInput",
      required: true,
      hideAddButton: true,
      selectList: [],
      labelList: [
        { dispnee: "dispnee*" },
        { tuse: "tuse*" },
        { sughit: "sughit" },
        { pleurezie: "pleurezie" },
        { hemoptizie: "hemoptizie" },
        { altele: "altele" }
      ],
      showRecord: false,
      isCaseDeceased: false
    }
  },

  {
    key: "digestives",
    label: "Digestive",
    answer: {
      type: "MultipleLongTextInput",
      required: true,
      hideAddButton: true,
      selectList: [],
      labelList: [
        { inapetenta: "inapetenta*" },
        { disfagie: "disfagie*" },
        { xerostomie: "xerostomie*" },
        { candidoza: "candidoza bucala" },
        { disgeuzie: "disgeuzie" },
        { halena: "halena" },
        { hipersalivatie: "hipersalivatie" },
        { pirozis: "pirozis" },
        { greata: "greaţă *" },

        { varsaturi: "vărsături" },
        { constipatie: "constipaţie" },
        { tranzit: "absenţă tranzit pt gaze" },
        { diaree: "diaree" },
        { hematemeza: "hematemeză" },
        { melena: "melenă" },
        { rectoragie: "rectoragie" },
        { patologice: "elemente patologice în scaun*" },
        { ascita: "ascită" },
        { meteorism: "meteorism" }
      ],
      showRecord: false,
      isCaseDeceased: false,
      dateLabel: "Data si ora decesului:"
    }
  },
  {
    key: "genitourinary",
    label: "Genito-urinare",
    answer: {
      type: "MultipleLongTextInput",
      required: true,
      hideAddButton: true,
      selectList: [],
      labelList: [
        { poliurie: "poliurie" },
        { polakiurie: "polakiurie" },
        { disurie: "disurie" },
        { oligo: "oligo/anurie" },
        { hematurie: "hematurie" },
        { glob: "glob vezical" },
        { incontinenta: "incontinenţă urinară" },
        { secretii: "secreţii vaginale/uretrale" },
        { metroragie: "metroragie" },

        { altele: "altele" }
      ],
      showRecord: false,
      isCaseDeceased: false,
      dateLabel: "Data si ora decesului:"
    }
  },
  {
    key: "patientCarrying",
    label: "Pacient purtător de",
    answer: {
      type: "MultipleLongTextInput",
      required: true,
      hideAddButton: true,
      selectList: [],
      labelList: [
        { sonda: "sondă urinară" },
        { pampers: "pampers" },
        { condom: "condom" }
      ],
      showRecord: false,
      isCaseDeceased: false,
      dateLabel: "Data si ora decesului:"
    }
  },
  {
    key: "nervousSystem",
    label: "Sistem nervos",
    answer: {
      type: "MultipleLongTextInput",
      required: true,
      hideAddButton: true,
      selectList: [],
      labelList: [
        { deficit: "deficit motor" },
        { tulburari: "tulburări senzoriale" },
        { mioclonii: "mioclonii" },
        { tulburari: "tulburări senzitive" },
        { spasticitate: "spasticitate/crampe musc ulare" },
        { compresie: "compresie medulară" },
        { convulsii: "convulsii" },
        { altele: "altele" }
      ],
      showRecord: false,
      isCaseDeceased: false,
      dateLabel: "Data si ora decesului:"
    }
  },
  {
    key: "psychiatric",
    label: "Psihiatrice",
    answer: {
      type: "MultipleLongTextInput",
      required: true,
      hideAddButton: true,
      selectList: [],
      labelList: [
        { insomnie: "insomnie*" },
        { anxietate: "anxietate" },
        { panica: "atac de panică" },
        { depresie: "depresie*" },
        { agitatie: "agitaţie psiho-motorie" },
        { hiperactiv: "delir hiperactiv" },
        { hipoactiv: "delir hipoactiv" },
        { mixt: "delir mixt" },
        { altele: "altele" }
      ],
      showRecord: false,
      isCaseDeceased: false
    }
  },

  {
    key: "initialAssessment",
    label: "Evaluare initiala:",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["evaluare initiala", "reevaluare"]
    }
  },

  {
    title: "",
    key: "pain",
    label: "Durere",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["yes", "no"],
      selectListName: ["Da", "Nu"]
    }
  },
  {
    key: "painDescription",
    label: "Caracterul durerii (cum resimte pacientul durerea)",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    label: "Mecanim portabil",
    description: "",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "neuropathicPain",
    label: "Durere neuropata",

    question: "Anestezia a fost loco-regionala, prin sedare, generala?",
    answer: {
      type: "CheckBoxInput",
      required: true,
      selectList: ["Prin compresie", "Prin distruţie"]
      // isDisabled: true,
    }
  },
  {
    key: "nociceptivePain",
    label: "Durere nociceptiva",

    question: "Anestezia a fost loco-regionala, prin sedare, generala?",
    answer: {
      type: "CheckBoxInput",
      required: true,
      selectList: ["Viscerala", "Somatica"]
      // isDisabled: true,
    }
  },
  {
    key: "localizareImage",
    // label: "Conditii de viata si munca",
    answer: {
      type: "Image",
      required: true,
      selectList: []
    }
  },
  {
    key: "intensityOfPain",
    label: "Intensitatea durerii (medie/24 h, maximă)",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "timeClassification",
    label: "Clasificarea temporală (De când e durerea?)",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "causesOfPain",
    label: "Ce produce/ Ce agravează durerea?",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "whatRelievesPain",
    label:
      "Ce ameliorează? (medicaţie: doză, frecvenţa administrării, eficienţă, toxicitate)",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "consequencesOfPain",
    label: "Ce consecinţe are durerea?",

    question: "Anestezia a fost loco-regionala, prin sedare, generala?",
    answer: {
      type: "CheckBoxInput",
      required: true,
      selectList: [
        "lnterferează cu starea generală",
        "Interfereaza cu abilitatea de a merge",
        "Indispozitie",
        "Limiteaza activitatile curente",
        "Interfereaza cu relatiile cu alte persoane",
        "Insomnie",
        "Impiedica autoingrijirea",
        "Altele"
      ]
      // isDisabled: true,
    }
  },
  {
    label: "Domiciliul legal:",
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "hardToTreatPainFactors",
    label: "Factori de durere greu tratabilă:",

    question: "Anestezia a fost loco-regionala, prin sedare, generala?",
    answer: {
      type: "CheckBoxInput",
      required: true,
      selectList: [
        "Neuropata",
        "Incidenta",
        "Componenta non-fizica de durere",
        "Pacient Confuz",
        "Nu exista"
      ]
      // isDisabled: true,
    }
  },
  {
    key: "painDiagnosis",
    label: "Diagnostic durere:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  }
];

export default holisticMock;
