import React from "react";
// import TextField from "@material-ui/core/TextField";
// import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";
import lscache from "lscache";
import DatePickerInput from "./DatePickerInput";
import TimePickerInput from "./TimePickerInput";
const TimeAndDateInput = props => {
  const {
    item,
    formState,
    handleChange,
    hasError,
    handleBlur
    // labelList,
    // labelName
  } = props;
  // const [error, setError] = useState({ message: null });
  // var itemName = item.key;
  // var itemNameLabel = labelList !== undefined ? labelList : item.key;
  // const handlePhoneCall = () => {};

  return (
    <div className={"questionGrid"}>
      <div className={"answerBox"}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginLeft: "25px"
          }}
        >
          <DatePickerInput
            item={item}
            formState={formState}
            handleChange={handleChange}
            handleBlur={handleBlur}
            hasError={hasError}
            customStyle={item.answer.customStyle}
            customName={item.answer.customName[0]}
          />

          <TimePickerInput
            item={item}
            formState={formState}
            handleChange={handleChange}
            handleBlur={handleBlur}
            hasError={hasError}
            customStyle={item.answer.customStyle}
            customName={item.answer.customName[1]}
          />
        </div>
      </div>
      {lscache.get("operatorType") === "Operator A" ? (
        <div className="answerTextContainer">
          <div className="answerDotContainer">
            <p className="answerDot">A</p>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default TimeAndDateInput;
