import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button as Btn, Card, Collapse } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import EvaluationForm from "./EvaluationForm";

const Accordion19 = (props) => {
  const {
    toggleAccordion,
    accordionStates,
    formData,
    sheetId,
    userData,
  } = props;

  return (
    <Card
      className={clsx("card-box", {
        "panel-open": accordionStates[`accordion19`],
      })}
    >
      <Card>
        <div className="card-header">
          <div className="panel-title">
            <div className="accordion-toggle">
              <Btn
                variant="text"
                size="large"
                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                onClick={() => toggleAccordion(19)}
                aria-expanded={accordionStates[`accordion19`]}
              >
                <span>Evolutie Simptome</span>
                <FontAwesomeIcon
                  icon={["fas", "angle-up"]}
                  className="font-size-xl accordion-icon"
                />
              </Btn>
            </div>
          </div>
        </div>
        {accordionStates[`accordion19`] && (
          <Collapse in={accordionStates[`accordion19`]}>
            <div className={"accordion_body"}>
              <h3 className="formSubtitle">Evolutie Simptome</h3>
              <div>
                <EvaluationForm
                  data={formData}
                  sheetId={sheetId}
                  userData={userData}
                />

                <div style={{ marginLeft: "2rem" }}>
                  <h4>Legenda:</h4>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p>Usor: 1, 2, 3</p>
                    <p>Moderat: 4, 5, 6</p>
                    <p>Sever: 7, 8, 9, 10</p>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        )}
      </Card>
    </Card>
  );
};

export default Accordion19;
