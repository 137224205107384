import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Box, Divider, Menu } from "@material-ui/core";
import lscache from "lscache";
import React, { useState } from "react";
import avatar200 from "../../assets/images/avatars/avatar200.png";

const SidebarUserbox = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="app-sidebar--userbox">
        <Box className="card-tr-actions">
          <Menu
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchorEl)}
            classes={{ list: "p-0" }}
            onClose={handleClose}
          >
            <div className="dropdown-menu-lg overflow-hidden p-0">
              <div className="align-box-row align-items-center p-3">
                <div className="avatar-icon-wrapper avatar-icon-md">
                  <div className="avatar-icon rounded-circle">
                    <img alt="..." src={avatar200} />
                  </div>
                </div>
                <div className="pl-2">
                  <span className="font-weight-bold d-block">
                    {lscache.get("operatorName")}
                  </span>
                  <div className="badge badge-success border-0">Activ</div>
                </div>
              </div>
              <Divider className="w-100" />
              <div className="d-flex py-3 justify-content-center">
                <div className="d-flex align-items-center">
                  <div>
                    <FontAwesomeIcon
                      icon={["far", "user"]}
                      className="font-size-xxl text-success"
                    />
                  </div>
                  <div className="pl-3 line-height-sm">
                    <b className="font-size-lg">14,596</b>
                    <span className="text-black-50 d-block">
                      apeluri preluate
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Menu>
        </Box>
        <div className={`side-menu-hopspital-name`}>
          <b>{lscache.get("hospitalName")}</b>
        </div>
        <div className="avatar-icon-wrapper avatar-icon-md">
          <Badge
            variant="dot"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent=" "
            overlap="circle"
            classes={{ badge: "bg-success badge-circle" }}
          >
            <div className="avatar-icon rounded-circle">
              <img alt="..." src={avatar200} />
            </div>
          </Badge>
        </div>
        <div className="my-3 userbox-details">
          <span>{lscache.get("operatorName")}</span>
          <small className="d-block text-white-50">
            ({lscache.get("operatorType")})
          </small>
        </div>
      </div>
    </>
  );
};

export default SidebarUserbox;
