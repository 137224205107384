import * as foaieObservatie from "./ati_secondForm_mock_partials";

export default [
  ...foaieObservatie.tipulInternarii,
  ...foaieObservatie.apartinatori,
  ...foaieObservatie.diagnosticTrimitere,
  ...foaieObservatie.stareExternare,
  ...foaieObservatie.anamneza,
  ...foaieObservatie.examenClinicGeneral

  // {
  //   key: "oncologicExam",
  //   label: "EXAMEN ONCOLOGIC:",
  //   answer: {
  //     type: "MultipleLongTextInput",
  //     required: true,
  //     selectList: [],
  //     labelList: [
  //       { mouth: "Cavitatea bucala" },
  //       { teguments: "Tegumente" },
  //       { ganglia: "Grupe ganglioni palpabile" },
  //       { breast: "San" },
  //       { genitalsWomen: "Organe genitale feminine" },
  //       { vaginalCytology: "Citologia secretiei vaginale" },
  //       { prostateRectum: "Prostata si Rect" },
  //       { others: "Altele" }
  //     ],
  //     showRecord: false,
  //     hideAddButton: true
  //   }
  // },

  // {
  //   key: "signAttendingPhysician",
  //   label: "Semnatura si parafa medicului",
  //   answer: {
  //     type: "SignTextInput",
  //     required: true,
  //     selectList: []
  //   }
  // }
];
