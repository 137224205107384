import DateFnsUtils from "@date-io/date-fns";
import {
  Card,
  CardContent,
  InputAdornment,
  Table,
  TextField,
} from "@material-ui/core";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import Pagination from "@material-ui/lab/Pagination";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";

const TablesAti = (props) => {
  const {
    data,
    maxPage,
    handleChangePage,
    showDatePicker,
    dateToDisplay,
    handleDateChange,
    list,
    searchData,
  } = props;

  const handleChange = (event) => {
    searchData(event.target.value);
  };

  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="search-wrapper">
          {list === "INTERNATI" || list === "EXTERNATI" ? (
            <TextField
              variant="outlined"
              size="small"
              id="input-search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                handleChange(event);
              }}
            />
          ) : null}
          {showDatePicker ? (
            <div className="datePickerContainer">
              <div className="datePickerLabel"> Programarile din data: </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  labe={"Data"}
                  placeholder={"ZZ/LL/AAAA"}
                  format="dd/MM/yyyy"
                  className="textField"
                  error={false}
                  helperText={null}
                  onChange={(event) => {
                    handleDateChange(event);
                  }}
                  value={dateToDisplay}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          ) : null}
        </div>
        <div className="divider" />
        <CardContent className="pt-3 px-4 pb-4">
          <Table className="table table-alternate-spaced mb-0">
            <thead>
              <tr>
                <th>PACIENT</th>
                <th className="text-center">CNP</th>
                <th className="text-center">STATUS</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => {
                return (
                  item.patient && (
                    <tr
                      key={item.id}
                      onClick={() => (window.location = `/ati-id/${item.id}`)}
                      // style={{width:}}
                    >
                      <td>
                        <span className="font-weight-bold">{`${item.patient.firstName} ${item.patient.lastName}`}</span>
                      </td>
                      <td className="text-center">
                        <span className="text-danger font-weight-bold">
                          {item.patient.cnp}
                        </span>
                      </td>
                      <td className="text-center">
                        <div className="badge badge-danger forStatus">
                          {item.status}
                        </div>
                      </td>
                    </tr>
                  )
                );
              })}
              <tr className="divider"></tr>
            </tbody>
          </Table>
        </CardContent>
        <div className="card-footer py-3 d-flex justify-content-between">
          <Pagination
            className="pagination-second"
            variant="outlined"
            count={maxPage}
            onChange={handleChangePage}
          />
        </div>
      </Card>
    </>
  );
};

export default TablesAti;
