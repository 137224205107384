import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import validate from "validate.js";
import {
  DatePickerInput,
  LongTextInput,
  MultipleLongTextInput,
  MultipleRadioInput,
  SignTextInput,
  TextInput,
  TitleForInput,
} from "../../../../../components";
import Mocks from "./components/Mocks/formMock";
import { obsEmptyState } from "./components/Mocks/obsEmptyState";
import DischargeSheetSchema from "./DischargeSheetSchema";

const DischargeSheet = (props) => {
  const { ticketId, handleThirdPage, dischargeData } = props;

  const [formState, setFormState] = useState({
    isValid: false,
    values: dischargeData || obsEmptyState,
    touched: {},
    errors: {},
    counter: 0,
    speechResponse: "",
  });
  const [showOkButton, setShowOkButton] = React.useState(true);

  useEffect(() => {
    const errors = validate(formState.values, DischargeSheetSchema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));

    handleThirdPage(formState.values);
    // eslint-disable-next-line
  }, [formState.values]);

  const handleBlur = (event) => {
    document
      .querySelectorAll("[data-replace='#" + event.target.name + "#']")
      .forEach((el) => {
        el.innerText = el.dataset.q.replace(
          el.dataset.replace,
          event.target.value
        );
      });
  };

  const handleChange = (event, callLoad = null, forList = [], ref = null) => {
    event.persist();

    let myVal = null;
    let tmpVals = formState.values;

    if (ref !== null && ref.affects !== undefined) {
      ref.affects.forEach((el) => {
        delete tmpVals[el];
      });
    }
    if (ref !== null) {
      if (ref.setConfirmed !== undefined) {
        delete tmpVals["confirmed"];
        if (event.target.value !== "false" && event.target.value.length) {
          tmpVals.confirmed = true;
        }
      }
    }
    if (event.target.type === "multiple") {
      if (event.target.index === undefined) {
        myVal = event.target.value;
      } else {
        myVal =
          formState.values[event.target.name] !== undefined
            ? formState.values[event.target.name]
            : [];
        if (myVal === null) {
          myVal = [event.target.value];
        } else {
          myVal[event.target.index] = event.target.value;
        }
      }
    } else if (forList !== null && forList.key !== undefined) {
      if (callLoad && callLoad === null) {
      } else {
        if (typeof callLoad === "function") {
          let cat = forList["cat"];
          myVal =
            formState.values[event.target.name] === null
              ? {}
              : formState.values[event.target.name];
          myVal = {
            ...myVal,
            [cat]: event.target.value,
          };

          callLoad(forList.sub);
        }
      }
    } else if (event.target.type === "radio") {
      if (event.replace) {
        event.target.name = event.replace;
      }
      myVal = event.target.value;
    } else if (event.target.type === "Date") {
      myVal = new Date(event.target.value);
    } else if (event.target.type === "checkbox") {
      let getKey =
        event.replace === undefined ? event.target.name : event.replace;
      let listVals =
        formState.values[getKey] === undefined ? [] : formState.values[getKey];
      if (event.target.checked) {
        if (Array.isArray(listVals)) {
          listVals.push(event.target.value);
        } else {
          listVals = event.target.value;
        }
      } else {
        if (Array.isArray(listVals)) {
          listVals = listVals.filter((el) => {
            return el !== event.target.value;
          });
        } else {
          listVals = false;
        }
      }
      myVal = listVals;
    } else {
      if (event.replace) {
        event.target.name = event.replace;
      }
      myVal = event.target.value;
    }
    let useName = event.target.name;
    const getRadioValueBasedOnTargetName = () => {
      if (
        event.target.name === "indication" ||
        event.target.name === "prescription" ||
        event.target.name === "medicalDevices" ||
        event.target.name === "medicalCare" ||
        event.target.name === "vacation"
      ) {
        return event.target.value;
      }

      return event.target.type === "radio" ? myVal === "true" : myVal;
    };

    setFormState((formState) => ({
      ...formState,
      counter: Object.keys(formState.values).length + 1,
      values: {
        ...tmpVals,
        [useName]: getRadioValueBasedOnTargetName(),
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));

    const valuriMozart = [
      "prescriptieSerie",
      "prescriptieNumar",
      "concediuSerie",
      "concediuNumar",
      "termenDeRevenire",
    ];

    if (valuriMozart.includes(event.target.name)) {
      setStateForFormHandicapat({
        name: event.target.name,
        value: event.target.value,
        type: null,
        checked: null,
      });
    }
  };

  const setStateForFormHandicapat = (newState) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [newState.name]:
          newState.type === "checkbox" ? newState.checked : newState.value,
      },
      touched: {
        ...formState.touched,
        [newState.name]: true,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleOkButtonFromRadio = () => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        confirmed: true,
      },
    });
    setShowOkButton(false);
  };

  return (
    <Grid container className={"DischargeSheet"}>
      {Mocks.map((item, index) => {
        if (item.answer.type === "wait") {
          return null;
        }

        let showIt = true;
        if (item.depends !== undefined) {
          if (Array.isArray(item.depends.vals)) {
            showIt = item.depends.vals.includes(
              formState.values[item.depends.key]
            );
          } else {
            showIt = item.depends.vals === formState.values[item.depends.key];
          }
        }
        return (
          showIt &&
          (() => {
            if (item.answer.type === "TextInput") {
              return (
                <Grid item xs={8} key={`${item.answer.type}_${index}`}>
                  <TextInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    label={item.label}
                    className={item.answer.className}
                  />
                </Grid>
              );
            } else if (item.answer.type === "TitleForInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <TitleForInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    label={item.label}
                    className={item.answer.className}
                  />
                </Grid>
              );
            } else if (item.answer.type === "SignTextInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <SignTextInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showColumn={true}
                    className={item.answer.className}
                  />
                </Grid>
              );
            } else if (item.answer.type === "LongTextInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <LongTextInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showRecord={item.answer.showRecord}
                    label={item.label}
                    className={item.answer.className}
                    ticketId={ticketId}
                  />
                </Grid>
              );
            } else if (item.answer.type === "MultipleRadioInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <MultipleRadioInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showOkButton={showOkButton}
                    handleOkButtonFromRadio={handleOkButtonFromRadio}
                    className={item.answer.className}
                    key={`MultipleRadioInput_${item.answer.type}_${index}`}
                  />
                </Grid>
              );
            } else if (item.answer.type === "DatePickerInput") {
              return (
                <Grid item xs={6} key={`${item.answer.type}_${index}`}>
                  <DatePickerInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    label={item.answer.dateLabel}
                    className={item.answer.className}
                  />
                </Grid>
              );
            } else if (item.answer.type === "MultipleLongTextInput") {
              return (
                <Grid item xs={12} key={`${item.answer.type}_${index}`}>
                  <MultipleLongTextInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    hasError={hasError}
                    showRecord={item.answer.showRecord}
                    isCaseDeceased={item.answer.isCaseDeceased}
                    hideAddButton={item.answer.hideAddButton}
                  />
                </Grid>
              );
            } else if (item.answer.type === "wait") {
              return <div></div>;
            } else {
              return <div></div>;
            }
          })()
        );
      })}
    </Grid>
  );
};

export default DischargeSheet;
