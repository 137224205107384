const tipulInternarii = [
  // {
  //   key: "cas",
  //   label: "CAS",
  //   answer: {
  //     type: "DropDownInput",
  //     required: true,
  //     selectList: [
  //       "CASAOPSNAJ",
  //       "TRANSPORTURI",
  //       "CASMB",
  //       "JUDETEANA",
  //       "TALON DE PENSIE"
  //     ],
  //     isDisabled: false
  //   }
  // },

  // {
  //   key: "casNumber",
  //   label: "Numar CAS",
  //   answer: {
  //     type: "TextInput",
  //     required: true,
  //     selectList: []
  //   }
  // },
  {
    key: "socialSecurityNr",
    label: "Nr. carnet asigurat:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "admissionType",
    label: "Tipul internarii:",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: [
        "urgenta",
        "trimitere medic de familie",
        "trimitere medic specialist ambulatoriu",
        "transport interspitalicesc",
        "la cerere",
        "altele",
      ],
    },
  },
];

export default tipulInternarii;
