export const obsEmptyState = {
  whereIsThePain: null,
  alergies: null,
  inGenTreatment: null,
  inAntiCoagulantTreatment: null,
  inOsteoTreatment: null,
  hadSurgery: null,
  anesthezis: null,
  anesthezisIssues: null,
  prevTreatmentIssues: null,
  congenital: null,
  peachemaker: null,
  bloodType: null,
  rh: null,
  alcoholTobacco: null,
  selectData: {
    spec: null,
    spital: null,
    medic: null,
  },
  confirmData: null,
  finalConfirmDate: null,
  documents: null,
  sex: null,
  intraDeathDate: null,
  otherSurgicalInterventions: [
    {
      drg: null,
      consent: null,
      mainMedic: null,
      secondMedic: null,
      thirdMedic: null,
      assistant: null,
      atiMedic: null,
      surgeryDate: null,
    },
  ],
  concomitantWithMainSurgery: [
    {
      drg: null,
      consent: null,
      mainMedic: null,
      secondMedic: null,
      thirdMedic: null,
      assistant: null,
      atiMedic: null,
      surgeryDate: null,
    },
  ],
  intraDischargeState: null,
  intraDischargeType: null,
  intraDeath: null,
  ifCaseDeceased: {
    directCause: null,
    anteCause: null,
    initialMorbidState: null,
    otherMorbidState: null,
  },
  admissionDiagnostic: null,
  threeDayDiagnostic: null,
  casNumber: null,
  socialSecurityNr: null,
  admissionType: null,
  mainDischargeDiagnostic: null,
  signMainPhysicianSef: null,
  signMainPhysicianCurant: null,
  mainSurgery: [
    {
      drg: null,
      consent: null,
      mainMedic: null,
      secondMedic: null,
      thirdMedic: null,
      assistant: null,
      atiMedic: null,
      surgeryDate: null,
    },
  ],
  cytologicExamination: null,
  extemporaneousExamination: null,
  histopathologyExamination: null,
  intraHospitalTransfer: [
    {
      AdmissionDate: null,
      DischargeDate: null,
      NrOfDays: null,
      Department: null,
      AdmissionHour: null,
      DischargeHour: null,
    },
  ],
  anatomoPatologicDiagnostic: null,
  microscopic: null,
  morphologicCode: null,
  otherObs: null,
  reasonForHospitalization: null,
  anamneza: {
    heredoColateral: null,
    personal: null,
    conditions: null,
    behavior: null,
    medication: null,
  },
  diseaseHistory: null,
  generalClinicExam: null,
  objectivExam: null,
  generalState: null,
  nutritionState: null,
  facies: null,
  teguments: null,
  mucous: null,
  fanere: null,
  adiposeConnectiveTissue: null,
  nodalSystem: null,
  muscularSystem: null,
  osteoSystem: null,
  respiratorySystem: null,
  cardioVascularSystem: null,
  digestiveSystem: null,
  abdominalOrgans: null,
  uroGenitalSystem: null,
  nervousAndTactilSystem: null,
  oncologicExam: {
    mouth: null,
    teguments: null,
    ganglia: null,
    breast: null,
    genitalsWomen: null,
    vaginalCytology: null,
    prostateRectum: null,
    others: null,
  },
  otherSpecialtyExams: null,
  laboratoryExams: null,
  radiologyExams: null,
  ecographExams: null,
  surgeryInterventions: null,
  anatomoPatoExams: null,
  supportOfDiagAndTreament: {
    clinic: null,
    paraclinic: null,
  },
  epicrisis: null,
  signAttendingPhysician: null,
  radiologicInvestigations: null,
  funcExplorations: null,
  secondDischargeDiagnostic: null,
};
