import { Button, Card, Container, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SecondOpinionContext } from "context/secondOpinionContext";
import lscache from "lscache";
import React, { useContext, useEffect, useState } from "react";
import validate from "validate.js";
import hero6 from "../../../assets/images/hero-bg/hero-1.jpg";
import logoImage from "../../../assets/images/logo.png";
import secondOpinionSchema from "./components/secondOpinionSchema";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SecondOpinionLogin = (props) => {
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const context = useContext(SecondOpinionContext);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  useEffect(() => {
    const errors = validate(formState.values, secondOpinionSchema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleSignIn = async (event) => {
    event.preventDefault();

    if (formState.isValid) {
      context &&
        context.setSecondOpinionPassword(
          formState && formState.values && formState.values.password
        );
      lscache.set("password", formState.values.password);
      context && context.setWrongCredentials(false);
    } else {
      context && context.setWrongCredentials(true);
    }
  };

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="hero-wrapper w-100 bg-composed-wrapper bg-midnight-bloom min-vh-100">
          <div
            className="flex-grow-1 w-100 d-flex align-items-center"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="bg-composed-wrapper--image opacity-6"
              style={{ backgroundImage: "url(" + hero6 + ")" }}
            />
            <div className="bg-composed-wrapper--bg bg-second opacity-7" />
            <div className="bg-composed-wrapper--content p-3 p-md-5">
              <Container className={"container"}>
                <Card className="rounded-sm modal-content p-3 bg-white-10">
                  <Card className="rounded-sm overflow-hidden shadow-xxl font-size-sm p-3 p-sm-0">
                    <Grid container spacing={0} style={{ padding: "40px" }}>
                      <Grid
                        item
                        lg={12}
                        className="d-flex align-items-center justify-content-center flex-column"
                      >
                        <img src={logoImage} className={"logoImage"} alt={""} />
                        <form className={classes.form} noValidate>
                          <TextField
                            type="password"
                            required
                            fullWidth
                            id="password"
                            name="password"
                            label="Parola"
                            variant="outlined"
                            onChange={handleChange}
                            error={hasError("password")}
                            helperText={
                              hasError("password")
                                ? formState.errors.password[0]
                                : null
                            }
                            className={"loginFormTextInput"}
                            value={formState.values.password || ""}
                          />
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={"singInButton"}
                            onClick={handleSignIn}
                            style={{ marginTop: "15px" }}
                          >
                            Autentifica-te
                          </Button>
                          {context && context.wrongCredentials ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "red",
                                fontWeight: "bold",
                                marginTop: "15px",
                              }}
                            >
                              Date de conectare incorecte!
                            </div>
                          ) : null}
                        </form>
                      </Grid>
                    </Grid>
                  </Card>
                </Card>
              </Container>
            </div>
          </div>
          <div className="hero-footer w-100 pb-4" />
        </div>
      </div>
    </>
  );
};

export default SecondOpinionLogin;
