import React from "react";
import { Grid } from "@material-ui/core";

const TrectButton = (props) => {
  const { vitals } = props;
  return (
    <Grid container className="widgetContainer">
      <Grid item xs={4} md={4}>
        <div className="widgetText">Trect</div>
      </Grid>
      <Grid item xs={8} md={8}>
        <div className="widgetValueTskin">
          {vitals && vitals.data && vitals.data["VITAL TRECT"] ? (
            vitals && vitals.data && vitals.data["VITAL TRECT"]
          ) : (
            <div className="missingValue"> - </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default TrectButton;
