const specs = [
  "Alergologie și imunologie clinica",
  "Anestezie și terapie intensiva",
  "Boli infecțioase",
  "Cardiologie",
  "Cardiologie pediatrica*",
  "Dermatovenerologie",
  "Diabet zaharat, nutriție și boli metabolice*",
  "Endocrinologie",
  "Expertiza medicalA a capacitAții de munca*",
  "Farmacologie clinica",
  "Gastroenterologie",
  "Gastroenterologie pediatrica*",
  "Genetica medicalA",
  "Geriatrie și gerontologie",
  "Hematologie",
  "MedicinA de familie",
  "MedicinA de urgențA",
  "MedicinA internA",
  "MedicinA fizica și de reabilitare",
  "Medicina muncii",
  "MedicinA sportivA**",
  "Nefrologie",
  "Nefrologie pediatrica*",
  "Neonatologie*",
  "Neurologie",
  "Neurologie pediatrica*",
  "Oncologie medicalA",
  "Oncologie și hematologie pediatrica*",
  "Pediatrie",
  "Pneumologie",
  "Pneumologie pediatrica*",
  "Psihiatrie",
  "Psihiatrie pediatrica",
  "Radioterapie",
  "Reumatologie",
  "Chirurgie cardiovascularA**",
  "Chirurgie generalA",
  "Chirurgie oralA și maxilo-faciala*",
  "Chirurgie pediatrica",
  "Chirurgie plastica, estetica și microchirurgie reconstructivA",
  "Chirurgie toracica",
  "Chirurgie vascularA",
  "Neurochirurgie",
  "Obstetrica-ginecologie",
  "Oftalmologie",
  "Ortopedie pediatrica*",
  "Ortopedie și traumatologie",
  "Otorinolaringologie",
  "Urologie",
  "Anatomie patologica",
  "Epidemiologie*",
  "Igiena*",
  "Medicina de laborator",
  "Medicina legala*",
  "MedicinA nucleara",
  "Microbiologie medicala*",
  "Radiologie-imagistica medicala",
  "Sanatate publica și management",
];

let spitale = [
  "Spitalul Clinic Copii Maria Sklodowska Curie",
  "Spitalul Clinic Judetean Ilfov",
  "Spitalul Clinic Urgenta Copii Grigore Alexandrescu",
  "Spitalul Clinic Colentina",
  "Spitalul Clinic Caritas",
  "Spitalul Clinic ColTea",
  "Spitalul Clinic de Boli InfecTioase Prof. Dr. Victor Babes",
  "Spitalul Clinic de Copii Dr. Victor Gomoiu",
  "Spitalul Clinic de Psihiatrie Prof. Dr. Al Obregia",
  "Spitalul Clinic de Urgenta Bagdasar Arseni",
  "Spitalul Clinic de Urgenta Chirurgie Plastica Reparatorie Si Arsuri",
  "Spitalul Clinic de UrgenTe Oftalmologice",
  "Spitalul Clinic Dermato-Venerice Prof. Dr. Scarlat Longhin",
  "Spitalul Clinic Dr. Ion Cantacuzino",
  "Spitalul Clinic Filantropia",
  "Spitalul Clinic Nefrologie Dr. Carol Davila",
  "Spitalul Clinic Nicolae Malaxa",
  "Spitalul Clinic Obstetrica-Ginecologie Prof. Dr. Panait Sârbu",
  "Spitalul Clinic Ortopedie FoiSor",
  "Spitalul clinic Prof. Dr. C. Angelescu",
  "Spitalul Clinic Polizu",
  "Spitalul clinic Sfânta Maria",
  "Spitalul Clinic de Urgenta Floreasca",
  "Spitalul Clinic Urgenta Sfântul Pantelimon",
  "Spitalul Clinic Urgenta Sfântul Ioan",
  "Spitalul Clinic Urologie Prof. Dr. Theodor Burghele",
  "Spitalul de Bolnavi Cronici Si Geriatrie Sfântul Luca",
  "Spitalul de Pneumoftiziologie Sfântul Stefan",
  "Spitalul General CF nr. 2",
  "Spitalul Militar Central BucureSti",
  "Spitalul Militar de Urgenta al Ministerului AdministraTiei Si Internelor Prof. Dr. Dimitrie Gerota",
  "Spitalul Militar de Urgenta Prof. Dr. Agrippa Ionescu",
  "Spitalul Universitar CF Witting",
  "Spitalul Universitar de Urgenta",
  "Spitalul Universitar Urgenta Elias",
];

let medici = [
  "COLCER FLORIN-ALEXANDRU",
  "MIRON COSMIN",
  "DINU DENISA-FLORENTINA",
  "DINESCU IOAN",
  "ANITAS QUINTUS-DORU-HOREA",
  "HAMURARU OCTAVIAN",
  "BADEA SEVER",
  "CANTIMIR OCTAVIAN",
  "COLITA ION",
  "DOBRESCU PAUL",
  "TILICH IOAN-FLORIAN",
  "STOICA MARIAN",
  "PACURARU SORIN",
  "GROSU RADU-SORIN",
  "COHN SORIN-RAUL",
  "POPOVSCHI ARISTIDE",
  "DICULESCU CONSTANTIN",
  "PARASCHIV ION-NELU",
  "BRICEAG GHEORGHE",
  "BOTAN GHEORGHE",
  "PETRENCIC SAVA-CATALIN",
  "STEFANESCU ION",
  "ELEFTERESCU MIRON-ALEXANDRU",
  "VERINCEANU VICTOR",
  "CHIRVASITU CRISTIAN",
  "GHITA LIVIU",
  "BLÂNDU NICOLAE",
  "ANDONII VITALII",
  "GRUITA DARIE-NICOLAE",
  "MARINESCU LUCIAN GABRIEL",
  "FLORESCU LIVIU-VASILE",
  "POCIOVALISTEANU PETRICA",
  "MUSTATEA ION",
  "ANCIU VALERIAN",
  "GEORGESCU LAURENTIU-MIHAIL",
  "GRAUR GILLY-JUSTIN",
  "POPA MIHAI",
  "STATE ROMEO-SORIN",
  "DONESCU CRISTIAN-CASIUS",
  "SERBAN CONSTANTIN-MARIAN",
  "NEAGU ROBERT",
  "DOROBANTU CATALIN",
  "PODOSU MIHAIL",
  "TELESCU LILIAN",
  "DRAGOMIRESCU ELENA-FINLANDA",
  "VOLONCS ANGELA",
  "HAMURARU MARTHA-YOLANDA-MICHAELA",
  "LIMONA ANCA-MARIA-LUIZA",
  "BALACEANU TEODORA-ELISABETA-EMILIA",
  "OPREA FLORINA-VERONICA",
  "ZARMA RODICA",
  "ARON EMILIA-LILIANA",
  "ION MIHAELA",
  "CANTIMIR CAMELIA-CARMEN",
  "STOICA FLORENTINA ADRIANA",
  "TOMEK MARIA",
  "MARINESCU GEORGETA",
  "BURZO DANIELA",
  "IONESCU VIORICA-MAGDALENA",
  "RUSU MARIANA",
  "ALEXANDRU OLGA",
  "HORNER VASILICA",
  "MANOLOVICI CARMEN-GEORGETA",
];
specs.sort();
medici.sort();

const getGen = () => {
  return [0, 10];
};

const all = specs.map((el) => {
  let spitSub = getGen();
  return {
    key: el,
    cat: "spec",
    sub: spitale.slice(...spitSub).map((sp) => {
      let medSub = getGen();
      return {
        key: sp,
        cat: "spital",
        sub: medici.slice(...medSub).map((md) => {
          return {
            key: md,
            cat: "medic",
            sub: [],
          };
        }),
      };
    }),
  };
});

export { all };
