import React, { useState, useEffect } from "react";
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import lscache from "lscache";
import { getStringFromBetween } from "../../Utils";

const MultipleRadioInput = (props) => {
  const { item, formState, handleChange, className } = props;

  var itemName = item.key;

  const [evLocal, setEvLocal] = useState({
    target: {
      name: null,
      type: null,
      value:
        (formState && formState.values && formState.values[itemName]) || null,
    },
    persist: () => true,
  });
  const [ev, setEv] = useState({
    target: {
      name: null,
      type: null,
      value:
        (formState && formState.values && formState.values[itemName]) || null,
    },
    persist: () => true,
  });

  const changeHandler = (event) => {
    event.persist();
    setEvLocal(event);
    setEv((prevState) => {
      return {
        ...prevState,
        target: {
          value: event.target.value,
          type: event.target.type,
          name: event.target.name,
        },
      };
    });
  };

  useEffect(() => {
    handleChange(ev);
    // eslint-disable-next-line
  }, [ev]);

  useEffect(() => {
    setEv((prevState) => {
      return {
        ...prevState,
        target: {
          value:
            (formState && formState.values && formState.values[itemName]) ||
            null,
        },
      };
    });
    // eslint-disable-next-line
  }, [formState]);

  const getValue = () => {
    if (evLocal && evLocal.target && evLocal.target.value) {
      return evLocal.target.value;
    }

    return ev.target.value;
  };

  return (
    <div className={item && className ? className : "answerBox"}>
      {lscache.get("operatorType") === "SefSectie" ? null : (
        <div className="labelContainer">
          <p className="labelText">{item.label}</p>
        </div>
      )}

      <div className="mutliRadioContainer">
        <RadioGroup
          id={`${itemName}`}
          name={`${itemName}`}
          value={getValue() || ""}
          onChange={changeHandler}
          className="radioList"
        >
          {item.answer.selectList.map((el, index) => {
            let extra;

            const hasInterpolation = getStringFromBetween.get(el, "{{", "}}");

            if (!!hasInterpolation.length) {
              extra = (
                <div>
                  {hasInterpolation.map((interpo, index) => {
                    return (
                      <TextField
                        id={interpo}
                        name={interpo}
                        placeholder={interpo
                          .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
                          .toUpperCase()}
                        onChange={changeHandler}
                        className={"m-r-1-rem"}
                      />
                    );
                  })}
                </div>
              );
            }

            return (
              <FormControlLabel
                key={`multiple_radio_input_${el}`}
                value={el}
                control={<Radio />}
                label={
                  item.answer.selectListName !== undefined
                    ? item.answer.selectListName[index]
                    : extra || el
                }
              />
            );
          })}
        </RadioGroup>
      </div>
    </div>
  );
};

export default MultipleRadioInput;
