import React, { useEffect, useContext, useState } from "react";
import { REST } from "../../../Utils";
import { FormStateContext } from "../../../context/formStateContext";
import EquiptmentTable from "./Components/Table";
import { useSnackbar } from "notistack";

const EquipmentManagement = (props) => {
  const { userDataContext } = useContext(FormStateContext);
  const { enqueueSnackbar } = useSnackbar();

  const [equipments, setEquipments] = useState(null);
  const data = userDataContext;


  const getEquipments = async () => {
    const hopspitalId = data?.data?.department?.hospital?.id ? data?.data?.department?.hospital?.id : null;

    if (hopspitalId != null) {
      await REST.get(
        `equipment/getAllMedicalEquipments?hospitalId=${hopspitalId}`
      )
        .then((answer) => {
          setEquipments(answer.data);
        })
        .catch((err) => { })
    }
  };

  useEffect(() => {
    getEquipments();
    // eslint-disable-next-line
  }, [userDataContext]);

  const showSnack = (variant) => {
    enqueueSnackbar("Cod QR in proces de printare!", { variant });
  };

  const handleQr = async (id) => {
    await REST.post(`/equipment/printEquipmentQr?equipmentId=${id}`).then(
      (answer) => {
        showSnack("success");
      }
    );
  };

  return (
    <div>
      <EquiptmentTable data={equipments} handleQr={handleQr} />
    </div>
  );
};

export default EquipmentManagement;
