const resedinta = [
  {
    label: "Resedinta:",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "county",
    label: "Judet",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "locality",
    label: "Localitate",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "street",
    label: "Strada",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "number",
    label: "Numarul Strazii",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
];

export default resedinta;
