const loginFormSchema = {
    username: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 128, message: "maximum 128 characters."
      },
    },
    password: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 128, minimum: 1, message: "Minimum 3 characthers"
      },
    },
  };
  
  export default loginFormSchema;
  