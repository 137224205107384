export const formStateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_FORMSTATE':
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
}