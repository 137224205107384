import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Grid } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { REST } from "../../../../Utils/index";
import { FormStateContext } from "context/formStateContext";

const TopCards = () => {
  const { userDataContext = null } = useContext(FormStateContext);
  const departmentId = userDataContext?.data?.department?.id;

  const [freeBeds, setFreeBeds] = useState(0);
  const [usedBeds, setUsedBeds] = useState(0);
  const [numOfSchedueledPatients, setNumOfSchedueledPatients] = useState(0);
  const [numOfInactiveTickets, setNumOfInactiveTickets] = useState(0);
  // eslint-disable-next-line

  const getNumOfFreeBeds = async () => {
    if (departmentId) {
      await REST.get(
        `/spital/getNumberOfFreeBedsByDepartment?departmentId=${departmentId}`
      ).then((answer) => {
        setFreeBeds(answer.data);
      });
    }
  };

  const getNumOfUsedBeds = async () => {
    if (departmentId) {
      await REST.get(
        `/spital/getNumberOfUsedBedsByDepartment?departmentId=${departmentId}`
      ).then((answer) => {
        setUsedBeds(answer.data);
      });
    }
  };

  const getNumOfSchedueledPatients = async () => {
    await REST.get(`/spital/getNumberOfPatientsScheduledOnCurrentDate`).then(
      (answer) => {
        setNumOfSchedueledPatients(answer.data);
      }
    );
  };

  const getNumOfInactiveTickets = async () => {
    await REST.get(`/spital/getNumberOfInactiveTickets`).then((answer) => {
      setNumOfInactiveTickets(answer.data);
    });
  };

  useEffect(() => {
    getNumOfFreeBeds();
    getNumOfUsedBeds();
    // eslint-disable-next-line
  }, [departmentId]);

  useEffect(() => {
    getNumOfSchedueledPatients();
    getNumOfInactiveTickets();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="mb-spacing-6">
        <Grid container spacing={6}>
          <Grid item xl={3} md={3}>
            <Card className="dashboard-card-box border-0 shadow-first-sm p-4">
              <div className="d-flex align-items-center">
                <div className="d-40 btn-icon rounded-circle bg-first text-white text-center font-size-lg mr-3">
                  <FontAwesomeIcon icon={["far", "keyboard"]} />
                </div>
                <div className="text-black-50">Paturi Libere</div>
              </div>
              <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                <div>{freeBeds}</div>
              </div>
            </Card>
          </Grid>
          <Grid item xl={3} md={3}>
            <Card className="dashboard-card-box border-0 shadow-success-sm p-4">
              <div className="d-flex align-items-center">
                <div className="d-40 btn-icon rounded-circle bg-success text-white text-center font-size-lg mr-3">
                  <FontAwesomeIcon icon={["far", "file-excel"]} />
                </div>
                <div className="text-black-50">Pacienti programati astazi</div>
              </div>
              <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                <div>{numOfSchedueledPatients}</div>
              </div>
            </Card>
          </Grid>
          <Grid item xl={3} md={3}>
            <Card className="dashboard-card-box border-0 shadow-danger-sm p-4">
              <div className="d-flex align-items-center">
                <div className="d-40 btn-icon rounded-circle bg-danger text-white text-center font-size-lg mr-3">
                  <FontAwesomeIcon icon={["far", "user"]} />
                </div>
                <div className="text-black-50">Internati astazi</div>
              </div>
              <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                <div>{usedBeds}</div>
              </div>
            </Card>
          </Grid>
          <Grid item xl={3} md={3}>
            <Card className="dashboard-card-box border-0 shadow-primary-sm p-4">
              <div className="d-flex align-items-center">
                <div className="d-40 btn-icon rounded-circle bg-primary text-white text-center font-size-lg mr-3">
                  <FontAwesomeIcon icon={["far", "user"]} />
                </div>
                <div className="text-black-50">
                  Nr. externati din aceasta luna
                </div>
              </div>
              <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                <div>{numOfInactiveTickets}</div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TopCards;
