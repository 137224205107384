import DateFnsUtils from "@date-io/date-fns";
import { Card } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import React, { useState } from "react";

export default function LivePreviewExample(props) {
  const { setDate, isModalOpen } = props;

  const [selectedDate, setSelectedDate] = useState(new Date());

  React.useEffect(() => {
    var currentDate = getCurrentDay();
    setSelectedDate(currentDate);
    setDate(currentDate);
    // eslint-disable-next-line
  }, [isModalOpen === true]);

  React.useEffect(() => {
    setDate(selectedDate);
    // eslint-disable-next-line
  }, [selectedDate]);

  const handleDateChange = (date) => {
    changeSelectedDateFormat(date);
  };

  const changeSelectedDateFormat = (date) => {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0");
    var yyyy = date.getFullYear();

    setSelectedDate(mm + "/" + dd + "/" + yyyy);
  };

  const getCurrentDay = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  };

  return (
    <>
      <Card
        style={{
          height: "35px",
          width: "200px",
          marginTop: "20px",
          marginRight: " 90px",
          // border: "1px solid black",
          // borderRadius: "25px",
        }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="m-4">
            <KeyboardDatePicker
              style={{ marginTop: "-20px" }}
              disableToolbar
              //variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </Card>
    </>
  );
}
