import lottie from "lottie-web";
import lscache from "lscache";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import loadingAnimation from "../../../../../assets/animationJson/loadingJson.json";
import { useAti } from "../../../../../hooks";
import PrintButton from "../PrintButton";
import AllAccordionsComponent from "./AllAccordions.component";
import { hydrateAccordionsWithState } from "./hydrateAccordions";

const PopulateCtx = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    ctxData,
    toggleAccordion,
    accordionStates,
    sheetId,
    toggle,
    setAllData,
    activeTab,
    history,
    location,
    allData,
    monitorData,
  } = props;
  const ctx = useAti();
  // eslint-disable-next-line
  let animation = null;

  // eslint-disable-next-line
  const [showPrint, setShowPrint] = useState(
    lscache.get("operatorType") === "Medic" ? true : false
  );
  const [blockWindow, setBlockWindow] = useState(true);
  const [success, setSuccess] = useState(false);
  const invokeAnimation = () => {
    animation = lottie.loadAnimation({
      container: document.querySelector("#loadingAnimation"),
      animationData: loadingAnimation,
    });
  };

  useEffect(() => {
    invokeAnimation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ctxData !== null) {
      hydrateAccordionsWithState(ctx, ctxData, sheetId);
    }

    return () => null;
    // eslint-disable-next-line
  }, [ctxData]);

  useEffect(() => {
    setBlockWindow(ctx.rState.blockwindow.values.hide);
    const body = document.getElementsByTagName("body")[0];

    if (!!ctx.rState.blockwindow.values.hide) {
      body.classList.add("bodyIsLoading") && invokeAnimation();
    } else {
      body.classList.remove("bodyIsLoading");
    }
    // eslint-disable-next-line
  }, [ctx?.rState?.blockwindow]);

  const handleSubmit = () => {
    showSnack("success");
    setSuccess(true);

    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  };

  const showSnack = (variant) => {
    enqueueSnackbar("Modificarile au fost salvate!", { variant });
  };

  return (
    <>
      {!blockWindow && (
        <div>
          {showPrint ? null : <PrintButton ticketId={sheetId} />}
          <h1>
            {ctx.rState?.acc1.values &&
              `${ctx.rState?.acc1.values.firstName} - `}
            {ctx.rState?.acc1.values &&
              `${ctx.rState?.acc1.values.lastName} - `}
            {ctx.rState?.acc1.values && ctx.rState?.acc1.values.cnp}
          </h1>
        </div>
      )}

      {blockWindow && (
        <div className={"blockWindow"}>
          <div className={"curtain"}></div>
          <div className={"loader"}>
            <div id="loadingAnimation" />
          </div>
        </div>
      )}

      {success && (
        <div className={"saveSuccess"}>
          <p>Salvare reusita</p>
        </div>
      )}

      {!blockWindow && (
        <AllAccordionsComponent
          toggleAccordion={toggleAccordion}
          accordionStates={accordionStates}
          sheetId={sheetId}
          toggle={toggle}
          setAllData={setAllData}
          activeTab={activeTab}
          history={history}
          location={location}
          allData={allData}
          handleSubmit={handleSubmit}
          monitorData={monitorData}
        />
      )}
    </>
  );
};

export default PopulateCtx;
