import { Button } from "@material-ui/core";
import lscache from "lscache";
import React from "react";

const Tabs = (props) => {
  const { handleChangeTab, selectedTab, badge } = props;

  return (
    <div className={"listareContent"}>
      <div className={"buttonContainer"}>
        {lscache.get("operatorType") === "Registratura" && (
          <Button
            size="large"
            variant="contained"
            className="btn-primary tab-button"
            onClick={() => handleChangeTab("PROGRAMATI")}
            style={{
              backgroundColor: selectedTab === "PROGRAMATI" ? null : "grey",
            }}
          >
            <div>
              <span>Programati</span>
              <div className="badge badge-danger rounded-circle ml-3">
                {badge && badge.totalActive
                  ?
                  <div>{badge.totalAppointments}</div>
                  :
                  <div>0</div>
                }
              </div>
            </div>
          </Button>
        )}

        <Button
          size="large"
          variant="contained"
          className="btn-primary tab-button"
          onClick={() => handleChangeTab("INTERNATI")}
          style={{
            backgroundColor: selectedTab === "INTERNATI" ? null : "grey",
          }}
        >
          <div>
            <span>Internati</span>
            <div className="badge badge-danger rounded-circle ml-3">
              {badge && badge.totalActive ? (
                <div>{badge.totalActive}</div>
              ) : (
                <div>0</div>
              )}
            </div>
          </div>
        </Button>

        <Button
          size="large"
          variant="contained"
          className="btn-primary tab-button"
          onClick={() => handleChangeTab("EXTERNATI")}
          style={{
            backgroundColor: selectedTab === "EXTERNATI" ? null : "grey",
          }}
        >
          <div>
            <span>Externati</span>
            <div className="badge badge-danger rounded-circle ml-3">
              {badge && badge.totalInactive ? (
                <div>{badge.totalInactive}</div>
              ) : (
                <div>0</div>
              )}
            </div>
          </div>
        </Button>

        <Button
          size="large"
          variant="contained"
          className="btn-primary tab-button"
          onClick={() => handleChangeTab("ATI")}
          style={{ backgroundColor: selectedTab === "ATI" ? null : "grey" }}
        >
          <div>
            <span>ATI</span>
            <div className="badge badge-danger rounded-circle ml-3">
              {badge && badge.totalAti ? (
                <div>{badge.totalAti}</div>
              ) : (
                <div>0</div>
              )}
            </div>
          </div>
        </Button>
      </div>
    </div>
  );
};
export default Tabs;
