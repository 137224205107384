import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { REST } from "../../../Utils/index";

const ChangePassword = () => {
  const [data, setData] = useState({
    email: null,
    oldPassword: null,
    newPassword: null,
  });

  const handleChange = (event) => {
    event.persist();

    setData((data) => ({
      ...data,
      [event.target.name]: event.target.value,
    }));
  };

  const changePw = async () => {
    await REST.put(`admin/updateUserPassword`, data);
  };

  return (
    <div className={`change_password_container`}>
      <div className={`title_container`}>
        <h3>Modifica parola</h3>
      </div>

      <div className={`inputs_container`}>
        <div className={`input_item`}>
          <TextField
            id="outlined-basic"
            label="e-Mail"
            variant="outlined"
            name="email"
            onChange={handleChange}
          />
        </div>

        <div className={`input_item`}>
          <TextField
            id="outlined-basic"
            label="Parola actuala"
            variant="outlined"
            name="oldPassword"
            onChange={handleChange}
            type="password"
          />
        </div>

        <div className={`input_item`}>
          <TextField
            id="outlined-basic"
            label="Noua parola"
            variant="outlined"
            name="newPassword"
            onChange={handleChange}
            type="password"
          />
        </div>
      </div>

      <div className={`save_container`}>
        <Button variant="contained" color="primary" onClick={changePw}>
          Salveaza
        </Button>
      </div>
    </div>
  );
};

export default ChangePassword;
