import React, { useState } from "react";
import AutocompleteDRGInput from "./AutocompleteDRGInput";
import { Button } from "@material-ui/core";

const MultipleDRGTextInput = (props) => {
  const {
    item,
    formState,
    handleChange,
    hasError,
    handleBlur,
    hideAddButton,
  } = props;
  var itemName = item.key;

  const [inputs, setInputs] = useState([
    ...(formState && formState.values && formState.values[itemName]
      ? formState.values[itemName]
      : [""]
    ).map((e) => ""),
  ]);

  const addInput = () => {
    setInputs([...inputs, ""]);
  };

  return (
    <div
      className="answerBox"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="multipleDRGlabelContainer">
        <p className="labelText">{item.label}</p>
        {hideAddButton ? null : (
          <div className={"buttonsContainer"}>
            <Button className={"addButton"} onClick={(e) => addInput(e)}>
              <p className={"buttonText"}>+</p>
            </Button>
          </div>
        )}
      </div>
      <div className="multipleDRGTextFieldContainer">
        <ol>
          {inputs.map((input, index) => (
            <li key={`multipleDRGTextFieldContainer_li_${index}`}>
              <AutocompleteDRGInput
                key={input}
                item={item}
                index={index}
                formState={formState}
                handleChange={handleChange}
                handleBlur={handleBlur}
                hasError={hasError}
                hideLabel={true}
                showNumberInput={item.answer.showNumberInput}
              />
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default MultipleDRGTextInput;
