import React, { useState } from "react";
import { RenderAtiAccordionNonTableComponent } from "../../../../../../components/componentsAti";
import { accordionConfigs } from "../../../../../../context/hydrateAccordionsFactory.helper";
import { useAti } from "../../../../../../hooks";
import { REST } from "../../../../../../Utils";
import Button from "../13_EvolutionList/Buttons1";
import FoaiePaliativeTable from "./FoaiePaliative.table";
import Mocks from "./Mocks/formMock";

const FoaiePaliativeForm = (props) => {
  const ctx = useAti();
  const ctxAccKey = "acc16";

  const { sheetId } = props;

  const [valueSecondDD, setValueSecondDD] = useState([]);
  const [valueThirdDD, setValueThirdDD] = useState([]);
  const [formState, setFormState] = useState({});

  const handleChange = (event) => {
    if (event.target.value) {
      setFormState((prevState) => {
        const stateByType = { [event.target.name]: event.target.value };

        return {
          ...prevState,
          ...stateByType,
        };
      });
    }
  };

  const changeHandler = (event) => {
    handleChange(event);
  };

  const addToCollection = () => {
    postCollection();

    ctx.dispatch({
      type: accordionConfigs[ctxAccKey].accNo,
      payload: formState,
      payloadKey: accordionConfigs[ctxAccKey].accKey,
      isPayloadForTable: true,
    });
  };

  const postCollection = async () => {
    await REST.post(
      `/ati/addGeneralObservations?sheetId=${sheetId}`,
      formState
    );
  };

  return (
    <>
      <RenderAtiAccordionNonTableComponent
        Mocks={Mocks}
        ctxState={ctx.rState}
        ctxAccKey={ctxAccKey}
        changeHandler={changeHandler}
        setValueSecondDD={setValueSecondDD}
        setValueThirdDD={setValueThirdDD}
        valueSecondDD={valueSecondDD}
        valueThirdDD={valueThirdDD}
        sheetId={sheetId}
      >
        <Button onClick={addToCollection} text="ADAUGA"></Button>
      </RenderAtiAccordionNonTableComponent>
      <FoaiePaliativeTable />
    </>
  );
};

export default FoaiePaliativeForm;
