import clsx from "clsx";
import { Card } from "@material-ui/core";
import Form from "./Form";
import React from "react";

const Accordion01 = props => {
  return (
    <Card
      className={clsx("card-box", {
        "panel-open": true
      })}
    >
      <div className={"accordion_body"}>
        <h3 className="formSubtitle">Date generale pacient</h3>
        <Form />
      </div>
    </Card>
  );
};

export default Accordion01;
