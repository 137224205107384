import { Grid } from "@material-ui/core";
import React from "react";

const SpO2FPButton = (props) => {
  const { vitals } = props;
  return (
    <Grid container className="widgetContainer">
      <Grid item xs={4} md={4}>
        <div className="widgetTextSp02-fp">SpO2-FP</div>
      </Grid>
      <Grid item xs={8} md={8}>
        <div className="widgetValueSp02-fp">
          {vitals && vitals.data && vitals.data["VITAL PR(spo2)"] ? (
            vitals && vitals.data && vitals.data["VITAL PR(spo2)"]
          ) : (
            <div className="missingValue"> - </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default SpO2FPButton;
