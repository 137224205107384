import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import TopTabs from "./Components/TopTabs";
import MyPatients from "./Components/MyPatients";
import Schedueled from "./Components/Schedueled";


const MedDashboard = () => {
  const [componentToShow, setComponentToShow] = useState("PacientiiMei");

  const handleChangeTab = (e) => {
    setComponentToShow(e);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <TopTabs handleChangeTab={handleChangeTab} />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        {componentToShow === "PacientiiMei" ? <MyPatients /> : <Schedueled />}
      </Grid>
    </Grid>
  );
};

export default MedDashboard;
