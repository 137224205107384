const schema = {
  date: {
    presence: true
  },
  ora: {
    presence: true
  },
  vital_spo2: {
    presence: false,
    numericality: {
      onlyInteger: false,
      lessThanOrEqualTo: 100,
      message: "trebuie sa fie intre 0-100"
    }
  },
  vital_hr: {
    presence: false,
    numericality: {
      onlyInteger: false,
      lessThanOrEqualTo: 300,
      message: "trebuie sa fie intre 0-300"
    }
  },
  vital_resp: {
    presence: false,
    numericality: {
      onlyInteger: false,
      lessThanOrEqualTo: 100,
      message: "trebuie sa fie intre 0-100"
    }
  },
  vital_trect: {
    presence: false,
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
      lessThanOrEqualTo: 50,
      message: "trebuie sa fie intre 0-50"
    }
  },
  vital_tskin: {
    presence: false,
    numericality: {
      onlyInteger: false,
      lessThanOrEqualTo: 50,
      message: "trebuie sa fie intre 0-50"
    }
  },
  vital_art_s: {
    presence: false,
    numericality: {
      onlyInteger: false,
      lessThanOrEqualTo: 400,
      message: "trebuie sa fie intre 0-400"
    }
  },
  vital_art_d: {
    presence: false,
    numericality: {
      onlyInteger: false,
      lessThanOrEqualTo: 400,
      message: "trebuie sa fie intre 0-400"
    }
  }
};

export default schema;
