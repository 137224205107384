import { Card, Grid, LinearProgress, List, ListItem } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import CountUp from "react-countup";
import doctor_icon from "../../../../assets/images/avatars/doctor_icon.png";
import { REST } from "../../../../Utils/index";
import { FormStateContext } from "context/formStateContext";


const DoctorsList = () => {
  const { userDataContext = null } = useContext(FormStateContext);

  const [drList, setDrList] = useState([]);

  const departmentId = userDataContext?.data?.department?.id;

  const getDoctorByDepartment = async () => {
    if (departmentId) {
      await REST.get(
        `/spital/getDoctorsStatisticsByDepartment?departmentId=${departmentId}`
      ).then((answer) => {
        setDrList(answer.data);
      });
    }
  };

  useEffect(() => {
    getDoctorByDepartment();
    // eslint-disable-next-line
  }, [departmentId]);

  return (
    <>
      <div className="mb-spacing-6">
        <Grid container spacing={6}>
          <Grid item xl={12} xs={12}>
            <div className="mb-spacing-6-x2">
              <Card className="dashboard-card-box">
                <div className="card-header">
                  <div className="card-header--title">
                    <b>Lista Doctori</b>
                  </div>
                </div>
                <List component="div" className="list-group-flush">
                  {drList.map((item, index) => {
                    return (
                      <ListItem className="py-3" key={`list_item_${index}`}>
                        <Grid item container spacing={2}>
                          <Grid
                            item
                            xl={6}
                            md={6}
                            xs={6}
                            className="d-flex align-items-center"
                          >
                            <div className="d-flex align-items-center">
                              <div className="avatar-icon-wrapper mr-2">
                                <div className="avatar-icon">
                                  <img alt="..." src={doctor_icon} />
                                </div>
                              </div>
                              <div>
                                <a
                                  href="#/"
                                  onClick={() =>
                                    (window.location = `/dashboard-md/${item.doctor.id}/${item.doctor.doctorName}`)
                                  }
                                  className="font-weight-bold text-black"
                                  title="..."
                                >
                                  {item.doctor.doctorName}
                                </a>
                                <span className="text-black-50 d-block">
                                  Dr. id: {item.doctor.id}
                                </span>
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xl={6}
                            md={6}
                            xs={6}
                            className="pt-3 pt-xl-0 d-flex align-items-center"
                          >
                            <div className="align-box-row flex-grow-1">
                              <div className="d-flex flex-column flex-grow-1">
                                <div className="d-flex flex-row flex-grow-1">
                                  <span>Paturi ocupate:&nbsp;</span>
                                  <div className="d-flex justify-content-between text-dark">
                                    <div className="ml-auto">
                                      <div className="font-weight-bold">
                                        <CountUp
                                          start={0}
                                          end={item.numberOfUsedBeds}
                                          duration={4}
                                          separator=""
                                          decimals={0}
                                          decimal=","
                                          prefix=""
                                          suffix=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <span> / </span>
                                  <div className="d-flex justify-content-between text-dark">
                                    <div className="ml-auto">
                                      <div className="font-weight-bold">
                                        <CountUp
                                          start={0}
                                          end={item.numberOfAllocatedBeds}
                                          duration={4}
                                          separator=""
                                          decimals={0}
                                          decimal=","
                                          prefix=""
                                          suffix=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <LinearProgress
                                  variant="determinate"
                                  className="progress-sm progress-bar-rounded progress-bar-primary"
                                  value={
                                    (item.numberOfUsedBeds /
                                      item.numberOfAllocatedBeds) *
                                    100
                                  }
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  })}
                </List>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DoctorsList;
