import React, { useState, useEffect } from "react";
import LongTextInput from "./LongTextInput";
import { Button } from "@material-ui/core";

const MultipleLongTextInput = (props) => {
  const {
    item,
    formState,
    handleChange,
    hasError,
    handleBlur,
    index,
    isCaseDeceased,
    hideAddButton,
    className,
  } = props;
  const [more, setMore] = useState([]);
  const [inputs, setInputs] = useState({
    ...(formState &&
    formState.values &&
    formState.values[item.key] !== undefined
      ? formState.values[item.key][index] !== undefined
        ? formState.values[item.key][index]
        : formState.values[item.key]
      : // eslint-disable-next-line
        item.answer.labelList.reduce(
          // eslint-disable-next-line
          (a, b) => ((a[Object.keys(b)[0]] = null), a),
          {}
        )),
  });
  var itemName = item.key;

  useEffect(() => {
    let start =
      formState &&
      formState.values &&
      formState.values[itemName] &&
      formState.values[itemName]["initialMorbidState"]
        ? formState.values[itemName]["initialMorbidState"].map((el) => "")
        : [];
    setMore(start);
    // eslint-disable-next-line
  }, []);

  const handleSubChange = (event, key, name, index) => {
    let vals = inputs["initialMorbidState"] ? inputs["initialMorbidState"] : [];

    vals[index] = event.target.value;

    setInputs({
      ...inputs,
      initialMorbidState: vals,
    });

    handleChange({
      target: {
        name: name,
        type: "multiple",
        value: inputs,
      },
      persist: () => true,
    });
  };
  const handleMyChange = (event, key, name, index) => {
    let vals = {
      ...inputs,
      [key]: event.target.value,
    };

    setInputs(vals);

    handleChange({
      target: {
        name: name,
        type: "multiple",
        index: index,
        value: vals,
      },
      persist: () => true,
    });
  };

  const addInput = () => {
    setMore([...more, ""]);
  };

  return (
    <div
      className={item && className ? className : "answerBox"}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="multipleDRGlabelContainer">
        <p className="labelText">{item.label}</p>
        {hideAddButton ? null : (
          <div className={"buttonsContainer"}>
            <Button className={"addButton"} onClick={(e) => addInput(e)}>
              <p className={"buttonText"}>+</p>
            </Button>
          </div>
        )}
      </div>
      <div className="multipleDRGTextFieldContainer">
        <ol>
          {item.answer.labelList.map((el, index) => {
            let theKey = Object.keys(el)[0];
            if (theKey === "initialMorbidState") {
            }

            return (
              <li key={`li_multipleDRGTextFieldContainer${theKey}_${index}`}>
                <LongTextInput
                  key={theKey + index}
                  item={{ key: theKey }}
                  formState={{
                    values: {
                      [theKey]: inputs[theKey]
                        ? (typeof inputs[theKey]).toLowerCase() === "object"
                          ? inputs[theKey][0]
                          : inputs[theKey]
                        : "",
                    },
                  }}
                  handleChange={(event) => {
                    if (theKey === "initialMorbidState") {
                      handleSubChange(event, "initialMorbidState", itemName, 0);
                    } else {
                      handleMyChange(event, theKey, itemName);
                    }
                  }}
                  handleBlur={handleBlur}
                  hasError={hasError}
                  label={el[theKey]}
                />
              </li>
            );
          })}

          {more.map((input, index) => {
            let theKey = "initialMorbidState";
            return (
              <li key={`li_item_${index + 1}`}>
                <LongTextInput
                  key={"others_" + (index + 1)}
                  item={{ key: theKey }}
                  formState={{
                    values: {
                      [theKey]: inputs[theKey]
                        ? (typeof inputs[theKey]).toLowerCase() === "object"
                          ? inputs[theKey][index + 1]
                          : inputs[theKey]
                        : "",
                    },
                  }}
                  index={index + 1}
                  handleChange={(event) => {
                    handleSubChange(event, theKey, itemName, index + 1);
                  }}
                  handleBlur={handleBlur}
                  hasError={hasError}
                  hideLabel={true}
                  label={<p style={{ opacity: 0 }}>.</p>}
                />
              </li>
            );
          })}
        </ol>
        {isCaseDeceased ? (
          <LongTextInput
            key={"otherMorbidState"}
            item={{ key: "otherMorbidState" }}
            formState={{ values: inputs }}
            handleChange={(event) => {
              handleMyChange(event, "otherMorbidState", itemName);
            }}
            handleBlur={handleBlur}
            hasError={hasError}
            hideLabel={true}
            label={"Alte stari morbide importante"}
          />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default MultipleLongTextInput;
