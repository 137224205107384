import React, { useState, useEffect } from "react";
import SurgicalTextInputs from "./SurgicalTextInputs";
import { Button } from "@material-ui/core";

const MultipleSurgicalTextInputs = (props) => {
  const {
    item,
    formState,
    handleChange,
    hasError,
    handleBlur,
    hideAddButton,
    save,
    setModalSave
  } = props;

  const [inputs, setInputs] = useState([]);
  var itemName = item.key;

  useEffect(() => {
    let start = formState.values[itemName]
      ? formState.values[itemName].map((el) => "")
      : [""];
    setInputs(start);
    // eslint-disable-next-line
  }, []);

  const addInput = () => {
    setInputs([...inputs, ""]);
  };

  return (
    <div>
      <div className="surgicallabelContainer">
        <p className="labelText">{item.label}</p>

        {hideAddButton ? (
          <div></div>
        ) : (
          <div className={"buttonsContainer"}>
            <Button className={"addButton"} onClick={(e) => addInput(e)}>
              <p className={"buttonText"}>+</p>
            </Button>
          </div>
        )}
      </div>

      {inputs.map((input, index) => (
        <SurgicalTextInputs
          key={`MultipleSurgicalTextInputs_${item.key}_${index}`}
          item={item}
          formState={formState}
          handleChange={handleChange}
          handleBlur={handleBlur}
          index={index}
          hasError={hasError}
          hideAddButton={hideAddButton}
          save={save}
          setModalSave={setModalSave}
        />
      ))}
    </div>
  );
};

export default MultipleSurgicalTextInputs;
