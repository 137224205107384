import { Card, List, ListItem, Modal } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import ARTButton from "components/FloatingMonitorBtn/components/ARTButton";
import BpmButton from "components/FloatingMonitorBtn/components/BpmButton";
import IMPButton from "components/FloatingMonitorBtn/components/IMPButton";
import PVCButton from "components/FloatingMonitorBtn/components/PVCButton";
import SpO2Button from "components/FloatingMonitorBtn/components/SpO2Button";
import SpO2FPButton from "components/FloatingMonitorBtn/components/SpO2FPButton";
import STButton from "components/FloatingMonitorBtn/components/STButton";
import TpieleButton from "components/FloatingMonitorBtn/components/TpieleButton";
import TrectButton from "components/FloatingMonitorBtn/components/TrectButton";
import { FormStateContext } from "context/formStateContext";
import lottie from "lottie-web";
import lscache from "lscache";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import loadingAnimation from "../../../assets/animationJson/loadingJson.json";
// import loadingAnimation from "../../../assets/animationJson/loadingJsonNew.json";
import {
  AgendaModal,
  BackToTop,
  FloatingAgenda,
  FloatingSave,
} from "../../../components";
import { AskForSecondOpinion } from "../../../components/AskForSecondOpinion";
import FloatingMonitorBtn from "../../../components/FloatingMonitorBtn/FloatingMonitorBtn";
import { getHistoryPathArray } from "../../../helpers";
import { REST } from "../../../Utils";
import Button from "../Chestionar/components/EvolutionList/Buttons1/index";
import DischargeSheet from "./components/DischargeSheet/DischargeSheet";
import EvolutionList from "./components/EvolutionList";
import Form from "./components/Form/Form";
import PrintButton from "./components/PrintButton";
import RapoarteTemperatura from "./components/RapoarteTemperatura";
import SecondForm from "./components/SecondForm/SecondForm";
import Temperatura from "./components/Temperatura";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const Chestionar = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { history, location } = props;
  const { dispatchFormState, userDataContext: userData } = useContext(
    FormStateContext
  );

  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line
  let animation = null;
  // eslint-disable-next-line
  const [error, setError] = useState({ message: null });
  const [formData, setFormData] = useState(null);
  const [obsData, setObsData] = useState(null);
  const [dischargeData, setDischargeData] = useState(null);
  const [allData, setAllData] = useState(null);
  // eslint-disable-next-line
  const [ticketId, setTicketId] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  // eslint-disable-next-line
  const [showLoading, setShowLoading] = useState(true);
  const [monitorData, setMonitorData] = useState("");

  const [getAllDRG, setGetAllDRG] = useState(null);
  const [firstPageForm, setFirstPageForm] = useState([]);
  const [secondPageForm, setSecondPageForm] = useState([]);
  const [thirdPageForm, setThirdPageForm] = useState([]);
  const [agendaModal, setAgendaModal] = useState(false);
  const [floatingVitals, setFloatingVitals] = useState(false);
  const [vitals, setVitals] = useState("INTERNATI");
  const [modalSave, setModalSave] = useState(false);

  // eslint-disable-next-line
  const [showPrint, setShowPrint] = useState(
    lscache.get("operatorType") === "Medic" ? true : false
  );

  useEffect(() => {
    let myInterval;

    if (floatingVitals) {
      myInterval = setInterval(() => {
        getVitals();
      }, 5000);
    }

    return () => clearInterval(myInterval);
    // eslint-disable-next-line
  }, [floatingVitals]);

  const handleAgenda = () => {
    setAgendaModal(!agendaModal);
  };

  const getAllDRGData = async () => {
    await REST.get(`/spital/getAllDrg?key=1`)
      .then((answer) => {
        setGetAllDRG(answer);
        setShowLoading(false);
        setIsPageLoading(false);
      })
      .catch((err) => { });
  };

  const getData = async (entryId) => {
    await REST.get(`/spital/getTicketById/?id=${entryId}`)
      .then((answer) => {
        setError({ message: null });
        const allData = answer.data;

        if (!answer.data.form) {
          window.location = "/listare";
        }

        dispatchFormState(answer.data.form);
        setFormData(answer.data.form);
        setObsData(answer.data.observationSheet);

        // populate discharge sheet
        if (answer.data.dischargeSheet !== null) {
          setDischargeData(answer.data.dischargeSheet);
        } else {
          createDischargeSheetData(
            answer.data.form,
            answer.data.observationSheet
          );
        }

        setAllData(allData.monitorData);
        setMonitorData(answer.data.monitorData);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          window.location = "/listare";

          setError({
            message,
          });
        }
      });
  };

  const createDischargeSheetData = (formDataAux, obsDataAux) => {
    var dischargeSheetData = {
      name: formDataAux.firstName + " " + formDataAux.lastName,
      birthDate: formDataAux.birthDate,
      CNP: formDataAux.CNP,
      finalConfirmDate: formDataAux.finalConfirmDate,
      register: null,
      reasonForHospitalization: obsDataAux.reasonForHospitalization,
      admissionDiagnostic: obsDataAux.admissionDiagnostic,
      anamneza: obsDataAux.anamneza,
      riskFactors: null,
      general: null,
      local: null,
      normalValues: null,
      pathologicalValues: null,
      ekg: null,
      eco: null,
      rx: null,
      other: null,
      performedTreatment: null,
      otherInfo: null,
      recomendedTreatment: null,
      indication: null,
      prescription: null,
      vacation: null,
      medicalCare: null,
      medicalDevices: null,
      countyUnit: null,
      socialSecurityNr: obsDataAux.socialSecurityNr
        ? obsDataAux.socialSecurityNr
        : null,
      dischargeDate: obsDataAux.dischargeDate ? obsDataAux.dischargeDate : null,
      signature: obsDataAux.signMainPhysicianCurant
        ? obsDataAux.signMainPhysicianCurant
        : null,
      transmissionPath: obsDataAux.transmissionPath
        ? obsDataAux.transmissionPath
        : null,
    };

    setDischargeData(dischargeSheetData);
    setThirdPageForm(dischargeSheetData);
  };

  useEffect(() => {
    const entryId = getHistoryPathArray(history).slice(-1);
    setTicketId(entryId);
    getData(entryId);
    getAllDRGData();
    // eslint-disable-next-line
  }, []);

  const handleFirstPage = (data) => {
    setFirstPageForm(data);
  };

  const handleSecondPage = (data) => {
    setSecondPageForm(data);
  };
  const handleThirdPage = (data) => {
    setThirdPageForm(data);
  };

  const handleSubmit = async () => {
    if (Object.keys(firstPageForm).length > 0) {
      await REST.post(`/spital/updateForm?id=${ticketId}`, firstPageForm)
        .then((answer) => {
          if (!modalSave) {
            window.location = "/listare";
          }
          updateSnack("success");
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            setError({
              message,
            });
          }
        });
    }

    if (Object.keys(secondPageForm).length > 0) {
      await REST.post(
        `/spital/updateObservationSheet?id=${ticketId}`,
        secondPageForm
      )
        .then((answer) => {
          if (!modalSave) {
            window.location = "/listare";
          }
          updateSnack("success");
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            setError({
              message,
            });
          }
        });
    }

    if (Object.keys(thirdPageForm).length > 0) {
      await REST.post(
        `/spital/updateDischargeSheet?id=${ticketId}`,
        thirdPageForm
      )
        .then((answer) => {
          if (!modalSave) {
            window.location = "/listare";
          }
          updateSnack("success");
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            setError({
              message,
            });
          }
        });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
    animation = lottie.loadAnimation({
      container: document.querySelector("#loadingAnimation"),
      animationData: loadingAnimation,
    });
    // eslint-disable-next-line
  }, []);

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const updateSnack = (variant) => {
    enqueueSnackbar("Fisa pacientului a fost actualizata!", { variant });
  };

  const getVitals = async () => {
    await REST.get(`/spital/getMonitorData?ticketId=${ticketId}`).then(
      (answer) => {
        setVitals(answer);
      }
    );
  };

  const handleFloatingVitals = () => {
    setFloatingVitals(!floatingVitals);
  };
  

  return (
    <div className={"chestionarContent"}>
      {isPageLoading && (
        <div className={"blockWindow"}>
          <div className={"curtain"}></div>
          <div className={"loader"}>
            <div id="loadingAnimation" />
          </div>
        </div>
      )}
      {!isPageLoading && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {showPrint ? null : <PrintButton ticketId={ticketId} />}
          <h1>
            Fisa - {formData && formData.lastName}{" "}
            {formData && formData.firstName} - {formData && formData.CNP}
          </h1>
        </div>
      )}
    
      {/* {((lscache.get("operatorType") != null) && (lscache.get("operatorType") === "Medic") ||
        (lscache.get("operatorType") === "SefSectie" )&& (
          <AskForSecondOpinion ticketId={ticketId} />
        ))} */}
      {<AskForSecondOpinion ticketId={ticketId} />}
      {!isPageLoading && formData && ticketId && (
        <div className="allFormsContainer">
          <div className={classes.root}>
            <AppBar position="static" color="default" className="tabs-header">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                scrollButtons="auto"
                aria-label="full width tabs example"
                className="tabs-header"
              >
                <Tab
                  label="DATE GENERALE PACIENT"
                  {...a11yProps(0)}
                  className="top-tab-btn"
                />
                <Tab
                  label="FOAIE DE OBSERVATIE CLINICA GENERALA"
                  {...a11yProps(1)}
                  className="top-tab-btn"
                />
                <Tab
                  label="FOAIE DE EVOLUTIE SI TRATAMENT"
                  {...a11yProps(2)}
                  className="top-tab-btn"
                />
                <Tab
                  label="FOAIE DE MASURATORI VITALE"
                  {...a11yProps(3)}
                  className="top-tab-btn"
                />
                <Tab
                  label="FOAIE DE EXTERNARE"
                  {...a11yProps(4)}
                  className="top-tab-btn"
                />
              </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
              <div className={"accordion_body"}>
                <h3 className="formSubtitle">Date generale pacient</h3>
                <Form
                  data={formData}
                  ticketId={ticketId}
                  handleFirstPage={handleFirstPage}
                />
              </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <div className={"accordion_body"}>
                <h3 className="formSubtitle">
                  Foaie de observatie clinica generala{" "}
                </h3>
                <SecondForm
                  data={formData}
                  obsData={obsData}
                  ticketId={ticketId}
                  userData={userData}
                  handleSecondPage={handleSecondPage}
                  getAllDRG={getAllDRG}
                  createDischargeSheet={createDischargeSheetData}
                  save={handleSubmit}
                  setModalSave={setModalSave}
                />
              </div>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <div className={"accordion_body"}>
                <EvolutionList
                  history={history}
                  location={location}
                  ticketId={ticketId}
                  formState={userData}
                />
              </div>
            </TabPanel>

            <TabPanel value={value} index={3}>
              <Card className="p-3">
                <List className="nav-tabs nav-tabs-primary d-flex">
                  <ListItem
                    button
                    disableRipple
                    selected={activeTab === "0"}
                    onClick={() => {
                      toggle("0");
                    }}
                  >
                    Adauga Valori
                  </ListItem>
                  <ListItem
                    button
                    disableRipple
                    selected={activeTab === "1"}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Rapoarte
                  </ListItem>
                </List>

                <div
                  className={clsx("tab-item-wrapper", {
                    active: activeTab === "0",
                  })}
                  index={0}
                >
                  <div className="my-5">
                    <Temperatura
                      allData={allData}
                      setAllData={setAllData}
                      ticketId={ticketId}
                    />
                  </div>
                </div>
                <div
                  className={clsx("tab-item-wrapper", {
                    active: activeTab === "1",
                  })}
                  index={1}
                >
                  <div className="text-center my-5">
                    {allData && <RapoarteTemperatura allData={allData} />}
                  </div>
                </div>
              </Card>
            </TabPanel>

            <TabPanel value={value} index={4}>
              <div className={"accordion_body"}>
                <h3 className="formSubtitle" style={{ marginBottom: "64px" }}>
                  SCRISOARE MEDICALA* / BILET DE EXTERNARE
                </h3>
                <DischargeSheet
                  data={formData}
                  obsData={obsData}
                  dischargeData={dischargeData}
                  ticketId={ticketId}
                  userData={userData}
                  handleThirdPage={handleThirdPage}
                  getAllDRG={getAllDRG}
                />
              </div>
            </TabPanel>
          </div>
        </div>
      )}
      {!isPageLoading && (
        <div>
          <div className={"sendForm_btn"}>
            <Button onClick={handleSubmit} text="SALVEAZA"></Button>
          </div>
        </div>
      )}
      {!isPageLoading && <BackToTop />}
      {!isPageLoading && <FloatingSave handleSubmit={handleSubmit} />}
      {!isPageLoading && <FloatingAgenda handleAgenda={handleAgenda} />}
      {!isPageLoading && monitorData && (
        <FloatingMonitorBtn
          handleVitals={handleFloatingVitals}
          thicketId={ticketId}
          monitorData={monitorData}
        />
      )}

      <Modal
        open={agendaModal}
        onBackdropClick={handleAgenda}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(3px)",
          width: "100%",
          height: "100%",
        }}
      >
        <div className="modal_container">
          <AgendaModal handleModal={handleAgenda} ticketId={ticketId} />
        </div>
      </Modal>

      {floatingVitals && (
        <div className={`vitals_floating_container`}>
          <div className={`vitals_item`}>
            <TrectButton vitals={vitals} />
          </div>

          <div className={`vitals_item`}>
            <TpieleButton vitals={vitals} />
          </div>

          <div className={`vitals_item`}>
            <IMPButton vitals={vitals} />
          </div>

          <div className={`vitals_item`}>
            <SpO2Button vitals={vitals} />
          </div>

          <div className={`vitals_item`}>
            <PVCButton vitals={vitals} />
          </div>

          <div className={`vitals_item`}>
            <ARTButton vitals={vitals} />
          </div>

          <div className={`vitals_item`}>
            <STButton vitals={vitals} />
          </div>

          <div className={`vitals_item`}>
            <SpO2FPButton vitals={vitals} />
          </div>

          <div className={`vitals_item`}>
            <BpmButton vitals={vitals} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Chestionar;
