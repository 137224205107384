import React from "react";

const TitleForInput = (props) => {
  const { item, label, className } = props;

  return (
    <div>
      <h4 className={item && className ? className : "titleForInput"}>
        {label}
      </h4>
      <p>{item.description}</p>
    </div>
  );
};

export default TitleForInput;
