const secondOpinionSchema = {
  password: {
    presence: { allowEmpty: false, message: "este necesara" },
    length: {
      maximum: 128,
      minimum: 6,
      message: "Minim 6 caractere",
    },
  },
};

export default secondOpinionSchema;
