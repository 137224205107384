import React from "react";
import { useAti } from "../../../../../../hooks";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const MedicatieTable = () => {
  const ctx = useAti();
  const classes = useStyles();

  return (
    <>
      {ctx.rState.acc17.values.medicines && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Data administrarii</TableCell>
                <TableCell align="right">Ora</TableCell>
                <TableCell align="right">Alergii medicamentoase</TableCell>
                <TableCell align="right">Doctor</TableCell>
                <TableCell align="right">Dozaj</TableCell>
                <TableCell align="right">Max / 24h</TableCell>
                <TableCell align="right">Medicatie</TableCell>
                <TableCell align="right">Cale administrare</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ctx && ctx.rState.acc17.values.medicines &&
                ctx.rState.acc17.values.medicines.medicinesEntries.map(
                  (row, index) => {
                    return (
                      <TableRow
                        key={row.name}
                        style={
                          index % 2
                            ? { background: "#fdffe0" }
                            : { background: "white" }
                        }
                      >
                        <TableCell component="th" scope="row">
                          {row.dateOfAdministration &&
                            moment(row.dateOfAdministration).format(
                              "YYYY-MM-DD"
                            )}
                        </TableCell>

                        <TableCell align="right">
                          {row.startDate &&
                            moment(row.startDate).format("HH:mm")}
                        </TableCell>
                        <TableCell align="right">
                          {ctx.rState.acc17.values.medicines.drugAllergies}
                        </TableCell>
                        <TableCell align="right">
                          {row.doctor || "--"}
                        </TableCell>
                        <TableCell align="right">{row.dose || "--"}</TableCell>
                        <TableCell align="right">
                          {row.maxDoseIn24Hours || "--"}
                        </TableCell>
                        <TableCell align="right">
                          {row.medication || "--"}
                        </TableCell>
                        <TableCell align="right">
                          {row.routeOfAdministration || "--"}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default MedicatieTable;
