import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React from "react";
import { useAti } from "../../../../../../hooks";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const EvolutieSimptomeTable = () => {
  const ctx = useAti();
  const classes = useStyles();

  return (
    <>
      {ctx.rState.acc19.values.evolutionGraph && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell align="right">Durere</TableCell>
                <TableCell align="right">Fatigabilitate</TableCell>
                <TableCell align="right">Stare de bine</TableCell>
                <TableCell align="right">Inapetenta</TableCell>
                <TableCell align="right">Greata/voma</TableCell>
                <TableCell align="right">Constipatie</TableCell>
                <TableCell align="right">Anxietate</TableCell>
                <TableCell align="right">Depresie</TableCell>
                <TableCell align="right">Insomnie</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ctx.rState.acc19.values.evolutionGraph.map((row, index) => {
                let symptomsAccumulator = {
                  date: null,
                  durere: null,
                  fatigabilitate: null,
                  stareDeBine: null,
                  dispnee: null,
                  inapetenta: null,
                  greata: null,
                  constipatie: null,
                  anxietate: null,
                  depresie: null,
                  insomnie: null,
                };
                row.symptopms &&
                  // eslint-disable-next-line
                  row.symptopms.map((s) => {
                    symptomsAccumulator[s.description] = s.levelNumber;
                  });
                return (
                  <TableRow
                    key={row.name}
                    style={
                      index % 2
                        ? { background: "#fdffe0" }
                        : { background: "white" }
                    }
                  >
                    <TableCell component="th" scope="row">
                      {moment(row.date).format("YYYY-MM-DD")}
                    </TableCell>

                    <TableCell align="right">
                      {symptomsAccumulator.durere || "--"}
                    </TableCell>
                    <TableCell align="right">
                      {symptomsAccumulator.fatigabilitate || "--"}
                    </TableCell>
                    <TableCell align="right">
                      {symptomsAccumulator.stareDeBine || "--"}
                    </TableCell>
                    <TableCell align="right">
                      {symptomsAccumulator.dispnee || "--"}
                    </TableCell>
                    <TableCell align="right">
                      {symptomsAccumulator.inapetenta || "--"}
                    </TableCell>
                    <TableCell align="right">
                      {symptomsAccumulator.greata || "--"}
                    </TableCell>
                    <TableCell align="right">
                      {symptomsAccumulator.constipatie || "--"}
                    </TableCell>
                    <TableCell align="right">
                      {symptomsAccumulator.anxietate || "--"}
                    </TableCell>
                    <TableCell align="right">
                      {symptomsAccumulator.depresie || "--"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default EvolutieSimptomeTable;
