import { default as acc1Hydrate } from "./acc1.hydrate";
import { default as acc2Hydrate } from "./acc2.hydrate";
import hydrateAccordionsFactory, {
  accordionConfigs
} from "../../../../../../context/hydrateAccordionsFactory.helper";

export const hydrateAccordionsWithState = (ctx, ctxData, sheetId) => {
  ctx.dispatch({
    type: "sheetId",
    payload: sheetId
  });

  acc1Hydrate(ctx, ctxData);
  acc2Hydrate(ctx, ctxData);

  for (let ii = 3; ii <= 21; ii++) {
    if (ii !== 10)
      hydrateAccordionsFactory(
        ctx.dispatch,
        ctxData,
        ctx.ACTIONS[accordionConfigs[`acc${ii}`].accNo],
        accordionConfigs[`acc${ii}`].accKey
      );
  }

  setTimeout(() => {
    ctx.dispatch({
      type: "blockwindow",
      payload: {
        hide: false
      }
    });
  }, 2000);
};
