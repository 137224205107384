import { all } from "../../../../../../../../Utils/spitalList";

const anamnezaSecondForm = [
  {
    label: "ANAMNEZA",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "heredoColateral",
    label: "Antecedente heredo-colaterale",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: all
    }
  },

  {
    key: "personal",
    label: "Antecedente personale, fiziologice si patologice",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: all
    }
  },

  {
    key: "medication",
    label:
      "Medicatie de fond administrata inaintea internarii (inclusiv preparate hormonale si imunosupresoare)",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: all
    }
  },

  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "reasonForHospitalization",
    label: "MOTIVELE SOLICITARII SERVICIULUI:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "patientExpectation",
    label:
      "ASTEPTARILE PACIENTULUI/FAMILIEI LA LUAREA IN EVIDENTA SERVICIULUI DE PALIATIE:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "diseaseHistory",
    label: "ISTORICUL BOLII:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  }
];

export default anamnezaSecondForm;
