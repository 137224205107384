import { Grid } from "@material-ui/core";
import React from "react";

const STButton = (props) => {
  const { vitals } = props;
  return (
    <Grid container className="widgetContainer">
      <Grid item xs={4} md={4}>
        <div className="widgetTextST-II">ST-II</div>
      </Grid>
      <Grid item xs={8} md={8}>
        <div className="widgetValueST-II">
          {vitals && vitals.data && vitals.data["VITAL ST2"] ? (
            vitals && vitals.data && vitals.data["VITAL ST2"]
          ) : (
            <div className="missingValue"> - </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default STButton;
