import React, { useState } from "react";
import QrReader from "react-qr-reader";
import { Grid, Button, TextField } from "@material-ui/core";
import bigQr from "../../../../assets/images/qr/big-qr.svg";

const PacientScanQR = (props) => {
  const { handleError, setCurrentStep, setTicketId } = props;

  const [openCamera, setOpenCamera] = useState(false);

  const handleScan = async (data) => {
    if (data) {
      setTicketId(data);
      setCurrentStep("bed");
    }
  };

  const handleInputCode = (event) => {
    setTicketId(event.target.value);
  };

  const handleApplyCode = () => {
    setCurrentStep("bed");
  };

  const opneCamera = () => {
    setOpenCamera(true);
  };

  return (
    <Grid container className={"qr_root"}>
      <Grid item xs={12} md={12} className={"title_container"}>
        <h1>Va rugam sa scanati codul QR de pe bratara pacientului!</h1>
      </Grid>

      {openCamera ? (
        <Grid item xs={12} md={12} lg={12} className={"camera_container"}>
          <div className={"qr_container"}>
            <QrReader
              delay={10}
              onError={handleError}
              onScan={handleScan}
              resolution={2000}
              style={{ width: "100%" }}
              constraints={{
                focusMode: "continuous",
                sharpness: 999,
                torch: true,
                exposureMode: "continuous",
                whiteBalanceMode: "continuous",
                focusDistance: 100,
                facingMode: "environment",
              }}
            />

            <br />
          </div>
        </Grid>
      ) : (
        <Grid item container className={"body_wrapper"}>
          <div className={"body_scan_container"}>
            <div className={"code_input_container"}>
              <img src={bigQr} alt="" style={{ width: 200, height: 200 }} />
            </div>

            <div className={"btn_container"}>
              <Button className={"submitBtn"} onClick={opneCamera}>
                Scaneaza
              </Button>
            </div>
          </div>

          <div className={"body_input_container"}>
            <div className={"code_title_container"}>
              <h4>Introduceti codul alfa numeric</h4>
            </div>

            <div className={"code_input_container"}>
              <TextField
                id="outlined-password-input"
                placeholder="..."
                type="text"
                variant="outlined"
                className={"code_input"}
                onChange={handleInputCode}
              />
            </div>
            <div className={"btn_container"}>
              <Button className={"submitBtn"} onClick={handleApplyCode}>
                Aplica
              </Button>
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  );
};
export default PacientScanQR;
