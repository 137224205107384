import lscache from "lscache";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { REST } from "../../../Utils/index";
import Tabs from "./Components/Tabs";
import Wrapper from "./Components/Wrapper";
import { Tables, TablesAti } from "./Components/WTZ-Tables";
import MedTables from "./Components/WTZ-Tables/Med-Table";

const Listare = (props) => {
  // const { history } = props;

  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [data, setData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [pageToDisplay, setPageToDisplay] = useState("INTERNATI");
  const [dateToDisplay, setDateToDisplay] = useState(new Date());
  const [inactiveTickets, setInactiveTickets] = useState([]);
  const [badge, setBadge] = useState({
    totalActive: 0,
    totalInactive: 0,
    totalAppointments: 0,
    totalAti: 0,
  });

  const getBadges = async () => {
    await REST.get(`/spital/getCardsDetails`)
      .then((answer) => {
        setBadge((state) => {
          return {
            ...state,
            totalActive: answer.data.hospitalized,
            totalInactive: answer.data.discharged,
            totalAppointments: answer.data.schedule,
            totalAti: answer.data.atiHospitalized,
          };
        });
        console.log(`badges >>`, answer.data);
      })
      .catch((err) => {});
  };

  const getData_activeTickets = async (pageToLoad) => {
    // setIsPageLoading(true);
    await REST.get(`/spital/getAllActiveTickets?page=${pageToLoad}&size=10`)
      .then((answer) => {
        setData(answer.data.content);
        setMaxPage(answer.data.totalPages);
        lscache.set("numberOfTickets", answer.data.numberOfElements);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          setMaxPage(1);
        }
      });
  };

  const getData_inactiveTickets = async (pageToLoad) => {
    await REST.get(`/spital/getAllInactiveTickets?page=${pageToLoad}&size=10`)
      .then((answer) => {
        setMaxPage(answer.data.totalPages);
        lscache.set("numberOfTickets", answer.data.numberOfElements);
        setInactiveTickets(answer.data);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          setMaxPage(1);
        }
      });
  };

  const getData_activeTicketsByData = async (pageToLoad, dataToLoad) => {
    // setIsPageLoading(true);
    await REST.get(
      `/callcenter/getAllTicketsByDate?date=${moment(dataToLoad).format(
        "YYYY/MM/DD"
      )}&page=${pageToLoad}&size=40`
    )
      .then((answer) => {
        setData(answer.data.content);
        setMaxPage(answer.data.totalPages);
        lscache.set("numberOfTickets", answer.data.numberOfElements);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          setMaxPage(1);
        }
      });
  };

  const getDataFiltred_activeTickets = async (searchKey) => {
    await REST.get(
      `/spital/searchActiveTickets?page=0&path=${searchKey}&size=40`
    )
      .then((answer) => {
        setData(answer.data.content);
        setMaxPage(answer.data.totalPages);
        lscache.set("numberOfTickets", answer.data.numberOfElements);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          setMaxPage(1);
        }
      });
  };

  const getDataFiltred_inactiveTickets = async (searchKey) => {
    await REST.get(
      `/spital/searchInactiveTickets?page=0&path=${searchKey}&size=10`
    )
      .then((answer) => {
        setData(answer.data.content);
        setMaxPage(answer.data.totalPages);
        lscache.set("numberOfTickets", answer.data.numberOfElements);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          setMaxPage(1);
        }
      });
  };

  const getAtiTickets = async (pageToLoad) => {
    await REST.get(
      `/ati/getAtisheetsByStatus?page=${pageToLoad}&size=10&status=Registratura`
    )
      .then((answer) => {
        setData(answer.data.content);
        setMaxPage(answer.data.totalPages);
        lscache.set("numberOfTickets", answer.data.numberOfElements);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          setMaxPage(1);
        }
      });
  };

  const getAtiMedTickets = async (pageToLoad) => {
    // setIsPageLoading(true);
    await REST.get(`/ati/getAllAtisheetsByDoctorId?page=${pageToLoad}&size=10`)
      .then((answer) => {
        setData(answer.data.content);
        setMaxPage(answer.data.totalPages);
        lscache.set("numberOfTickets", answer.data.numberOfElements);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          setMaxPage(1);
        }
      });
  };

  const handleChangePage = (event, value) => {
    setPage(value - 1);
    if (pageToDisplay === "PROGRAMATI") {
      getData_activeTicketsByData(value - 1);
    } else if (pageToDisplay === "INTERNATI") {
      getData_activeTickets(value - 1);
    } else if (pageToDisplay === "EXTERNATI") {
      getData_inactiveTickets(value - 1);
    }
  };

  useEffect(() => {
    if (["Registratura", "Medic"].includes(lscache.get("operatorType"))) {
      getData_activeTickets(0);
    }
    getBadges();
    // eslint-disable-next-line
  }, []);

  const handleDateChange = (date) => {
    setDateToDisplay(date);
    getData_activeTicketsByData(0, moment(date).format("YYYY/MM/DD"));
  };

  const handleChangeTab = (tab) => {
    if (tab === "PROGRAMATI") {
      setPageToDisplay("PROGRAMATI");
      getData_activeTicketsByData(0);
    } else if (tab === "INTERNATI") {
      setPageToDisplay("INTERNATI");
      getData_activeTickets(0);
    } else if (tab === "EXTERNATI") {
      setPageToDisplay("EXTERNATI");
      getData_inactiveTickets(0);
    } else if (tab === "ATI") {
      setPageToDisplay("ATI");
      lscache.get("operatorType") === "Medic"
        ? getAtiMedTickets(0)
        : getAtiTickets(0);
    }
  };

  if (isPageLoading) {
    return (
      <div className={"listareContent"}>
        <Wrapper>
          <Tables
            data={[]}
            page={page}
            handleChangePage={handleChangePage}
            maxPage={maxPage}
          />
        </Wrapper>
      </div>
    );
  } else {
    if (pageToDisplay === "PROGRAMATI") {
      return (
        <div className={"listareContent"}>
          <Tabs
            selectedTab={pageToDisplay}
            handleChangeTab={handleChangeTab}
            badge={badge}
          />
          <div>
            <Wrapper>
              <Tables
                data={data}
                page={page}
                handleChangePage={handleChangePage}
                maxPage={maxPage}
                showDatePicker={true}
                dateToDisplay={dateToDisplay}
                handleDateChange={handleDateChange}
                type={"registratura"}
                list={"PROGRAMATI"}
              />
            </Wrapper>
          </div>
        </div>
      );
    } else if (pageToDisplay === "INTERNATI") {
      return (
        <div className={"listareContent"}>
          <Tabs
            selectedTab={pageToDisplay}
            handleChangeTab={handleChangeTab}
            badge={badge}
          />
          <div>
            <Wrapper>
              {lscache.get("operatorType") === "Medic" ? (
                <MedTables
                  data={data}
                  page={page}
                  handleChangePage={handleChangePage}
                  maxPage={maxPage}
                  type={"registratura"}
                  list={"INTERNATI"}
                  searchData={getDataFiltred_activeTickets}
                />
              ) : (
                <Tables
                  data={data}
                  page={page}
                  handleChangePage={handleChangePage}
                  maxPage={maxPage}
                  type={"registratura"}
                  list={"INTERNATI"}
                  searchData={getDataFiltred_activeTickets}
                />
              )}
            </Wrapper>
          </div>
        </div>
      );
    } else if (pageToDisplay === "ATI") {
      return (
        <div className={"listareContent"}>
          <Tabs
            selectedTab={pageToDisplay}
            handleChangeTab={handleChangeTab}
            badge={badge}
          />
          <div>
            <Wrapper>
              <TablesAti
                data={data}
                page={page}
                handleChangePage={handleChangePage}
                maxPage={maxPage}
                type={"registratura"}
                searchData={getDataFiltred_activeTickets}
              />
            </Wrapper>
          </div>
        </div>
      );
    } else {
      return (
        <div className={"listareContent"}>
          <Tabs
            selectedTab={pageToDisplay}
            handleChangeTab={handleChangeTab}
            badge={badge}
          />
          <div>
            <Wrapper>
              <Tables
                data={inactiveTickets.content}
                page={page}
                handleChangePage={handleChangePage}
                maxPage={maxPage}
                type={"registratura"}
                list={"EXTERNATI"}
                searchData={getDataFiltred_inactiveTickets}
              />
            </Wrapper>
          </div>
        </div>
      );
    }
  }
};

export default Listare;
