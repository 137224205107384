import { Grid } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import lscache from "lscache";

const TopTabs = (props) => {
  const { name } = useParams();
  return (
    <Grid item xs={12} md={12} xl={12} className={"dashboard_top_tabs"}>
      {lscache.get("operatorType") === "SefSectie"
        ?
        <div>
          {name && <h3>Lista pacienti Dr. {name}</h3>}
        </div>
        :
        null
      }
    </Grid>
  );
};

export default TopTabs;
