export const aparatTypes = [
  "VITAL APSEC(RESP)",
  "VITAL rPR(spo2)",
  "VITAL CVP(S)",
  "VITAL PR(spo2)",
  "VITAL MONITOR ID",
  "VITAL PR(art)",
  "vital_art_d",
  "VITAL CVP(M)",
  "VITAL STV2",
  "VITAL CVP(D)",
  "VITAL STV1",
  "VITAL STV4",
  "VITAL STV3",
  "VITAL ST1",
  "VITAL STV6",
  "VITAL ST2",
  "VITAL STV5",
  "VITAL ST3",
  "vital_spo2",
  "vital_hr",
  "VITAL STVF",
  "vital_resp",
  "vital_trect",
  "VITAL ART(M)",
  "VITAL STVL",
  "VITAL STVR",
  "vital_date",
  "VITAL rRESP(imp)",
  "vital_tskin",
  "vital_art_s",
  "VITAL VPC"
];
