import { Grid } from "@material-ui/core";
import React from "react";
import DoctorsList from "./components/DoctorsList";
import PageTitle from "./components/PageTitle";
import TopCards from "./components/TopCards";

const Dashboard = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={12} xl={12}>
        <PageTitle
          titleHeading="Dashboard"
          titleDescription="Statistici rapide"
        />
      </Grid>
      <Grid item xs={12} md={12} xl={12}>
        <TopCards />
      </Grid>
      <Grid item xs={12} md={12} xl={12}>
        <DoctorsList />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
