import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { debounce } from "lodash";
import React, { useCallback } from "react";
import { REST } from "../../Utils/index";

export default function AutocompleteDRGInput(props) {
  const {
    item,
    formState,
    handleChange,
    hideLabel,
    index,
    setProcedure,
  } = props;

  const itemName = item.key;

  const [value, setValue] = React.useState(
    formState.values[itemName]
      ? formState.values[itemName][index]
        ? formState.values[itemName][index]
        : formState.values[itemName]
      : ""
  );
  // eslint-disable-next-line
  const [inputValue, setInputValue] = React.useState(
    formState.values[itemName]
      ? formState.values[itemName][index]
        ? formState.values[itemName][index]
        : formState.values[itemName]
      : ""
  );

  const [options, setOptions] = React.useState([]);

  const runGet = (value) => {
    if (value && value.length < 10) {
      REST.get(`/spital/getAllDrgByKey?key=${value}`)
        .then((answer) => {
          setOptions(answer.data);
        })
        .catch((err) => {});
    }
  };
  const debounceLoadData = useCallback(debounce(runGet, 1000), []);

  React.useEffect(() => {
    // eslint-disable-next-line
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [value]);

  const debounceHandleChange = debounce((value) => handleChange(value), 1000);

  const handleMyChange = async (value, key, name, index) => {
    let work = {
      target: {
        name: name,
        type: "multiple",
        index: index,
        value: value,
      },
      persist: () => true,
    };
    debounceHandleChange(work);
  };

  const callSetProcedure = (newValue) => {
    newValue &&
      newValue.description &&
      setProcedure &&
      setProcedure(newValue.description);
  };

  return (
    <div className="answerBox">
      {!hideLabel ? (
        <div className="DRGlabelContainer">
          <p className="labelText">{item.label}</p>
        </div>
      ) : (
        <div></div>
      )}

      <div className="AutoDRGTextFieldContainer">
        <Autocomplete
          getOptionLabel={(option, index) =>
            typeof option === "string"
              ? option
              : option === undefined
              ? ""
              : option.procedureCode + "-" + option.description
          }
          filterOptions={(x) => x}
          loading={true}
          loadingText={"Se Incarca..."}
          options={options}
          value={value}
          className="DRGTextField"
          onChange={(event, newValue) => {
            handleMyChange(newValue, "text", itemName, index);
            callSetProcedure(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setValue(newInputValue);
            if (newInputValue !== null && newInputValue.length > 2) {
              debounceLoadData(newInputValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id={`${itemName}${index ? index : ""}Text`}
              name={`${itemName}${index ? index : ""}Text`}
              label={item.displayLabel}
              placeholder="Text DRG"
              value={value}
              margin={"normal"}
            />
          )}
          renderOption={(option) => {
            return (
              <Grid container alignItems="center">
                <Grid item md>
                  <span style={{ fontWeight: 400 }}>{option.description}</span>

                  <Typography variant="body2" color="textSecondary">
                    {option.procedureCode}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    </div>
  );
}
