import React, { createContext, useState } from "react";
import { secondOpinionMock } from "./secondOpinionMock";

export const SecondOpinionContext = createContext();

const SecondOpinionContextProvider = props => {
  const [secondOpinionForm, setSecondOpinionForm] = useState(secondOpinionMock);
  const [secondOpinionPassword, setSecondOpinionPassword] = useState(null);
  const [wrongCredentials, setWrongCredentials] = useState(false);

  return (
    <SecondOpinionContext.Provider
      value={{
        secondOpinionForm,
        setSecondOpinionForm,
        secondOpinionPassword,
        setSecondOpinionPassword,
        wrongCredentials,
        setWrongCredentials
      }}
    >
      {props.children}
    </SecondOpinionContext.Provider>
  );
};

export default SecondOpinionContextProvider;
