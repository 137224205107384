import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";

const TextInput = (props) => {
  const {
    item,
    formState,
    handleChange,
    hasError,
    handleBlur,
    hideLabel,
    label,
    isDisabled,
    className,
    handleDoctor,
  } = props;

  var itemName = item.key;

  const [val, setVal] = useState(
    (formState && formState.values && formState.values[itemName]) || ""
  );

  return (
    <div className={item && className ? className : "answerBox"}>
      {!hideLabel ? (
        <div className="labelContainer">
          <p className="labelText">{label}</p>
        </div>
      ) : (
        <div></div>
      )}
      <div className={"textFieldContainer"}>
        <TextField
          id={`${itemName}`}
          name={`${itemName}`}
          label={item.displayLabel}
          className="textField"
          disabled={isDisabled}
          onChange={(event) => {
            event.persist();
            setVal(event.target.value);
            handleDoctor && handleDoctor(event);
          }}
          onBlur={(event) => {
            handleChange(event);
            handleBlur && handleBlur(event);
          }}
          error={hasError && hasError(itemName)}
          helperText={
            hasError && hasError(itemName)
              ? formState.errors[itemName][0]
              : null
          }
          value={val}
        />
      </div>
    </div>
  );
};

export default TextInput;
