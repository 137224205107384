import { Button, Card, Container, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import lscache from "lscache";
import React, { useEffect, useState } from "react";
import validate from "validate.js";
import hero6 from "../../../assets/images/hero-bg/hero-1.jpg";
import logoImage from "../../../assets/images/logo.png";
import loginFormSchema from "../../../Schemas/loginFormSchema";
import { beURL, REST } from "../../../Utils/index";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = (props) => {
  const classes = useStyles();

  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [tokenLocal, setTokenLocal] = useState(null);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  useEffect(() => {
    const errors = validate(formState.values, loginFormSchema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    // eslint-disable-next-line
  }, [formState.values]);

  useEffect(() => {
    if (tokenLocal) saveOperatorType();
    // eslint-disable-next-line
  }, [tokenLocal]);

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleSignIn = async (event) => {
    setWrongCredentials(false);

    event.preventDefault();
    if (formState.isValid) {
      await REST.post(`/spital/loginRegistratura`, formState.values)
        .then((answer) => {
          if (answer.data.token) {
            const accessToken = answer.data.token;
            lscache.set("accessToken", accessToken);

            setTokenLocal({
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json;charset=UTF-8",
              },
            });
          }
        })
        .catch((err) => {
          setWrongCredentials(true);

          if (err === "Error: Request failed with status code 401") {
            setWrongCredentials(true);
          }
        });
    } else {
      setWrongCredentials(true);
    }
  };

  const saveOperatorType = async () => {
    await REST.get(`${beURL}/spital/getUserStatus`, { ...tokenLocal })
      .then((answer) => {
        lscache.set("operatorType", answer.data);

        REST.get(`/spital/getUserProfile`, { ...tokenLocal })
          .then((answer) => {
            lscache.set("operatorName", answer.data.name);

            const configRedirectAfterLogin = {
              Registratura: {
                location: "/listare",
              },
              Medic: {
                location: "/listare",
              },
              SefSectie: {
                location: "/listare-ss",
              },
            };

            if (!answer.data.type) {
              setWrongCredentials(true);
            } else {
              redirect(
                answer.data.department.hospital.id,
                configRedirectAfterLogin[answer.data.type].location
              );
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {
        setWrongCredentials(true);
      });
  };

  const redirect = async (hospitalId, redirectTo) => {
    REST.post(`/spital/getHospitalById?hospitalId=${hospitalId}`)
      .then((answer) => {
        lscache.set("hospitalName", answer.data.hospitalName);
        window.location = redirectTo;
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="hero-wrapper w-100 bg-composed-wrapper bg-midnight-bloom min-vh-100">
          <div
            className="flex-grow-1 w-100 d-flex align-items-center"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="bg-composed-wrapper--image opacity-6"
              style={{ backgroundImage: "url(" + hero6 + ")" }}
            />
            <div className="bg-composed-wrapper--bg bg-second opacity-7" />
            <div className="bg-composed-wrapper--content p-3 p-md-5">
              <Container className={"container"} style={{ width: "600px" }}>
                <Card className="rounded-sm modal-content p-3 bg-white-10">
                  <Card className="rounded-sm overflow-hidden shadow-xxl font-size-sm p-3 p-sm-0">
                    <Grid container spacing={0} style={{ padding: "40px" }}>
                      <Grid
                        item
                        lg={12}
                        className="d-flex align-items-center justify-content-center flex-column"
                      >
                        <img src={logoImage} className={"logoImage"} alt={""} />
                        <form className={classes.form} noValidate>
                          <TextField
                            id="username"
                            name="username"
                            label="Username"
                            variant="outlined"
                            onChange={handleChange}
                            error={hasError("username")}
                            required
                            fullWidth
                            helperText={
                              hasError("username")
                                ? formState.errors.username[0]
                                : null
                            }
                            className={"loginFormTextInput"}
                            value={formState.values.username || ""}
                          />
                          <TextField
                            type="password"
                            required
                            fullWidth
                            id="password"
                            name="password"
                            label="Password"
                            variant="outlined"
                            onChange={handleChange}
                            error={hasError("password")}
                            helperText={
                              hasError("password")
                                ? formState.errors.password[0]
                                : null
                            }
                            className={"loginFormTextInput"}
                            value={formState.values.password || ""}
                          />
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={"singInButton"}
                            onClick={handleSignIn}
                          >
                            Sign In
                          </Button>
                          {wrongCredentials ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "red",
                                fontWeight: "bold",
                                marginTop: "15px",
                              }}
                            >
                              Date de conectare incorecte!
                            </div>
                          ) : null}
                        </form>
                      </Grid>
                    </Grid>
                  </Card>
                </Card>
              </Container>
            </div>
          </div>
          <div className="hero-footer w-100 pb-4" />
        </div>
      </div>
    </>
  );
};

export default Login;
