export const typeTitles = {
  congenital: "Boli",
  whereIsThePain: "Ce va doare / ce va supara",
  alergies: "Alergii",
  inGenTreatment: "Tratament specific",
  inAntiCoagulantTreatment: "Tratament cu anticoagulante",
  inOsteoTreatment: "Tatament impotriva osteoporozei",
  hadSurgery: "Interventii chirurgicale",
  anesthezis: "Anestezia",
  anesthezisIssues: "Probleme legate de anestezie",
  prevTreatmentIssues: "Complicatii la tratamentele sau anesteziile anterioare",
  peachemaker: "Pacemaker",
  bloodType: "Grupa sangvina",
  rh: "RH-ul",
  alcoholTobacco: "Alcool sau tutun",
  selectData: "Unde a fost internat:",
  spec: "Specializarea",
  spital: "Spital",
  medic: "Medic",
  procedureCode: "Codul Drg",
  code: "Codul Drg",
  files: "Documente",
  interpretare: "Interpretare",
  number: "Numar DRG",
  description: "Descriere Drg",
  otherSurgicalInterventions: "Alte interventii chirurgicale",
  concomitantWithMainSurgery:
    "Interventii chirurgicale concomitente (cu cea principala)",
  mainSurgery: "Interventia chirurgicala principala",
  intraHospitalTransfer: "Transfer intre sectile spitalului",
  ifCaseDeceased: "Diagnostic in caz de deces",
  anamneza: "Anamneza",
  oncologicExam: "Examen Oncologic",
  // confirmData: "Data Confirmarii",
  supportOfDiagAndTreament: "Sustinerea diagnosticului si tratamentului",
  finalConfirmDate: "Data Confirmarii",
  finalConfirmDate_hour: "Data Confirmarii",
  finalConfirmDate_date: "Data Confirmarii",
  //documents: "Documente",
  sex: "Sex",
  intraDeathDate: "Data Decesului",
  drg: "Codul DRG",
  consent: "Consimtamantul pentru interventie",
  mainMedic: "Medic operator principal",
  secondMedic: "Medic operator II",
  thirdMedic: "Medic operator III",
  assistant: "Asistent/a",
  atiMedic: "Medic ATI",
  surgeryDate: "Ziua Operatiei",
  intraDischargeState: "Starea la externare",
  intraDischargeType: "Tipul externarii",
  intraDeath: "Deces",
  directCause: "Cauza directa (imediata)",
  anteCause: "Cauza antecedenta",
  initialMorbidState: "Stari morbide initiale",
  otherMorbidState: "Alte stari morbide importante",
  admissionDiagnostic: "Diagnosticul la internare",
  threeDayDiagnostic: "Diagnosticul la 72 de ore",
  referralDiagnostic: "Diagnosticul de trimitere",
  secondDischargeDiagnostic:
    "Diagnostice secundare la externare (complicatii / comorbiditati)",
  //casNumber: "Numar CAS",
  //socialSecurityNr: "Nr. carnet asigurat",
  admissionType: "Tipul internarii",
  mainDischargeDiagnostic: "Diagnosticul principal la externare",
  //signMainPhysicianSef: "Semnatura si parafa medicului sef",
  //signMainPhysicianCurant: "Semnatura si parafa medicului curant",
  cytologicExamination: "Examen citologic",
  extemporaneousExamination: "Examen extemporaneu",
  histopathologyExamination:
    "Examen histopatologic (biopsie - piesa peratorie)",
  AdmissionDate: "Data internarii",
  DischargeDate: "Data iesirii",
  NrOfDays: "Nr. zile spit.",
  Department: "Sectia",
  AdmissionHour: "Ora internarii",
  DischargeHour: "Ora iesirii",
  anatomoPatologicDiagnostic:
    "Diagnostic anatomo-patologic (autopsie), macroscopic",
  microscopic: "Microscopic",
  morphologicCode: "Codul morfologic (in caz de cancer)",
  otherObs: "Alte Observatii",
  reasonForHospitalization: "Motivele internarii",
  heredoColateral: "Antecedente heredo-colaterale",
  personal: "Antecedente personale, fiziologice si patologice",
  conditions: "Conditii de viata si munca",
  behavior: "Comportamente (fumat, alcool etc.)",
  medication:
    "Medicatie de fond administrata inaintea internarii (inclusiv preparate hormonale si imunosupresoare)",
  diseaseHistory: "Istoricul Bolii",
  generalClinicExam: "Examenul clinic general",
  objectivExam: "Examen Obiectiv",
  generalState: "Starea generala",
  nutritionState: "Starea de nutritie",
  facies: "Facies",
  mucous: "Mucoase",
  fanere: "Fanere",
  adiposeConnectiveTissue: "Tesut conjunctiv-adipos",
  nodalSystem: "Sistem ganglionar",
  muscularSystem: "Sistem muscular",
  osteoSystem: "Sistem osteo-articular",
  respiratorySystem: "Aparat Respirator",
  cardioVascularSystem: "Aparat Cardiovascular",
  digestiveSystem: "Aparat Digestiv",
  abdominalOrgans: "Ficat, Cai bileare, Splina",
  uroGenitalSystem: "Aparat uro-genital",
  nervousAndTactilSystem: "Sistem nervos, endocrin, organe de simt",
  mouth: "Cavitatea bucala",
  teguments: "Tegumente",
  ganglia: "Grupe ganglioni palpabile",
  breast: "San",
  genitalsWomen: "Organe genitale feminine",
  vaginalCytology: "Citologia secretiei vaginale",
  prostateRectum: "Prostata si rect",
  others: "Altele",
  otherSpecialtyExams: "Alte examene de specialitate",
  laboratoryExams: "Examene de laborator",
  radiologyExams: "Examene radiologice(rezultate)",
  ecographExams: "Examene ecografice(rezultate)",
  surgeryInterventions:
    "Interventii chirurgicale(numarul interventiei chirurgicale, protocol operator)",
  anatomoPatoExams: "Examene anatomo-patologice",
  clinic: "Clinic",
  paraclinic: "Paraclinic",
  epicrisis: "Epicriza",
  //signAttendingPhysician: "Semnatura si parafa medicului",
  radiologicInvestigations: "Investigatii radiologice",
  funcExplorations: "Explorari functionale",

  intraDeathHour: "Ora Decesului",
  pacientDocuments: "Documentele urcate de pacient",
};
