import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React from "react";
import { useAti } from "../../../../../../hooks";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const CoordonareaIngrijiriiTable = () => {
  const ctx = useAti();
  const classes = useStyles();

  return (
    <>
      {ctx.rState.acc7.values.careCoordanation && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell align="right">Conculzie</TableCell>
                <TableCell align="right">Obiectiv / Interventii</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ctx.rState.acc7.values.careCoordanation &&
                ctx.rState.acc7.values.careCoordanation.map((row, index) => {
                  return (
                    <TableRow
                      key={row.name}
                      style={
                        index % 2
                          ? { background: "#fdffe0" }
                          : { background: "white" }
                      }
                    >
                      <TableCell component="th" scope="row">
                        {(row.date && moment(row.date).format("DD-MM-YYYY")) ||
                          "--"}
                      </TableCell>

                      <TableCell align="right">
                        {(row.conclusion && row.conclusion.toString()) || "--"}
                      </TableCell>
                      <TableCell align="right">
                        {(row.purpose && row.purpose.toString()) || "--"}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default CoordonareaIngrijiriiTable;
