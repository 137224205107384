const diagnosticTrimitere = [
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "referralDiagnostic",
    label: "Diagnosticul de trimitere:",
    answer: {
      type: "AutocompleteDRGInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "admissionDiagnostic",
    label: "Diagnosticul la preluarea in serviciu:",
    answer: {
      type: "AutocompleteDRGInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "threeDayDiagnostic",
    label: "Diagnosticul la 72 de ore de la preluare:",
    answer: {
      type: "AutocompleteDRGInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "mainDischargeDiagnostic",
    label: "Diagnosticul principal la iesirea din serviciu:",
    answer: {
      type: "AutocompleteDRGInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "secondDischargeDiagnostic",
    label:
      "Diagnostice secundare la iesirea din serviciu (complicatii / comorbiditati):",
    answer: {
      type: "MultipleDRGTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "signMainPhysician",
    label: "Semnatura si parafa medicului sef",
    answer: {
      type: "MultipleSignTextInput",
      required: true,
      selectList: [],
      selectLabelList: [
        "Semnatura si parafa medicului sef",
        "Semnatura si parafa medicului curant"
      ],
      additionalName: ["Sef", "Curant"]
    }
  }
];

export default diagnosticTrimitere;
