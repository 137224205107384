import React, { useState } from "react";
import { REST } from "../../Utils/index";
import { Button, TextField, Modal } from "@material-ui/core";

const AskForSecondOpinion = (props) => {
  const { ticketId } = props;
  const [modal, setModal] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hidePhonePrefix = () => {
    const phonePrefix = document.getElementById("phonePrefix");
    phonePrefix.style.display = "none";
  };

  const handlePhoneBlur = () => {
    const phonePrefix = document.getElementById("phonePrefix");
    if (formState.values.phone) {
      phonePrefix.style.display = "inline";
    } else {
      phonePrefix.style.display = "none";
    }
  };

  const handlePhoneFocus = () => {
    const phonePrefix = document.getElementById("phonePrefix");

    phonePrefix.style.display = "inline";
  };

  const handleSentRequest = async (event) => {
    event.preventDefault();

    await REST.post(
      `/spital/secondOpinion/sendToDoctor?doctorEmail=${formState.values.email}&phoneNumber=${formState.values.phone}&hours=${formState.values.hours}&ticketID=${ticketId}`
    )
      .then((answer) => {
        setFormState(() => ({
          values: {
            email: "",
            phone: "",
            hours: "",
          },
        }));
        setModal(false);
        hidePhonePrefix();
      })
      .catch((err) => {});
  };

  const addModal = () => {
    setModal(!modal);
  };

  const closeModal = () => {
    setModal(!modal);
  };

  return (
    <div className="askForSecondOpinionContainer">
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className="showAskForSecondOpinionModalBtn"
        onClick={addModal}
      >
        Trimite catre un coleg
      </Button>

      <Modal
        open={modal}
        onClose={closeModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(3px)",
          width: "100%",
          height: "100%",
        }}
      >
        <div className="askForSecondOpinionModal">
          <div className="askForSecondOpinionCloseContainer">
            <p className="askForSecondOpinionClose" onClick={closeModal}>
              x
            </p>
          </div>

          <div className="askForSecondOpinionTitleContainer">
            <h5 className="askForSecondOpinionTitle">Trimite catre un coleg</h5>
          </div>
          <div className="askForSecondOpinionElements">
            <p className="askSecondOpinionEmailLabel">Numar telefon</p>

            <p className={"askSecondOpinionPhoneText"} id="phonePrefix">
              +4
            </p>
            <TextField
              required
              id="phone"
              name="phone"
              label="Telefon"
              onChange={handleChange}
              className={"askSecondOpinionPhone"}
              onFocus={handlePhoneFocus}
              onBlur={handlePhoneBlur}
              value={formState.values.phone || ""}
            />

            <p className="askSecondOpinionEmailLabel">Adresa de e-mail</p>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              onChange={handleChange}
              className={"askSecondOpinionEmail"}
              value={formState.values.email || ""}
            />
            <p className="askSecondOpinionEmailLabel">
              Cat timp doriti ca link-ul sa fie activ?
            </p>

            <TextField
              required
              id="hours"
              name="hours"
              label="Ore"
              onChange={handleChange}
              className={"askSecondOpinionHour"}
              value={formState.values.hours || ""}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={"askSecondOpinionSubmit"}
              onClick={handleSentRequest}
            >
              Trimite
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AskForSecondOpinion;
