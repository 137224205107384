const holisticMock = [
  {
    key: "paraclinicalInvestigations",
    label: "Investigatii paraclinice",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "epicrisis",
    label: "Epicriza:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },

  {
    label: "Recomandari la externare/transfer",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
    },
  },

  {
    key: "treatment",
    label: "Tratament",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "recipe",
    label: "Reteta(se vor specifica medicamentele prescrise)",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },

  {
    title: "",
    key: "sickLeave",
    label: "Concediu Medical",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "others",
    label: "Altele(recomandari, precizatii)",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    title: "",
    key: "date",
    label: "Data (ZZ/LL/AAAA)",
    answer: {
      type: "DatePickerInput",
      required: true,
    },
  },
  {
    key: "doctor",
    label: "Medic",
    answer: {
      type: "SignTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "department",
    label: "Departament / Sectie",
    answer: {
      type: "SignTextInput",
      required: true,
      selectList: [],
    },
  },
  // {
  //   key: "signAttendingPhysician",
  //   label: "Semnatura si parafa medicului",
  //   answer: {
  //     type: "SignTextInput",
  //     required: true,
  //     selectList: [],
  //   },
  // },
];

export default holisticMock;
