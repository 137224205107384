import React, { useEffect, useState,useContext } from "react";
import { getHistoryPathArray } from "../../../helpers";
import { REST } from "../../../Utils";
import TipBForm from "./components/TipBForm/TipBForm";
import { FormStateContext } from "context/formStateContext";

const Chestionar_SefSectie = (props) => {
  const { history } = props;
  const { userDataContext = [] } = useContext(FormStateContext);
  const userData = userDataContext?.data;
  // eslint-disable-next-line
  const [error, setError] = useState({ message: null });
  const [data, setData] = useState("");
  const [ticketId, setTicketId] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    setIsPageLoading(false)
  }, [userData]);

  const getData = async (entryId) => {
    await REST.get(`/spital/getTicketById/?id=${entryId}`)
      .then((answer) => {
        setError({ message: null });

        if (!answer.data.form) {
          window.location = "/listare-ss";
        }

        setData(answer.data.form);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          setError({
            message,
          });
        }
      });
  };

  useEffect(() => {
    const entryId = getHistoryPathArray(history).slice(-1);

    setTicketId(entryId);
    // getUserData();
    getData(entryId);
    // eslint-disable-next-line
  }, []);

  if (isPageLoading) {
    return <></>;
  } else {
    return (
      <div className={"chestionarContent"}>
        <h1>
          Fisa - {data && data.lastName} {data && data.firstName} -{" "}
          {data && data.CNP}
        </h1>
        {data && ticketId && (
          <TipBForm data={data} ticketId={ticketId} userData={userData} />
        )}
      </div>
    );
  }
};

export default Chestionar_SefSectie;

