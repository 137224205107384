import clsx from "clsx";
import { Button as Btn, Card, Collapse } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MonitorizarePacientForm from "./MonitorizarePacient.form";
import React from "react";

const Accordion13 = (props) => {
  const {
    history,
    location,
    ticketId,
    userData,
    toggleAccordion,
    accordionStates,
  } = props;

  return (
    <Card
      className={clsx("card-box", {
        "panel-open": accordionStates[`accordion20`],
      })}
    >
      <Card>
        <div className="card-header">
          <div className="panel-title">
            <div className="accordion-toggle">
              <Btn
                variant="text"
                size="large"
                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                onClick={() => toggleAccordion(20)}
                aria-expanded={accordionStates[`accordion20`]}
              >
                <span>Monitorizare Pacient</span>
                <FontAwesomeIcon
                  icon={["fas", "angle-up"]}
                  className="font-size-xl accordion-icon"
                />
              </Btn>
            </div>
          </div>
        </div>
        {accordionStates[`accordion20`] && (
          <Collapse in={accordionStates[`accordion20`]}>
            <div className={"accordion_body"}>
              <MonitorizarePacientForm
                history={history}
                location={location}
                sheetId={ticketId}
                formState={userData}
              />
            </div>
          </Collapse>
        )}
      </Card>
    </Card>
  );
};

export default Accordion13;
