import { all } from "../../../../Utils/spitalList";

const internareDate = [
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "admissionDate",
    label: "Data internarii:",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "dischargeDate",
    label: "Data externarii:",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: []
    }
  },
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "weightNewBorn",
    label: "Greutatea la nastere (nou nascuti):",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  },
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "profession",
    label: "Ocupatia:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "workPlace",
    label: "Locul de munca",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  },
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "hospitalizationDays",
    label: "Nr. zile spitalizare",
    question: "Un nr. de tel si o adresa de mail?",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "hospitalizationSickLeave",
    label: "Nr. zile c. m. spitalizare",
    question: "Un nr. de tel si o adresa de mail?",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "identityNumber",
    label: "Numar CI",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  },
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "birthSerialNumber",
    label: "Numar",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "ciCounty",
    label: "Judetul:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "registrationNr",
    label: "Nr. inregistrare:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  },

  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "hospitalCity",
    label: "Localitate",
    value: "",
    answer: {
      // type: "DisabledTextInput",
      type: "TextInput",
      required: true,
      selectList: [],
      disabled: false
    }
  },
  {
    key: "hospitalName",
    label: "Spitalul",
    value: "",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      disabled: false
    }
  },
  {
    key: "hospitalDepartment",
    label: "Sectia",
    value: "",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      disabled: false
    }
  },

  {
    key: "doctorSeal",
    label: "Intocmit de:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: all
    }
  },

  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    // key: "criteriuEligibilitatePreluarePaliatie",
    key: "eligibilityCriteria",
    label: "Criteriu eligibilitate pentru preluare in serviciu de paliatie:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: all
    }
  }
];

export default internareDate;
