import React, { useState } from "react";
import { RenderAtiAccordionNonTableComponent } from "../../../../../../components/componentsAti";
import { accordionConfigs } from "../../../../../../context/hydrateAccordionsFactory.helper";
import { addToArrayHelper } from "../../../../../../helpers";
import { useAti } from "../../../../../../hooks";
import { REST } from "../../../../../../Utils";
import Button from "../13_EvolutionList/Buttons1";
import Mocks from "./Mocks/formMock";
import MonitorizareTable from "./MonitorizarePacient.table";

const MonitorizarePacient = (props) => {
  const ctx = useAti();
  const ctxAccKey = "acc20";

  const { sheetId } = props;

  const [valueSecondDD, setValueSecondDD] = useState([]);
  const [valueThirdDD, setValueThirdDD] = useState([]);
  const [formState, setFormState] = useState({
    date: null,
    alimentationProgram: [],
    hydratation: [],
    stipple: [],
    patchs: null,
    position: [],
  });

  const handleChange = (event) => {
    if (event.target.value) {
      setFormState((prevState) => {
        const stateByType =
          event.target.name === "date"
            ? { date: event.target.value }
            : event.target.name === "patchs"
            ? { patchs: event.target.value }
            : {
                [event.target.name]: addToArrayHelper(
                  prevState[event.target.name],
                  event.target.value
                ),
              };

        return {
          ...prevState,
          ...stateByType,
        };
      });
    }
  };

  const changeHandler = (event) => {
    handleChange(event);
  };

  const addToCollection = () => {
    ctx.dispatch({
      type: accordionConfigs[ctxAccKey].accNo,
      payload: formState,
      payloadKey: accordionConfigs[ctxAccKey].accKey,
      isPayloadForTable: true,
    });

    postCollection();
  };

  const postCollection = async () => {
    await REST.post(`/ati/addMonitoring?sheetId=${sheetId}`, formState);
  };

  return (
    <>
      <RenderAtiAccordionNonTableComponent
        Mocks={Mocks}
        ctxState={ctx.rState}
        ctxAccKey={ctxAccKey}
        changeHandler={changeHandler}
        setValueSecondDD={setValueSecondDD}
        setValueThirdDD={setValueThirdDD}
        valueSecondDD={valueSecondDD}
        valueThirdDD={valueThirdDD}
        sheetId={sheetId}
      >
        <Button onClick={addToCollection} text="ADAUGA"></Button>
      </RenderAtiAccordionNonTableComponent>
      <MonitorizareTable />
    </>
  );
};

export default MonitorizarePacient;
