const evolutieSimptome = [
  {
    title: "",
    key: "date",
    label: "Data",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },

  {
    // label: "Domiciliul legal:",
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "durere",
    label: "Durere",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      isDisabled: false
    }
  },

  {
    key: "fatigabilitate",
    label: "Fatigabilitate",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      isDisabled: false
    }
  },

  {
    key: "stareDeBine",
    label: "Stare de bine",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      isDisabled: false
    }
  },

  {
    key: "dispnee",
    label: "Dispnee",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      isDisabled: false
    }
  },

  {
    label: "",
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "inapetenta",
    label: "Inapetenta",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      isDisabled: false
    }
  },

  {
    key: "greata",
    label: "Greata/Voma",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      isDisabled: false
    }
  },

  {
    key: "constipatie",
    label: "Constipatie",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      isDisabled: false
    }
  },

  {
    key: "anxietate",
    label: "Anxietate",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      isDisabled: false
    }
  },

  {
    key: "depresie",
    label: "Depresie",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      isDisabled: false
    }
  },

  {
    key: "insomnie",
    label: "Insomnie",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      isDisabled: false
    }
  }
];

export default evolutieSimptome;
