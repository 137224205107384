import React from "react";
import {
  BackToTop,
  FloatingSave,
} from "../../../../../components/componentsAti";
import { useAti } from "../../../../../hooks";
import { REST } from "../../../../../Utils";
import Button from "../../../Chestionar/components/EvolutionList/Buttons1";

const SaveBtns = () => {
  const ctx = useAti();

  const handleSubmit = async () => {
    const body = ctx.state.firstAccordion.values;

    await REST.post(`/ati/createPatient`, body)
      .then((answer) => {
        REST.post(`ati/createAtiSheet?patientId=${answer.data.id}`, body)
          .then((answer) => {
            window.location = "/listare";
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className={"sendForm_btn"}>
        <Button onClick={handleSubmit} text="SALVEAZA"></Button>
      </div>
      <BackToTop />
      <FloatingSave handleSubmit={handleSubmit} />
    </>
  );
};

export default SaveBtns;
