import React from "react";
import { Grid } from "@material-ui/core";

const SpO2Button = props => {
    const { vitals } = props;
    return (
        <Grid container className="widgetContainer">
            <Grid item xs={4} md={4}>
                <div className="widgetTextSp02-fp">SpO2</div>
            </Grid>
            <Grid item xs={8} md={8}>
                <div className="widgetValueSp02-fp">
                    {vitals && vitals.data && vitals.data["VITAL SpO2"] ? (
                        vitals && vitals.data && vitals.data["VITAL SpO2"]
                    ) : (
                        <div className="missingValue"> - </div>
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default SpO2Button;