import {
  Button,
  makeStyles,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import lscache from "lscache";
import * as moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { REST } from "../../Utils/index";
import BedItem from "./Components/BedItem";
import BedManagement from "./Components/BedManagement";
import YearPicker from "./Components/YearPicker";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  head: {
    backgroundColor: "#fff",
    minWidth: "120px",
  },
  tableContainer: {
    maxHeight: "100%",
    display: "flex",
    flexdirection: "row",
  },
  cell: {
    minWidth: "100px",
  },
  cellTds: {
    minWidth: "100px",
    border: "1px solid",
  },
  bed: {
    minWidth: "100px",
    display: "flex",
    flexdirection: "row",
  },
  headerContainer: {
    display: "flex",
    flexdirection: "row",
    justifyContent: "space-between",
  },
}));

const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#d1d7e5",
    color: theme.palette.common.black,
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    backgroundColor: "#d1d7e5",
    minWidth: "70px",
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#d1d7e5",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Paturi = (props) => {
  const {
    formState,
    ticketId,
    item,
    hasError,
    showOkButton,
    updateBeds,
    forceUpdate,
    rerenderFraier,
    setRerenderFraier,
    assignToMedic,
    scrollToView
  } = props;

  const finalConfirmDate = moment(formState.values.finalConfirmDate).format(
    "DD-MMM-YYYY"
  );
  const dateToSplit = finalConfirmDate.split("-");
  const monthForRequest = moment(new Date()).format("MM");
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);
  const [dates, setDates] = useState([]);
  const [year, setYear] = useState(dateToSplit[2]);
  const [month, setMonth] = useState(monthForRequest);
  const [bedModal, setBedModal] = useState(false);
  const [availableBeds, setAvailableBeds] = useState(0);
  const [assignedBeds, setAssignedBeds] = useState(0);
  const [drId, setDrId] = useState(0);
  // eslint-disable-next-line
  const [patientId, setPatientId] = useState(0);
  const [tableTitle, setTableTitle] = useState(false);
  const [toggleForGetData, setToggleForGetData] = useState(false);

  const getDrId = (e) => {
    setDrId(e);
  };

  const getData = async () => {
    const spec =
      (formState.values.selectData && formState.values.selectData.spec) ||
      lscache.get("dpt");
    if (month || monthForRequest) {
      await REST.get(
        `/spital/getMedicalBedsRepartition?date=${year}/${
          month ? month : monthForRequest
        }/1&departmentId=${spec}&ticketId=${formState.ticketId || ticketId}`
      )
        .then((answer) => {
          setBedModal(false);
          setData(answer.data);
          setDates(answer.data);
          if (window.location.pathname === "/listare-ss") {
            setTableTitle(true);
            scrollToView();
          } else {
            setTableTitle(false);
          }
        })
        .catch((err) => {});
    }
  };

  const totalBedsNr = async () => {
    await REST.get(
      `/spital/getNumberOfFreeBedsByDepartment?departmentId=${(formState.values
        .selectData &&
        formState.values.selectData.spec) ||
        lscache.get("dpt")}`
    )
      .then((answer) => {
        setAvailableBeds(answer.data);
      })
      .catch((err) => {});
  };

  const changeMonth = (newMonth) => {
    setMonth(newMonth);
  };

  const getCurrentDay = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "/" + mm + "/" + dd;
    return today;
  };

  const getDrBeds = async (id) => {
    await REST.get(
      `/spital/getNumberOfBedsByDoctorAndCurrentDate?doctorId=${id}&startDate=${getCurrentDay()}`
    )
      .then((answer) => {
        setAssignedBeds(answer.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    totalBedsNr();
    setTimeout(() => {
      getData();

      if (dateToSplit.length) {
        const thCollection = document.querySelectorAll(".thuri");
        const thList = Array.prototype.slice.call(thCollection);

        let tempDate = dateToSplit[0];

        if (tempDate < "10") {
          tempDate = tempDate.slice(1);
        }

        const tdCollectionRegular = document.querySelectorAll(`.tduri-general`);
        const tdRegularList = Array.prototype.slice.call(tdCollectionRegular);

        const tdCollectionHighilighted = document.querySelectorAll(
          `.tduri-${tempDate}`
        );
        const tdHighlightedList = Array.prototype.slice.call(
          tdCollectionHighilighted
        );

        // eslint-disable-next-line
        thList.map((item) => {
          item.classList.remove("focusForAppointment");
        });

        // eslint-disable-next-line
        tdRegularList.map((item) => {
          item.classList.remove("focusForAppointment");
        });

        // eslint-disable-next-line
        tdHighlightedList.map((item) => {
          item.classList.add("focusForAppointment");
        });

        const element = document.getElementById(`th-paturi-${tempDate}`);

        if (element) {
          element.scrollIntoView({ inline: "center" });
          element.classList.add("focusForAppointment");
        }
      }
      if (window.location.pathname === "/listare-ss") {
        return null
      }else {
        window.scrollTo(0, 0);
      }
    }, 1500);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [month || year || formState]);

  const classes = useStyles();

  const myDates = dates.map((n) => {
    n.beds.sort((a, b) => {
      let f = new Date(a.day);
      let s = new Date(b.day);
      return f - s;
    });
    return n.beds.map((y, index) => {
      return (
        <StyledTableCell
          key={`style_table_cell_${index}`}
          className={`${classes.head} thuri`}
          id={`th-paturi-${index + 1}`}
        >
          {moment(y.day).format("DD-MMM")}
        </StyledTableCell>
      );
    });
  });

  const chageYear = (year) => {
    setYear(year);
  };

  const addBedModal = () => {
    setBedModal(!bedModal);
  };

  const closeModal = async () => {
    await REST.post(`callcenter/removeDummyBeds`)
      .then(async (answer) => {
        setRerenderFraier(rerenderFraier + 2);
        getData();
        setBedModal(!bedModal);
        updateBeds();
        forceUpdate();
      })
      .catch((err) => {});
  };

  const handlePatientId = (id) => {
    setPatientId(id);
  };

  let keyDate = dateToSplit[0];

  const spec =
    (formState.values.selectData && formState.values.selectData.spec) ||
    lscache.get("dpt");

  const dummyHospitalizePatients = async () => {
    await REST.post(`callcenter/dummyHospitalizePatients`)
      .then(async (answer) => {
        forceUpdate();
        setRerenderFraier(rerenderFraier + 1);
        await REST.get(
          `/spital/getMedicalBedsRepartition?date=${year}/${monthForRequest}/1&departmentId=${spec}&ticketId=${ticketId}`
        )
          .then((answer) => {
            setData(answer.data);
            setDates(answer.data);
          })
          .catch((err) => {});
        // TODO:
        enqueueSnackbar("Patul cu ID 97 a fost ocupat !");
        enqueueSnackbar("Patul cu ID 98 a fost ocupat !");
        setTimeout(() => {
          removeDummyBeds();
        }, 5000);
      })
      .catch((err) => {});
  };

  const removeDummyBeds = async () => {
    const spec = formState.values.selectData.spec;
    await REST.post(`callcenter/removeDummyBeds`)
      .then(async (answer) => {
        setRerenderFraier(rerenderFraier + 2);

        await REST.get(
          `/spital/getMedicalBedsRepartition?date=${year}/${monthForRequest}/1&departmentId=${spec}&ticketId=${ticketId}`
        )
          .then((answer) => {
            setData(answer.data);
            setDates(answer.data);
          })
          .catch((err) => {});
        // TODO:
        enqueueSnackbar("Patul cu ID 97 a fost eliberat !");
        enqueueSnackbar("Patul cu ID 98 a fost eliberat !");
        updateBeds();
        forceUpdate();
      })
      .catch((err) => {});
  };

  return (
    <div>
      {tableTitle ? (
        <h3>
          Atribuie pacientul ( {formState && formState.values.firstName}{" "}
          {formState && formState.values.lastName}) altui doctor{" "}
        </h3>
      ) : (
        <h3>
          Evidenta Paturi{" "}
          {(formState.values.repData && formState.values.repData.spec) ||
            lscache.get("dpt")}
        </h3>
      )}
      <div className={classes.headerContainer}>
        <div>
          <YearPicker
            getMonth={changeMonth}
            getYear={chageYear}
            monthSplit={dateToSplit[8]}
            yearSplit={dateToSplit[2]}
          />
        </div>
        <div style={{ marginRight: "1rem" }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={"singInButton"}
            onClick={addBedModal}
          >
            Adauga pat
          </Button>
        </div>

        {!tableTitle ? (
          <div style={{ marginRight: "1rem" }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={"singInButton"}
              onClick={dummyHospitalizePatients}
            >
              refresh paturi
            </Button>
          </div>
        ) : null}
      </div>

      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StickyTableCell className={classes.head}>
                <div className={classes.cell}>Nume Doctor</div>
              </StickyTableCell>
              {myDates[0]}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((n, lindex) => {
                return (
                  <StyledTableRow key={`${n.id}_${lindex}`}>
                    <StickyTableCell>
                      <div className={classes.cell}>{n.medic.doctorName}</div>
                    </StickyTableCell>
                    {n.beds.map((item, index) => {
                      return (
                        <StyledTableCell
                          align="center"
                          className={`${
                            classes.cellTds
                          } tduri-general tduri-${index + 1}`}
                          id={`td-paturi-${lindex}-${index + 1}`}
                          key={`td-paturi-${lindex}-${index + 1}`}
                        >
                          <div
                            key={`bedItem-${lindex}-${index + 1}`}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              cursor: "pointer",
                            }}
                          >
                            {item["medBeds"].map((bed) => {
                              const selectedDay = item["day"];
                              return (
                                <BedItem
                                  key={bed.bedId + keyDate + bed.doctorId}
                                  item={bed}
                                  formState={formState}
                                  handlePatientId={handlePatientId}
                                  bedDate={keyDate}
                                  rerenderFraier={rerenderFraier}
                                  getData={getData}
                                  year={year}
                                  monthForRequest={monthForRequest}
                                  spec={spec}
                                  ticketId={ticketId}
                                  assignToMedic={assignToMedic}
                                  selectedDay={selectedDay}
                                  toggleForGetData={toggleForGetData}
                                  setToggleForGetData={setToggleForGetData}
                                />
                              );
                            })}
                          </div>
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={bedModal}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(3px)",
          width: "100%",
          height: "100%",
        }}
      >
        <div>
          <BedManagement
            item={item}
            formState={formState}
            data={data}
            getAvailableBeds={availableBeds}
            hasError={hasError}
            showOkButton={showOkButton}
            getDrId={getDrId}
            assignedBeds={assignedBeds}
            getDrBeds={getDrBeds}
            drId={drId}
            closeModal={closeModal}
            setBedModal={setBedModal}
            getData={getData}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Paturi;
