import { useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    height: "100%",
  },
}));

const SecondOpinionLayout = (props) => {
  const { location, children } = props;

  const pageName = location.pathname.split("/")[1] || "homepage";

  const classes = useStyles();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <div className={`pageWrapper ${pageName}`}>
        <main className={classes.content}>{children}</main>
      </div>
    </div>
  );
};

SecondOpinionLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default SecondOpinionLayout;
