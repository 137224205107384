import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { REST } from "../../Utils";

const DropzonePictures = (props) => {
  const { ticketId, handleShowFiles } = props;
  const [imgs, setImgs] = useState([]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      if (file.type.match("image.*")) {
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
          setImgs([...imgs, encoded]);
        };
      }
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadFiles = async (fileBase64StringArray) => {
    await REST.post(
      `/spital/upload?ticketId=${ticketId}`,
      fileBase64StringArray
    )
      .then((answer) => {
        // handleSetPictureState(answer.data);
        handleShowFiles(answer.data);
      })

      .catch((err) => {});
  };

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const promiseAccumulator = [];
      setFiles(
        acceptedFiles.map((file) => {
          promiseAccumulator.push(getBase64(file));

          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        })
      );

      Promise.all(promiseAccumulator).then((values) => {
        uploadFiles(values);
      });
      sendFiles();
    },
  });

  const sendFiles = () => {
    handleShowFiles(files);
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p
          style={{
            cursor: "pointer",
            border: "1px dashed black",
            borderRadius: "5px",
            padding: "5px",
            textAlign: "center",
          }}
        >
          Da click pentru a adauga poze sau trage-le aici.
        </p>
      </div>
      {/* <aside style={thumbsContainer}>{thumbs}</aside> */}
    </section>
  );
};

export default DropzonePictures;
