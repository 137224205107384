import { Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import moment from "moment";
import React, { useEffect, useState } from "react";

const SurgeryRoomIntervals = ({
  intervalsData,
  setIntervalsData,
  setSelectedInterval,
}) => {
  // eslint-disable-next-line
  const [value, setValue] = useState(0);

  useEffect(() => {}, []);

  const selectInterval = (roomIndex, slotIndex) => {
    let newArr = [...intervalsData];

    let clone = newArr.map((room, i) => {
      return i === roomIndex
        ? {
            ...room,
            appointments: room.appointments.map((app, j) => {
              let cloneApp = null;
              if (j === slotIndex) {
                cloneApp = { ...app, selected: !app.selected };
                if (cloneApp.selected === true) {
                  setSelectedInterval({
                    interval: cloneApp,
                    roomId: room.roomId,
                  });
                } else {
                  setSelectedInterval({
                    interval: null,
                    roomId: null,
                  });
                }
              } else {
                cloneApp = { ...app, selected: false };
              }
              return cloneApp;
            }),
          }
        : {
            ...room,
            appointments: room.appointments.map((app) => {
              return { ...app, selected: false };
            }),
          };
    });
    setIntervalsData(clone);
  };

  const handleChangeTab = (event, newValue) => {
    let newArr = [...intervalsData];

    let clone = newArr.map((room, i) => {
      return {
        ...room,
        appointments: room.appointments.map((app) => {
          return { ...app, selected: false };
        }),
      };
    });
    setIntervalsData(clone);

    setValue(newValue);
  };

  return (
    <Grid container className="surgeryRoomsContainer">
      <AppBar position="static" color="default" className="tabs-header">
        <Tabs value={value} onChange={handleChangeTab} className="tabs-header">
          {intervalsData &&
            intervalsData.map((room) => (
              <Tab
                label={`${room.roomName}`}
                key={`roomTab--${room.roomName}`}
              />
            ))}
        </Tabs>
      </AppBar>

      {intervalsData && intervalsData.length > 0 ? (
        intervalsData.map((room, i) => {
          return (
            i === value && (
              <div
                className="intervalsContainer"
                key={`room--${room.roomName}--intervals`}
              >
                {room.appointments.map((app, j) => {
                  return (
                    <div
                      className={`timeFrame ${app.selected && `selected`}`}
                      key={`timeFrame--room--${room.roomName}--${j}`}
                      onClick={() => selectInterval(i, j)}
                    >
                      {`${moment(app.start).format("HH:mm")} -
                      ${moment(app.end).format("HH:mm")}`}
                    </div>
                  );
                })}
              </div>
            )
          );
        })
      ) : (
        <div className="intervalsContainer">Niciun interval disponibil</div>
      )}
    </Grid>
  );
};

export default SurgeryRoomIntervals;
