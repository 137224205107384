import { Grid } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import surgeryImg from "../../../assets/images/surgery/surgeryRoom.png";
import { slugify } from "../../../helpers";
import { REST } from "../../../Utils";
import AvailabilityTable from "./Components/AvailabilityTable";
import CalendarComponent from "./Components/CalendarComponent";
import { OperatingRoomsContext } from "context/operatingRooms.context";
import moment from "moment";

const SalaOperatii = (props) => {
  const { state: SurgeryRooms } = useContext(OperatingRoomsContext);

  const [appointments, setAppointments] = useState([]);
  const [allAppointments, setAllAppointments] = useState([]);
  // eslint-disable-next-line
  const [appointmentInfo, setAppointmentInfo] = useState({
    procedure: "",
    doctor: "",
    patient: "",
    department: "",
  });
  const [agendaModal, setAgendaModal] = useState(false);

  const getAllAppointments = async () => {
    await REST.get(`/spital/getAllAppointmentsForHospital`).then((answer) => {
      setAllAppointments(answer.data);
    });
  };

  const CustomEvent = ({ procedure, doctor, patient, department, room }) => {
    return (
      <div
        className={`calendar-cell-general calendar-cell-${slugify(room)}`}
        onClick={() => {
          setAppointmentInfo({
            procedure: procedure,
            doctor: doctor,
            patient: patient,
            department: department,
            room: room,
          });
          handleAgenda();
        }}
      >
        <h5 className={`agenda-infoText`}>Procedura: {procedure}</h5>
        <h6 className={`agenda-infoText`}>{room}</h6>
        {department && (
          <div className={`agenda-infoText`}>Sectia: {department} </div>
        )}
        {doctor && <div className={`agenda-infoText`}>Dr: {doctor} </div>}
        {patient && <div className={`agenda-infoText`}>Pacient: {patient}</div>}
      </div>
    );
  };

  const javaData = () => {
    var newArr = [];

    allAppointments &&
      // eslint-disable-next-line
      allAppointments.map((item) => {
        const operatingRoom = item.operatingRoomName;
        // eslint-disable-next-line
        item.appointments.map((item) => {
          let newValue = {
            id: item.id,
            title: CustomEvent({
              procedure: item.title.procedure,
              doctor: item.title.doctor,
              patient: item.title.patient,
              department: item.title.department,
              room: operatingRoom,
            }),
            start: new Date(moment.utc(item.start)),
            end: new Date(moment.utc(item.end)),
          };
          newArr.push(newValue);
        });
      });
    setAppointments(newArr);
  };

  useEffect(() => {
    javaData();
    // eslint-disable-next-line
  }, [allAppointments]);

  useEffect(() => {
    javaData();
    getAllAppointments();
    // eslint-disable-next-line
  }, []);

  const handleSelect = (id, name) => {
    window.location = `/sala-operatii/${id}/${name}`;
  };

  const defaultView = "agenda";
  const defaultDate = new Date();

  const handleAgenda = () => {
    setAgendaModal(!agendaModal);
  };

  return (
    <Grid container className={`selectRoom_container`}>
      <Grid item xs={12} md={12} className={`header_container`}>
        <h2>Selectati o sala de operatii</h2>
      </Grid>

      <Grid item container xs={12} md={12} className={`list_container`}>
        {SurgeryRooms &&
          SurgeryRooms.map((item, index) => {
            return (
              <Grid
                item
                xs={5}
                md={2}
                className={`item_container room-${item.id}`}
                onClick={() => handleSelect(item.id, item.roomName)}
                key={`item_container room--${index}`}
              >
                <div className={`img_container`}>
                  <img alt="" src={surgeryImg} className={`img`} />
                </div>
                <div className={`item_description`}>{item.roomName}</div>
              </Grid>
            );
          })}
      </Grid>
      <Grid item container xs={12} md={12} className={`list_container`}>
        <CalendarComponent
          events={appointments}
          defaultView={defaultView}
          defaultDate={defaultDate}
        />
      </Grid>
      <Grid item container xs={12} md={12} className={`list_container`}>
        <AvailabilityTable surgeryRooms={SurgeryRooms && SurgeryRooms} />
      </Grid>
    </Grid>
  );
};

export default SalaOperatii;
