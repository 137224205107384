import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button as Btn, Card, Collapse } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import ConsultAlteDisciplineForm from "./ConsultAlteDiscipline.form";

const Accordion8 = (props) => {
  const { toggleAccordion, accordionStates, ticketId } = props;
  return (
    <Card
      className={clsx("card-box", {
        "panel-open": accordionStates[`accordion18`],
      })}
    >
      <Card>
        <div className="card-header">
          <div className="panel-title">
            <div className="accordion-toggle">
              <Btn
                variant="text"
                size="large"
                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                onClick={() => toggleAccordion(8)}
                aria-expanded={accordionStates[`accordion8`]}
              >
                <span>Consult Alte Discipline</span>
                <FontAwesomeIcon
                  icon={["fas", "angle-up"]}
                  className="font-size-xl accordion-icon"
                />
              </Btn>
            </div>
          </div>
        </div>
        {accordionStates[`accordion8`] && (
          <Collapse in={accordionStates[`accordion8`]}>
            <div className={"accordion_body"}>
              <h3 className="formSubtitle">
                Consult Alte Discipline (in afara institutiei / sectiei)
              </h3>
              <ConsultAlteDisciplineForm sheetId={ticketId} />
            </div>
          </Collapse>
        )}
      </Card>
    </Card>
  );
};

export default Accordion8;
