const managementPlan = [
  {
    title: "",
    key: "date",
    label: "Selecteaza Data",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },

  {
    title: "",
    key: "problems",
    label: "Probleme / Nevoi",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },

  {
    title: "",
    key: "interventions",
    label: "Obiectiv / Interventii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },

  {
    title: "",
    key: "review",
    label: "Revizuire",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  }
];

export default managementPlan;
