import DateFnsUtils from "@date-io/date-fns";
import {
  Card,
  CardContent,
  InputAdornment,
  Table,
  TextField,
} from "@material-ui/core";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import Pagination from "@material-ui/lab/Pagination";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import React from "react";

export default function Tables(props) {
  const {
    data,
    maxPage,
    handleChangePage,
    showDatePicker,
    dateToDisplay,
    handleDateChange,
    type,
    list,
    searchData,
  } = props;

  const handleChange = (event) => {
    searchData(event.target.value);
  };

  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="search-wrapper">
          {list === "INTERNATI" || list === "EXTERNATI" ? (
            <TextField
              variant="outlined"
              size="small"
              id="input-search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                handleChange(event);
              }}
            />
          ) : null}
          {showDatePicker ? (
            <div className="datePickerContainer">
              <div className="datePickerLabel"> Programarile din data: </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  labe={"Data"}
                  placeholder={"ZZ/LL/AAAA"}
                  format="dd/MM/yyyy"
                  className="textField"
                  error={false}
                  helperText={null}
                  onChange={(event) => {
                    handleDateChange(event);
                  }}
                  value={dateToDisplay}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          ) : null}
        </div>
        <div className="divider" />
        <CardContent className="pt-3 px-4 pb-4">
          <Table className="table table-alternate-spaced mb-0">
            <thead>
              <tr>
                <th>PACIENT</th>
                <th className="text-center">CNP</th>
                {type === "registratura" && (
                  <th className="text-center">STATUS</th>
                )}
                {type === "registratura" && (
                  <th className="text-center">DATA PROGRAMARII</th>
                )}
                {type === "registratura" && (
                  <th className="text-center">ORA</th>
                )}
                {type === "registratura" && (
                  <th className="text-center">SPECIALIZARE</th>
                )}
                {type === "registratura" && (
                  <th className="text-right" style={{ width: "15%" }}>
                    MEDIC
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr
                    key={item.id}
                    onClick={() => (window.location = `/chestionar/${item.id}`)}
                  >
                    <td>
                      <span className="font-weight-bold">
                        {item.patientName}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-danger font-weight-bold">
                        {item.patientCnp}
                      </span>
                    </td>
                    {type === "registratura" && (
                      <td className="text-center">
                        <div className="badge forStatus badge-danger">
                          {item.status}
                        </div>
                      </td>
                    )}
                    {type === "registratura" && (
                      <td className="text-center">
                        <span className="font-weight-bold text-diabled">
                          {item &&
                            item.form &&
                            item.form.finalConfirmDate &&
                            moment(item.form.finalConfirmDate).format(
                              "DD-MM-YYYY"
                            )}
                        </span>
                      </td>
                    )}
                    {type === "registratura" && (
                      <td className="text-center">
                        <span className="font-weight-bold text-diabled">
                          {item &&
                            item.form &&
                            item.form.finalConfirmHour &&
                            moment(item.form.finalConfirmHour).format("HH:mm")}
                        </span>
                      </td>
                    )}
                    {type === "registratura" && (
                      <td className="text-center">
                        <span className="font-weight-bold text-diabled">
                          {item && item.form && item.form.repData.spec}
                        </span>
                      </td>
                    )}
                    {type === "registratura" && (
                      <td className="text-right">
                        <span className="font-weight-bold text-diabled">
                          {item && item.form && item.form.repData.medic}
                        </span>
                      </td>
                    )}
                  </tr>
                ))}
              <tr className="divider"></tr>
            </tbody>
          </Table>
        </CardContent>
        <div className="card-footer py-3 d-flex justify-content-between">
          <Pagination
            className="pagination-second"
            variant="outlined"
            count={maxPage}
            onChange={handleChangePage}
          />
        </div>
      </Card>
    </>
  );
}
