import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import lscache from "lscache";
import React, { useEffect, useState } from "react";

const MultipleDropDownInput = (props) => {
  const {
    item,
    formState,
    handleChange,
    setValueSecondDD,
    setValueThirdDD,
    valueSecondDD,
    valueThirdDD,
  } = props;

  const [selectList, setSelectList] = useState([]);
  const [showSecondDD, setShowSecondDD] = React.useState(
    lscache.get("operatorType") === "Operator A" ? false : true
  );
  const [showThirdDD, setShowThirdDD] = React.useState(
    lscache.get("operatorType") === "Operator A" ? false : true
  );

  useEffect(() => {
    item.answer.selectList().then((data) => {
      setSelectList(data);
    });
    // eslint-disable-next-line
  }, []);

  var itemName = item.key;

  return (
    selectList.length && (
      <div className="questionGrid">
        <div className="answerBox">
          <div className="dropDownContainer">
            <Autocomplete
              options={selectList}
              getOptionLabel={(option) =>
                option !== undefined
                  ? option.name
                    ? option.name
                    : option
                  : null
              }
              defaultValue={
                formState.values[itemName]
                  ? selectList.filter(
                      (el) => el.id === formState.values[itemName]["spec"]
                    )[0]
                  : "Alege"
              }
              onChange={(event, value) => {
                event.persist();
                handleChange(
                  {
                    target: {
                      type: "SelectTree",
                      name: itemName,
                      value: event.target.textContent,
                    },
                    persist: () => true,
                  },
                  setValueSecondDD,
                  value
                );
                setShowSecondDD(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={`${itemName}`}
                  name={`${itemName}`}
                  style={{ width: 300 }}
                  datatype="selectMany"
                  value={
                    formState.values[itemName]
                      ? selectList.filter(
                          (el) => el.id === formState.values[itemName]["spec"]
                        )[0]
                      : "Alege"
                  }
                  label="Specializare"
                  margin="normal"
                />
              )}
            />

            {showSecondDD && valueSecondDD.length ? (
              <Autocomplete
                options={valueSecondDD}
                getOptionLabel={(option) => {
                  return option !== undefined
                    ? option.name
                      ? option.name
                      : option
                    : null;
                }}
                defaultValue={
                  formState.values[itemName]
                    ? formState.values[itemName]["spital"]
                      ? valueSecondDD.length
                        ? valueSecondDD.filter(
                            (el) =>
                              formState.values[itemName]["spital"] === el.id
                          )[0]
                        : "Alege"
                      : "Alege"
                    : "Alege"
                }
                style={{ width: 300 }}
                onChange={(event, value) => {
                  event.persist();
                  handleChange(
                    {
                      target: {
                        type: "SelectTree",
                        name: itemName,
                        value: event.target.textContent,
                      },
                      persist: () => true,
                    },
                    setValueThirdDD,
                    value
                  );
                  setShowThirdDD(true);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={`${itemName}`}
                    name={`${itemName}`}
                    value={
                      formState.values[itemName]
                        ? formState.values[itemName]["spital"]
                          ? valueSecondDD.length
                            ? valueSecondDD.filter(
                                (el) =>
                                  formState.values[itemName]["spital"] === el.id
                              )[0]
                            : ""
                          : "Alege"
                        : "Alege"
                    }
                    label="Spital"
                    margin="normal"
                  />
                )}
              />
            ) : (
              <div></div>
            )}
            {showThirdDD && valueThirdDD.length ? (
              <Autocomplete
                options={valueThirdDD ? valueThirdDD : []}
                getOptionLabel={(option) =>
                  option !== undefined
                    ? option.name
                      ? option.name
                      : option
                    : null
                }
                defaultValue={
                  formState.values[itemName]
                    ? formState.values[itemName]["medic"]
                      ? valueThirdDD.filter(
                          (el) => formState.values[itemName]["medic"] === el.id
                        )[0]
                      : "Alege"
                    : "Alege"
                }
                style={{ width: 300 }}
                onChange={(event, value) => {
                  event.persist();
                  handleChange(
                    {
                      target: {
                        type: "SelectTree",
                        name: itemName,
                        value: event.target.textContent,
                      },
                      persist: () => true,
                    },
                    () => true,
                    value
                  );
                }}
                // error={hasError(itemName)}
                // helperText={
                //   hasError(itemName) ? formState.errors[itemName][0] : null
                // }
                value={
                  formState.values[itemName]
                    ? formState.values[itemName]["medic"]
                      ? valueThirdDD.filter(
                          (el) => formState.values[itemName]["medic"] === el.id
                        )[0]
                      : "Alege"
                    : "Alege"
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={`${itemName}`}
                    name={`${itemName}`}
                    value={
                      formState.values[itemName]
                        ? formState.values[itemName]["medic"]
                          ? valueThirdDD.filter(
                              (el) =>
                                formState.values[itemName]["medic"] === el.id
                            )[0]
                          : "Alege"
                        : "Alege"
                    }
                    label="Medic"
                    margin="normal"
                  />
                )}
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
        {lscache.get("operatorType") === "Operator A" ? (
          <div className="answerTextContainer">
            <div className="answerDotContainer">
              <p className="answerDot">A</p>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    )
  );
};

export default MultipleDropDownInput;
