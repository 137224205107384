import DateFnsUtils from "@date-io/date-fns";
import { Button, Grid, Modal, TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import NativeSelect from "@material-ui/core/NativeSelect";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import MyCustomEvent from "../../../components/AgendaModal/CustomEvent";
import CalendarComponent from "../../../components/Calendar";
import Recorder from "../../../components/Recorder/Recorder";
import { REST } from "../../../Utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: "50%",
  },
  selectEmpty: {
    // marginTop: theme.spacing(2)
  },
  select: {
    margin: theme.spacing(2),
    minWidth: "30%",
  },
}));

// eslint-disable-next-line
function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const SurgeryRoom = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let entryId = window.location.pathname.split("/");

  // const now = moment(new Date()).format("D/MM/yyyy");
  const now = new Date();
  const hour = new Date();

  const [data, setData] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [surgeryHours, setSurgeryHours] = useState({
    hours: "",
  });
  const [speech, setSpeech] = useState("");
  const [selectedDate, setSelectedDate] = useState(now);
  const [hourInput, setHourInput] = useState(hour);
  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);
  const [appointmentInfo, setAppointmentInfo] = useState({
    procedure: "",
    doctor: "",
    patient: "",
    department: "",
  });
  const [agendaModal, setAgendaModal] = useState(false);

  const getRoomData = async () => {
    await REST.get(
      `/spital/getAllAppointmentsForRoom?roomId=${entryId[2]}`
    ).then((answer) => {
      setData(answer.data);
    });
  };

  const CustomEvent = ({ procedure, doctor, patient, department }) => {
    return (
      <div
        className={`calendar-cell-general calendar-cell-${department}`}
        onClick={() => {
          setAppointmentInfo({
            procedure: procedure,
            doctor: doctor,
            patient: patient,
            department: department,
          });
          handleAgenda();
        }}
      >
        <h5>Procedura: {procedure}</h5>
        {department && <div>Sectia: {department} </div>}
        {doctor && <div>Dr: {doctor} </div>}
        {patient && <div>Pacient: {patient}</div>}
      </div>
    );
  };

  useEffect(() => {
    getRoomData();
    // eslint-disable-next-line
  }, []);

  const javaData = () => {
    var newArr = [];
    data &&
      data.appointments &&
      // eslint-disable-next-line
      data.appointments.map((item) => {
        let newValue = {
          id: item.id,
          title: CustomEvent({
            procedure: item.title.procedure,
            doctor: item.title.doctor,
            patient: item.title.patient,
            department: item.title.department,
          }),
          start: new Date(item.start),
          end: new Date(item.end),
        };
        newArr.push(newValue);
      });
    setAppointments(newArr);
  };

  useEffect(() => {
    javaData();
    // eslint-disable-next-line
  }, [data]);

  const defaultView = "agenda";
  const defaultDate = new Date();

  const handleSurgeryHours = (event) => {
    setSurgeryHours((state) => {
      return {
        ...state,
        hours: event.target.value,
      };
    });
  };

  const handleSpeech = (answer) => {
    setSpeech(speech + " " + answer);
  };

  const startDate = `${moment(selectedDate).format("yyyy-MM-DD")}T${moment(
    hourInput
  ).format("HH:mm:00")}`;

  const surgeryAppointment = async () => {
    const body = {
      numberOfHours: parseInt(surgeryHours.hours),
      roomId: entryId[2],
      start: startDate,
      title: speech,
    };
    await REST.post(`/spital/addOperatingRoomMaintananceAppointment`, body)
      .then((answer) => {
        setSpeech("");
        setSelectedDate(now);
        setHourInput(hour);
        setSurgeryHours((state) => {
          return {
            ...state,
            hours: 0,
          };
        });
        refresh();
      })

      .catch((err) => {});
  };

  const showSnack = (variant) => {
    enqueueSnackbar("O nou eveniment a fost adaugat!", { variant });
  };

  const refresh = () => {
    setTimeout(() => {
      getRoomData();
    }, 200);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChangeHour = (date) => {
    setHourInput(date);
  };

  // eslint-disable-next-line
  const handleSnack = (Transition) => () => {
    setTransition(() => Transition);
    setOpen(true);
  };

  const closeSnack = () => {
    setOpen(false);
  };

  const handleAgenda = () => {
    setAgendaModal(!agendaModal);
  };

  return (
    <Grid container xs={12} md={12} className={`room_container`}>
      <Grid item xs={12} className={`header_container`}>
        <h1>{data.operatingRoomName}</h1>
      </Grid>

      <Grid item container xs={12} className={`add_room_event`}>
        <h3>Adaugati un eveniment nou</h3>
        <Grid item container xs={12} className={`event_inputs`}>
          <Grid item xs={12} className={`event_name`}>
            <div className={`inputs__container`}>
              <TextField
                label={`Tipul procedurii`}
                onChange={(event) => {
                  event.persist();
                  setSpeech(event.target.value);
                }}
                value={speech || ""}
                style={{ width: "90%" }}
              />

              <Recorder showRecord={true} handleSpeech={handleSpeech} />
            </div>
          </Grid>
          <Grid xs={12} className={`event_duration`}>
            <FormControl className={classes.select}>
              <NativeSelect
                value={surgeryHours.hours}
                onChange={handleSurgeryHours}
                name="age"
                className={classes.selectEmpty}
                inputProps={{ "aria-label": "age" }}
                style={{ width: "70%" }}
              >
                <option value=""></option>
                <optgroup label="Selectati numarul de ore">
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                </optgroup>
              </NativeSelect>
              <FormHelperText>Durata procedurii</FormHelperText>
            </FormControl>

            <Grid container justify="space-around" className={`date_pickers`}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="DATA"
                  format="d/MM/yyyy"
                  value={selectedDate}
                  defaultValue={now}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardTimePicker
                  ampm={false}
                  margin="normal"
                  id="time-picker"
                  label="ORA"
                  defaultValue={hour}
                  value={hourInput}
                  onChange={handleChangeHour}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={`submit__container`}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              surgeryAppointment();
              showSnack("success");
            }}
            style={{ width: "50%" }}
          >
            ADAUGA
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} className={`calendar_container`}>
        <CalendarComponent
          events={appointments}
          defaultView={defaultView}
          defaultDate={defaultDate}
        />
      </Grid>
      <Snackbar
        open={open}
        onClose={closeSnack}
        TransitionComponent={transition}
        message="I love snacks"
        key={transition ? transition.name : ""}
      />

      <Modal
        open={agendaModal}
        onBackdropClick={handleAgenda}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(3px)",
          width: "100%",
          height: "100%",
        }}
      >
        <div>
          <MyCustomEvent
            appointmentInfo={appointmentInfo}
            handleAgenda={handleAgenda}
          />
        </div>
      </Modal>
    </Grid>
  );
};

export default SurgeryRoom;
