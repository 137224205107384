import React, { useState } from "react";
import { beURL } from "../../../Utils/index";
import { Button, InputLabel, Select, MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    width: "100%",
  },

  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    // margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "60%",
  },
  datePicker: {
    height: "20px",
  },
}));


const Raportare = (props) => {
  const today = moment(new Date()).format("DD/MM/yyyy")
  const classes = useStyles();

  const [dates, setDates] = useState({ start: null, end: null });

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleStartDate = (date) => {
    const start = moment(date).format("YYYY/MM/DD");
    formState.values.startDate = start;
    setDates({ ...dates, start });
  };

  const handleEndDate = (date) => {
    const end = moment(date).format("YYYY/MM/DD");
    formState.values.endDate = end;
    setDates({ ...dates, end });
  };

  const types = [
    { id: 1, key: "withAllergies", label: "Pacienti cu alergii" },
    {
      id: 2,
      key: "sameCityAndCounty",
      label: "Pacienti din judet localitate",
    },
    { id: 3, key: "groupedByDrg", label: "Pacienti grupati dupa DRG" },
    { id: 4, key: "withSameDrg", label: "Pacienti cu acelasi DRG" },
  ];

  const typeEndpoints = [
    "",
    "exportNumberOfTicketWithAllergies",
    "exportTicketsFromSameCityAndCounty",
    "exportTicketsGroupedByDrg",
    "exportTicketsWithSameDrg",
  ];

  const handleSend = async (event) => {
    event.preventDefault();

    const url = `/spital/reports/${typeEndpoints[formState.values.type]
      }?endDate=${formState.values.endDate}&startDate=${formState.values.startDate
      }`;

    window.location.href = `${beURL}${url}`;
  };

console.log(`today`, today)

  return (
    <div className="bed_mangament_root">
      <div className={"select_doctor_container"}>
        <h2 className={"action_labels"}>Selecteaza tip</h2>
        <div className={"select_doctor"}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="county_id">Selecteaza tip</InputLabel>
            <Select
              labelId="type"
              id="type"
              name={"type"}
              value={formState.values.type || ""}
              onChange={handleChange}
              label="Selecteaza tip"
            >
              <MenuItem value="">
                <em>--tip--</em>
              </MenuItem>
              {types.map((item, index) => {
                return (
                  <MenuItem
                    value={item.id}
                    key={`bed_management_menu_item--${index}`}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <div className={"all_beds"}>
          <div className={"date_container"}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div>
                <p className={"label_text"}>Din data:</p>
                <KeyboardDatePicker
                  disableToolbar
                  format="dd/MM/yyyy"
                  placeholder={"ZZ/LL/AAAA"}
                  margin="normal"
                  id="startDate"
                  onChange={handleStartDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  className={classes.datePicker}
                  value={dates.start || today}
                />
              </div>
              <div>
                <p className={"label_text"}>Pana la data:</p>
                <KeyboardDatePicker
                  disableToolbar
                  format="dd/MM/yyyy"
                  placeholder={"ZZ/LL/AAAA"}
                  margin="normal"
                  id="endDate"
                  value={dates.end || today}
                  onChange={handleEndDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  className={classes.datePicker}
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </div>

      <div className={"confirm_btn_container"}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={"singInButton"}
          onClick={handleSend}
        >
          <h4 className={"btn_text"}>Confirm</h4>
        </Button>
      </div>
    </div>
  );
};

export default Raportare;
