const FormSchema = {
  lastName: {
    presence: { allowEmpty: true, message: "necesita completarea campului" },
    length: {
      maximum: 32
    }
  },
  firstName: {
    presence: { allowEmpty: true, message: "necesita completarea campului" },
    length: {
      maximum: 32
    }
  },
  birthDate: {
    presence: { allowEmpty: true, message: "necesita completarea campului" },
    // format: {
    //   pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
    //   message: "formatul cerut este ZZ/LL/AAAA"
    // }
  },
  sex: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  bloodType: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  rh: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  legalAddressCounty: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  legalAddressCity: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  legalAddressStreet: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  legalAddressStreetNr: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  county: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  city: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  street: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  streetNr: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  admissionDate: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  dischargeDate: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  weightNewBorn: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  profession: {
    presence: { allowEmpty: true, message: "necesita completarea campului" },
    length: {
      maximum: 32
    }
  },
  workPlace: {
    presence: { allowEmpty: true, message: "necesita completarea campului" },
    length: {
      maximum: 32
    }
  },
  hospitalizationDays: {
    presence: { allowEmpty: true, message: "necesita completarea campului" },
    length: {
      maximum: 32
    }
  },
  hospitalizationSickLeave: {
    presence: { allowEmpty: true, message: "necesita completarea campului" },
    length: {
      maximum: 32
    }
  },
  serialId: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  serialNumber: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  birthSerialId: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  birthSerialNumber: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  ciCounty: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  registrationNr: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },

  CNP: {
    presence: { allowEmpty: true, message: "necesita completarea campului" },
    length: {
      maximum: 13,
      minimum: 13,
      message: "este format din 13 cifre"
    },
    format: {
      pattern: /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/,
      message: "nu este valid"
    }
  },

  hospitalCity: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  },
  doctorSeal: {
    presence: { allowEmpty: true, message: "necesita completarea campului" }
  }
};

export default FormSchema;
