import TextField from "@material-ui/core/TextField";
import React from "react";

const DisabledTextInput = (props) => {
  const { item, formState, hideLabel } = props;

  if (item.key === "hospitalName") {
    item.value =
      formState &&
      formState.values &&
      formState.values["repData"] &&
      formState.values["repData"]["spital"];
  } else if (item.key === "hospitalDepartment") {
    item.value =
      formState &&
      formState.values &&
      formState.values["repData"] &&
      formState.values["repData"]["spec"];
  }

  var itemName = item.key;
  return (
    <div className="answerBox">
      {!hideLabel ? (
        <div className="labelContainer">
          <p className="labelText">{item.label}</p>
        </div>
      ) : (
        <div></div>
      )}
      <div className="textFieldContainer">
        <TextField
          id={`${itemName}`}
          name={`${itemName}`}
          label={item.displayLabel}
          className="textField"
          multiline
          rows={1}
          rowsMax={4}
          disabled={item.answer.disabled}
          value={item.value}
        />
      </div>
    </div>
  );
};

export default DisabledTextInput;
