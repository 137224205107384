import clsx from "clsx";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import mainContext from "../../context/mainContext";
import HeaderUserbox from "../../layout-components/HeaderUserbox";
import { setSidebarToggleMobile } from "../../reducers/ThemeOptions";

const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile,
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const context = useContext(mainContext);
  const location = useLocation();
  const newLocation = location.pathname.split("/");
  return (
    <>
      <div
        className={clsx("app-header", {
          "app-header--shadow": headerShadow,
          "app-header--opacity-bg": headerBgTransparent,
        })}
      >
        <p className="headerText">
          {newLocation[1] === "chestionar" &&
          context &&
          context.userDataContext &&
          context.userDataContext["type"] === "Medic"
            ? " " + context && context.formStateContext
              ? context.formStateContext["firstName"] +
                " " +
                context.formStateContext["lastName"] +
                " - " +
                context.formStateContext["CNP"]
              : null
            : null}
        </p>

        <div className="app-header--pane">
          <button
            className={clsx(
              "navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn",
              { "is-active": sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
        <div className="app-header--pane">
          <HeaderUserbox />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
