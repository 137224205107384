import moment from "moment";
import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";

const localizer = momentLocalizer(moment);

const CalendarComponent = (props) => {
  return (
    <div>
      <Calendar
        localizer={localizer}
        events={props.events}
        // allDayAccessor={false}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600, width: 1000 }}
        defaultView={props.defaultView}
      />
    </div>
  );
};

export default CalendarComponent;
