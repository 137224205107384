export default [
  {
    key: "date",
    label: "Data",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
    },
  },
];
