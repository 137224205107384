import { addToArrayHelper } from "../helpers";

const universalAction = (
  stateKey,
  state,
  type,
  payload,
  payloadKey,
  eraseFromNestedCollection,
  addToNestedCollection,
  isActionGenerated
) => {
  let populateWith = {
    ...state,
    [stateKey]: {
      ...state[stateKey],
      values: {
        ...state[stateKey].values,
        ...payload
      }
    },
    generated: isActionGenerated
  };

  if (!!payloadKey) {
    let payloadFiltered = {
      ...state[stateKey].values[payloadKey],
      ...payload
    };

    if (eraseFromNestedCollection !== null) {
      const { name, valueToBeErased } = eraseFromNestedCollection;

      const filterIt = {
        [name]: state[stateKey].values[payloadKey][name].filter(item => {
          return item !== valueToBeErased;
        })
      };

      payloadFiltered = {
        ...state[stateKey].values[payloadKey],
        ...filterIt
      };
    }

    if (addToNestedCollection !== null) {
      const { name, valueToBeAdded } = addToNestedCollection;
      const nestedCollectionHasValues =
        state[stateKey].values[payloadKey] &&
        state[stateKey].values[payloadKey][name] &&
        state[stateKey].values[payloadKey][name].length > 0;

      if (nestedCollectionHasValues) {
        payloadFiltered = {
          ...state[stateKey].values[payloadKey],
          [name]: [...state[stateKey].values[payloadKey][name], valueToBeAdded]
        };
      } else {
        payloadFiltered = {
          ...state[stateKey].values[payloadKey],
          ...payload
        };
      }
    }

    populateWith = {
      ...state,
      [stateKey]: {
        values: {
          [payloadKey]: payloadFiltered
        }
      },
      generated: isActionGenerated
    };

    delete populateWith[stateKey].values[payloadKey].payloadKey;
  }

  return populateWith;
};

export default universalAction;

export const acc17 = (
  state,
  stateKey,
  payloadKey,
  payload,
  isActionGenerated
) => {
  if (payloadKey && state[stateKey].values && payload) {
    return {
      ...state,
      [stateKey]: {
        values: {
          [payloadKey]: {
            drugAllergies: payload.drugAllergies,
            medicinesEntries: addToArrayHelper(
              state[stateKey]?.values[payloadKey]?.medicinesEntries || [],
              payload.medicinesEntries
            )
          }
        }
      },
      generated: isActionGenerated
    };
  }

  return { ...state };
};

export const acc19 = (
  state,
  stateKey,
  payloadKey,
  payload,
  isActionGenerated
) => {
  return {
    ...state,
    [stateKey]: {
      values: {
        [payloadKey]: addToArrayHelper(
          state[stateKey].values[payloadKey],
          payload
        )
      }
    },
    generated: isActionGenerated
  };
};

export const acc21 = (
  state,
  stateKey,
  payloadKey,
  payload,
  isActionGenerated
) => {
  return {
    ...state,
    [stateKey]: {
      values: {
        [payloadKey]: addToArrayHelper(
          state[stateKey].values[payloadKey],
          payload
        )
      }
    },
    generated: isActionGenerated
  };
};
