export const aparatTypeTitles = {
  "VITAL APSEC(RESP)": null,
  "VITAL rPR(spo2)": null,
  "VITAL CVP(S)": null,
  "VITAL PR(spo2)": null,
  "VITAL MONITOR ID": null,
  "VITAL PR(art)": null,
  vital_art_d: "Tensiune arteriala diastolica",
  "VITAL CVP(M)": null,
  "VITAL STV2": null,
  "VITAL CVP(D)": null,
  "VITAL STV1": null,
  "VITAL STV4": null,
  "VITAL STV3": null,
  "VITAL ST1": null,
  "VITAL STV6": null,
  "VITAL ST2": null,
  "VITAL STV5": null,
  "VITAL ST3": null,
  vital_spo2: "Saturatia de Oxigen",
  vital_hr: "Puls",
  "VITAL STVF": null,
  vital_resp: "Respiratie pe minut",
  vital_trect: "Temperatura Rect",
  "VITAL ART(M)": null,
  "VITAL STVL": null,
  "VITAL STVR": null,
  vital_date: null,
  "VITAL rRESP(imp)": null,
  vital_tskin: "Temperatura piele",
  vital_art_s: "Tensiune arteriala sistolica",
  "VITAL VPC": null
};
