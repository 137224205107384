import { Button } from "@material-ui/core";
import React from "react";

export default function LivePreviewExample(props) {
  const { text, onClick } = props;

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={onClick}
          variant="contained"
          className="btn-primary m-2"
          style={{
            height: "40px",
            justifyContent: "flex-end",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "900",
              paddingTop: "15px",
              textAlign: "center",
            }}
          >
            {text}
          </p>
        </Button>
      </div>
    </>
  );
}
