import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import lscache from "lscache";

const CheckBoxInput = (props) => {
  const { item, formState, handleChange, hasError } = props;

  var itemName = item.key;

  return (
    <div className="answerBox">
      {lscache.get("operatorType") === "SefSectie" ? null : (
        <div className="labelContainer">
          <p className="labelText">{item.label}</p>
        </div>
      )}
      <div className="checkboxContainer">
        {item.answer.selectList.map((el) => {
          return (
            <FormControlLabel
              key={`check_box_input_${el}`}
              label={el}
              className="checkboxList"
              control={
                <Checkbox
                  id={`${itemName + "_" + el}`}
                  name={`${itemName}`}
                  label={`${el}`}
                  className="checkboxElement"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  error={`${hasError(itemName)}`}
                  helpertext={
                    hasError(itemName) ? formState.errors[itemName][0] : null
                  }
                  value={el}
                  checked={
                    formState.values &&
                    formState.values[itemName] &&
                    formState.values[itemName].includes(el)
                  }
                />
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default CheckBoxInput;
