import React from "react";
import { Select, MenuItem, InputLabel } from "@material-ui/core";
import lscache from "lscache";

const DropDownInput = (props) => {
  const {
    item,
    formState,
    handleChange,
    hasError,
    // handleBlur,
    isDisabled,
    userType,
  } = props;
  // const [error, setError] = useState({ message: null });

  var itemName = item.key;

  if (userType === "SefSectie") {
    return (
      <div className="questionGrid">
        <div className="answerBox">
          <div className="checkboxContainer">
            <InputLabel id="singleDropDown"></InputLabel>
            <Select
              id={`${itemName}`}
              name={`${itemName}`}
              label={item.displayLabel}
              onChange={(event) => {
                handleChange(event, null, [], item);
              }}
              disabled={isDisabled}
              error={hasError(itemName)}
              helpertext={
                hasError(itemName) ? formState.errors[itemName][0] : null
              }
              value={formState.values[itemName] || ""}
            >
              <MenuItem value="NONE">
                <em>None</em>
              </MenuItem>
              {item.answer.selectList.map((el) => {
                return (
                  <MenuItem value={el} key={`dropDownInput-ss_${el}`}>
                    {el}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </div>
        {lscache.get("operatorType") === "Operator A" ? (
          <div className="answerTextContainer">
            <div className="answerDotContainer">
              <p className="answerDot">A</p>
            </div>
          </div>
        ) : (
            <div></div>
          )}
      </div>
    );
  } else {
    return (
      <div className="answerBox">
        <div className="labelContainer">
          <p className="labelText">{item.label}</p>
        </div>
        <div className="singleDropDownContainer">
          <InputLabel id="singleDropDown"></InputLabel>
          <Select
            id={`${itemName}`}
            name={`${itemName}`}
            label={item.displayLabel}
            className="singleDropDown"
            onChange={(event) => {
              handleChange(event, null, [], item);
            }}
            disabled={isDisabled}
            value={formState[itemName] || ""}
          >
            <MenuItem value="NONE">
              <em>None</em>
            </MenuItem>
            {item.answer.selectList.map((el) => {
              return (
                <MenuItem value={el} key={`dropDownInput_${el}`}>
                  {el}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
    );
  }

};

export default DropDownInput;
