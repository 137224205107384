import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button as Btn, Card, Collapse } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { RenderAccForm } from "../../../../../../components/InputsAti";
import Mocks from "./Mocks/secondFormMock";

const Accordion02 = (props) => {
  const {
    formData,
    ticketId,
    userData,
    accordionStates,
    toggleAccordion,
  } = props;
  return (
    <Card
      className={clsx("card-box", {
        "panel-open": accordionStates[`accordion2`],
      })}
    >
      <Card>
        <div className="card-header">
          <div className="panel-title">
            <div className="accordion-toggle">
              <Btn
                variant="text"
                size="large"
                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                onClick={() => toggleAccordion(2)}
                aria-expanded={accordionStates[`accordion2`]}
              >
                <span>Foaie de observatie clinica generala</span>
                <FontAwesomeIcon
                  icon={["fas", "angle-up"]}
                  className="font-size-xl accordion-icon"
                />
              </Btn>
            </div>
          </div>
        </div>
        {accordionStates[`accordion2`] && (
          <Collapse in={accordionStates[`accordion2`]}>
            <div className={"accordion_body"}>
              <h3 className="formSubtitle">
                Foaie de observatie clinica generala{" "}
              </h3>

              <RenderAccForm
                data={formData}
                ticketId={ticketId}
                userData={userData}
                ctxAccKey={"acc2"}
                Mocks={Mocks}
              />
            </div>
          </Collapse>
        )}
      </Card>
    </Card>
  );
};

export default Accordion02;
