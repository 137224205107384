import { accordionConfigs } from "./hydrateAccordionsFactory.helper";
import { REST } from "../Utils";

const callSaveEndpoint = async (acc, state) => {
  const { accKey, saveEndpoint } = accordionConfigs[acc];
  const accordionStateValues =
    (accKey && state[acc].values[accKey]) || state[acc].values;

  if (saveEndpoint) {
    await REST.post(
      `ati/${saveEndpoint}?sheetId=${state.sheetId}`,
      accordionStateValues
    )
      .then((answer) => {})
      .catch((err) => {});
  }
};

export default callSaveEndpoint;
