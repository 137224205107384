import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

let formats = {
  timeGutterFormat: (date, culture, localizer) =>
    localizer.format(date, "HH:mm", culture),
};

const CalendarComponent = (props) => {
  const localizer = momentLocalizer(moment);

  return (
    <div>
      <Calendar
        {...props}
        formats={formats}
        localizer={localizer}
        events={props.events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600, width: 1000 }}
        defaultView={props.defaultView}
      />
    </div>
  );
};

export default CalendarComponent;
