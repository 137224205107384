import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";

const TextInput = (props) => {
  const {
    item,
    formState,
    handleChange,
    hideLabel,
    label,
    isDisabled,
    className,
  } = props;

  var itemName = item.key;

  const [evLocal, setEvLocal] = useState(null);
  const [ev, setEv] = useState({
    target: {
      name: null,
      type: null,
      value:
        (formState && formState.values && formState.values[itemName]) || null,
    },
    persist: () => true,
  });

  const changeHandler = (event) => {
    event.persist();

    setEvLocal(event);
    setEv((prevState) => {
      return {
        ...prevState,
        target: {
          value: event.target.value,
          type: event.target.type,
          name: event.target.name,
        },
      };
    });
  };

  useEffect(() => {
    handleChange(ev);
    // eslint-disable-next-line
  }, [ev]);

  useEffect(() => {
    setEv((prevState) => {
      return {
        ...prevState,
        target: {
          value:
            (formState && formState.values && formState.values[itemName]) ||
            null,
        },
      };
    });
    // eslint-disable-next-line
  }, [formState]);

  const getValue = () => {
    if (evLocal && evLocal.target && evLocal.target.value) {
      return evLocal.target.value;
    }

    return ev.target.value;
  };

  return (
    <div className={item && className ? className : "answerBox"}>
      {!hideLabel ? (
        <div className="labelContainer">
          <p className="labelText">{label}</p>
        </div>
      ) : (
        <div></div>
      )}
      <div className={"textFieldContainer"}>
        <TextField
          id={itemName}
          name={itemName}
          label={item.displayLabel}
          className="textField"
          disabled={isDisabled}
          onChange={changeHandler}
          value={getValue() || ""}
        />
      </div>
    </div>
  );
};

export default TextInput;
