import AirlineSeatFlatIcon from "@material-ui/icons/AirlineSeatFlat";
import BallotTwoToneIcon from "@material-ui/icons/BallotTwoTone";
import ChevronRightTwoToneIcon from "@material-ui/icons/ChevronRightTwoTone";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import lscache from "lscache";
import React, { useEffect, useState, useContext } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import qrIcon from "../../assets/images/qr/qr-code.svg";
import { setSidebarToggleMobile } from "../../reducers/ThemeOptions";
import { REST } from "../../Utils/index";
import SidebarUserbox from "../SidebarUserbox";

import { FormStateContext } from "context/formStateContext";
import { OperatingRoomsContext } from "context/operatingRooms.context";

const SidebarMenu = (props) => {
  const { dispatch } = useContext(FormStateContext);
  const { dispatch: dispatchRooms } = useContext(OperatingRoomsContext);

  const { setSidebarToggleMobile, sidebarUserbox, pageName } = props;
  const [drId, setDrId] = useState(null);

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const handleLogOut = () => {
    lscache.flush();
    lscache.remove("accessToken");
  };

  const getUserData = async () => {
    await REST.get(`spital/getUserProfile`)
      .then((answer) => {
        setDrId(answer.data.doctorId);
        lscache.set("dpt", answer.data.department.id);
        dispatch({ type: "SET_USER", payload: answer.data });
      })
      .catch((err) => {});
  };

  const getSurgeryRooms = async () => {
    await REST.get(`/spital/getAllOperatingRooms`)
      .then((answer) => {
        dispatchRooms({ type: "SET_ROOMS", payload: answer.data });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getUserData();
    getSurgeryRooms();
    //eslint-disable-next-line
  }, []);

  const isPageQRScan = pageName.includes("qrScan");
  const isCalendar = pageName.includes("calendar");
  const isPageQRScanMed = pageName.includes("scanQr");
  const isPageRaportare = pageName.includes("raportare");
  const isRegistratura = lscache.get("operatorType") === "Registratura";

  return (
    <>
      <PerfectScrollbar>
        <div style={{ marginTop: "15%" }}>
          {sidebarUserbox && <SidebarUserbox />}
        </div>
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>Meniu de navigare</span>
          </div>
          <ul>
            {lscache.get("operatorType") === "SefSectie" ? (
              <li>
                <NavLink
                  activeClassName="active"
                  onClick={toggleSidebarMobile}
                  className="nav-link-simple"
                  to={
                    lscache.get("operatorType") === "SefSectie"
                      ? "/dashboard-ss"
                      : "/"
                  }
                >
                  <span className="sidebar-icon">
                    <BallotTwoToneIcon />
                  </span>

                  <span className="sidebar-item-label">
                    <div>Dashboard</div>
                  </span>
                  <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                    <ChevronRightTwoToneIcon />
                  </span>
                </NavLink>
              </li>
            ) : lscache.get("operatorType") === "Medic" ? (
              <li>
                <NavLink
                  activeClassName="active"
                  onClick={toggleSidebarMobile}
                  className="nav-link-simple"
                  to={
                    lscache.get("operatorType") === "Medic"
                      ? `/dashboard-md/${drId}/${lscache.get("operatorType")}`
                      : "/"
                  }
                >
                  <span className="sidebar-icon">
                    <BallotTwoToneIcon />
                  </span>

                  <span className="sidebar-item-label">
                    <div>Dashboard</div>
                  </span>
                  <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                    <ChevronRightTwoToneIcon />
                  </span>
                </NavLink>
              </li>
            ) : null}

            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to={
                  lscache.get("operatorType") === "SefSectie"
                    ? "/listare-ss"
                    : "/listare"
                }
              >
                <span className="sidebar-icon">
                  <BallotTwoToneIcon />
                </span>

                <span className="sidebar-item-label">
                  {lscache.get("operatorType") === "Operator A" ? (
                    <div>Listare apeluri</div>
                  ) : (
                    <div>Listare pacienti</div>
                  )}
                </span>
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>

            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to={"/sala-operatii"}
              >
                <span className="sidebar-icon">
                  <AirlineSeatFlatIcon />
                </span>

                <span className="sidebar-item-label">
                  <div>Bloc operator</div>
                </span>
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>

            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to={"/management-echipamente"}
              >
                <span className="sidebar-icon">
                  <ImportantDevicesIcon />
                </span>

                <span className="sidebar-item-label">
                  <div>Management echipamente</div>
                </span>
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>

            {isRegistratura && (
              <li>
                <NavLink
                  activeClassName="active"
                  onClick={toggleSidebarMobile}
                  className="nav-link-simple"
                  to={"/ati-add"}
                >
                  <span className="sidebar-icon">
                    <BallotTwoToneIcon />
                  </span>

                  <span className="sidebar-item-label">
                    <div>ATI</div>
                  </span>
                  <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                    <ChevronRightTwoToneIcon />
                  </span>
                </NavLink>
              </li>
            )}

            <li>
              <a
                className={`nav-link-simple ${isPageQRScan ? "active" : ""}`}
                href={
                  lscache.get("operatorType") === "Medic"
                    ? "/qrScan"
                    : "/scanQr"
                }
                onClick={toggleSidebarMobile}
              >
                <span className="sidebar-icon">
                  <img src={qrIcon} alt={""} />
                </span>

                <span className="sidebar-item-label">
                  <div>
                    {lscache.get("operatorType") === "Medic"
                      ? "Atribuire aparat"
                      : "Scaneaza pacient"}
                  </div>
                </span>
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
            </li>

            <li>
              <a
                className={`nav-link-simple ${isCalendar ? "active" : ""}`}
                href={
                  lscache.get("operatorType") === "Medic"
                    ? "/calendar"
                    : "/calendar"
                }
                onClick={toggleSidebarMobile}
              >
                <span className="sidebar-icon">
                  <EventNoteIcon />
                </span>

                <span className="sidebar-item-label">
                  <div>
                    {lscache.get("operatorType") === "Medic"
                      ? "Calendar programari"
                      : "Calendar programari"}
                  </div>
                </span>
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
            </li>
            {lscache.get("operatorType") === "Medic" ? (
              <li>
                <a
                  className={`nav-link-simple ${
                    isPageQRScanMed ? "active" : ""
                  }`}
                  href="/scanQr"
                  onClick={toggleSidebarMobile}
                >
                  <span className="sidebar-icon">
                    <img src={qrIcon} alt={""} />
                  </span>

                  <span className="sidebar-item-label">
                    <div>Scanare Pacient</div>
                  </span>
                  <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
              </li>
            ) : null}

            <li>
              <a
                className={`nav-link-simple ${isPageRaportare ? "active" : ""}`}
                href="/raportare"
                onClick={toggleSidebarMobile}
              >
                <span className="sidebar-icon">
                  <CloudDownloadIcon />
                </span>

                <span className="sidebar-item-label">
                  <div>Raportare</div>
                </span>

                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
            </li>

            <li>
              <NavLink
                activeClassName="active"
                onClick={handleLogOut}
                className="nav-link-simple"
                to="/login"
              >
                <span className="sidebar-icon">
                  <ExitToAppIcon />
                </span>
                <span className="sidebar-item-label">Logout</span>
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
  // }
};
const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
