import MicRecorder from "mic-recorder-to-mp3";
import React, { useEffect, useState } from "react";
import stopRecord from "../../assets/images/apps/record/pause.svg";
import startRecord from "../../assets/images/apps/record/rec.svg";
import { REST } from "../../Utils/index";

const Recorder = (props) => {
  const { showRecord, handleSpeech, ticketId } = props;

  const [isRecordin, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(true);
  // eslint-disable-next-line
  const [blobURL, setBlobURL] = useState("");
  // eslint-disable-next-line
  const [recorder, setRecorder] = useState(new MicRecorder({ bitRate: 128 }));
  // eslint-disable-next-line
  const [error, setError] = useState("");
  // eslint-disable-next-line
  const [respose, setResponse] = useState("");

  useEffect(() => {
    navigator.getUserMedia(
      // navigator.mediaDevices.getUserMedia(
      { audio: true },
      () => {
        setIsBlocked(false);
      },
      () => {
        setIsBlocked(true);
      }
    );
  }, []);

  const startRecording = () => {
    if (isBlocked) {
    } else {
      setResponse("");
      handleSpeech("");
      recorder
        .start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = async () => {
    await recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setBlobURL(blobURL);

        var f = new FileReader();
        f.onload = async function(e) {
          const body = "body=" + e.target.result.toString("base64").slice(37);
          await REST.post(`/spital/speechToText?ticketId=${ticketId}`, body)
            .then((answer) => {
              setError({ message: null });
              setResponse(answer.data);
              handleSpeech(answer.data);
            })
            .catch((err) => {
              if (err && err.response && err.response.data) {
                const { message } = err.response.data;
                setError({
                  message,
                });
              }
            });
        };
        f.readAsDataURL(new Blob(buffer));
        setIsRecording(false);
      })
      .catch((e) => {});
  };

  if (showRecord) {
    return (
      <div>
        {isRecordin ? (
          <div onClick={stopRecording} className={"stopRecord"}>
            <img src={stopRecord} alt={""} />
          </div>
        ) : (
          <div onClick={startRecording} className={"startRecord"}>
            <img src={startRecord} alt={""} />
          </div>
        )}

        {/* MODIFICARE */}
        {/* <div>
          <p>{handleSpeech}</p>
        </div> */}
      </div>
    );
  } else {
    return null;
  }
};

export default Recorder;
