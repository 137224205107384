const stareSociala = [
  {
    key: "familyTree",
    label: "Arbore genealogic",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "existingSocialSupport",
    label: "Suport social existent",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  //familyRoleForCare
  {
    key: "familyRoleForCare",
    label: "Familia joaca un rol important in ingrijire",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: ["Da", "Nu", "Nu este cazul"],
      isDisabled: false
    }
  },
  {
    key: "theKeyPersonInvolvedInPatientCare",
    label: "Persoana cheie implicata in ingrijirea pacientului",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "patientNeedsSupportFor",
    label: "Pacientul sau familia au nevoie de informatii/sprijin pentru",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "livingConditions",
    label: "Conditii de locuit",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "financialStatus",
    label: "Status financiar",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "otherSocialIssues",
    label: "Alte aspecte sociale",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "needEquipment",
    label:
      "Nevoie de echipament (scaun cu rotile, cadru de mers, baston, carja, toaleta mobile etc.)",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    label: "Observatii",
    answer: {
      type: "ObservationsInputs",
      required: true,
      selectList: ["Da", "Nu"]
    }
  }
];

export default stareSociala;
