import { SecondOpinionContext } from "context/secondOpinionContext";
import { createBrowserHistory } from "history";
import React, { useContext, useEffect, useState } from "react";
import { REST } from "../../../Utils/index";
import EvolutionList from "../Chestionar/components/EvolutionList";
import RapoarteTemperatura from "../Chestionar/components/RapoarteTemperatura";
import SecondOpinionLogin from "../SecondOpinionLogin/SecondOpinionLogin";
import SecondOpinionBody from "./components/SecondOpinionBody";

const browserHistory = createBrowserHistory();

const SecondOpinion = (props) => {
  const { history, location } = props;

  const [showTicket, setShowTicket] = useState(false);

  const context = useContext(SecondOpinionContext);
  let password = context && context.secondOpinionPassword;

  const ticketId = browserHistory.location.search.replace("?", "");

  const [monitorData, setMonitorData] = useState("");
  const [evolutionData, setEvolutionData] = useState([]);

  const handleGetTicketData = async () => {
    if (password !== null) {
      await REST.get(
        `/spital/secondOpinion/getSecondOpinionTicket?token=${password}`
      )
        .then((answer) => {
          context && context.setSecondOpinionForm(answer.data.observationSheet);

          context && context.setWrongCredentials(false);

          try {
            setMonitorData(answer.data.monitorData);
          } catch (e) {}

          try {
            setEvolutionData(answer.data.evolutionSheet.evolutionList);
          } catch (e) {}

          setShowTicket(true);
        })
        .catch((err) => {
          setShowTicket(false);
          context && context.setWrongCredentials(true);
        });
    }
  };

  useEffect(() => {
    handleGetTicketData();

    // eslint-disable-next-line
  }, [password]);

  return !showTicket ? (
    <div className="secondOpinionLogin">
      <SecondOpinionLogin />
    </div>
  ) : (
    <div className="ticketWrapper">
      <div className="title">
        <h2>Fisa Observatie</h2>
      </div>

      <div className="body">
        <SecondOpinionBody />
      </div>

      <div>
        <EvolutionList
          history={history}
          location={location}
          ticketId={ticketId}
          evolutionData={evolutionData}
        />
      </div>

      {monitorData && (
        <div>
          <RapoarteTemperatura allData={monitorData} />
        </div>
      )}
    </div>
  );
};

export default SecondOpinion;
