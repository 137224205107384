import React, { useState } from "react";
import QrReader from "react-qr-reader";
import { Grid, Button } from "@material-ui/core";

const DeviceQR = (props) => {
  const { handleError, setMonitorId, showWarning } = props;

  // eslint-disable-next-line
  const [code, setCode] = useState("");
  const [openCamera, setOpenCamera] = useState(false);

  const handleScan = async (data) => {
    if (data && data != null) {
      setMonitorId(data);
      return;
    }
  };
  const handleDontScan = () => {
    setMonitorId(null);
  };

  const opneCamera = () => {
    setOpenCamera(true);
  };

  return (
    <Grid container className={"qr_root"}>
      <Grid item xs={12} md={12} className={"title_container"}>
        <h1>Va rugam sa scanati codul QR de pe monitorul de functii vitale!</h1>
      </Grid>
      <div className="warningTextCameraContainer">
        {showWarning ? (
          <h5 className="warningTextCamera">
            ID-ul este gresit, va rugam scanati din nou bratara pacientului
          </h5>
        ) : null}
      </div>

      {openCamera ? (
        <Grid item xs={12} md={12} lg={12} className={"camera_container"}>
          <div className={"qr_container"}>
            <QrReader
              delay={10}
              onError={handleError}
              onScan={handleScan}
              resolution={2000}
              style={{ width: "100%" }}
              constraints={{
                focusMode: "continuous",
                sharpness: 999,
                torch: true,
                exposureMode: "continuous",
                whiteBalanceMode: "continuous",
                focusDistance: 100,
                facingMode: "environment",
              }}
            />
          </div>
        </Grid>
      ) : (
        <Grid item container className={"body_wrapper"}>
          <div className="questionContainer">
            <div className="titleWrapper">
              <h1 className={"title_container"}>
                Doriti sa scanati monitorul?
              </h1>
            </div>

            <div className="buttonsContainer">
              <Button className={"scanButton"} onClick={opneCamera}>
                Da, scanez
              </Button>
              <Button className={"passButton"} onClick={handleDontScan}>
                Nu scanez
              </Button>
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  );
};
export default DeviceQR;
