import { useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";
import DraggableScanButton from "../../components/DraggableScanButton/DraggableScanButton";
import { LeftSidebar } from "../../layout-blueprints";

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    height: "100%",
  },
}));

const IsLogged = (props) => {
  const { location, children } = props;

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.3,
  };

  const pageName = location.pathname.split("/")[1] || "homepage";

  const classes = useStyles();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <div className={`pageWrapper ${pageName}`}>
        <main className={classes.content}>
          <DraggableScanButton />
          <LeftSidebar pageName={pageName}>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              {children}
            </motion.div>
          </LeftSidebar>
        </main>
      </div>
    </div>
  );
};

IsLogged.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default IsLogged;
