export const operatingRoomsReducer = (state, action) => {

    switch (action.type) {
        case 'SET_ROOMS':
            return [
                ...state,
                ...action.payload
            ];
        default:
            return state;
    }
}