const comunicarePacient = [
  {
    key: "admissionType",
    label: "Tipul evaluarii:",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["evaluare initiala", "reevaluare"]
    }
  },

  {
    key: "date",
    label: "Data evaluarii",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },
  {
    label: "1. Comunicarea cu pacientul",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "inabilityToCommunicate",
    label: "Incapacitate fizica / psihica de comunicare cu pacientul",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "patientUnderstandsTheDiagnosis",
    label: "Pacientul intelege diagnosticul",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: [
        "Intelegere deplina",
        "Intelegere partiala",
        "Pacientul nu intelege",
        "Pacientul nu comunica"
      ]
    }
  },
  {
    key: "patientUnderstandsThePrognosis",
    label: "Pacientul intelege prognosticul",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: [
        "Intelegere deplina",
        "Intelegere partiala",
        "Pacientul nu intelege",
        "Pacientul nu comunica"
      ]
    }
  },
  {
    key: "pediatricPatient",
    label: "Pacientul pediatric",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key: "moreInfo",
    label: "Pacientul doreste mai multe informatii:",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["da", "nu"]
    }
  }
];

export default comunicarePacient;
