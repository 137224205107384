const evolutieSimptome = [
  {
    title: "",
    key: "medication",
    label: "Medicatie Prescriptie regulata",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      isDisabled: false,
    },
  },
  {
    title: "",
    key: "singleDose",
    label: "Doza unica",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      isDisabled: false,
    },
  },
  {
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: [],
    },
  },

  {
    title: "",
    key: "routeOfAdministration",
    label: "Cale Administrare",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      isDisabled: false,
    },
  },

  {
    title: "",
    key: "startDate",
    label: "Data incepere",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
      isDisabled: false,
    },
  },
  {
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: [],
    },
  },

  {
    title: "",
    key: "numberOfDays",
    label: "Nr. zile",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      isDisabled: false,
    },
  },
  {
    title: "",
    key: "frequency",
    label: "Frecventa",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      isDisabled: false,
    },
  },
  {
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: [],
    },
  },
];

export default evolutieSimptome;
