import DateFnsUtils from "@date-io/date-fns";
import { Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import * as moment from "moment";
import React, { useState } from "react";
import { REST } from "../../../Utils/index";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    width: "100%",
  },

  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {},
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "60%",
  },
  datePicker: {
    height: "20px",
  },
}));

const BedManagement = (props) => {
  const {
    formState,
    data,
    getAvailableBeds,
    getDrId,
    getDrBeds,
    assignedBeds,
    drId,
    setBedModal,
    closeModal,
    getData,
  } = props;

  const classes = useStyles();
  // eslint-disable-next-line
  const [state, setState] = useState({
    doctorName: "",
    id: 0,
  });

  const [assignBed, setAssignBeds] = useState(0);
  const [removeBeds, setRemoveBeds] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleSelectDoctor = (event) => {
    getDrId(event.target.value);
    getDrBeds(event.target.value);
  };

  const addBeds = (event) => {
    setAssignBeds(event.target.value);
  };

  const handleRemoveBeds = (event) => {
    setRemoveBeds(event.target.value);
  };

  const handleStartDate = (date) => {
    const start = moment(date).format("YYYY/MM/DD");
    setStartDate(start);
  };

  const handleEndtDate = (date) => {
    const end = moment(date).format("YYYY/MM/DD");
    setEndDate(end);
  };

  const allocateBed = async () => {
    await REST.post(
      `/spital/allocationBedsToDoctors?departmentId=${formState.values.selectData.spec}&doctorId=${drId}&endDate=${endDate}&numberOfBeds=${assignBed}&startDate=${startDate}`
    )
      .then((answer) => {
        setBedModal(false);
        closeModal();
        getData();
      })

      .catch((err) => {});
  };

  const handleRemove = async () => {
    await REST.post(
      `/spital/removeBedsFromDoctor?doctorId=${drId}&numberOfBeds=${removeBeds}`
    )
      .then((answer) => {
        setBedModal(false);
        closeModal();
        getData();
      })

      .catch((err) => {
        setBedModal(false);
        closeModal();
        getData();
      });
  };

  const handleBeds = () => {
    if (assignBed !== 0) {
      allocateBed();
      closeModal();
      getData();
    } else if (removeBeds !== 0) {
      handleRemove();
    } else {
      return;
    }
  };

  return (
    <div className="bed_mangament_root">
      <div>
        <span
          className={"close_btn"}
          onClick={() => getData}
          style={{ cursor: "pointer" }}
        >
          x
        </span>
      </div>
      <div className={"select_doctor_container"}>
        <h2 className={"action_labels"}>Selecteaza doctor</h2>
        <div className={"select_doctor"}>
          <FormControl className={classes.formControl} variant="filled">
            <NativeSelect
              value={state.name}
              onChange={handleSelectDoctor}
              className={classes.selectEmpty}
              // inputProps={{ 'aria-label': 'age' }}
            >
              <option value={0}>Selecteaza doctor</option>

              {data &&
                data.map((n, lindex) => {
                  return (
                    <option key={n.medic.id} value={n.medic.id}>
                      {n.medic.doctorName}
                    </option>
                  );
                })}
            </NativeSelect>
          </FormControl>
        </div>
        <div className={"all_beds"}>
          <div className={"beds_info"}>
            <div className={"assigned_beds"}>
              <h4 className={"info_txt"}>PATURI ATRIBUITE: </h4>
              <div className={"bed_label"}>
                <h4>{assignedBeds}</h4>
              </div>
            </div>
            <div className={"available_beds"}>
              <h4 className={"info_txt"}>PATURI DISPONIBILE: </h4>
              <div className={"bed_label"}>
                <h4>{getAvailableBeds}</h4>
              </div>
            </div>
          </div>

          <div className={"assign_bed"}>
            <h3 className={"action_labels"}>Adauga paturi</h3>
            <div>
              <TextField
                id="filled-basic"
                variant="filled"
                onChange={(event) => {
                  addBeds(event);
                }}
              />
            </div>
          </div>
          <div className={"date_container"}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div>
                <p className={"label_text"}>Din data:</p>
                <KeyboardDatePicker
                  disableToolbar
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={startDate}
                  onChange={handleStartDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  className={classes.datePicker}
                />
              </div>
              <div>
                <p className={"label_text"}>Pana in data:</p>
                <KeyboardDatePicker
                  disableToolbar
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={endDate}
                  onChange={handleEndtDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  className={classes.datePicker}
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>

          <div className={"assign_bed"}>
            <h3 className={"action_labels"}>Scade paturi</h3>
            <div>
              <TextField
                id="filled-basic"
                variant="filled"
                onChange={(event) => {
                  handleRemoveBeds(event);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={"confirm_btn_container"}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={"singInButton"}
          onClick={handleBeds}
        >
          <h4 className={"btn_text"}>Confirm</h4>
        </Button>
      </div>
    </div>
  );
};

export default BedManagement;
