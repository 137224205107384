import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { DatePickerInput, TimePickerInput } from "../Inputs";
import moment from "moment";

const IntraHospitalTransfer = (props) => {
  const { item, hasError, formState, handleChange, hideAddButton } = props;

  var itemName = item.key;
  const [indexMap, setIndexMap] = useState(0);
  const [inputsVal, setInputsVal] = useState([
    ...(formState.values[item.key] !== undefined
      ? [formState.values[item.key]]
      : [""]
    ).map((e) => ""),
  ]);

  const [inputs, setInputs] = useState(
    formState.values[item.key] ? formState.values[item.key] : []
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const [admissionDate, setAdmissionDate] = useState("");
  const [dischargeDate, setDischargeDate] = useState("");
  // eslint-disable-next-line
  const [hospitalizationDays, sethospitalizationDays] = useState("");

  const handleMyChange = async (event, key, name, index) => {
    event.persist();
    let vals = inputs;
    vals[index] = vals[index] ? vals[index] : {};
    vals[index][key] = event.target.value;

    setCurrentIndex(index);

    if (vals[index].AdmissionDate) {
      setAdmissionDate(vals[index].AdmissionDate);
    }

    if (vals[index].DischargeDate) {
      setDischargeDate(vals[index].DischargeDate);
    }

    if (vals[index].NrOfDays) {
      sethospitalizationDays(vals[index].NrOfDays);
    }

    setInputs(vals);
    handleChange({
      target: {
        name: name,
        type: "multiple",

        value: vals,
        item: item,
      },
      persist: () => true,
    });
  };

  React.useEffect(() => {
    if (inputs.length) {
      setInputsVal([...inputs.map((e) => "")]);
    }
    // eslint-disable-next-line
  }, []);

  const addInput = () => {
    setInputsVal([...inputsVal, ""]);
    setIndexMap(indexMap + 1);
  };

  useEffect(() => {
    const from = moment(admissionDate).format("YYYY-MM-DD");
    const to = moment(dischargeDate).format("YYYY-MM-DD");

    const start = moment(from, "YYYY-MM-DD");
    const end = moment(to, "YYYY-MM-DD");

    //Difference in number of days
    let diferenta = moment.duration(start.diff(end)).asDays();
    diferenta = parseInt(-diferenta);
    handleMyChange(
      { target: { value: diferenta }, persist: () => true },
      "NrOfDays",
      itemName,
      currentIndex
    );

    // eslint-disable-next-line
  }, [admissionDate, dischargeDate]);

  return (
    <Grid container className={"transfer__root"}>
      <Grid item xs={12} md={12} className={"header__container"}>
        <div className={"titleContainer"}>
          <p className={"title"}>Transfer intre sectile spitalului:</p>

          {hideAddButton ? (
            <div></div>
          ) : (
            <div className={"buttonsContainer"}>
              <Button className={"addButton"} onClick={(e) => addInput(e)}>
                <p className={"buttonText"}>+</p>
              </Button>
            </div>
          )}
        </div>
      </Grid>

      <Grid item container className={"table__header"}>
        <Grid item xs={3} md={2}>
          <p>Sectia</p>
        </Grid>

        <Grid item xs={2} md={2}>
          <p>Data Intrarii</p>
        </Grid>

        <Grid item xs={1} md={2}>
          <p>Ora</p>
        </Grid>

        <Grid item xs={2} md={2}>
          <p>Data Iesirii</p>
        </Grid>

        <Grid item xs={1} md={2}>
          <p>Ora</p>
        </Grid>

        <Grid item xs={1} md={1}>
          <p>Nr. zile spit.</p>
        </Grid>
      </Grid>

      {inputsVal.map((input, index) => (
        <Grid
          item
          container
          className={"table__inputs"}
          key={`IntraHospitalTransfer_${index}`}
        >
          <Grid item xs={3} md={2} className={"input__container"}>
            <div className={"input__sectie"}>
              <TextField
                id={`${itemName}${index ? index : ""}Department`}
                name={`${itemName}${index ? index : ""}Department`}
                label={item.displayLabel}
                onChange={(event) => {
                  event.persist();
                  handleMyChange(event, "Department", itemName, index);
                }}
                value={inputs[index] ? inputs[index]["Department"] || "" : ""}
              />
            </div>
          </Grid>

          <Grid item xs={2} md={2} className={"input__container"}>
            <div className={"input__dIntarare"}>
              <div className="textFieldContainer">
                <DatePickerInput
                  formState={{
                    values: {
                      AdmissionDate: formState.values[itemName]
                        ? formState.values[itemName][index]
                          ? formState.values[itemName][index]["AdmissionDate"]
                          : null
                        : null,
                    },
                  }}
                  index={index}
                  item={{ key: "AdmissionDate", parent: itemName }}
                  hasError={hasError}
                  handleChange={(event) => {
                    event.persist();
                    handleMyChange(event, "AdmissionDate", itemName, index);
                  }}
                  id={`${itemName}${index ? index : ""}AdmissionDate`}
                  name={`${itemName}${index ? index : ""}AdmissionDate`}
                  label={item.displayLabel}
                  helperText={
                    hasError(itemName) ? formState.errors[itemName][0] : null
                  }
                  customStyle={item.answer.customStyle}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={1} md={2} className={"input__container"}>
            <div className={"input__ora"}>
              <div className="textFieldContainer">
                <TimePickerInput
                  item={{ key: "AdmissionHour", parent: itemName }}
                  index={index}
                  hasError={hasError}
                  handleChange={(event) => {
                    event.persist();
                    handleMyChange(event, "AdmissionHour", itemName, index);
                  }}
                  formState={formState}
                  id={`${itemName}${index ? index : ""}AdmissionHour`}
                  name={`${itemName}${index ? index : ""}AdmissionHour`}
                  label={item.displayLabel}
                  helperText={
                    hasError(itemName) ? formState.errors[itemName][0] : null
                  }
                  value={inputs[index] ? inputs[index]["AdmissionHour"] : ""}
                  customStyle={item.answer.customStyle}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={2} md={2} className={"input__container"}>
            <div className={"input__dIesire"}>
              <div className="textFieldContainer">
                <DatePickerInput
                  formState={{
                    values: {
                      DischargeDate: formState.values[itemName]
                        ? formState.values[itemName][index]
                          ? formState.values[itemName][index]["DischargeDate"]
                          : null
                        : null,
                    },
                  }}
                  item={{ key: "DischargeDate", parent: itemName }}
                  index={index}
                  hasError={hasError}
                  handleChange={(event) => {
                    event.persist();
                    handleMyChange(event, "DischargeDate", itemName, index);
                  }}
                  id={`${itemName}${index ? index : ""}DischargeDate`}
                  name={`${itemName}${index ? index : ""}DischargeDate`}
                  label={item.displayLabel}
                  helperText={
                    hasError(itemName) ? formState.errors[itemName][0] : null
                  }
                  customStyle={item.answer.customStyle}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={1} md={2} className={"input__container"}>
            <div className={"input__ora"}>
              <div className="textFieldContainer">
                <TimePickerInput
                  item={{ key: "DischargeHour", parent: itemName }}
                  index={index}
                  hasError={hasError}
                  handleChange={(event) => {
                    event.persist();
                    handleMyChange(event, "DischargeHour", itemName, index);
                  }}
                  formState={formState}
                  id={`${itemName}${index ? index : ""}DischargeHour`}
                  name={`${itemName}${index ? index : ""}DischargeHour`}
                  label={item.displayLabel}
                  helperText={
                    hasError(itemName) ? formState.errors[itemName][0] : null
                  }
                  value={inputs[index] ? inputs[index]["DischargeHour"] : ""}
                  customStyle={item.answer.customStyle}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={1} md={1} className={"input__container"}>
            <div className={"input__zileSpit"}>
              {/* <div className="textFieldContainer" > */}
              <TextField
                id={`${itemName}${index ? index : ""}NrOfDays`}
                name={`${itemName}${index ? index : ""}NrOfDays`}
                item={{ key: "NrOfDays", parent: itemName }}
                label={item.displayLabel}
                // value={hospitalizationDays || 0}
                value={inputs[index] ? inputs[index]["NrOfDays"] || "" : 0}
              />
              {/* </div> */}
            </div>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default IntraHospitalTransfer;
