export const aparatTypes = [
  "VITAL APSEC(RESP)",
  "VITAL rPR(spo2)",
  "VITAL CVP(S)",
  "VITAL PR(spo2)",
  "VITAL MONITOR ID",
  "VITAL PR(art)",
  "VITAL ART(D)",
  "VITAL CVP(M)",
  "VITAL STV2",
  "VITAL CVP(D)",
  "VITAL STV1",
  "VITAL STV4",
  "VITAL STV3",
  "VITAL ST1",
  "VITAL STV6",
  "VITAL ST2",
  "VITAL STV5",
  "VITAL ST3",
  "VITAL SpO2",
  "VITAL HR",
  "VITAL STVF",
  "VITAL RESP",
  "VITAL TRECT",
  "VITAL ART(M)",
  "VITAL STVL",
  "VITAL STVR",
  "VITAL DATE",
  "VITAL rRESP(imp)",
  "VITAL TSKIN",
  "VITAL ART(S)",
  "VITAL VPC",
];
