const medicatie = [
  // drugAllergies
  {
    title: "",
    key: "drugAllergies",
    label: "Alergii medicamentoase",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },

  {
    title: "",
    key: "dateOfAdministration",
    label: "Data administrarii",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },

  {
    title: "",
    key: "startDate",
    label: "Ora administrarii",
    answer: {
      type: "TimePickerInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },

  {
    title: "",
    key: "doctor",
    label: "Doctor",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },

  {
    title: "",
    key: "dose",
    label: "Dozaj",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },

  {
    title: "",
    key: "maxDoseIn24Hours",
    label: "Max / 24h",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },

  {
    title: "",
    key: "medication",
    label: "Medicatie",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  },

  {
    title: "",
    key: "routeOfAdministration",
    label: "Cale de administrare",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      isDisabled: false
    }
  }
];

export default medicatie;
