import React from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const MyCustomEvent = (props) => {
  const { appointmentInfo, handleAgenda } = props;

  return (
    <div className={`modal-cell calendar-cell-${appointmentInfo.department}`}>
      <div className="close-button-container">
        <HighlightOffIcon className="close-button" onClick={handleAgenda} />
      </div>
      {appointmentInfo.procedure && (
        <h4 className={`modal-header`}>
          Procedura: {appointmentInfo.procedure}
        </h4>
      )}
      {appointmentInfo.department && (
        <div className={`modal-department`}>
          Sectia: {appointmentInfo.department}{" "}
        </div>
      )}
      {appointmentInfo.doctor && (
        <div className={`modal-doctor`}>Dr: {appointmentInfo.doctor} </div>
      )}
      {appointmentInfo.patient && (
        <div className={`modal-patient`}>
          Pacient: {appointmentInfo.patient}
        </div>
      )}
    </div>
  );
};

export default MyCustomEvent;
