const reactiiEmotionale = [
  {
    label:
      "3. Reactii emotionale la boala, la consecintele acesteia si optiunile terapeutice",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "emotionalReactionsToIllness",
    label: "Reactii emotionale",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    label: '4. "Va simtiti deprimat?"',
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "depression",
    label: "Raspuns",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    label: "5. Recomandare pentru asistenta psihologica",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "recommendationForPatientPsychologicalAssistance",
    label: "Recomandare",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    label: "6. Colaborarea pacientului cu personalul de ingrijire",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "thePatientIsCompliantWithTreatment",
    label: "Pacientul este compliant la tratament / ingrijiri",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["da", "nu"]
    }
  },

  {
    label: "7. Luarea deciziilor",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "thePatientInTheManagementDecisionsProcess",
    label:
      "Modul de implicare a pacientului / familiei in procesul decizional vizand managementul de caz:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  }
];

export default reactiiEmotionale;
