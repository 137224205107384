import { Checkbox, FormControlLabel } from "@material-ui/core";
import lscache from "lscache";
import React, { useEffect, useState } from "react";

const CheckBoxInput = (props) => {
  const { item, formState, handleChange } = props;

  var itemName = item.key;

  const [ev, setEv] = useState({
    target: {
      name: null,
      type: null,
      value:
        (formState && formState.values && formState.values[itemName]) || null,
    },
    persist: () => true,
  });

  const changeHandler = (event) => {
    event.persist();

    setEv((prevState) => {
      return {
        ...prevState,
        target: {
          value: event.target.value,
          type: event.target.type,
          name: event.target.name,
          checked: event.target.checked,
        },
      };
    });
  };

  useEffect(() => {
    handleChange(ev);
    // eslint-disable-next-line
  }, [ev]);

  useEffect(() => {
    setEv((prevState) => {
      return {
        ...prevState,
        target: {
          value:
            (formState && formState.values && formState.values[itemName]) ||
            null,
        },
      };
    });
    // eslint-disable-next-line
  }, [formState]);

  return (
    <div className="answerBox">
      {lscache.get("operatorType") === "SefSectie" ? null : (
        <div className="labelContainer">
          <p className="labelText">{item.label}</p>
        </div>
      )}
      <div className="checkboxContainer">
        {item.answer.selectList.map((el) => {
          const isChecked =
            formState.values &&
            formState.values[itemName] &&
            formState.values[itemName].includes(el);

          return (
            <FormControlLabel
              key={`check_box_input_${el}`}
              label={el}
              className="checkboxList"
              control={
                <Checkbox
                  id={`${itemName + "_" + el}`}
                  name={`${itemName}`}
                  label={`${el}`}
                  className="checkboxElement"
                  onChange={changeHandler}
                  value={el}
                  checked={!!isChecked}
                />
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default CheckBoxInput;
