import clsx from "clsx";
import { Button as Btn, Card, Collapse } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import FoaiePaliative from "./FoaiePaliative.form";

const Accordion12 = (props) => {
  const { sheetId, toggleAccordion, accordionStates } = props;

  return (
    <Card
      className={clsx("card-box", {
        "panel-open": accordionStates[`accordion16`],
      })}
    >
      <Card>
        <div className="card-header">
          <div className="panel-title">
            <div className="accordion-toggle">
              <Btn
                variant="text"
                size="large"
                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                onClick={() => toggleAccordion(16)}
                aria-expanded={accordionStates[`accordion16`]}
              >
                <span>Foaie de Observatie - Ingrijiri paliative</span>
                <FontAwesomeIcon
                  icon={["fas", "angle-up"]}
                  className="font-size-xl accordion-icon"
                />
              </Btn>
            </div>
          </div>
        </div>
        {accordionStates[`accordion16`] && (
          <Collapse in={accordionStates[`accordion16`]}>
            <div className={"accordion_body"}>
              <FoaiePaliative sheetId={sheetId} />
            </div>
          </Collapse>
        )}
      </Card>
    </Card>
  );
};

export default Accordion12;
