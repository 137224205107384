import React, { useState, useEffect } from "react";
import { REST } from "../../../Utils/index";

import PacientQR from "./components/pacient";
import BedQR from "./components/bed";
import DeviceQR from "./components/deviceHL7";

const QrSCan = () => {
  // const { data } = props
  const [status, setStatus] = useState("");
  // eslint-disable-next-line
  const [error, setError] = useState("");
  const [currentStep, setCurrentStep] = useState("pacient");
  const [ticketId, setTicketId] = useState("");
  const [bedId, setBedId] = useState("");
  const [monitorId, setMonitorId] = useState("");
  const [showWarning, setShowWarning] = useState(false);

  const handleCurrentStep = (value) => {
    setCurrentStep(value);
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleTicketId = (value) => {
    setTicketId(value);
  };
  const handleBedId = (value) => {
    setBedId(value);
  };
  const handleMonitorId = (value) => {
    setMonitorId(value);
  };

  const updateAll = () => {
    if (monitorId) {
      updateMonitor(monitorId);
    }
    if (bedId) {
      updateBed(bedId);
    }
    if (ticketId) {
      updateTicket(ticketId);
    }
  };

  useEffect(() => {
    updateAll();
    // eslint-disable-next-line
  }, [monitorId]);

  const updateTicket = async (data) => {
    await REST.put(`/spital/updateTicketStatus?status=Medic&ticketId=${data}`)
      .then((answer) => {
        setStatus("registratura");
        setShowWarning(false);

        window.location =
          answer.data.ticketType === "Ati_Ticket"
            ? `/ati-id/${ticketId}`
            : `/chestionar/${ticketId}`;
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          setShowWarning(true);
        }
      });
  };

  const updateBed = async (data) => {
    await REST.post(`/spital/scanBed?bedId=${data}&ticketId=${ticketId}`)
      .then((answer) => {})
      .catch((err) => {});
  };

  const updateMonitor = async (data) => {
    await REST.post(`spital/scanMonitor?monitorId=${data}&ticketId=${ticketId}`)
      .then((answer) => {
        setError({ message: null });
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          setError({
            message,
          });
        }
      });
  };
  if (currentStep === "pacient") {
    return (
      <PacientQR
        handleError={handleError}
        updateTicket={updateTicket}
        status={status}
        setCurrentStep={handleCurrentStep}
        setTicketId={handleTicketId}
      />
    );
  } else if (currentStep === "bed") {
    return (
      <BedQR
        handleError={handleError}
        updateTicket={updateTicket}
        status={status}
        setCurrentStep={handleCurrentStep}
        setBedId={handleBedId}
      />
    );
  } else if (currentStep === "device") {
    return (
      <DeviceQR
        handleError={handleError}
        updateTicket={updateTicket}
        status={status}
        setCurrentStep={handleCurrentStep}
        setMonitorId={handleMonitorId}
        ticketId={ticketId}
        showWarning={showWarning}
      />
    );
  }
};

export default QrSCan;
