const domiciliu = [
  {
    label: "Domiciliul legal:",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    label: "Domiciliul legal:",
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: []
    }
  },
  {
    title: "",
    key: "legalCounty",
    label: "Judet",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  },
  {
    title: "",
    key: "legalCity",
    label: "Localitate",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  },
  {
    title: "",
    key: "legalStreet",
    label: "Strada",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  },
  {
    title: "",
    key: "legalStreetNr",
    label: "Numarul Strazii",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  }
];

export default domiciliu;
