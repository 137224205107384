import { all } from "../../../../../../../../Utils/spitalList";

const stareExternare = [
  {
    title: "",
    key: "intraDischargeState",
    label: "Starea la externare:",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: ["vindecat", "ameliorat", "stationar", "agravat", "decedat"],
      className: "dischargeButton"
    }
  },
  {
    title: "",
    key: "intraDischargeType",
    label: "Tipul externarii:",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: [
        "externat",
        "externat la cerere",
        "transfer intraspitalicesc",
        "decedat"
      ]
    }
  },
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },
  {
    title: "",
    key: "intraDeath",
    label: "Deces",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: [
        "intraoperator",
        "postoperator: 0-23 ore",
        "24-47 ore",
        ">48 ore"
      ]
    }
  },
  {
    title: "",
    key: "intraDeathDate",
    label: "Data Decesului",
    answer: {
      type: "DatePickerInput",
      required: true
    }
  },
  {
    title: "",
    key: "intraDeathHour",
    label: "Ora Decesului",
    answer: {
      type: "TimePickerInput",
      required: true
    }
  },
  {
    key: "ifCaseDeceased",
    label: "Diagnostic in caz de deces:",
    answer: {
      type: "MultipleLongTextInput",
      required: true,

      selectList: [],
      labelList: [
        { directCause: "Cauza directa (imediata): " },
        { anteCause: "Cauza antecedenta" },
        { initialMorbidState: "Stari morbide initiale:" }
      ],
      showRecord: false,
      isCaseDeceased: true,
      dateLabel: "Data si ora decesului:"
    }
  },

  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "conditions",
    label: "Conditii de viata si munca",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: all
    }
  },

  {
    key: "behavior",
    label: "Comportamente (fumat, alcool etc.)",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: all
    }
  }
];

export default stareExternare;
