import React, { useState, useEffect } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import { REST } from "../../../Utils";
import moment from "moment";
import { useSnackbar } from "notistack";

const BedItem = (props) => {
  const {
    item,
    selectedDay,
    formState,
    getData,
    handlePatientId,
    rerenderFraier,
    assignToMedic,
    toggleForGetData,
    setToggleForGetData,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [patientName, setPatientName] = useState("");
  // eslint-disable-next-line
  const [availability, setAvailability] = useState(item.status);
  const [anchorEl, setAnchorEl] = useState(null);
  const [itemStatus, setItemStatus] = useState(item.status);
  const open = !!anchorEl;

  const dayForRequest = moment(selectedDay).format("YYYY/MM/DD");

  useEffect(() => {
    if (item.bedId === 4 || item.bedId === 7) setAvailability(item.status);
    handlePatientId(item.patientId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (item.bedId === 4 || item.bedId === 7) setAvailability(item.status);
    handlePatientId(item.patientId);
    // eslint-disable-next-line
  }, [toggleForGetData]);

  const handleClick = (event) => {
    if (event.target === event.currentTarget) {
      setAnchorEl(event.currentTarget);
      getPatient();
    }
  };

  const handleClose = (event) => {
    if (assignToMedic) {
      transferPatient();
    } else {
      handleBedAvailability();
    }
    setAvailability(event.target.value);
    setAnchorEl(null);
  };

  const handleBedAvailability = async () => {
    await REST.post(
      `callcenter/updateBedStatus?medicalBedId=${item.bedId}&doctorId=${item.doctorId}&patientCnp=${formState.values.CNP}&startDate=${dayForRequest}`
    )
      .then(async (answer) => {
        setAvailability(answer.data.status);
        getData().then((answer) => {
          setItemStatus("Ocupat");
          enqueueSnackbar(`Patul cu ID ${item.bedId} a fost ocupat !`);
        });

        // window.location.reload();
      })
      .catch((err) => {});
  };

  const transferPatient = async () => {
    const currentDay = new Date();
    // setItemStatus("Ocupat")
    await REST.post(
      `/callcenter/transferPatientInOtherBed?doctorId=${
        item.doctorId
      }&medicalBedId=${item.bedId}&patientCnp=${
        formState.values.CNP
      }&startDate=${moment(currentDay).format("YYYY/MM/DD")}`
    )
      .then((answer) => {
        setToggleForGetData(!toggleForGetData);
        enqueueSnackbar(`Patul cu ID ${item.bedId} a fost ocupat !`);
      })
      .catch((err) => {
        setToggleForGetData(!toggleForGetData);
        enqueueSnackbar(`Patul cu ID ${item.bedId} a fost ocupat !`);
      });
  };

  const getPatient = async () => {
    await REST.get(`/spital/getPatientByPatientId?patientId=${item.patientId}`)
      .then((answer) => {
        setPatientName(answer.data.lastName + " " + answer.data.firstName);
      })
      .catch((err) => {});
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <div className={"beds_container"}>
      <div
        key={rerenderFraier}
        className={
          itemStatus === "Liber" && item.patientId === 0
            ? "availableBed"
            : "unavailableBed"
        }
        onClick={handleClick}
      >
        {anchorEl && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Menu
              id={item.id}
              anchorEl={anchorEl}
              keepMounted
              open={open}
              TransitionComponent={Fade}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ borderBottom: "1px solid", padding: "5px" }}>
                  <div onClick={handleClickAway}>
                    <p
                      onClick={handleClickAway}
                      style={{
                        fontSize: "13px",
                        cursor: "pointer",
                        textAlign: "end",
                        color: "red",
                        margin: 0,
                      }}
                    >
                      x
                    </p>
                  </div>
                  ID Pat: {item.bedId} + Status pat: {item.status} <br />
                  {item.patientId}:{" "}
                  {patientName !== "undefined undefined"
                    ? patientName
                    : "Pat liber"}
                </div>
                {item.status === "Liber" ? (
                  <div>
                    <MenuItem value={"Ocupat"} onClick={handleClose}>
                      Ocupa
                    </MenuItem>
                  </div>
                ) : (
                  <div>
                    <MenuItem value={"Liber"} onClick={handleClose}>
                      Elibereaza
                    </MenuItem>
                  </div>
                )}
              </div>
            </Menu>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
};

export default BedItem;
