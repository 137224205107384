const managementPlan = [
  {
    title: "",
    key: "date",
    label: "Selecteaza Data",
    answer: {
      type: "DatePickerInput",
      required: true,
      selectList: [],
      isDisabled: false,
    },
  },

  {
    title: "",
    key: "conclusion",
    label: "Concluzie",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      isDisabled: false,
    },
  },

  {
    title: "",
    key: "purpose",
    label: "Obiectiv / Interventii",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      isDisabled: false,
    },
  },
];

export default managementPlan;
