import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Recorder from "../Recorder/Recorder";
import { Button } from "@material-ui/core";

const LongTextInput = (props) => {
  const {
    item,
    formState,
    handleChange,
    hasError,
    label,
    className,
    handleModal,
    handleUploadedFilesModal,
    ticketId,
  } = props;

  const [speech, setSpeech] = useState(
    formState.values[item.key] ? formState.values[item.key] : ""
  );


  var itemName = item.key;

  const handleSpeech = (answer) => {
    setSpeech(speech + " " + answer);
    handleChange({
      target: { value: answer, name: itemName, type: "Text" },
      persist: () => true,
    });
  };

  return (
    <div className={item && className ? className : "answerBox"}>
      <div className="labelContainer">
        <p className="labelText">{label}</p>
        {label === "ALTE EXAMENE DE SPECIALITATE" || label === "EXAMENE DE LABORATOR" || label === "EXAMENE RADIOLOGICE (rezultate)" || label === "EXAMENE ECOGRAFICE (rezultate)" || label === "EXAMENE ANATOMO-PATOLOGICE:" ? (
          <p style={{ cursor: "pointer", color:"blue" }} onClick={() => handleUploadedFilesModal(label)}>Vezi documente salvate</p>) : null}
      </div>

      <div className="textFieldContainer">
        <TextField
          id={`${itemName}`}
          name={`${itemName}`}
          label={item.displayLabel}
          className="textField"
          multiline={true}
          rows={1}
          rowsMax={10}
          onChange={(event) => {
            event.persist();
            setSpeech(event.target.value);
          }}
          onBlur={(event) => {
            event.persist();
            handleChange(event);
          }}
          error={hasError(itemName)}
          helperText={
            hasError(itemName)
              ? formState.errors[itemName]
                ? formState.errors[itemName][0]
                : null
              : null
          }
          value={speech || ""}
        />
        <Recorder
          showRecord={true}
          handleSpeech={handleSpeech}
          ticketId={ticketId}
        />
        <div className={`upl_btn_container`}>
          {label === "ALTE EXAMENE DE SPECIALITATE" ||
          label === "EXAMENE DE LABORATOR" ||
          label === "EXAMENE RADIOLOGICE (rezultate)" ||
          label === "EXAMENE ECOGRAFICE (rezultate)" ||
          label === "EXAMENE ANATOMO-PATOLOGICE:" ? (
            <Button
              className={"edit__button"}
              onClick={() => handleModal(label)}
            >
              Upload
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LongTextInput;
