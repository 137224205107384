import React, { useState, useEffect } from "react";
import { Select, MenuItem } from "@material-ui/core";
import { REST } from "../../../../../../Utils";
import { useAti } from "../../../../../../hooks";

const DowntonBody = (props) => {
  const { ticketId } = props;
  const ctx = useAti();

  const [initialData, setInitialData] = useState(null);
  const [option, setOption] = useState({
    previousFalls: "NU",
    medication: "Nici unul",
    sensoryDeficiency: "Nici unul",
    mentalState: "Orientat",
    movement: "Normala",
  });
  const [score, setScore] = useState(0);

  useEffect(() => {
    setInitialData(ctx.rState.acc15.values.downtown);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setScore(initialData && initialData.score);
    setOption((state) => {
      return {
        ...state,
        previousFalls: initialData && initialData.previousFalls,
        medication: initialData && initialData.medication,
        sensoryDeficiency: initialData && initialData.sensoryDeficiency,
        mentalState: initialData && initialData.mentalState,
        movement: initialData && initialData.movement,
      };
    });
    // eslint-disable-next-line
  }, [initialData]);

  const handleSore = () => {
    const falls = option.previousFalls !== "NU" ? 1 : 0;
    const meds =
      option.medication !== "Nici unul" &&
      option.medication !== "Alte medicamente"
        ? 1
        : 0;
    const deficiency = option.sensoryDeficiency !== "Nici unul" ? 1 : 0;
    const mentalState = option.mentalState !== "Orientat" ? 1 : 0;
    const movement =
      option.movement !== "Nesigura cu ajutor/fara ajutor" ? 0 : 1;
    setScore(falls + meds + deficiency + mentalState + movement);
  };

  useEffect(() => {
    handleSore();

    // eslint-disable-next-line
  }, [option]);

  useEffect(() => {
    handleAddDownton();

    // eslint-disable-next-line
  }, [score]);

  const handleAddDownton = async () => {
    const body = {
      previousFalls: option.previousFalls,
      medication: option.medication,
      sensoryDeficiency: option.sensoryDeficiency,
      mentalState: option.mentalState,
      movement: option.movement,
      score: score,
    };
    await REST.post(`/ati/addDowntown?sheetId=${ticketId}`, body);
  };

  return (
    <div style={{ paddingLef: "1rem", paddingRight: "1rem" }}>
      <div className={`downton_list`}>
        <h5 className={`label_name`}>CADERI ANTERIOARE</h5>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={option.previousFalls || ""}
          onChange={(event) => {
            setOption((state) => {
              return {
                ...state,
                previousFalls: event.target.value,
              };
            });
            handleSore();
          }}
        >
          <MenuItem value={"NU"}>NU</MenuItem>
          <MenuItem value={"DA"}>DA</MenuItem>
        </Select>
      </div>

      <div className={`downton_list`}>
        <h5 className={`label_name`}>MEDICAMENTE</h5>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={option.medication || ""}
          onChange={(event) => {
            setOption((state) => {
              return {
                ...state,
                medication: event.target.value,
              };
            });
          }}
        >
          <MenuItem value={"Nici unul"}>Nici unul</MenuItem>
          <MenuItem value={"Tranchilizante/sedante"}>
            Tranchilizante/sedante
          </MenuItem>
          <MenuItem value={"Diuretice"}>Diuretice</MenuItem>
          <MenuItem value={"Hipotensoare(nediuretice)"}>
            Hipotensoare(nediuretice)
          </MenuItem>
          <MenuItem value={"Antiparkisoniene"}>Antiparkisoniene</MenuItem>
          <MenuItem value={"Antidepresive"}>Antidepresive</MenuItem>
          <MenuItem value={"Alte medicamente"}>Alte medicamente</MenuItem>
        </Select>
      </div>

      <div className={`downton_list`}>
        <h5 className={`label_name`}>DEFICIT SENZORIAL</h5>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={option.sensoryDeficiency || ""}
          onChange={(event) => {
            setOption((state) => {
              return {
                ...state,
                sensoryDeficiency: event.target.value,
              };
            });
          }}
        >
          <MenuItem value={"Nici unul"}>Nici unul</MenuItem>
          <MenuItem value={"Alteratii vizuale"}>Alteratii vizuale</MenuItem>
          <MenuItem value={"Alteratii auditive"}>Alteratii auditive</MenuItem>
          <MenuItem value={"Extremitati(ictus,...)"}>
            Extremitati(ictus,...)
          </MenuItem>
        </Select>
      </div>

      <div className={`downton_list`}>
        <h5 className={`label_name`}>STARE MINTALA</h5>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={option.mentalState || ""}
          onChange={(event) => {
            setOption((state) => {
              return {
                ...state,
                mentalState: event.target.value,
              };
            });
          }}
        >
          <MenuItem value={"Orientat"}>Orientat</MenuItem>
          <MenuItem value={"Confuz"}>Confuz</MenuItem>
        </Select>
      </div>

      <div className={`downton_list`}>
        <h5 className={`label_name`}>DEPLASARE</h5>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={option.movement || ""}
          onChange={(event) => {
            setOption((state) => {
              return {
                ...state,
                movement: event.target.value,
              };
            });
          }}
        >
          <MenuItem value={"Normala"}>Normala</MenuItem>
          <MenuItem value={"Sigura cu ajutor"}>Sigura cu ajutor</MenuItem>
          <MenuItem value={"Nesigura cu ajutor/fara ajutor"}>
            Nesigura cu ajutor/fara ajutor
          </MenuItem>
          <MenuItem value={"Imposibila"}>Imposibila</MenuItem>
        </Select>
      </div>
      <h3 className={`downton_score`}>SCOR : {score}</h3>
    </div>
  );
};

export default DowntonBody;
