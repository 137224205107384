import React, { useContext } from "react";
import { FormStateContext } from "../../context/formStateContext";
import FormData from "./FormData";

const MultipleFormData = (props) => {
  const { formStateContext } = useContext(FormStateContext);

  const { item } = props;

  return (
    <div
      className="answerBox"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="formDataLabelContainer">
        <p className="labelText">{item.label}</p>
      </div>
      {formStateContext && (
        <div>
          <div>
            <FormData
              question="Peacemaker:"
              answer={
                formStateContext && formStateContext["peachemaker"]
                  ? "Da"
                  : "Nu"
              }
            />
          </div>
          {formStateContext && formStateContext["alcoholTobacco"] && (
            <div>
              <FormData
                question="Consum alcool/tutun:"
                answer={
                  formStateContext["alcoholTobacco"]
                    ? formStateContext["alcoholTobacco"]
                    : "Nu"
                }
              />
            </div>
          )}
          <div>
            <FormData
              question="Alergii"
              answer={
                formStateContext["alergies"]
                  ? formStateContext["alergies"]
                  : "Nu"
              }
            />
          </div>
          <div>
            <FormData
              question="Ce va supara? Ce va deranjeaza?"
              answer={
                formStateContext["whereIsThePain"]
                  ? formStateContext["whereIsThePain"]
                  : "Nu"
              }
            />
          </div>
          <div>
            <FormData
              question="Urmati vreun tratament specific? Homeopatic, medicamentos, fitoterapeutic?"
              answer={
                formStateContext["inGenTreatment"]
                  ? formStateContext["inGenTreatment"]
                  : "Nu"
              }
            />
          </div>
          <div>
            <FormData
              question="Urmati vreun tratament cu anticoagulante?:"
              answer={
                formStateContext["inAntiCoagulantTreatment"]
                  ? formStateContext["inAntiCoagulantTreatment"]
                  : "Nu"
              }
            />
          </div>
          <div>
            <FormData
              question="Urmati vreun tatament impotriva osteoporozei cu unul dintre urmatoarele medicamente : Fosamax, Fasavance, Actonelm Bonviva, Zometa, Aclasta?"
              answer={
                formStateContext["inOsteoTreatment"]
                  ? formStateContext["inOsteoTreatment"]
                  : "Nu"
              }
            />
          </div>
          <div>
            <FormData
              question="Alte incidente sau afectiuni ati mai avut? Ati mai fost supus unor interventii chirurgicale?"
              answer={
                formStateContext["hadSurgery"]
                  ? formStateContext["hadSurgery"]
                  : "Nu"
              }
            />
          </div>
          <div>
            <FormData
              question="La tratamentele sau anesteziile anterioare au aparut complicatii? Greata, lesin, anumite alergii?"
              answer={
                formStateContext["prevTreatmentIssues"]
                  ? formStateContext["prevTreatmentIssues"]
                  : "Nu"
              }
            />
          </div>
          <div>
            <FormData
              question="Suferiti de boli congenitale, de inima, vasculare, hipertensiune arteriala, atac vascular cerebral, gastrita, ulcer, probleme respiratorii (astm), boli ale ficatului, ale rinichiului, diabet, boli endocrine, boli psihice, neurologice, atacuri de panica, boli infectioase, neoplasme"
              answer={
                formStateContext["congenital"]
                  ? formStateContext["congenital"]
                  : "Niciuna"
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MultipleFormData;
