const constientizareaBolii = [
  {
    label: "2. Constientizarea bolii de catre familie",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "awarenessOfTheCurrentStageOfTheDiseaseByTheFamily",
    label: "Constientizarea stadiului actual al bolii de familie",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "thePatientOpenlyDiscussesCurrentHealthIssuesWithTheFamily",
    label:
      "Pacientul discuta deschis despre problemele actuale de sanatate cu familia",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },
  {
    key:
      "obstaclesInOpenCommunicationBetweenThePatientFamilyAndTheMedicalStaff",
    label:
      "Exista obstacole in comunicarea deschisa intre pacient / familie si personalul medical",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  }
];

export default constientizareaBolii;
