import { all } from "../../../../../../../../Utils/spitalList";

const apartinatori = [
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    label: "Apartinatori",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "caregiverName",
    label: "Numele:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: all
    }
  },

  {
    key: "caregiverDegree",
    label: "Grad de rudenie:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: all
    }
  },

  {
    key: "caregiverPhoneNumber",
    label: "Telefon:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: all
    }
  },

  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "familyDoctor",
    label: "Medic de familie:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: all
    }
  },

  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "needsAtTakeover",
    label: "Complexitate nevoi de paliatie la preluare",
    displayLabel: "Complexitate nevoi de paliatie la preluare",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: [
        "Complex (suferinta severa/comorbiditati)",
        "Necomplicat (suferinta usoara/medie)"
      ]
    }
  },

  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "prognosisEstimated",
    label:
      "Prognostic estimat la admiterea in serviciu (zile, saptamani, luni, ani):",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: all
    }
  }
];

export default apartinatori;
