import React, { useState, useEffect } from "react";
import { REST, beURL } from "../../Utils";

const UploadedFiles = (props) => {
  const {
    ticketId,
    uploaderExamsSectionType,
    uploadDocumentsLabel,
    trigger,
  } = props;

  const [files, setFiles] = useState([]);
  // const [displayedFile, setDisplayedFile] = useState(null)

  const getAllFilesBySection = async () => {
    await REST.get(
      `spital/files/getFiles?section=${uploaderExamsSectionType}&ticketId=${ticketId}`
    )
      .then((answer) => {
        if (answer.data.files) setFiles(answer.data.files);
        else setFiles("unavailable");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAllFilesBySection();
    // eslint-disable-next-line
  }, [uploaderExamsSectionType, trigger]);

  return (
    <div className={`uploadedDocuments`}>
      <h3>
        Documente existente <br /> {uploadDocumentsLabel}
      </h3>
      <div className={"uplaodModal_container"}>
        {!files && <p>in progress ...</p>}
        {files === "unavailable" && <p>nu exista documente</p>}
        {files &&
          files !== "unavailable" &&
          files.map((fileItem, indexItem) => {
            const filePath = `${beURL}/spital/files/getFileByFilename?fileName=${fileItem}&section=${uploaderExamsSectionType}&ticketId=${ticketId}`;
            return (
              <div className={`uploadedFileItem`}>
                <a href={filePath} target={`_blank`}>
                  <img src={filePath} alt="uploaded file" />
                </a>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UploadedFiles;
