export default [
  {
    key: "cas",
    label: "CAS",
    answer: {
      type: "DropDownInput",
      required: true,
      selectList: [
        "CASAOPSNAJ",
        "TRANSPORTURI",
        "CASMB",
        "JUDETEANA",
        "TALON DE PENSIE",
      ],
      isDisabled: true,
    },
  },
  {
    label: " ",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "casNumber",
    label: "Numar CAS",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "socialSecurityNr",
    label: "Nr. carnet asigurat:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "admissionType",
    label: "Tipul internarii:",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: [
        "urgenta",
        "trimitere medic de familie",
        "trimitere medic specialist ambulatoriu",
        "transport interspitalicesc",
        "la cerere",
        "altele",
      ],
    },
  },
  {
    key: "referralDiagnostic",
    label: "Diagnosticul de trimitere:",
    answer: {
      type: "AutocompleteDRGInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "admissionDiagnostic",
    label: "Diagnosticul la internare:",
    answer: {
      type: "AutocompleteDRGInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "threeDayDiagnostic",
    label: "Diagnosticul la 72 de ore:",
    answer: {
      type: "AutocompleteDRGInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "mainDischargeDiagnostic",
    label: "Diagnosticul principal la externare:",
    answer: {
      type: "AutocompleteDRGInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "secondDischargeDiagnostic",
    label: "Diagnostice secundare la externare (complicatii / comorbiditati):",
    answer: {
      type: "MultipleDRGTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "signMainPhysician",
    label: "Semnatura si parafa medicului sef",
    answer: {
      type: "MultipleSignTextInput",
      required: true,
      selectList: [],
      selectLabelList: [
        "Semnatura si parafa medicului sef",
        "Semnatura si parafa medicului curant",
      ],
      additionalName: ["Sef", "Curant"],
    },
  },

  {
    key: "mainSurgery",
    label: "Interventia chirurgicala principala:",
    answer: {
      type: "MultipleSurgicalTextInputs",
      required: true,
      selectList: [],
      showAdditionalInput: true,
      showAdditionalInputLabel: "Consimtamantul pentru interventie:",
      dateLabel: "Data interventiei chirurgicale:",
    },
  },
  {
    key: "concomitantWithMainSurgery",
    label: "Interventii chirurgicale concomitente (cu cea principala):",
    answer: {
      type: "MultipleSurgicalTextInputs",
      required: true,
      selectList: [],
      dateLabel: "Data interventiei chirurgicale:",
    },
  },
  {
    key: "otherSurgicalInterventions",
    label: "Alte interventii chirurgicale:",
    answer: {
      type: "MultipleSurgicalTextInputs",
      required: true,
      selectList: [],
      dateLabel: "Data interventiei chirurgicale:",
    },
  },
  {
    key: "cytologicExamination",
    label: "Examen citologic",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      showRecord: false,
    },
  },

  {
    key: "extemporaneousExamination",
    label: "Examen extemporaneu",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      showRecord: false,
    },
  },

  {
    key: "histopathologyExamination",
    label: "Examen histopatologic (biopsie - piesa peratorie)",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      showRecord: false,
    },
  },

  {
    key: "intraHospitalTransfer",
    label: "",
    answer: {
      type: "IntraHospitalTransfer",
      required: true,
      selectList: [],
      customStyle: true,
    },
  },
  {
    title: "",
    key: "intraDischargeState",
    label: "Starea la externare:",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: ["vindecat", "ameliorat", "stationar", "agravat", "decedat"],
      className: "dischargeButton",
    },
  },
  {
    title: "",
    key: "intraDischargeType",
    label: "Tipul externarii:",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: [
        "externat",
        "externat la cerere",
        "transfer intraspitalicesc",
        "decedat",
      ],
    },
  },
  {
    title: "",
    key: "intraDeath",
    label: "Deces",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: [
        "intraoperator",
        "postoperator: 0-23 ore",
        "24-47 ore",
        ">48 ore",
      ],
    },
  },
  {
    title: "",
    key: "intraDeathDate",
    label: "Data Decesului",
    answer: {
      type: "DatePickerInput",
      required: true,
    },
  },
  {
    title: "",
    key: "intraDeathHour",
    label: "Ora Decesului",
    answer: {
      type: "TimePickerInput",
      required: true,
    },
  },
  {
    key: "ifCaseDeceased",
    label: "Diagnostic in caz de deces:",
    answer: {
      type: "MultipleLongTextInput",
      required: true,
      selectList: [],
      labelList: [
        { directCause: "Cauza directa (imediata): " },
        { anteCause: "Cauza antecedenta" },
        { initialMorbidState: "Stari morbide initiale:" },
      ],
      showRecord: false,
      isCaseDeceased: true,
      dateLabel: "Data si ora decesului:",
    },
  },
  {
    key: "anatomoPatologicDiagnostic",
    label: "Diagnostic anatomo-patologic (autopsie), macroscopic:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      showRecord: true,
    },
  },
  {
    key: "microscopic",
    label: "Microscopic:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
      showRecord: false,
    },
  },
  {
    key: "morphologicCode",
    label: "Codul morfologic (in caz de cancer)",
    answer: {
      type: "TextInput",
      required: true,
      selectList: [],
      showRecord: true,
    },
  },
  {
    key: "funcExplorations",
    label: "Explorari functionale:",
    answer: {
      type: "RadiologicalInvestigations",
      required: true,
      selectList: [],
      showNumberInput: true,
      disabled: true,
    },
  },
  {
    key: "radiologicInvestigations",
    label: "Investigatii radiologice:",
    answer: {
      type: "RadiologicalInvestigations",
      required: true,
      selectList: [],
      showNumberInput: true,
      hasUploadFiles: true,
      disabled: true,
    },
  },
  {
    key: "otherObs",
    label: "ALTE OBSERVATII:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "reasonForHospitalization",
    label: "MOTIVELE INTERNARII:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "callcenterData",
    label: "Informatii preluate din callcenter:",
    answer: {
      type: "MultipleFormData",
      required: true,
      selectList: [],
    },
  },
  {
    key: "anamneza",
    label: "ANAMNEZA:",
    answer: {
      type: "MultipleLongTextInput",
      required: true,
      selectList: [],
      labelList: [
        { heredoColateral: "Antecedente heredo-colaterale" },
        { personal: "Antecedente personale, fiziologice si patologice" },
        { conditions: "Conditii de viata si munca" },
        { behavior: "Comportamente (fumat, alcool etc.)" },
        {
          medication:
            "Medicatie de fond administrata inaintea internarii (inclusiv preparate hormonale si imunosupresoare)",
        },
      ],
      showRecord: false,
      hideAddButton: true,
    },
  },
  {
    key: "diseaseHistory",
    label: "ISTORICUL BOLII:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "generalClinicExam",
    label: "EXAMENUL CLINIC GENERAL",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "objectivExam",
    label: "EXAMEN OBIECTIV",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "generalState",
    label: "Starea generala",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "nutritionState",
    label: "Starea de nutritie",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "facies",
    label: "Facies",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "teguments",
    label: "Tegumente",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },

  {
    key: "mucous",
    label: "Mucoase",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "fanere",
    label: "Fanere",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "adiposeConnectiveTissue",
    label: "Tesut conjunctiv-adipos",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "nodalSystem",
    label: "Sistem ganglionar",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "muscularSystem",
    label: "Sistem muscular",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "osteoSystem",
    label: "Sistem osteo-articular",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "respiratorySystem",
    label: "APARAT RESPIRATOR",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "cardioVascularSystem",
    label: "APARAT CARDIOVASCULAR",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "digestiveSystem",
    label: "APARAT DIGESTIV",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "abdominalOrgans",
    label: "FICAT, CAI BILIARE, SPLINA",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "uroGenitalSystem",
    label: "APARAT URO-GENITAL",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "nervousAndTactilSystem",
    label: "SISTEM NERVOS, ENDOCRIN, ORGANE DE SIMT",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "oncologicExam",
    label: "EXAMEN ONCOLOGIC:",
    answer: {
      type: "MultipleLongTextInput",
      required: true,
      selectList: [],
      labelList: [
        { mouth: "Cavitatea bucala" },
        { teguments: "Tegumente" },
        { ganglia: "Grupe ganglioni palpabile" },
        { breast: "San" },
        { genitalsWomen: "Organe genitale feminine" },
        { vaginalCytology: "Citologia secretiei vaginale" },
        { prostateRectum: "Prostata si Rect" },
        { others: "Altele" },
      ],
      showRecord: false,
      hideAddButton: true,
    },
  },
  {
    key: "otherSpecialtyExams",
    label: "ALTE EXAMENE DE SPECIALITATE",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "laboratoryExams",
    label: "EXAMENE DE LABORATOR",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "radiologyExams",
    label: "EXAMENE RADIOLOGICE (rezultate)",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "ecographExams",
    label: "EXAMENE ECOGRAFICE (rezultate)",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "surgeryInterventions",
    label:
      "INTERVENTII CHIRURGICALE (numarul interventiei chirurgicale, protocol operator):",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "anatomoPatoExams",
    label: "EXAMENE ANATOMO-PATOLOGICE:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "supportOfDiagAndTreament",
    label: "SUSTINEREA DIAGNOSTICULUI SI TRATAMENTULUI:",
    answer: {
      type: "MultipleLongTextInput",
      required: true,
      selectList: [],
      labelList: [{ clinic: "CLINIC:" }, { paraclinic: "PARACLINIC:" }],
      showRecord: false,
      hideAddButton: true,
    },
  },
  {
    key: "epicrisis",
    label: "EPICRIZA:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: [],
    },
  },
  {
    key: "signAttendingPhysician",
    label: "Semnatura si parafa medicului",
    answer: {
      type: "SignTextInput",
      required: true,
      selectList: [],
    },
  },
];
