import React, { useEffect, useState } from "react";
import { REST } from "../../Utils";
import moment from "moment";

// components
import AvailabilityTable from "../AvaiabilityTable";

const OperatingRoomDisponibilityModal = ({ addAppointment }) => {
  const [surgeryRooms, setSurgeryRooms] = useState([]);
  const [intervalsData, setIntervalsData] = useState([]);
  // eslint-disable-next-line
  const [selectedInterval, setSelectedInterval] = useState({
    interval: null,
    roomId: null,
  });
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [noOfHours, setNoOfHorus] = useState(0);
  const [noOfMinutes, setNoOfMinutes] = useState(0);

  const getSurgeryRooms = async () => {
    await REST.get(`/spital/getAllOperatingRooms`)
      .then((answer) => {
        setSurgeryRooms(answer.data);
      })
      .catch((err) => {});
  };

  const getFreeIntervals = async (date, duration) => {
    REST.get(
      `/spital/getAllOperatingRoomsAvailability?desireDate=${date}&duration=${duration}`
    )
      .then((answer) => {
        setIntervalsData(
          answer?.data?.map((room) => {
            return {
              ...room,
              appointments: room.appointments.map((app) => {
                return { ...app, selected: false };
              }),
            };
          })
        );
      })
      .catch((err) => console.log("ERROR:", err));
  };

  useEffect(() => {
    if (selectedDate !== null && !(noOfHours === 0 && noOfMinutes === 0)) {
      let date = moment(selectedDate).format("YYYY-MM-DD");
      let duration = noOfHours * 60 + noOfMinutes;
      getFreeIntervals(date, duration);
    } else {
      setIntervalsData([]);
    }
  }, [selectedDate, noOfHours, noOfMinutes]);

  useEffect(() => {
    getSurgeryRooms();
  }, []);

  const handleAddAppointment = () => {
    addAppointment &&
      addAppointment(
        selectedInterval.interval.start,
        noOfHours,
        noOfMinutes,
        selectedInterval.roomId
      );
  };

  return (
    <div className={`modal-cell operatingRoomAvailability`}>
      <AvailabilityTable
        surgeryRooms={surgeryRooms && surgeryRooms}
        createAppointment={true}
        addAppointment={handleAddAppointment}
        intervalsData={intervalsData}
        setIntervalsData={setIntervalsData}
        selectedInterval={selectedInterval}
        setSelectedInterval={setSelectedInterval}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        noOfHours={noOfHours}
        setNoOfHorus={setNoOfHorus}
        noOfMinutes={noOfMinutes}
        setNoOfMinutes={setNoOfMinutes}
      />
    </div>
  );
};

export default OperatingRoomDisponibilityModal;
