const pacientSiFamilie = [
  {
    label: "EVALUARE SPIRITUALA - RELIGIOASA",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    label: "Pacientul",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "faithful",
    label: "Credicios",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Da", "Nu"]
    }
  },

  {
    key: "typeOfFaith",
    label: "Confesiune, rit",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "atheist",
    label: "Ateu",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Da", "Nu"]
    }
  },

  {
    // label: "Domiciliul legal:",
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "practitioner",
    label: "Practicant",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Da", "Nu"]
    }
  },

  {
    key: "practitioner",
    label: "Nepracticant",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Da", "Nu"]
    }
  },

  {
    // label: "Domiciliul legal:",
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "faithIsImportant",
    label: "Credinta este importanta",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Da", "Nu"]
    }
  },

  {
    // label: "Domiciliul legal:",
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "religiousSupportFromACommunity",
    label: "Sprijin spiritual/religions din partea unei comunitati",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    label: "Familia",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "familyFaith",
    label: "Confesiune",
    answer: {
      type: "MultipleRadioInput",
      required: true,
      selectList: [
        "Identica cu cea a pacientului",
        "Diferita de cea a pacientului"
      ]
    }
  },

  {
    // label: "Domiciliul legal:",
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "theFaithfulInfluenceOfTheFamily",
    label:
      "In ce mod influenteaza confesiunea familiei asistenta spirituala si religioasa a pacientului",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    // label: "Domiciliul legal:",
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "spiritualSupportFromTeam",
    label:
      "1. Pacientul doreste asistenta sspirituala si religioasa din partea echipei",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Da", "Nu"]
    }
  },

  {
    key: "spiritualSupportFromTeamDescription",
    // label: "Da",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    // label: "Domiciliul legal:",
    answer: {
      type: "NewLineInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "thePatientFeelsAtPeace",
    label: "2. Pacientul se simte impacat sufleteste",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Da", "Nu"]
    }
  },

  {
    key: "spiritualSupportFromTeamDescription",
    label: "Da. Ce il sustine ?",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "thePatientFeelsAtPeaceDescription",
    label:
      "Nu. Ce temeri are ? (ca boala se extinde, timpul e limitat, suferinta nu poate fi tratata de medic, familia nu face fata, internarea in spital, nu sunt in ordine aspecte legate de mostenire, aspecte materiale, etc.)",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  },

  {
    label: "EVALUARE RISCULUI DE DOLIU PENTRU APARTINATORI:",
    description: "Recomandare pentru asistente in perioada de doliu:",
    answer: {
      type: "TitleForInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "recommendationForMourningAssistanceDescription",
    label: "Numele persoanei:",
    answer: {
      type: "TextInput",
      required: true,
      selectList: []
    }
  },

  {
    key: "recommendationForMourningAssistance",
    // label: "Credinta este importanta",
    answer: {
      type: "RadioInput",
      required: true,
      selectList: ["Accept", "Refuz"]
    }
  },

  {
    key: "favoritePlaceOfCare",
    label: "Locul preferat de ingrijire la sfarsitul vietii:",
    answer: {
      type: "LongTextInput",
      required: true,
      selectList: []
    }
  }
];

export default pacientSiFamilie;
