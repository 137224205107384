import { Grid } from "@material-ui/core";
import ChatRoom from "components/ChatRoom/ChatRoom";
import lscache from "lscache";
import React, { useEffect, useState } from "react";
import validate from "validate.js";
import {
  CheckBoxInput,
  DatePickerInput,
  DropDownInput,
  MultipleDropDownInput,
  MultipleRadioInput,
  Paturi,
  RadioInput,
  TextInput,
  TimeAndDateInput,
  TimePickerInput,
} from "../../../../../components";
import { REST } from "../../../../../Utils/index";
import tipBFormSchema from "../TipBForm/tipBFormSchema";
import Mocks from "./components/Mocks";

import FloatingChat from "../../../../../components/FloatingChat/FloatingChat";

const useForceUpdate = () => {
  const [count, setCount] = useState(0);

  const increment = () => setCount((prevCount) => prevCount + 1);
  // eslint-disable-next-line
  useEffect(() => {}, [count]);
  return [increment, count];
};

const TipBForm = (props) => {
  const { data, ticketId, userData = {} } = props;

  // eslint-disable-next-line
  const [error, setError] = useState({ message: null });
  const [isPending, setIsPending] = useState(false);
  const [bedRefresh, setBedRefrsh] = useState(0);
  const [showMessages, setShowMessages] = useState(false);

  const handleMessages = () => {
    setShowMessages(!showMessages);
  };

  // eslint-disable-next-line
  const finalConfirmHourNew = data["finalConfirmHour"];
  const [formState, setFormState] = useState({
    isValid: false,
    values: data,
    touched: {},
    errors: {},
    counter: 0,
  });

  const updateBeds = () => {
    setBedRefrsh(bedRefresh + 1);
  };

  const [showOkButton, setShowOkButton] = useState(true);
  const [dataDRG, setDRGData] = useState(null);

  const [valueSecondDD, setValueSecondDD] = useState([]);
  const [valueThirdDD, setValueThirdDD] = useState([]);
  const [accordion, setAccordion] = useState(false);
  const [rerenderFraier, setRerenderFraier] = useState(0);

  const [forceUpdate] = useForceUpdate();

  const onClickHandler = (e) => {
    forceUpdate();
  };

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        finalConfirmHour: finalConfirmHourNew,
      },
      isValid: formState.errors ? false : true,
      errors: formState.errors || {},
    }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, tipBFormSchema);

    setFormState((formState) => ({
      ...formState,
      finalConfirmHour: finalConfirmHourNew,
      isValid: errors ? false : true,
      errors: errors || {},
    }));

    if (
      formState.values["hasTicket"] !== null &&
      formState.values["hasTicket"] &&
      formState.values["hasTicket"].length > 2
    ) {
      getDRGData(formState.values["hasTicket"]);
    }
    // eslint-disable-next-line
  }, [formState.values]);

  const handleBlur = (event) => {
    document
      .querySelectorAll("[data-replace='#" + event.target.name + "#']")
      .forEach((el) => {
        el.innerText = el.dataset.q.replace(
          el.dataset.replace,
          event.target.value
        );
      });
  };

  useEffect(() => {
    Mocks.filter((item) => {
      return item.key === "selectData";
    })[0]
      ["answer"]["selectList"]()
      .then((response) => {
        let dataForInit = response.filter(
          (e) => e.id === formState.values.selectData["spec"]
        )[0]["sub"];
        dataForInit.toCall(dataForInit.param).then((response) => {
          setValueSecondDD(response);
        });
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (valueSecondDD.length) {
      let dataForInit = valueSecondDD.filter(
        (e) => e.id === formState.values.selectData["spital"]
      )[0];
      if (dataForInit !== undefined) {
        dataForInit.sub.toCall(dataForInit.sub.param).then((response) => {
          setValueThirdDD(response);
        });
      }
    } else {
      setValueThirdDD([]);
    }
    // eslint-disable-next-line
  }, [valueSecondDD]);

  const handleChange = async (
    event,
    callLoad = null,
    forList = [],
    ref = null
  ) => {
    event.persist();
    let myVal = null;
    let repVal =
      formState.values.repData !== undefined ? formState.values.repData : {};
    let tmpVals = formState.values;

    if (ref !== null && ref.affects !== undefined) {
      ref.affects.forEach((el) => {
        delete tmpVals[el];
      });
    }

    if (ref !== null) {
      if (ref.setConfirmed !== undefined) {
        delete tmpVals["confirmed"];
        if (event.target.value !== "false" && event.target.value.length) {
          tmpVals.confirmed = true;
        }
      }
    }

    if (forList !== null && forList.name !== undefined) {
      if (callLoad && callLoad === null) {
      } else {
        if (typeof callLoad === "function") {
          if (forList.sub.toCall !== undefined) {
            await forList.sub.toCall(forList.sub.param).then((response) => {
              let cat = forList["cat"];
              myVal =
                formState.values[event.target.name] === null
                  ? {}
                  : formState.values[event.target.name];
              myVal = {
                ...myVal,
                [cat]: forList.id,
              };
              repVal[cat] = forList.name;
              callLoad(response);
            });
          } else {
            let cat = forList["cat"];
            repVal[cat] = forList.name;
            myVal =
              formState.values[event.target.name] === null
                ? {}
                : formState.values[event.target.name];
            myVal = {
              ...myVal,
              [cat]: forList.id,
            };
          }
        }
      }
    } else if (event.target.type === "radio") {
      if (event.replace) {
        event.target.name = event.replace;
      }
      myVal = event.target.value;
    } else if (event.target.type === "Date") {
      myVal = new Date(event.target.value);
    } else if (event.target.type === "checkbox") {
      let getKey =
        event.replace === undefined ? event.target.name : event.replace;
      let listVals =
        formState.values[getKey] === undefined ? [] : formState.values[getKey];
      if (event.target.checked) {
        if (Array.isArray(listVals)) {
          listVals.push(event.target.value);
        } else {
          listVals = event.target.value;
        }
      } else {
        if (Array.isArray(listVals)) {
          listVals = listVals.filter((el) => {
            return el !== event.target.value;
          });
        } else {
          listVals = false;
        }
      }
      myVal = listVals;
    } else {
      if (event.replace) {
        event.target.name = event.replace;
      }
      myVal = event.target.value;
    }

    const getRadioValueBasedOnTargetName = () => {
      if (event.target.name === "bloodType" || event.target.name === "rh") {
        return event.target.value;
      }

      return event.target.type === "radio" ? myVal === "true" : myVal;
    };

    setFormState((formState) => ({
      ...formState,
      counter:
        Object.keys(formState.values).filter((el) => el !== "confirmed")
          .length +
        (formState.values[event.target.name] === undefined ? 0 : -1),
      values: {
        ...tmpVals,
        [event.target.name]: getRadioValueBasedOnTargetName(),
        repData: repVal,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const disableButtons = () => {
    setIsPending(true);
  };

  const enableButtons = () => {
    setIsPending(false);
  };

  // eslint-disable-next-line
  const toggleAccordion = () => {
    setAccordion(!accordion);
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleSubmit = async () => {
    disableButtons();
    if (formState.isValid) {
      const body = {
        form: formState.values,
        patientCnp: formState.values.CNP,
        patientName: formState.values.name,
      };
      await REST.post(`/spital/updateTicketCC?ticketId=${ticketId}`, body)
        .then((answer) => {
          setError({ message: null });

          window.location = "/listare-ss";
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            setError({
              message,
            });
          }
          enableButtons();
        });
    } else {
      setError({ message: null });
      enableButtons();
    }
  };

  const handleApprove = async () => {
    disableButtons();
    if (formState.isValid) {
      const body = {
        form: formState.values,
        patientCnp: formState.values.CNP,
        patientName: formState.values.name,
      };

      await REST.post(`/spital/updateTicketCC?ticketId=${ticketId}`, body)
        .then((answer) => {
          setError({ message: null });

          if (lscache.get("operatorType") === "SefSectie") {
            REST.put(
              `/callcenter/updateTicketStatus?&status=Registratura&ticketId=${ticketId}`
            )
              .then((answer) => {
                window.location = "/listare-ss";
              })
              .catch((err) => {});
          }
          enableButtons();
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            setError({
              message,
            });
          }
          enableButtons();
        });
    } else {
      setError({ message: null });
      enableButtons();
    }
  };

  const handleReject = async () => {
    disableButtons();
    if (formState.isValid) {
      const body = {
        form: formState.values,
        patientCnp: formState.values.CNP,
        patientName: formState.values.name,
      };

      await REST.post(`/spital/updateTicketCC?ticketId=${ticketId}`, body).then(
        (answer) => {
          setError({ message: null });

          if (lscache.get("operatorType") === "SefSectie") {
            REST.put(
              `/spital/updateTicketStatus?&status=Operator C&ticketId=${ticketId}`
            )
              .then((answer) => {
                window.location = "/listare-ss";
              })
              .catch((err) => {});
            enableButtons();
          }
        }
      );
    } else {
      setError({ message: null });
      enableButtons();
    }
  };

  const getDRGData = async (drgCode) => {
    await REST.get(`/spital/getDrgByDrgCode?drgCode=${drgCode}`)
      .then((answer) => {
        setError({ message: null });

        setDRGData(answer.data.drgDescription);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          setError({
            message,
          });
        }
      });
  };

  const handleOkButtonFromRadio = () => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        confirmed: true,
      },
    });
    setShowOkButton(false);
  };

  return (
    <Grid container direction="row" className="TipBForm-ss">
      {Mocks.map((item, index) => {
        if (item.answer.type === "wait") {
          return null;
        }

        let showIt = true;
        if (item.depends !== undefined) {
          if (Array.isArray(item.depends.vals)) {
            showIt = item.depends.vals.includes(
              formState.values[item.depends.key]
            );
          } else {
            showIt = item.depends.vals === formState.values[item.depends.key];
          }
        }
        return (
          showIt && (
            <Grid
              item
              xs={6}
              className="formItemContainer"
              key={`tip_B_form_${index}`}
            >
              <div>
                <div className="questionContainer">
                  <div className="questionTextContainer">
                    {index === formState.counter ? (
                      <p className="questionText">{item.label}</p>
                    ) : (
                      <p className="questionTextSelected">{item.label}</p>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="answerContainer">
                  {(() => {
                    if (item.answer.type === "TextInput") {
                      return (
                        <TextInput
                          item={item}
                          formState={formState}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          hasError={hasError}
                          label={""}
                          isDisabled={item.answer.isDisabled}
                        />
                      );
                    } else if (item.answer.type === "RadioInput") {
                      return (
                        <RadioInput
                          item={item}
                          dataDRG={dataDRG}
                          formState={formState}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          hasError={hasError}
                          showOkButton={showOkButton}
                          handleOkButtonFromRadio={handleOkButtonFromRadio}
                          isDisabled={item.answer.isDisabled}
                        />
                      );
                    } else if (item.answer.type === "MultipleRadioInput") {
                      return (
                        <MultipleRadioInput
                          item={item}
                          formState={formState}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          hasError={hasError}
                          showOkButton={showOkButton}
                          handleOkButtonFromRadio={handleOkButtonFromRadio}
                          isDisabled={item.answer.isDisabled}
                        />
                      );
                    } else if (item.answer.type === "TimePickerInput") {
                      let paturi;

                      return (
                        <>
                          <TimePickerInput
                            item={item}
                            formState={formState}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            hasError={hasError}
                            showOkButton={showOkButton}
                            handleOkButtonFromRadio={handleOkButtonFromRadio}
                          />
                          {paturi}
                        </>
                      );
                    } else if (item.answer.type === "DatePickerInput") {
                      return (
                        <DatePickerInput
                          item={item}
                          formState={formState}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          hasError={hasError}
                          isDisabled={item.answer.isDisabled}
                        />
                      );
                    } else if (item.answer.type === "TimeAndDateInput") {
                      return (
                        <TimeAndDateInput
                          item={item}
                          formState={formState}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          hasError={hasError}
                        />
                      );
                    } else if (item.answer.type === "wait") {
                      return <div></div>;
                    } else if (item.answer.type === "DropDownInput") {
                      return (
                        <DropDownInput
                          item={item}
                          formState={formState}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          hasError={hasError}
                          showOkButton={showOkButton}
                          handleOkButtonFromRadio={handleOkButtonFromRadio}
                          isDisabled={item.answer.isDisabled}
                          userType={userData.type || null}
                        />
                      );
                    } else if (item.answer.type === "MultipleDropDownInput") {
                      return (
                        <MultipleDropDownInput
                          setValueSecondDD={setValueSecondDD}
                          setValueThirdDD={setValueThirdDD}
                          valueSecondDD={valueSecondDD}
                          valueThirdDD={valueThirdDD}
                          item={item}
                          formState={formState}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          hasError={hasError}
                          showOkButton={showOkButton}
                          handleOkButtonFromRadio={handleOkButtonFromRadio}
                        />
                      );
                    } else if (item.answer.type === "CheckBoxInput") {
                      return (
                        <CheckBoxInput
                          item={item}
                          formState={formState}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          hasError={hasError}
                          showOkButton={showOkButton}
                          handleOkButtonFromRadio={handleOkButtonFromRadio}
                          checked={true}
                        />
                      );
                    } else {
                      return <div></div>;
                    }
                  })()}
                </div>
              </div>
            </Grid>
          )
        );
      })}

      {userData.type === "Operator C" || userData.type === "SefSectie" ? (
        <div className="accordion_container mb-spacing-0">
          <Paturi
            formState={formState}
            ticketId={ticketId}
            plm={bedRefresh}
            updateBeds={updateBeds}
            forceUpdate={onClickHandler}
            rerenderFraier={rerenderFraier}
            setRerenderFraier={setRerenderFraier}
          />
        </div>
      ) : (
        <div></div>
      )}
      {isPending ? (
        <div className="submitButtonContainer">
          <button className="approveButton">
            {userData.type === "Operator C"
              ? "Aproba Cererea"
              : userData.type === "SefSectie"
              ? "Trimite catre Registratura"
              : "Trimite catre C"}
          </button>

          <button className="submitButton">Salveaza</button>

          <button className="rejectButton">
            {userData.type === "Operator C"
              ? "Trimite catre B"
              : userData.type === "SefSectie"
              ? "Trimite catre C"
              : "Anuleaza Cererea"}
          </button>
        </div>
      ) : (
        <div className="submitButtonContainer">
          <button className="approveButton" onClick={handleApprove}>
            {userData.type === "Operator C"
              ? "Aproba Cererea"
              : userData.type === "SefSectie"
              ? "Trimite catre Registratura"
              : "Trimite catre C"}
          </button>

          <button className="submitButton" onClick={handleSubmit}>
            Salveaza
          </button>

          <button className="rejectButton" onClick={handleReject}>
            {userData.type === "Operator C"
              ? "Trimite catre B"
              : userData.type === "SefSectie"
              ? "Trimite catre C"
              : "Anuleaza Cererea"}
          </button>
        </div>
      )}
      {userData.type === "SefSectie" && (
        <FloatingChat handleMessages={handleMessages} />
      )}

      {showMessages ? (
        <div className="floating-message-container">
          <ChatRoom
            ticketId={ticketId}
            patientName={
              {
                firstName: formState.values.firstName,
                lastName: formState.values.lastName,
              } || ""
            }
          />
        </div>
      ) : null}
    </Grid>
  );
};

export default TipBForm;
