import { Button, CardContent, FormControl, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import validate from "validate.js";
import {
  DatePickerInput,
  TimePickerInput,
} from "../../../../../components/index";
import { getFromDate, REST } from "../../../../../Utils";
import { aparatTypes } from "../AparatTypes/aparatTypes";
import { aparatTypeTitles } from "../AparatTypes/aparatTypeTitles";
import MockDate from "./MockDate";
import MockTime from "./MockTime";
import schema from "./schemaTemp";

const Temperatura = (props) => {
  const { ticketId } = props;
  const [showOkButton, setShowOkButton] = React.useState(true);
  // eslint-disable-next-line
  const [error, setError] = useState({ message: null });
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    // eslint-disable-next-line
  }, [formState.values]);

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = (event) => {
    event.persist();

    const hasDateAndHour = formState.values.date && formState.values.ora;

    if (!hasDateAndHour) {
      setStateForForm({
        name: "date",
        value: new Date(),
        type: null,
        checked: null,
      });

      setStateForForm({
        name: "ora",
        value: new Date(),
        type: null,
        checked: null,
      });
    }

    setStateForForm({
      name: event.target.name,
      value: event.target.value,
      type: event.target.type,
      checked: event.target.checked || null,
    });
  };

  const setStateForForm = (newState) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [newState.name]:
          newState.type === "checkbox" ? newState.checked : newState.value,
      },
      touched: {
        ...formState.touched,
        [newState.name]: true,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formState.isValid) {
      delete formState.values["undefined"];

      formState.values["VITAL DATE"] = `${getFromDate(
        true,
        false,
        formState.values["date"]
      )} ${getFromDate(false, true, formState.values["ora"])}`.replace(
        /\s+/g,
        " "
      );

      delete formState.values["date"];
      delete formState.values["ora"];

      REST.post(
        `/spital/addMonitorDataByHand?ticketId=${
          Array.isArray(ticketId) ? ticketId[0] : ticketId
        }`,
        formState.values
      )
        .then((answer) => {
          setError({ message: null });
          setFormState({
            isValid: false,
            values: {},
            touched: {},
            errors: {},
          });
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            setError({
              message,
            });
          }
        });
    } else {
      setError({ message: null });
    }
  };

  const handleOkButtonFromRadio = () => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        confirmed: true,
      },
    });
    setShowOkButton(false);
  };

  return (
    <div className={"temperaturaChart"}>
      <form onSubmit={handleSubmit}>
        <CardContent>
          {aparatTypes.map((item, index) => {
            return (
              aparatTypeTitles[item] && (
                <FormControl
                  key={`${item}_${index}`}
                  className={"formControl"}
                  variant="outlined"
                  style={{ width: "400px", margin: "20px" }}
                >
                  <TextField
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseFloat(e.target.value))
                        .toString()
                        .slice(0, 5);
                    }}
                    min={0}
                    type="number"
                    id={aparatTypes[index]}
                    name={aparatTypes[index]}
                    label={aparatTypeTitles[item]}
                    onChange={handleChange}
                    onBlur={handleChange}
                    error={hasError(aparatTypes[index])}
                    helperText={
                      hasError(aparatTypes[index])
                        ? formState.errors[aparatTypes[index]]
                        : null
                    }
                    value={formState.values[aparatTypes[index]] || ""}
                  />
                </FormControl>
              )
            );
          })}
        </CardContent>

        <CardContent>
          {/* eslint-disable-next-line */}
          {MockDate.map((item, index) => {
            if (item.answer.type === "DatePickerInput") {
              return (
                <FormControl
                  className={"formControl datePickerFormatTemps"}
                  variant="outlined"
                  style={{ width: "400px", margin: "20px" }}
                  key={`FormControTemperatura--${index}`}
                >
                  <DatePickerInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleChange}
                    hasError={hasError}
                    isDisabled={item.answer.isDisabled}
                    value={formState.values.data || new Date()}
                    key={`DatePickerInput--${index}`}
                  />
                </FormControl>
              );
            }
          })}
        </CardContent>

        <CardContent>
          {/* eslint-disable-next-line */}
          {MockTime.map((item, index) => {
            if (item.answer.type === "TimePickerInput") {
              return (
                <FormControl
                  className={"formControl datePickerFormatTemps"}
                  variant="outlined"
                  style={{ width: "400px", margin: "20px" }}
                  key={`FormControl2--${index}`}
                >
                  <TimePickerInput
                    item={item}
                    formState={formState}
                    handleChange={handleChange}
                    handleBlur={handleChange}
                    hasError={hasError}
                    showOkButton={showOkButton}
                    handleOkButtonFromRadio={handleOkButtonFromRadio}
                    value={formState.values.ora || new Date()}
                    key={`TimePickerInput--${index}`}
                  />
                </FormControl>
              );
            }
          })}
        </CardContent>

        <div style={{ margin: "0 40px 0 0" }}>
          <Button
            style={{
              padding: "1rem",
              margin: "1rem",
            }}
            className={`btn-primary temperaturaBtnSubmit`}
            onClick={handleSubmit}
            disabled={!formState.isValid}
          >
            {/* Adauga in fisa de temperatura */}
            Adauga in fisa de masuratori vitale
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Temperatura;
