let ACTIONS = {};
let rInitState = {
  blockwindow: {
    values: {
      hide: true
    }
  },
  generated: true
};

for (let ii = 1; ii <= 21; ii++) {
  ACTIONS[`ACC${ii}`] = `ACC${ii}`;
  rInitState[`acc${ii}`] = { values: {} };
}

export { ACTIONS, rInitState };
