import React, { useState } from "react";
import QrReader from "react-qr-reader";
import { Grid, Button } from "@material-ui/core";

const PacientScanQR = (props) => {
  const { handleError, setCurrentStep, setBedId } = props;

  const [openCamera, setOpenCamera] = useState(false);

  const handleScan = async (data) => {
    if (data != null) {
      setBedId(data);
    }

    setCurrentStep("device");
  };

  const opneCamera = () => {
    setOpenCamera(true);
  };

  return (
    <Grid container className={"qr_root"}>
      <Grid item xs={12} md={12} className={"title_container"}>
        <h1>Va rugam sa scanati codul QR de pe pat!</h1>
      </Grid>

      {openCamera ? (
        <Grid item xs={12} md={12} lg={12} className={"camera_container"}>
          <div className={"qr_container"}>
            <QrReader
              delay={10}
              onError={handleError}
              onScan={handleScan}
              resolution={2000}
              style={{ width: "100%" }}
              // legacyMode={true}
              constraints={{
                focusMode: "continuous",
                sharpness: 999,
                torch: true,
                exposureMode: "continuous",
                whiteBalanceMode: "continuous",
                focusDistance: 100,
                facingMode: "environment",
              }}
            />

            <br />
          </div>
        </Grid>
      ) : (
        <Grid item container className={"body_wrapper"}>
          <div className="questionContainer">
            <div className="titleWrapper">
              <h1 className={"title_container"}>Doriti sa scanati patul?</h1>
            </div>
            <div className="buttonsContainer">
              <Button className={"scanButton"} onClick={opneCamera}>
                Da, scanez
              </Button>
              <Button className={"passButton"} onClick={() => handleScan(null)}>
                Nu scanez
              </Button>
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  );
};
export default PacientScanQR;
