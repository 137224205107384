import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import MonthPicker from "./MonthPicker";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
const YearPicker = props => {
  const { getYear, getMonth, monthSplit, yearSplit } = props;

  // const [month, setMonth] = useState("");

  let newDate = new Date();
  let currentYear = newDate.getFullYear();

  const [anchorEl, setAnchorEl] = useState(null);
  const [yearName, setYearName] = useState(yearSplit);

  const arrayOfYears = [
    // { year: 2005 },
    // { year: 2006 },
    // { year: 2007 },
    // { year: 2008 },
    // { year: 2009 },
    // { year: 2010 },
    // { year: 2011 },
    // { year: 2012 },
    // { year: 2013 },
    // { year: 2014 },
    // { year: 2015 },
    // { year: 2016 },
    // { year: 2017 },
    // { year: 2018 },
    // { year: 2019 },
    { year: 2020 },
    { year: 2021 },
    { year: 2022 }
  ];

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = item => {
    setAnchorEl(null);
    getYear(item.year);
    setYearName(item ? item.year : currentYear);
  };

  const fromMonthPicker = month => {
    getMonth(month);
  };

  return (
    <div className="bedPickers">
      <div className="yearPicker">
        <div className="yearPickerLabel">
          <p>An:</p>
        </div>
        <div className="yearPickerInputContainer">
          <Button
            onClick={handleClick}
            className="yearPickerInput"
            endIcon={<ArrowDropDownIcon />}
          >
            {yearName !== undefined ? yearName : currentYear}
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {arrayOfYears.map((item, index) => {
              return (

                <MenuItem
                  key={`year_picker_${index}`}
                  onClick={() => handleClose(item)}>
                  {item.year}</MenuItem>
              )
            })}
          </Menu>
        </div>
      </div>
      <div>
        <MonthPicker
          year={yearName}
          monthToSet={fromMonthPicker}
          monthSplit={monthSplit}
        />
      </div>
    </div>
  );
};

export default YearPicker;
