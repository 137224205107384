import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import lscache from "lscache";
import React from "react";

const RadioInput = (props) => {
  const {
    item,
    formState,
    handleChange,
    hasError,
    handleBlur,
    showOkButton,
    handleOkButtonFromRadio,
    dataDRG,
  } = props;

  var itemName = item.key;

  return (
    <div className="answerBox">
      {lscache.get("operatorType") === "SefSectie" ? null : (
        <div className="labelContainer">
          <p className="labelText">{item.label}</p>
        </div>
      )}

      <div className="radioContainer">
        <RadioGroup
          id={`${itemName}`}
          name={`${itemName}`}
          value={
            formState &&
            formState.values &&
            formState.values[itemName] !== undefined
              ? formState.values[itemName] !== false
              : null
          }
          onChange={(event) => handleChange(event, null, [], item)}
          className="radioList"
        >
          <FormControlLabel value={true} control={<Radio />} label="Da" />
          <FormControlLabel value={false} control={<Radio />} label="Nu" />
        </RadioGroup>
        {item.answer.showInput && formState.values[itemName] && (
          <div className="inputFieldFromRadio">
            <TextField
              id={`${itemName}_text`}
              name={`${itemName}_text`}
              label={item.displayLabel}
              className="textField"
              onChange={(event) => {
                handleChange(
                  {
                    target: event.target,
                    persist: event.persist,
                    replace: itemName,
                  },
                  null,
                  [],
                  item
                );
              }}
              onBlur={(event) => {
                handleBlur(event);
              }}
              error={hasError(itemName)}
              helperText={
                hasError(itemName) ? formState.errors[itemName][0] : null
              }
              value={
                formState.values[itemName] !== undefined &&
                ![true, false].includes(formState.values[itemName])
                  ? formState.values[itemName]
                  : ""
              }
            />
            <div>
              {itemName === "hasTicket" ? (
                <div className="drgCodeText">{dataDRG}</div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        )}
        {item.answer.showText && formState.values[itemName] && (
          <div className="textFromRadio">
            <p>Sunteti sigur ca dorit consulatie contracost?</p>

            <button
              className={
                showOkButton
                  ? "textFromRadioButton"
                  : "textFromRadioButtonPressed"
              }
              onClick={handleOkButtonFromRadio}
            >
              OK
            </button>
          </div>
        )}

        {item.answer.showCheckBox && formState.values[itemName] && (
          <div className="checkBoxFromRadio">
            {item.answer.selectList.map((el) => {
              return (
                <div className="checkboxElementRadio">
                  <FormControlLabel
                    label={el}
                    className="checkboxList"
                    control={
                      <Checkbox
                        id={`${itemName + "_" + el}`}
                        name={`${itemName}`}
                        label={`${el}`}
                        className="checkboxElement"
                        onChange={(event) => {
                          handleChange({
                            target: event.target,
                            persist: event.persist,
                            replace: itemName,
                          });
                        }}
                        error={hasError(itemName)}
                        helperText={
                          hasError(itemName)
                            ? formState.errors[itemName][0]
                            : null
                        }
                        value={el}
                      />
                    }
                  />
                </div>
              );
            })}
          </div>
        )}

        {item.answer.showRadio && formState.values[itemName] && (
          <div className="checkBoxFromRadio">
            {item.answer.selectList.map((el) => {
              return (
                <div className="checkboxElementRadio">
                  <FormControlLabel
                    label={el}
                    className="checkboxList"
                    control={
                      <Radio
                        id={`${itemName}`}
                        name={`${itemName}`}
                        label={`${el}`}
                        className="checkboxElement"
                        onChange={(event) => {
                          handleChange({
                            target: event.target,
                            persist: event.persist,
                            replace: itemName,
                          });
                        }}
                        error={hasError(itemName)}
                        helperText={
                          hasError(itemName)
                            ? formState.errors[itemName][0]
                            : null
                        }
                        value={el}
                      />
                    }
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default RadioInput;
