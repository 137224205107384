import { Button, Card, Table } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React from "react";

export default function EquiptmentTable(props) {
  const { data, handleQr } = props;

  return (
    <>
      <Card
        className="card-box shadow-none"
        style={{ overflowY: "hidden", overflowX: "hidden" }}
      >
        <div className="divider" />
        <div className="p-4">
          <div className="table-responsive-md">
            <Table className="table table-alternate-spaced mb-0">
              <thead>
                <tr>
                  <th
                    style={{ width: "300px" }}
                    className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark text-start"
                    scope="col"
                  >
                    Denumire aparat
                  </th>
                  <th
                    style={{ width: "300px" }}
                    className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                    scope="col"
                  >
                    Categorie
                  </th>
                  <th
                    className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark text-center"
                    scope="col"
                  >
                    Departament
                  </th>
                  <th
                    className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark text-center"
                    scope="col"
                  >
                    Status
                  </th>
                  <th
                    className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark text-right"
                    scope="col"
                  >
                    Printare cod QR
                  </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item, index) => {
                    return (
                      <tr key={`equipmentTableKey--${index}`}>
                        <td
                          className="text-start"
                          id={`${index}`}
                          style={{ width: "300px" }}
                        >
                          <div className="align-items-center">
                            <div className="pl-3">{item.description}</div>
                          </div>
                        </td>
                        <td id={`${index}`}>
                          <div
                            className="d-flex align-items-center"
                            style={{ justifyContent: "start" }}
                          >
                            <div>{item.category.description}</div>
                          </div>
                        </td>
                        <td id={`${index}`} className="text-center">
                          <div className="px-4 py-1 h-auto">
                            {item.department.departmentName}
                          </div>
                        </td>
                        <td id={`${index}`} className="text-center">
                          <div
                            className={`px-4 py-1 h-auto ${
                              item.status === "Disponibil"
                                ? "text-success"
                                : "text-danger"
                            } text-success border-1 ${
                              item.status === "Disponibil"
                                ? "border-success"
                                : "border-danger"
                            } badge forStatus ${
                              item.status === "Disponibil"
                                ? "badge-neutral-success"
                                : "badge-neutral-danger"
                            } badge-neutral-success`}
                          >
                            {item.status}
                          </div>
                        </td>
                        <td
                          className="font-size-lg font-weight-bold text-right"
                          id={`${index}`}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleQr(item.id)}
                          >
                            Printare QR
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center pt-3 mb-5">
          <Pagination
            className="pagination-primary"
            count={1}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </Card>
    </>
  );
}
