import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CalendarComponent from "../Calendar";
import { REST } from "../../Utils";
import { Button, Modal, TextField, Select, MenuItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import moment from "moment";
import Recorder from "../Recorder/Recorder";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import MyCustomEvent from "./CustomEvent";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputRoot: {
    color: "inherit",
    // backgroundColor: "Red",
    width: "100%",
  },
  inputInput: {
    margin: theme.spacing(1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const AgendaModal = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();
  const ticketId = id;

  console.log(`window: `, id);

  const now = new Date();
  const hour = new Date();

  const [selectedDate, setSelectedDate] = useState(now);
  const [hourInput, setHourInput] = useState(hour);
  const [speech, setSpeech] = useState("");
  const [surgery, setSrgery] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [data, setData] = useState([]);
  const [room, setRoom] = useState({
    roomName: "",
  });
  const [surgeryHours, setSurgeryHours] = useState({
    hours: 0,
    minutes: 0,
  });
  const [roomsList, setRoomsList] = useState([]);
  const [drList, setDrList] = useState([]);
  const [surgeryCode, setSurgeryCode] = useState([]);
  const [drName, setDrName] = useState("");
  const [appointmentInfo, setAppointmentInfo] = useState({
    procedure: "",
    doctor: "",
    patient: "",
    department: "",
  });
  const [agendaModal, setAgendaModal] = useState(false);

  const handleSurgery = (event) => {
    setSrgery(!surgery);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChangeHour = (date) => {
    setHourInput(date);
  };

  const getAppointments = async () => {
    await REST.get(`/patientAgenda/getPatientAgenda?patientSheetId=${ticketId}`)
      .then((answer) => {
        setData(answer.data);
        console.log(`get patient agenda >>`, answer);
      })
      .catch((err) => {});
  };

  const startDate = `${moment(selectedDate).format("yyyy-MM-DD")}T${moment(
    hourInput
  ).format("HH:mm:00")}`;

  const nextAppointment = async () => {
    console.log(`ticketId >>>`, ticketId);
    const body = {
      title: speech,
      start: startDate,
    };
    await REST.post(
      `/patientAgenda/createPatientAgendaEntry?patientSheetId=${ticketId}`,
      body
    )
      .then((answer) => {
        // handleModal();
        setSpeech("");
        setSelectedDate(now);
        setHourInput(hour);
        setSurgeryHours((state) => {
          return {
            ...state,
            hours: 0,
          };
        });
        showSnack("success");
        refresh();
      })

      .catch((err) => {});
  };

  const surgeryAppointment = async () => {
    const body = {
      title: speech.description,
      start: startDate,
      roomId: parseInt(room.roomName),
      numberOfHours: parseInt(surgeryHours.hours),
      numberOfMinutes: parseInt(surgeryHours.minutes),
      patientSheetId: ticketId[0],
      doctorName: drName.doctorName,
      procedureName: speech.description,
    };

    await REST.post(`/spital/addOperatingRoomAppointment`, body)
      .then((answer) => {
        // handleModal();
        setSpeech("");
        setDrName("");
        setSelectedDate(now);
        setHourInput(hour);
        setSurgeryHours((state) => {
          return {
            ...state,
            hours: 0,
            minutes: 0,
          };
        });
        setRoom((state) => {
          return {
            ...state,
            roomName: "",
          };
        });
        showSnack("success");
        refresh();
      })

      .catch((err) => {});
  };

  const getSurgeryRooms = async () => {
    await REST.get(`/spital/getAllOperatingRooms`)
      .then((answer) => {
        setRoomsList(answer.data);
      })
      .catch((err) => {});
  };

  const getDrList = async () => {
    await REST.get(`/doctor/getDoctorByHospital`)
      .then((answer) => {
        setDrList(answer.data);
      })
      .catch((err) => {});
  };

  const getSurgeryCodes = async () => {
    await REST.get(`/getAllSurgeryCodes`)
      .then((answer) => {
        setSurgeryCode(answer.data);
      })
      .catch((err) => {});
  };

  const refresh = () => {
    setTimeout(() => {
      getAppointments();
    }, 200);
  };

  useEffect(() => {
    getAppointments();
    getSurgeryRooms();
    getDrList();
    getSurgeryCodes();
    // eslint-disable-next-line
  }, []);

  const CustomEvent = ({ procedure, doctor, patient, department }) => {
    return (
      <div
        className={`calendar-cell-general calendar-cell-${department}`}
        onClick={() => {
          setAppointmentInfo({
            procedure: procedure,
            doctor: doctor,
            patient: patient,
            department: department,
          });
          handleAgenda();
        }}
      >
        <h5>Procedura: {procedure}</h5>
        {department && <div>Sectia: {department} </div>}
        {doctor && <div>Dr: {doctor} </div>}
        {patient && <div>Pacient: {patient}</div>}
      </div>
    );
  };

  const javaData = () => {
    var newArr = [];
    data &&
      // eslint-disable-next-line
      data.map((item) => {
        let newValue = {
          id: item.id,
          title: CustomEvent({
            procedure: item.title.procedure,
            doctor: item.title.doctor,
            patient: item.title.patient,
            department: item.title.department,
          }),
          // title: item.title,
          start: new Date(item.start),
          end: new Date(item.end),
        };

        newArr.push(newValue);
      });
    setAppointments(newArr);
  };

  useEffect(() => {
    javaData();
    // eslint-disable-next-line
  }, [data]);

  const handleSpeech = (answer) => {
    setSpeech(speech + " " + answer);
  };

  const defaultView = "agenda";

  const handleSurgeryRoom = (event) => {
    setRoom((state) => {
      return {
        ...state,
        roomName: event.target.value,
      };
    });
  };

  const handleSurgeryHours = (event) => {
    event &&
      event.target &&
      event.target.value &&
      setSurgeryHours((state) => {
        return {
          ...state,
          hours: event.target.value,
        };
      });
  };

  const handleSurgeryMinutes = (event) => {
    setSurgeryHours((state) => {
      return {
        ...state,
        minutes: event.target.value,
      };
    });
  };

  const showSnack = (variant) => {
    enqueueSnackbar("Un nou eveniment a fost adaugat!", { variant });
  };

  const unavailabilityHours = [
    {
      value: 0,
      label: "0h",
    },
    {
      value: 1,
      label: "1h",
    },
    {
      value: 2,
      label: "2h",
    },

    {
      value: 3,
      label: "3h",
    },
    {
      value: 4,
      label: "4h",
    },
    {
      value: 5,
      label: "5h",
    },
    {
      value: 6,
      label: "6h",
    },
    {
      value: 7,
      label: "7h",
    },

    {
      value: 8,
      label: "8h",
    },
    {
      value: 9,
      label: "9h",
    },
    {
      value: 10,
      label: "10h",
    },
    {
      value: 11,
      label: "11h",
    },
    {
      value: 12,
      label: "12h",
    },
  ];

  const unavailabilityMinutes = [
    {
      value: 0,
      label: "0 min",
    },
    {
      value: 10,
      label: "10 min",
    },
    {
      value: 20,
      label: "20 min",
    },
    {
      value: 30,
      label: "30 min",
    },
    {
      value: 40,
      label: "40 min",
    },
    {
      value: 50,
      label: "50 min",
    },
  ];

  const handleAgenda = () => {
    setAgendaModal(!agendaModal);
  };

  return (
    <div className={"agenda__container"}>
      <div className={`title__container`}>
        <h2>Agenda Pacientului</h2>
      </div>
      <div className={`input__container`}>
        <div className={`date__container`}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="DATA"
                format="d/MM/yyyy"
                value={selectedDate}
                defaultValue={now}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />

              <KeyboardTimePicker
                ampm={false}
                margin="normal"
                id="time-picker"
                label="ORA"
                defaultValue={hour}
                value={hourInput}
                onChange={handleChangeHour}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </div>

        <div className={`speech__text`}>
          <FormControlLabel
            control={
              <Checkbox
                checked={surgery}
                onChange={handleSurgery}
                name="checkedB"
                color="primary"
              />
            }
            label="Programati pentru Operatie"
            style={{ marginLeft: "1%" }}
          />

          {surgery ? (
            <div className={`surgery__appointment`}>
              <div className={`inputs__container`}>
                <Autocomplete
                  id="combo-box-demo"
                  options={surgeryCode}
                  getOptionLabel={(option) => option.description}
                  // onChange={event => {
                  //   event.persist();
                  //   setSpeech(event.target.textContent);
                  // }}
                  value={speech}
                  onChange={(event, newValue) => {
                    setSpeech(newValue);
                  }}
                  style={{ width: "90%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selectati tipul procedurii
                      "
                      // variant="outlined"
                      // value={speech || ""}
                    />
                  )}
                />
              </div>

              <div className={`inputs__container`}>
                <Autocomplete
                  id="combo-box-demo"
                  options={drList}
                  getOptionLabel={(option) => option.doctorName}
                  // onChange={(event, value) => {
                  //   event.persist();
                  //   setDrName(event.target.textContent);
                  // }}
                  value={drName}
                  onChange={(event, newValue) => {
                    setDrName(newValue);
                  }}
                  style={{ width: "90%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selectati doctorul"
                      // variant="outlined"
                      // value={drName || ""}
                    />
                  )}
                />
              </div>

              <div className={`inputs__container`}>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={surgeryHours.hours}
                    onChange={handleSurgeryHours}
                    className={classes.selectEmpty}
                  >
                    {unavailabilityHours.map((item) => {
                      return (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>Numarul de ore</FormHelperText>
                </FormControl>

                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={surgeryHours.minutes}
                    onChange={handleSurgeryMinutes}
                    className={classes.selectEmpty}
                  >
                    {unavailabilityMinutes.map((item) => {
                      return (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>Numarul de minute</FormHelperText>
                </FormControl>

                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={room.roomName}
                    onChange={handleSurgeryRoom}
                    className={classes.selectEmpty}
                  >
                    {roomsList.map((item) => {
                      return (
                        <MenuItem value={item.id}>{item.roomName}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>Selectati sala de operatii</FormHelperText>
                </FormControl>
              </div>
            </div>
          ) : (
            <div className={`inputs__container`}>
              <TextField
                label={`Urmatoarea investigatie`}
                onChange={(event) => {
                  event.persist();
                  setSpeech(event.target.value);
                }}
                value={speech || ""}
                style={{ width: "90%" }}
              />

              <Recorder
                showRecord={true}
                handleSpeech={handleSpeech}
                ticketId={ticketId}
              />
            </div>
          )}
        </div>
        <div className={`submit__container`}>
          <Button
            className={`save_btn`}
            variant="contained"
            onClick={surgery ? surgeryAppointment : nextAppointment}
          >
            ADAUGA
          </Button>
        </div>
      </div>
      <div className={`calendar__container`}>
        <CalendarComponent events={appointments} defaultView={defaultView} />
      </div>

      <Modal
        open={agendaModal}
        onBackdropClick={handleAgenda}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(3px)",
          width: "100%",
          height: "100%",
        }}
      >
        <div className="event_modal_container">
          <MyCustomEvent
            appointmentInfo={appointmentInfo}
            handleAgenda={handleAgenda}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AgendaModal;
