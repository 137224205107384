import React, { useEffect, useState, useRef } from "react";
import { Grid, TextField, InputAdornment, IconButton } from "@material-ui/core";
import moment from "moment";
import { REST } from "../../Utils";
import lscache from "lscache";

// assets
import SendIcon from "@material-ui/icons/Send";

const ChatRoom = (props) => {
  const { ticketId, patientName } = props;

  const [data, setData] = useState([]);
  const [messageToSend, setMessageToSend] = useState("");

  const getData = async () => {
    await REST.post(`/chat/getAllMessages?patientSheetId=${ticketId}`)
      .then((answer) => {
        setData(answer.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getData();
    setInterval(() => {
      getData();
    }, 2000);
    // eslint-disable-next-line
  }, []);

  const divRef = useRef(null);

  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: "smooth" });
  }, [data]);

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      sendMessage();
    }
  };

  const sendMessage = async () => {
    let body = {
      message: messageToSend,
      patientSheetId: `${ticketId}`,
    };

    REST.post(`/chat/sendMessageByDoctor`, body)
      .then((answer) => {
        getData();
      })
      .catch((err) => {});

    setMessageToSend("");
  };

  return (
    <Grid container className={"chatRoomContainer"}>
      {/* MESSAGES */}
      <Grid item xs={12} className={"chatRoomContent"}>
        {data &&
          data.map((item, index) => {
            if (item.sentBy === "Doctor") {
              return (
                <Grid
                  container
                  className={"chatRow "}
                  justify={"flex-end"}
                  key={`message--${index}`}
                >
                  <Grid item className={"chatRoomMessageCard "}>
                    <Grid item xs={12} className={"topBar me"}>
                      <div style={{ marginRight: "5px" }}>
                        {lscache.get("operatorName")}
                      </div>
                      <div style={{ color: "white" }}>
                        {moment(item.creationDate).format("HH:mm")}
                      </div>
                    </Grid>
                    <Grid item xs={12} className={"chatMessage"}>
                      <div className={"participantArrow"} />
                      <p className={"participantText"}>{item.message}</p>
                    </Grid>
                  </Grid>
                </Grid>
              );
            } else {
              return (
                <Grid
                  container
                  className={"chatRow "}
                  justify={"flex-start"}
                  key={`message--${index}`}
                >
                  <Grid item className={"chatRoomMessageCard "}>
                    <Grid item xs={12} className={"topBar participant"}>
                      <div style={{ marginRight: "5px" }}>
                        {`${patientName.firstName} ${patientName.lastName}`}
                      </div>
                      <div style={{ color: "white" }}>
                        {moment(item.creationDate).format("HH:mm")}
                      </div>
                    </Grid>
                    <Grid item xs={12} className={"chatMessage"}>
                      <div className={"meArrow"} />
                      <p className={"participantText"}>{item.message}</p>
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
          })}
        <div ref={divRef} />
      </Grid>

      {/* SEND MESSAGE TEXT INPUT */}
      <Grid container className={"sendMessageGrid"}>
        <TextField
          className={"textArea"}
          fullWidth
          name="text"
          type="text"
          value={messageToSend || ""}
          onKeyDown={handleOnKeyDown}
          onChange={(e) => setMessageToSend(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={sendMessage}
                >
                  <SendIcon className={"sendIcon"} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ChatRoom;
