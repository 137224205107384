import React from "react";
import { FaCalendarCheck } from "react-icons/fa";

const FloatingAgenda = props => {
  const { handleAgenda } = props;

  return (
    <FaCalendarCheck
      className="floatinAgenda"
      onClick={handleAgenda}
      style={{ height: 50 }}
    />
  );
};

export default FloatingAgenda;
