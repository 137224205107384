import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import moment from "moment";
import { REST } from "../../Utils";

// MUI Select
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

// components
import CustomizedTables from "./Table/Table";
import { TableDatePicker } from "../Inputs";
import SurgeryRoomIntervals from "./SurgeryRoomIntervals";

const AvailabilityTable = (props) => {
  const classes = useStyles();

  const {
    surgeryRooms = [],
    createAppointment = false,
    addAppointment = () => {},
    intervalsData = [],
    selectedInterval = {},
    setIntervalsData = () => {},
    setSelectedInterval = () => {},
    selectedDate = moment(new Date()).format("YYYY-MM-DD"),
    setSelectedDate = () => {},
    noOfHours = null,
    setNoOfHorus = () => {},
    noOfMinutes = null,
    setNoOfMinutes = () => {},
  } = props;

  const [data, setData] = useState(null);
  // eslint-disable-next-line
  const [operatingHours, setOperatingHours] = useState({
    start: null,
    end: null,
  });

  const getOverview = async () => {
    await REST.get(
      `/spital/getOperatingRoomsOverview?date=${moment(selectedDate).format(
        "YYYY-MM-DD"
      )}`
    ).then((answer) => {
      setData(answer.data);
    });
  };

  const getOperatingHours = async () => {
    //TOOD: request for hours
    let start = new Date();
    start.setHours(7);
    start.setMinutes(0);

    let end = new Date();
    end.setHours(22);
    end.setMinutes(0);
    setOperatingHours({ start: start, end: end });
  };

  useEffect(() => {
    getOverview();
    getOperatingHours();
    // eslint-disable-next-line
  }, [selectedDate]);

  const handleClickAddAppointment = () => {
    addAppointment && addAppointment();
  };

  const handleChangeNoOfHours = (event) => {
    setNoOfHorus(event.target.value);
  };

  const handleChangeNoOfMinutes = (event) => {
    setNoOfMinutes(event.target.value);
  };

  return createAppointment === true ? (
    <Grid container className={`availability__table__container`}>
      <Grid item xs={12} className="headerContent">
        <Grid item xs={5} className="createAppointmentTitle">
          <h3>Programeaza o interventie</h3>
        </Grid>
        <Grid item xs={7} className="pickersContainer">
          <Grid xs={3} item>
            <TableDatePicker onChange={setSelectedDate} />
          </Grid>

          <Grid xs={3} item className="timePickersContainer">
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Numarul de ore
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={noOfHours}
                onChange={handleChangeNoOfHours}
                className={classes.selectEmpty}
              >
                {hoursArr.map((item, index) => {
                  return (
                    <MenuItem
                      value={item.value}
                      key={`SurgicalTextInputs_unavailabilityHours_MenuItem_${index}`}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={3} item className="timePickersContainer">
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Numarul de minute
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={noOfMinutes}
                onChange={handleChangeNoOfMinutes}
                className={classes.selectEmpty}
              >
                {minutesArr.map((item, index) => {
                  return (
                    <MenuItem
                      value={item.value}
                      key={`SurgicalTextInputs_unavailabilityHours_MenuItem_${index}`}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={3} item className="timePickersContainer">
            <Button
              variant="contained"
              color="primary"
              className="button"
              onClick={handleClickAddAppointment}
              disabled={selectedInterval.interval === null}
            >
              <p className={`btn-text`}>PROGRAMEAZA</p>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SurgeryRoomIntervals
          intervalsData={intervalsData}
          setIntervalsData={setIntervalsData}
          setSelectedInterval={setSelectedInterval}
        />
      </Grid>

      <Grid item xs={12} className="createAppointmentTitle">
        <h3>Tabel de disponibilitate al blocului operator</h3>
      </Grid>
      <Grid item xs={12} md={12} className={`table__container`}>
        <CustomizedTables
          surgeryRooms={surgeryRooms}
          data={data}
          operatingHours={operatingHours}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid container className={`availability__table__container`}>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <h3>Tabel de disponibilitate al blocului operator</h3>
      </Grid>
      <Grid item xs={12} md={12} className={`datePicker__container`}>
        <TableDatePicker onChange={setSelectedDate} />
      </Grid>
      <Grid item xs={12} md={12} className={`table__container`}>
        <CustomizedTables
          surgeryRooms={surgeryRooms}
          data={data}
          operatingHours={operatingHours}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const hoursArr = [
  {
    value: 0,
    label: "0h",
  },
  {
    value: 1,
    label: "1h",
  },
  {
    value: 2,
    label: "2h",
  },

  {
    value: 3,
    label: "3h",
  },
  {
    value: 4,
    label: "4h",
  },
  {
    value: 5,
    label: "5h",
  },
  {
    value: 6,
    label: "6h",
  },
  {
    value: 7,
    label: "7h",
  },

  {
    value: 8,
    label: "8h",
  },
  {
    value: 9,
    label: "9h",
  },
  {
    value: 10,
    label: "10h",
  },
  {
    value: 11,
    label: "11h",
  },
  {
    value: 12,
    label: "12h",
  },
];

const minutesArr = [
  {
    value: 0,
    label: "0 min",
  },
  {
    value: 10,
    label: "10 min",
  },
  {
    value: 20,
    label: "20 min",
  },
  {
    value: 30,
    label: "30 min",
  },
  {
    value: 40,
    label: "40 min",
  },
  {
    value: 50,
    label: "50 min",
  },
];

export default AvailabilityTable;
