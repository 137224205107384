import React from "react";
import { aparatTypes } from "../AparatTypes/aparatTypes";
import Chart from "react-apexcharts";

const AllMatrix = (props) => {
  const { accumulators, filteredAcc } = props;

  let accRef = filteredAcc || accumulators;

  const chartCategories = (type) => {
    return (
      accRef[type] &&
      accRef[type].map((item) => {
        return (
          item.date &&
          item.date.length &&
          `${item.date.slice(0, item.date.length - 7)}`
        );
      })
    );
  };

  let allDates = [];

  // eslint-disable-next-line
  aparatTypes.map((item) => {
    if (accRef[item].length) {
      allDates.push(chartCategories(item));
    }
  });

  const timeAxis = {
    chart: {
      id: `basic-bar-all`,
    },
    xaxis: {
      categories: allDates.flat(Infinity),
    },
    fill: {
      opacity: 1,
    },
  };

  const mimi = (item) => item.value;

  const chartComposed1Data = [
    {
      name: "Respiratie",
      data: accRef["VITAL RESP"].map(mimi),
    },
    {
      name: "Puls",
      data: accRef["VITAL HR"].map(mimi),
    },
    {
      name: "Temperatura Rect",
      data: accRef["VITAL TRECT"].map(mimi),
    },
    {
      name: "Temperatura Piele",
      data: accRef["VITAL TSKIN"].map(mimi),
    },
    // {
    //   name: "Lichide",
    //   data: accRef["lichide"].map(item => item.valoare)
    // },
    // {
    //   name: "Diureza",
    //   data: accRef["diureza"].map(item => item.valoare)
    // },
    // {
    //   name: "Scaune",
    //   data: accRef["scaune"].map(item => item.valoare)
    // },
    // {
    //   name: "Dieta",
    //   data: accRef["dieta"].map(item => item.valoare)
    // },
    {
      name: "Saturatie Oxigen",
      data: accRef["VITAL SpO2"].map(mimi),
    },
    {
      name: "Tensiune Arteriala Sistolica",
      data: accRef["VITAL ART(S)"].map(mimi),
    },
    {
      name: "Tensiune Arteriala Diastolica",
      data: accRef["VITAL ART(D)"].map(mimi),
    },
  ];

  return (
    <>
      <div>
        <h2>Toate valorile</h2>
        <Chart options={timeAxis} series={chartComposed1Data} />
      </div>
    </>
  );
};

export default AllMatrix;
