import React, { createContext, useEffect, useReducer } from "react";
import { usePrevious } from "../hooks";
import { ACTIONS, rInitState } from "./Ati.initialState.context";
import reducer, { createReducer } from "./Ati.reducer";
import callSaveEndpoint from "./callSaveEndpoint.helper";

const AtiContext = createContext();

const AtiProvider = ({ children }) => {
  const initialAtiState = {
    firstAccordion: { values: {} },
    editForm: { values: null },
  };
  const [state, dispatchCreate] = useReducer(createReducer, initialAtiState);

  const [rState, dispatch] = useReducer(reducer, rInitState);

  const setEditFormValues = (payload) => {};

  const previousState = usePrevious(rState);

  useEffect(() => {
    const ctxKeys = Object.keys(rState).map((item) => item);
    // eslint-disable-next-line
    const alteredAccordions = ctxKeys.map((item, index) => {
      if (
        item !== "blockwindow" &&
        previousState &&
        previousState[item] &&
        previousState[item].values
      )
        return {
          accordion: item,
          altered:
            JSON.stringify(rState[item].values) !==
            JSON.stringify(previousState[item].values),
        };
    });
    // eslint-disable-next-line
    alteredAccordions.map((item, index) => {
      rState.generated === false &&
        item &&
        !!item.altered &&
        callSaveEndpoint(item.accordion, rState);
    });
    // eslint-disable-next-line
  }, [rState]);

  const defaultContext = {
    state,
    setEditFormValues,
    rState,
    dispatch,
    ACTIONS,
    dispatchCreate,
  };

  return (
    <AtiContext.Provider value={defaultContext}>{children}</AtiContext.Provider>
  );
};

export { AtiProvider, AtiContext };
