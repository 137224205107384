import React from "react";
import { Grid } from "@material-ui/core";

const PVCButton = props => {
    const { vitals } = props;
    return (
        <Grid container className="widgetContainer">
            <Grid item xs={4} md={4}>
                <div className="widgetText">PVC</div>
            </Grid>
            <Grid item xs={8} md={8}>
                <div className="widgetValue">
                    {vitals && vitals.data && vitals.data["VITAL CVP(M)"] ? (
                        vitals && vitals.data && vitals.data["VITAL CVP(M)"]
                    ) : (
                        <div className="missingValue"> - </div>
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default PVCButton;