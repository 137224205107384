const acc2Hydrate = (ctx, ctxData) => {
  const {
    socialSecurityNr,
    admissionType,
    caregiverName,
    caregiverDegree,
    caregiverPhoneNumber,
    familyDoctor,
    needsAtTakeover,
    prognosisEstimated,
    referralDiagnostic,
    admissionDiagnostic,
    threeDayDiagnostic,
    mainDischargeDiagnostic,
    secondDischargeDiagnostic,
    signMainPhysician,
    intraDischargeState,
    intraDischargeType,
    intraDeath,
    intraDeathDate,
    intraDeathHour,
    ifCaseDeceased,
    conditions,
    behavior,
    heredoColateral,
    personal,
    medication,
    reasonForHospitalization,
    patientExpectation,
    diseaseHistory,
    teguments,
    adiposeConnectiveTissue,
    nodalSystem,
    muscularSystem,
    osteoSystem,
    respiratorySystem,
    cardioVascularSystem,
    digestiveSystem,
    nutritionState,
    uroGenitalSystem,
    nervousAndTactilSystem,
    stareConstienta,
    selectPacientConfuz,
    scorMmse
  } = ctxData;

  ctx.dispatch({
    type: ctx.ACTIONS.ACC2,
    payload: {
      socialSecurityNr,
      admissionType,
      caregiverName,
      caregiverDegree,
      caregiverPhoneNumber,
      familyDoctor,
      needsAtTakeover,
      prognosisEstimated,
      referralDiagnostic,
      admissionDiagnostic,
      threeDayDiagnostic,
      mainDischargeDiagnostic,
      secondDischargeDiagnostic,
      signMainPhysician,
      intraDischargeState,
      intraDischargeType,
      intraDeath,
      intraDeathDate,
      intraDeathHour,
      ifCaseDeceased,
      conditions,
      behavior,
      heredoColateral,
      personal,
      medication,
      reasonForHospitalization,
      patientExpectation,
      diseaseHistory,
      teguments,
      adiposeConnectiveTissue,
      nodalSystem,
      muscularSystem,
      osteoSystem,
      respiratorySystem,
      cardioVascularSystem,
      digestiveSystem,
      nutritionState,
      uroGenitalSystem,
      nervousAndTactilSystem,
      stareConstienta,
      selectPacientConfuz,
      scorMmse
    }
  });
};

export default acc2Hydrate;
