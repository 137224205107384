import React, { useState } from "react";
import QrIcon from "../../assets/images/qr/qr-code-white.svg";

const DraggableScanButton = (props) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleOnClick = () => {
    window.location = "/qrScanner";
  };

  const clickIt = () => {
    if (!isDragging) {
      handleOnClick();
    }
    setIsDragging(false);
  };

  return (
    <div onClick={clickIt}>
      <div className="boxMovingAround">
        <img src={QrIcon} draggable={false} alt={""} />
      </div>
    </div>
  );
};

export default DraggableScanButton;
